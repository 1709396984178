import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { validateTypedInGraveSite } from "../../functions/Validation";
import { getExhumationById, editExhumation } from "../../services/exhumation/ExhumationService";
import { getMainBooksOfBuried, getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getGraveyards, getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSites, getGraveSiteById, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard, addGraveSite } from "../../services/graveSite/GraveSiteService";
import ExhumationForm from "../../components/forms/exhumation/ExhumationForm";
import { getCitizenById, getCitizenByPersonalNumber, getCitizens } from "../../services/citizen/CitizenService";

class EditExhumation extends FormComponent {

  validationList = {
    exhumation: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
    mainBookOfBuried: [{ type: Validators.REQUIRED }],
    graveyard: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    getExhumationById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data
      }, () => {
        getMainBookOfBuriedById(this.state.data.mainBookOfBuriedId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              mainBookOfBuried: response.data
            },
          });
        });
        getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              graveyard: response.data
            },
          });
        });
        {
          this.state.data.graveSiteId &&
            getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  graveSite: response.data
                },
              });
            })
        }
        {
          this.state.data.costId &&
          getCitizenById(this.state.data.costId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                cost: {
                  id: response.data.id,
                  personalNumber: response.data.personalNumber,
                  nameSurnameCost: response.data.name + " " + response.data.surname,
                  addressCost: response.data.fullAddress,
                  municipalityCost: response.data.postalCodeImprovedZip,
                  telephoneCost: response.data.telephone,
                }
              }
            });
          })
        }
      });
    });
  }

  keyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
          variant: "error"
        });
        return;
      }

      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {
        if (!response.ok) {

          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
            variant: "error"
          });

          this.handleError(messageKey);

          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            graveSite: response.data,
            graveSiteCode: response.data.code,
            graveSiteId: response.data.id
          }
        });

        this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

      });
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id
    }
  }


  submit() {

    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editExhumation(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.exhumation.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.exhumationEdited, { variant: 'success' });
      this.props.onFinish(response.data.exhumation);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      mainBookOfBuriedId: this.state.data.mainBookOfBuried.id,
      graveyardId: this.state.data.graveyard.id,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      costId: this.state.data.cost ? this.state.data.cost.id : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "EXHUMATION_WITH_EXHUMATION_NUMBER_ALREADY_EXIST":
        this.setError("exhumation", strings.exhumation.messages.EXHUMATION_WITH_EXHUMATION_NUMBER_ALREADY_EXIST);
        break;
      case "EXHUMATION_EXIST":
        this.setError("exhumation", strings.exhumation.messages.EXHUMATION_EXIST);
        break;
      case "EXHUMATION_NOT_EXIST":
        this.setError("exhumation", strings.exhumation.messages.EXHUMATION_NOT_EXIST);
        break;
      default:

    }
  }

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if(autocompleteName === "cost" && values != null){
      getCitizenByPersonalNumber(values.personalNumber).then(response =>{
          this.setState({
            data: {
              ...this.state.data,
              cost: {
                id: response.data.id,
                personalNumber: response.data.personalNumber,
                nameSurnameCost: response.data.nameSurname,
                addressCost: response.data.address,
                municipalityCost: response.data.municipality,
                telephoneCost: response.data.telephone,
              }
            }  
          });
      });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name == "mainBookOfBuried" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "deceasedNameSurname,ASC",
        year: -1,
        month: -1,
        generate: false,
        graveyard: -1,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getMainBooksOfBuried(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              mainBooksOfBuried: response.data.entities,
            });
          });
      }
    } else if (e.target.name == "graveyard" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });

    } else if (e.target.name == "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
      let data = {
          searchParam: e.target.value,
          companyId: this.props.cid,
          sort: "id,ASC",
          userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
                return;
            }
            this.setState({
                costs: response.data.entities,
            }); 
          });
      }
    }
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.exhumation.editPageTitle}</h1>
        </div>

        <Paper className="paper">
          <ExhumationForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            mainBooksOfBuried={this.state.mainBooksOfBuried == undefined ? [] : this.state.mainBooksOfBuried}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            costs={this.state.costs == undefined ? [] : this.state.costs}
            flag={this.props.flag}
            typedInGraveSite={this.state.typedInGraveSite}
            keyPress={this.keyPress}
            keyPressBackspace={this.keyPressBackspace}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditExhumation)
  )
);
