import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import AddGraveyard from './AddGraveyard';
import { getGraveyards, getDeletedGraveyards, deleteGraveyard, restoreGraveyard } from '../../services/graveyard/GraveyardService';
import EditGraveyard from './EditGraveyard';
import ViewGraveyard from './ViewGraveyard';


class GraveyardList extends TablePage {

  tableDescription = [
    { key: 'code', label: strings.graveyardList.code, sort: 'code' },
    { key: 'name', label: strings.graveyardList.name, sort: 'name' },
    { key: 'place', label: strings.graveyardList.place, sort: 'place' },
    { key: 'dateCreated', label: strings.graveyardList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";
    this.state.showDeleted = false;

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getGraveyards(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
        getDeletedGraveyards(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Гробља"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'code', label: strings.graveyardList.code, sort: 'code' },
      { key: 'name', label: strings.graveyardList.name, sort: 'name' },
      { key: 'place', label: strings.graveyardList.place, sort: 'place' },
      { key: 'dateCreated', label: strings.graveyardList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.graveyardList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddGraveyard cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
  }

  renderEditContent() {
    return <EditGraveyard
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewGraveyard id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteGraveyard(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.graveyardDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreGraveyard(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(GraveyardList)));