import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getExhumationRequests(data) {
    return await request('/api/exhumationRequests/search', data);
}

export async function addExhumationRequest(data, cid) {
    return await request('/api/exhumationRequests/' + cid, data, HttpMethod.POST);
}

export function getExhumationRequestById(id, userId, companyId) {
    return  request("api/exhumationRequests/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editExhumationRequest(data) {
    return await request("api/exhumationRequests/" + data.id, data, HttpMethod.PUT);
}

export async function deleteExhumationRequest(id, uid) {
    return await request('/api/exhumationRequests/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function restoreExhumationRequest(data) {
    return await request("api/exhumationRequests/restore/" + data.id, data, HttpMethod.PUT);
}

export async function generateExhumationRequestPdf(data) {
    return await request('/api/exhumationRequests/exhumation-request/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function getDeletedExhumationRequests(data) {
    return await request('/api/exhumationRequests/search/deleted', data);
}