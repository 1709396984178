import React from "react";
import TablePage from "../../common/TablePage";
import strings from "../../localization";
import { deleteCremationStatement, getCremationStatements, getDeletedCremationStatements } from "../../services/cremationStatement/CremationStatementService";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import ViewCremationStatement from "./ViewCremationStatement";
import EditCremationStatement from "./EditCremationStatement";
import AddCremationStatement from "./AddCremationStatement";

class CremationStatementList extends TablePage {
    tableDescription = [
        { key: "fullName", label: strings.cremationStatementList.fullName, sort: "fullName" },
        { key: "jmbg", label: strings.cremationStatementList.jmbg, sort: "jmbg" },
        { key: "municipalityName", label: strings.cremationStatementList.municipality, sort: "municipalityName" },
        { key: "phoneNumber", label: strings.cremationStatementList.phoneNumber, sort: "phoneNumber" },
        { key: "country", label: strings.cremationStatementList.country, sort: "country" },
        { key: "referenceNumber", label: strings.cremationStatementList.referenceNumber, sort: "referenceNumber" },
        { key: "comments", label: strings.cremationStatementList.comments, sort: "comments" },
        { key: "dateCreated", label: strings.cremationStatementList.dateCreated, transform: "renderColumnDate", sort: "dateCreated" },
        { key: "dateUpdated", label: strings.cremationStatementList.dateUpdated, transform: "renderColumnDate", sort: "dateUpdated" },
    ];

    constructor(props) {
        super(props);

        this.state.sortBy = "dateCreated,DESC";
        this.state.showDeleted = false;

    }

    fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            companyId: this.state.companyId,
            sort: this.state.sortBy,
            userId: this.props.auth.user.id
        };

        {
            (this.state.companyId != undefined && !this.state.showDeleted) &&
                getCremationStatements(data).then(response => {
                    if (!response.ok) {
                        return;
                    }
                    this.setState({
                        tableData: response.data.entities,
                        total: response.data.total,
                    });
                });
        }

        // {
        //     (this.state.companyId != undefined && this.state.showDeleted) &&
        //         getDeletedCremationStatements(data).then(response => {
        //             if (!response.ok) {
        //                 return;
        //             }
        //             this.setState({
        //                 tableData: response.data.entities,
        //                 total: response.data.total,
        //             });
        //         });
        // }
    }


    handleDeletedChange() {
        this.setState({
            showDeleted: !this.state.showDeleted
        }, () => { this.fetchData(); })

    }

    usePermissions() {
        let data = {
            userId: this.props.auth.user.id,
            category: "Изјаве за кремацију"
        }
        this.getPermissionsS(data);
        this.showAdd(this.props.showAdd);
        this.showRemove(this.props.remove);
        this.showEdit(this.props.showEdit);
        this.showView(this.props.showView);
        this.removedItemsShow(this.props.removedItemsShow);

    }

    componentDidMount() {
        this.usePermissions()
        this.setState({
            companyId: this.props.auth.user.company.id
        }, () => { this.fetchData(); })
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: "fullName", label: strings.cremationStatementList.fullName, sort: "fullName" },
            { key: "jmbg", label: strings.cremationStatementList.jmbg, sort: "jmbg" },
            { key: "municipalityName", label: strings.cremationStatementList.municipality, sort: "municipalityName" },
            { key: "phoneNumber", label: strings.cremationStatementList.phoneNumber, sort: "phoneNumber" },
            { key: "country", label: strings.cremationStatementList.country, sort: "country" },
            { key: "referenceNumber", label: strings.cremationStatementList.referenceNumber, sort: "referenceNumber" },
            { key: "dateCreated", label: strings.cremationStatementList.dateCreated, transform: "renderColumnDate", sort: "dateCreated" },
            { key: "dateUpdated", label: strings.cremationStatementList.dateUpdated, transform: "renderColumnDate", sort: "dateUpdated" },
        ];
    }

    getPageHeader() {
        return <h1>{strings.cremationStatementList.pageTitle}</h1>;
    }

    renderAddContent() {
        return <AddCremationStatement isDisabled={false} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
    }

    renderEditContent() {
        return <EditCremationStatement id={this.state.selectedItem.id} isDisabled={false} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
    }

    renderViewContent() {
        return <ViewCremationStatement id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
    }

    delete(item) {

        this.setState({
            lockTable: true
        });


        deleteCremationStatement(item.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {

            if (response && !response.ok) {
                this.onFinish(null);
                return;
            }

            this.props.enqueueSnackbar(strings.companyList.famousCitizenDeleted, { variant: 'success' });

            this.onFinish(item);
            this.cancelDelete();

            this.setState({
                lockTable: false
            });
        });

    }

    transformRequest(data) {
    }
    restore(item) {
    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CremationStatementList)));