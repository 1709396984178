import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import GraveSiteForm from "../../components/forms/graveSite/GraveSiteForm";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";

class ViewGraveSite extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {

    getGraveSiteById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data,

      }, () => {
        getGraveyardById(this.state.data.belongingGraveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              belongingGraveyard: response.data,
              renewal: this.state.data.renewal.toString(),
            },
          });
        });
      });
    });
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.graveSite.viewPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <GraveSiteForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            belongingGraveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            onAutocompleteChange={this.onAutocompleteChange}
            isDisabled={this.props.isDisabled}
            flag={this.props.flag}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewGraveSite)
  )
);
