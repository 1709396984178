import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getMainBooksOfCremated, deleteMainBookOfCremated, getDeletedMainBooksOfCremated, restoreMainBookOfCremated } from '../../services/mainBookOfCremated/MainBookOfCrematedService';
import AddMainBookOfCremated from './AddMainBookOfCremated';
import EditMainBookOfCremated from './EditMainBookOfCremated';
import ViewMainBookOfCremated from './ViewMainBookOfCremated';
import { getStates } from '../../services/state/StateService';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { validatePersonalNumber } from '../../functions/Validation';
import { addCitizen } from '../../services/citizen/CitizenService';
import PageState from '../../constants/PageState';
import { Drawer, TextField, FormControlLabel, Switch } from "@material-ui/core";
import update from 'immutability-helper';
import CitizenForm from '../../components/forms/citizen/CitizenForm';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DrawerWrapper from '../../common/DrawerWrapper';
import { getPostalAddressCodesWithParams } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';


class MainBookOfCrematedList extends TablePage {

  tableDescription = [
    { key: 'mainBookOfCremated', label: strings.mainBookOfCrematedList.deceasedNameSurname, sort: 'mainBookOfCremated'},
    { key: 'deceasedNameSurname', label: strings.mainBookOfCrematedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'submitterName', label: strings.mainBookOfCrematedList.submitterName, sort: 'submitterName' },
    { key: 'submitterSurname', label: strings.mainBookOfCrematedList.submitterSurname, sort: 'submitterSurname' },
    { key: 'userGraveSiteName', label: strings.mainBookOfCrematedList.userGraveSiteName, sort: 'userGraveSiteName' },
    { key: 'userGraveSiteSurname', label: strings.mainBookOfCrematedList.userGraveSiteSurname, sort: 'userGraveSiteSurname' },
    { key: 'urnName', label: strings.mainBookOfCrematedList.urnName, sort: 'urnName' },
    { key: 'dateTimeOfCremation', label: strings.mainBookOfCrematedList.dateTimeOfCremation, transform: 'renderColumnDate', sort: 'dateTimeOfCremation' },
    { key: 'dateTimeUrnTakeover', label: strings.mainBookOfCrematedList.dateTimeUrnTakeover, transform: 'renderColumnDate', sort: 'dateTimeUrnTakeover' },
    { key: 'dateCreated', label: strings.mainBookOfCrematedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "dateCreated,DESC";
    this.state.showDeleted = false;

    this.state = {
      ...this.state,
      checked: false,
      showCitizen: false,
      errors: {},
    }

    this.changeDataCitizen = this.changeDataCitizen.bind(this);
    this.renderAddCitizen = this.renderAddCitizen.bind(this);
    this.changeStateShowCitizen = this.changeStateShowCitizen.bind(this);
    this.submit = this.submit.bind(this);
    this.changeTypedInPersonalNumber = this.changeTypedInPersonalNumber.bind(this);
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getMainBooksOfCremated(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedMainBooksOfCremated(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Главна књига кремираних"
    }
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'mainBookOfCremated', label: strings.mainBookOfCrematedList.mainBookOfCremated, sort: 'mainBookOfCremated'},
      { key: 'deceasedNameSurname', label: strings.mainBookOfCrematedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'submitterName', label: strings.mainBookOfCrematedList.submitterName, sort: 'submitterName' },
      { key: 'submitterSurname', label: strings.mainBookOfCrematedList.submitterSurname, sort: 'submitterSurname' },
      { key: 'userGraveSiteName', label: strings.mainBookOfCrematedList.userGraveSiteName, sort: 'userGraveSiteName' },
      { key: 'userGraveSiteSurname', label: strings.mainBookOfCrematedList.userGraveSiteSurname, sort: 'userGraveSiteSurname' },
      { key: 'urnName', label: strings.mainBookOfCrematedList.urnName, sort: 'urnName' },
      { key: 'dateTimeOfCremation', label: strings.mainBookOfCrematedList.dateTimeOfCremation, transform: 'renderColumnDate', sort: 'dateTimeOfCremation' },
      { key: 'dateTimeUrnTakeover', label: strings.mainBookOfCrematedList.dateTimeUrnTakeover, transform: 'renderColumnDate', sort: 'dateTimeUrnTakeover' },
      { key: 'dateCreated', label: strings.mainBookOfCrematedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.mainBookOfCrematedList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddMainBookOfCremated 
      cid={this.state.companyId} 
      uemail={this.props.auth.user.email} 
      onCancel={this.onCancel} 
      onFinish={this.onFinish} 
      flag={"add"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber} 
      auth={this.props.auth}
    />
  }

  renderEditContent() {
    return <EditMainBookOfCremated
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber} 
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewMainBookOfCremated id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag={"view"} auth={this.props.auth} />
  }

  renderCitizenForm() {
    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.citizen.pageTitle}</h1>
      </div>

      <Paper className='paper'>
        <CitizenForm
          onChange={this.changeDataCitizen}
          onSubmit={this.submit}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowCitizen}
          handleChange={this.handleChange}
          onAutocompleteChange={this.onAutocompleteChange}
          handleTextInputChange={this.handleTextInputChange}
          onChangePersonalNumberField={this.onChangePersonalNumberField}
          flag={"add"}
          states={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
          citiesAndZips={this.state.zip == undefined ? [] : this.state.zip}
        />
      </Paper>

    </Grid>
  }

  changeStateShowCitizen() {
    this.setState({
      ...this.state,
      showCitizen: false,
      data: {
        personalNumber: "",
        name: "",
        postalCode: "",
        street: "",
        streetNumber: "",
        surname: "",
        telephone: "",
      },
      errors: {},
    });
  }

  changeDataCitizen(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  getPucs () {
    let d = {
      street: this.state.data.street ? this.state.data.street : "", 
      companyId: this.state.companyId,
      sort: "pac,ASC",
      userId: 0,
      stateId: this.state.data.state ? this.state.data.state.id : 0,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : 0,
      streetNumber: this.state.data.streetNumber ? this.state.data.streetNumber : "",
    }   
      d.companyId != undefined &&
        getPostalAddressCodesWithParams(d).then(response =>{
      
        if(!response.ok){
          return;
        }
         this.setState({
          data: {
            ...this.state.data,
            pac: response.data.pac,

          }
          
        })
      });
  }

  handleTextInputChange = (e) => {
    if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "name,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "postalCodeImproved" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: 'city',
        userId: 0
      };

      {
        data.companyId != undefined &&
        getPostalCodesImproved(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            zip: response.data.entities,
          }, () => {
            this.getPucs()
          });
        });
      }
    }
    else if (e.target.name === "street") {
      this.setState({
        data: {
          ...this.state.data,
          street: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
    else if (e.target.name === "streetNumber") {
      this.setState({
        data: {
          ...this.state.data,
          streetNumber: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    }, () => {
      this.getPucs()
    });
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)

    if (!validPersonalNumber) {
      this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
    }
    else {
      this.unsetError("personalNumber");
    }
    this.changeDataCitizen(event);
  }

  changeTypedInPersonalNumber(someValue) {
    this.setState({
      data: {
        personalNumber: someValue,
      },
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked }, () => {
      let validPersonalNumber = validatePersonalNumber(this.state.data.personalNumber)

      if (!validPersonalNumber) {
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
      }
      else {
        this.unsetError("personalNumber");
      }
    })
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();
    
    addCitizen(this.transformRequestCitizen(this.state.data), this.state.companyId).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
        this.props.enqueueSnackbar(strings.citizen.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.citizenAdded, { variant: 'success' });

      this.hideDrawerLoader();
      this.changeStateShowCitizen();
    });
  }

  transformRequestCitizen(data) {
    
    return {
      ...data,
      deceased: this.state.checked,
      userEmail: this.props.auth.user.email,
      stateCode: this.state.data.state ? this.state.data.state.id : null ,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : null
    }
  }

  renderAddCitizen() {
    this.setState({
      showCitizen: true,
    });
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteMainBookOfCremated(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.mainBookOfCrematedDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreMainBookOfCremated(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }

  render() {
    
    return (
      <Grid id='table-page'>

        {
          this.state.showCitizen &&
          <Drawer id='drawer' anchor='left' open={this.showDrawer()} onClose={() => this.changeStateShowCitizen()} ModalProps={{disableBackdropClick: true,}} >
            <DrawerWrapper onBack={() => this.changeStateShowCitizen()}>
              {this.renderCitizenForm()}
            </DrawerWrapper>
          </Drawer>
        } 

        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}
          <div className='show-deleted'>
            
          { this.state.removedItemsShow &&
            <FormControlLabel
            control = {<Switch
              checked={this.state.showDeleted}
              onChange={this.handleDeletedChange}
              inputProps={{ 'aria-label': 'controlled' }}
              />}
              labelPlacement="start"
              label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
          />
          }
            </div>
          
          <div className='filter-controls'>

    
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
                this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainBookOfCrematedList)));