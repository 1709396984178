import React from 'react';
import strings from '../../localization';
import TablePage from '../../common/TablePage';
import { getCrates, getDeletedCrates, deleteCrate, restoreCrate } from '../../services/crate/CrateService';
import { withSnackbar } from 'notistack';
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import AddCrate from './AddCrate';
import EditCrate from './EditCrate';
import ViewCrate from './ViewCrate';

class CrateList extends TablePage {
  tableDescription = [
    { key: 'name', label: strings.crateList.name, sort: 'name' },
    { key: 'dateCreated', label: strings.crateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' }
  ];

  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";
    this.state.showDeleted = false;
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    }

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getCrates(data).then(response => {
          if (!response.ok) {
            return;
          }

          this.setState({
            tableData: response.data.entities,
            total: response.data.total
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedCrates(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Списак сандука"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'name', label: strings.crateList.name, sort: 'name' },
      { key: 'dateCreated', label: strings.crateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' }
    ];
  }

  getPageHeader() {
    return <h1>{strings.crateList.pageTitle}</h1>
  }

  renderAddContent() {
    return <AddCrate
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
    />
  }
  renderEditContent() {
    return <EditCrate
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      isDisabled={false}
      id={this.state.selectedItem.id}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      auth={this.props.auth}
    />
  }
  renderViewContent() {
    return <ViewCrate
      id={this.state.selectedItem.id}
      cid={this.state.selectedItem.companyId}
      isDisabled={true}
      auth={this.props.auth}

    />
  }

  delete(item) {
    this.setState({
      lockTable: true
    });

    deleteCrate(item.id, this.props.auth.user.id).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.crateList.deleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });

  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreCrate(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CrateList))); 