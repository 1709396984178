import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllTypefaces() {
  return await request("api/typeface/all");
}

export async function getTypefaces(data) {
  return await request('api/typeface/search', data);
}

export function getTypefaceById(id, userId, companyId) {
  return request("api/typeface/" + id, {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function addTypeface(data, cid) {
  return await request('/api/typeface/' + cid, data, HttpMethod.POST);
}

export async function editTypeface(data) {
  return await request("api/typeface/" + data.id, data, HttpMethod.PUT);
}

export async function deleteTypeface(id, uid) {
  return await request('/api/typeface/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedTypefaces(data){
  return await request('/api/typeface/deleted',data)
}

export async function restoreTypeface(data) {
  return await request("api/typeface/restore/" + data.id, data, HttpMethod.PUT);
}
