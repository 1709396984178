import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const CremationStatementForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    municipalities,
    states,
    onAutocompleteChange,
    handleTextInputChange,
}) => {

    return (

        <form id='user-form'>
            <TextField
                label={strings.cremationStatementList.fullName + "*"}
                error={hasError(errors, 'fullName')}
                helperText={getError(errors, 'fullName')}
                fullWidth
                autoFocus
                name='fullName'
                onChange={onChange}
                margin="normal"
                defaultValue={data.fullName}
                value={data.fullName}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.cremationStatementList.jmbg + "*"}
                error={hasError(errors, 'jmbg')}
                helperText={getError(errors, 'jmbg')}
                fullWidth
                autoFocus
                name='jmbg'
                onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    onChange({ target: { name: 'jmbg', value } });
                }}
                margin="normal"
                defaultValue={data.jmbg}
                value={data.jmbg}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.cremationStatementList.phoneNumber + "*"}
                error={hasError(errors, 'phoneNumber')}
                helperText={getError(errors, 'phoneNumber')}
                fullWidth
                name='phoneNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.phoneNumber}
                value={data.phoneNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <Autocomplete
                id={"municipality"}
                name={"municipality"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationStatementList.municipality}
                        margin="normal"
                        fullWidth
                        name={"municipality"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipality ? municipalities.find((m) => m.id === data.municipality?.id) || null : null}
            />
            <TextField
                label={strings.cremationStatementList.referenceNumber + "*"}
                error={hasError(errors, 'referenceNumber')}
                helperText={getError(errors, 'referenceNumber')}
                fullWidth
                name='referenceNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.referenceNumber}
                value={data.referenceNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <Autocomplete
                id={"state"}
                name={"state"}
                options={states}
                getOptionLabel={option => `${option.name}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationStatementList.country}
                        margin="normal"
                        fullWidth
                        name={"state"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.state ? states.find((m) => m.id === data.state.id) || null : null}
            />
            <TextField
                label={strings.cremationStatementList.comments + "*"}
                error={hasError(errors, 'comments')}
                helperText={getError(errors, 'comments')}
                fullWidth
                name='comments'
                onChange={onChange}
                margin="normal"
                defaultValue={data.comments}
                value={data.comments}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.famousCitizen.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.famousCitizen.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};

export default CremationStatementForm;