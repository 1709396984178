import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import _default from "@material-ui/pickers/views/Calendar/Calendar";
import { dateToString2 } from "../../util/DateUtil";
import ExhumationRequestForm from "../../components/forms/exhumationRequest/ExhumationRequestForm";
import { editExhumationRequest, getExhumationRequestById } from "../../services/exhumationRequest/ExhumationRequestService";
import { getExhumationGraveSiteTypes } from "../../util/ReviewsUtil";

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class EditExhumationRequest extends FormComponent {

    validationList = {
        deceasedNameSurname: [{ type: Validators.REQUIRED }],
      };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      graveSiteType: [],
      graveSiteTypes: [],
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getExhumationRequestById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data,
        graveSiteTypes: getExhumationGraveSiteTypes(),
      }, () => {

        this.setState({
          graveSiteType: this.state.graveSiteTypes.find(type => type.value === this.state.data.graveSiteType),
        });
      });
    });
  }

  submit() {
    this.setState(
      {
        disabled: true
      }
    )
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState(
        {
          disabled: false
        }
      )
      return;
    }

    this.showDrawerLoader();

    editExhumationRequest(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.exhumationRequest.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.setState({
        disabled: false
      })
      this.props.enqueueSnackbar(strings.addCompany.exhumationRequestEdited, { variant: 'success' });
      this.props.onFinish(response.data.exhumationRequest);
      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      
      graveSiteType: this.state.graveSiteType ? this.state.graveSiteType.value : null,
      dateTimeOfExhumationString: this.state.data.dateTimeOfExhumation ? dateToString2(this.state.data.dateTimeOfExhumation) : "",
    }
  }  

  handleError(message) {
    switch (message) {
      case "EXHUMATION_REQUEST_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.exhumationRequest.messages.EXHUMATION_REQUEST_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
    }
  };

  handleGraveSiteTypeCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            graveSiteType: { id, value: value, name: type}
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
            <h1>{strings.exhumationRequest.editPageTitle}</h1>
        </div>

        <Paper className='paper'>
            <ExhumationRequestForm
                onChange={this.changeData}
                onSubmit={this.submit}
                data={this.state.data}
                errors={this.state.errors}
                onCancel={this.props.onCancel}
                handleChange={this.handleChange}
                flag={this.props.flag}
                date={(new Date().getFullYear())}
                disabled={this.state.disabled}
                graveSiteType={this.state.graveSiteType}
                graveSiteTypes={this.state.graveSiteTypes}
                handleGraveSiteTypeCheckboxChange={this.handleGraveSiteTypeCheckboxChange}
                isDisabled={this.props.isDisabled}
            />
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditExhumationRequest)
  )
);
