import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllPrincipals() {
    return await request("api/principal/all");
}

export async function getPrincipals(data) {
    return await request('/api/principal/search', data);
}

export async function addPrincipal(data, cid) {
    return await request('/api/principal/' + cid, data, HttpMethod.POST);
}

export function getPrincipalById(id, userId, companyId) {
    return  request("api/principal/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editPrincipal(data) {
    return await request("api/principal/" + data.id, data, HttpMethod.PUT);
}

export async function deletePrincipal(id, uid) {
    return await request('/api/principal/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedPrincipals(data){
    return await request('/api/principal/deleted', data)
}

export async function restorePrincipals(data) {
    return await request("api/principal/restore/" + data.id, data, HttpMethod.PUT);
}