import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup, TextareaAutosize } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { validatePersonalNumber, getDateFromIdentificationNumber } from "../../../functions/Validation";
import { stringToDate, stringToDate2 } from '../../../util/DateUtil';

const DeceasedMainBookOfBuriedForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  transporters,
  municipalities,
  states,
  occupations,
  causesOfDeath,
  doctors,
  famousCitizens,
  flag,
  dateFlag,
  dateOfBirthWhenValidIDNumber,
  onChangePersonalNumberField,
  date,
  defaultFamousCitizen,
  disabled,
}) => {
  return (

    <form id='user-form'>
      <TextField
        label={strings.mainBookOfBuried.form.mainBookOfBuries + "*"}
        error={hasError(errors, 'mainBookOfBuries')}
        helperText={getError(errors, 'mainBookOfBuries')}
        fullWidth
        autoFocus
        name='mainBookOfBuries'
        onChange={onChange}
        margin="normal"
        defaultValue={date}
        value={data.mainBookOfBuries}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.mainBookOfBuried.form.identificationNumber}
        error={hasError(errors, 'identificationNumber')}
        helperText={getError(errors, 'identificationNumber')}
        fullWidth
        name='identificationNumber'
        onChange={onChangePersonalNumberField}
        margin="normal"
        defaultValue={data.identificationNumber}
        value={data.identificationNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.mainBookOfBuried.form.deceasedNameSurname + "*"}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.mainBookOfBuried.form.gender} </FormLabel>
        <RadioGroup name="gender" value={(data.gender == true || data.gender == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.mainBookOfBuried.form.male}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.mainBookOfBuried.form.female}</span>} />
        </RadioGroup>
      </FormControl>
      <TextField
        label={strings.mainBookOfBuried.form.fathersName}
        error={hasError(errors, 'fathersName')}
        helperText={getError(errors, 'fathersName')}
        fullWidth
        name='fathersName'
        onChange={onChange}
        margin="normal"
        defaultValue={data.fathersName}
        value={data.fathersName}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {((data.state && flag == "edit") || (flag == "add") || (data.state && flag == "view")) &&
        <Autocomplete
          id={"state"}
          name={"state"}
          options={states}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.stateOfBirth}
              margin="normal"
              fullWidth
              name={"state"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.state}
        />
      }
      {((!data.state && flag == "edit") || (!data.state && flag == "view")) &&
        <Autocomplete
          id={"state"}
          name={"state"}
          options={states}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.stateOfBirth}
              margin="normal"
              fullWidth
              name={"state"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.state}
        />
      }

      {((data.municipality && flag == "edit") || (flag == "add") || (data.municipality && flag == "view")) &&
        <Autocomplete
          id={"municipality"}
          name={"municipality"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.municipality}
              margin="normal"
              fullWidth
              name={"municipality"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipality}
        />
      }
      {((!data.municipality && flag == "edit") || (!data.municipality && flag == "view")) &&
        <Autocomplete
          id={"municipality"}
          name={"municipality"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.municipality}
              margin="normal"
              fullWidth
              name={"municipality"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipality}
        />
      }
      <TextField
        label={strings.mainBookOfBuried.form.address}
        error={hasError(errors, 'address')}
        helperText={getError(errors, 'address')}
        fullWidth
        name='address'
        onChange={onChange}
        margin="normal"
        defaultValue={data.address}
        value={data.address}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {((data.occupation && flag == "edit") || (flag == "add") || (data.occupation && flag == "view")) &&
        <Autocomplete
          id={"occupation"}
          name={"occupation"}
          options={occupations}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.occupation}
              margin="normal"
              fullWidth
              name={"occupation"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.occupation}
        />
      }
      {((!data.occupation && flag == "edit") || (!data.occupation && flag == "view")) &&
        <Autocomplete
          id={"occupation"}
          name={"occupation"}
          options={occupations}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.occupation}
              margin="normal"
              fullWidth
              name={"occupation"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.occupation}
        />
      }
      {(data.dateOfBirthString != undefined && data.dateOfBirthString != null && data.dateOfBirthString != "") &&
        <TextField
          label={strings.mainBookOfBuried.form.dateOfBirthString}
          fullWidth
          name='dateOfBirthString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateOfBirthString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }

      {dateFlag == "validIDNumber" && flag != "add" &&
        <div style={{ width: "200px", display: "inline-block" }}>
          < DatePickerControl
            label={strings.mainBookOfBuried.form.dateOfBirth}
            error={getError(errors, 'dateOfBirth')}
            hasError={hasError(errors, 'dateOfBirth')}
            helperText={getError(errors, 'dateOfBirth')}
            placeholder={strings.mainBookOfBuried.form.dateOfBirth}
            name='dateOfBirth'
            onChange={onChange}
            date={new Date(dateOfBirthWhenValidIDNumber[2] + "-" + dateOfBirthWhenValidIDNumber[1] + "-" + dateOfBirthWhenValidIDNumber[0])}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            disabled={isDisabled}
          />
        </div>
      }

      {dateFlag == "invalidIDNumber" && flag != "add" &&
        <div style={{ width: "200px", display: "inline-block" }}>
          < DatePickerControl
            label={strings.mainBookOfBuried.form.dateOfBirth}
            error={getError(errors, 'dateOfBirth')}
            hasError={hasError(errors, 'dateOfBirth')}
            helperText={getError(errors, 'dateOfBirth')}
            placeholder={strings.mainBookOfBuried.form.dateOfBirth}
            name='dateOfBirth'
            onChange={onChange}
            date={data.dateOfBirth ? new Date(data.dateOfBirth) : data.dateOfBirth}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            disabled={isDisabled}
          />
        </div>
      }

      {flag == "add" &&
        <div class="yearOfBirth">
          <label class="yearOfBirthLabelAdd"> Година рођења</label>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ style: { fontSize: 15, paddingLeft: 7 }, disableUnderline: true }}
            margin={"normal"}
            disabled={isDisabled}
            name='yearOfBirth'
            value={data.yearOfBirth}
            error={hasError(errors, 'yearOfBirth')}
            helperText={getError(errors, 'yearOfBirth')}
            onChange={onChange}
            class={"yearOfBirthTextFieldAdd"}
          />
        </div>
      }

      {flag == "view" &&
        <div class="yearOfBirth">
          <label class="yearOfBirthLabelViewAndEdit">Година рођења</label>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ style: { fontSize: 13, paddingLeft: 7, color: '#545454' }, disableUnderline: true }}
            margin={"normal"}
            disabled={isDisabled}
            name='yearOfBirth'
            value={data.yearOfBirth}
            error={hasError(errors, 'yearOfBirth')}
            helperText={getError(errors, 'yearOfBirth')}
            onChange={onChange}
            class={"yearOfBirthTextFieldView"}
          />
        </div>
      }

      {flag == "edit" &&
        <div class="yearOfBirth">
          <label class="yearOfBirthLabelViewAndEdit">Година рођења</label>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ style: { fontSize: 13, paddingLeft: 7 }, disableUnderline: true }}
            margin={"normal"}
            disabled={isDisabled}
            name='yearOfBirth'
            value={data.yearOfBirth}
            error={hasError(errors, 'yearOfBirth')}
            helperText={getError(errors, 'yearOfBirth')}
            onChange={onChange}
            class={"yearOfBirthTextFieldEdit"}
          />
        </div>
      }

      {((data.stateOfBirth && flag == "view")) &&
        <TextField
          value={data.stateOfBirth}
          variant="standard"
          label={strings.mainBookOfBuried.form.stateOfBirth}
          margin="normal"
          fullWidth
          name={"stateOfBirth"}
          onChange={handleTextInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      }
      {(!data.stateOfBirth && flag == "edit") || (!data.stateOfBirth && (flag == "view")) &&
        <Autocomplete
          id={"stateOfBirth"}
          name={"stateOfBirth"}
          options={states}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.stateOfBirth}
              margin="normal"
              fullWidth
              name={"stateOfBirth"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stateOfBirth}
        />
      }

      {((data.municipalityOfBirth && flag == "edit") || (flag == "add") || data.municipalityOfBirth && (flag == "view")) &&
        <Autocomplete
          id={"municipalityOfBirth"}
          name={"municipalityOfBirth"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.municipalityOfBirth}
              margin="normal"
              fullWidth
              name={"municipalityOfBirth"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfBirth}
        />
      }
      {((!data.municipalityOfBirth && flag == "edit") || (!data.municipalityOfBirth && (flag == "view"))) &&
        <Autocomplete
          id={"municipalityOfBirth"}
          name={"municipalityOfBirth"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.municipalityOfBirth}
              margin="normal"
              fullWidth
              name={"municipalityOfBirth"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfBirth}
        />
      }
      {(data.dateTimeOfDeathString != undefined && data.dateTimeOfDeathString != null && data.dateTimeOfDeathString != "") &&
        <TextField
          label={strings.mainBookOfBuried.form.dateTimeOfDeathString}
          fullWidth
          name='dateTimeOfDeathString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateTimeOfDeathString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.mainBookOfBuried.form.dateTimeOfDeath}
        error={getError(errors, 'dateTimeOfDeath')}
        hasError={hasError(errors, 'dateTimeOfDeath')}
        helperText={getError(errors, 'dateTimeOfDeath')}
        placeholder={strings.mainBookOfBuried.form.dateTimeOfDeath}
        name='dateTimeOfDeath'
        onChange={onChange}
        date={data.dateTimeOfDeath ? new Date(data.dateTimeOfDeath) : data.dateTimeOfDeath}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />
      {((data.causeOfDeath && flag == "edit") || (flag == "add") || (data.causeOfDeath && flag == "view")) &&
        <Autocomplete
          id={"causeOfDeath"}
          name={"causeOfDeath"}
          options={causesOfDeath}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.causeOfDeath}
              margin="normal"
              fullWidth
              name={"causeOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.causeOfDeath}
        />
      }
      {((!data.causeOfDeath && flag == "edit") || (!data.causeOfDeath && flag == "view")) &&
        <Autocomplete
          id={"causeOfDeath"}
          name={"causeOfDeath"}
          options={causesOfDeath}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.causeOfDeath}
              margin="normal"
              fullWidth
              name={"causeOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.causeOfDeath}
        />
      }
      {((data.doctor && flag == "edit") || (flag == "add") || (data.doctor && flag == "view")) &&
        <Autocomplete
          id={"doctor"}
          name={"doctor"}
          options={doctors}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.doctor}
              margin="normal"
              fullWidth
              name={"doctor"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.doctor}
        />
      }
      {((!data.doctor && flag == "edit") || (!data.doctor && flag == "view")) &&
        <Autocomplete
          id={"doctor"}
          name={"doctor"}
          options={doctors}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.doctor}
              margin="normal"
              fullWidth
              name={"doctor"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.doctor}
        />
      }
      <TextField
        label={strings.mainBookOfBuried.form.registryBookOfDeath}
        error={hasError(errors, 'registryBookOfDeath')}
        helperText={getError(errors, 'registryBookOfDeath')}
        fullWidth
        name='registryBookOfDeath'
        onChange={onChange}
        margin="normal"
        defaultValue={data.registryBookOfDeath}
        value={data.registryBookOfDeath}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.takeoverDateTimeString != undefined && data.takeoverDateTimeString != null && data.takeoverDateTimeString != "") &&
        <TextField
          label={strings.mainBookOfBuried.form.takeoverDateTimeString}
          fullWidth
          name='takeoverDateTimeString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.takeoverDateTimeString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.mainBookOfBuried.form.takeoverDateTime}
        error={getError(errors, 'takeoverDateTime')}
        hasError={hasError(errors, 'takeoverDateTime')}
        helperText={getError(errors, 'takeoverDateTime')}
        placeholder={strings.mainBookOfBuried.form.takeoverDateTime}
        name='takeoverDateTime'
        onChange={onChange}
        date={data.takeoverDateTime ? new Date(data.takeoverDateTime) : data.takeoverDateTime}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />
      {(transporters && ((data.transporter && flag == "edit") || (flag == "add") || (data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }
      {(transporters && ((!data.transporter && flag == "edit") || (!data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }
      <TextField
        label={strings.mainBookOfBuried.form.weigth}
        error={hasError(errors, 'weight')}
        helperText={getError(errors, 'weight')}
        fullWidth
        name='weight'
        onChange={onChange}
        margin="normal"
        defaultValue={data.weight}
        value={data.weight}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.mainBookOfBuried.form.height}
        error={hasError(errors, 'height')}
        helperText={getError(errors, 'height')}
        fullWidth
        name='height'
        onChange={onChange}
        margin="normal"
        defaultValue={data.height}
        value={data.height}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(famousCitizens && ((data.famousCitizen && flag == "edit") || (flag == "add" && defaultFamousCitizen != undefined) || (data.famousCitizen && flag == "view"))) &&
        <Autocomplete
          id={"famousCitizen"}
          name={"famousCitizen"}
          options={famousCitizens}
          defaultValue={defaultFamousCitizen}
          getOptionLabel={option => option.description}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.famousCitizen}
              margin="normal"
              fullWidth
              name={"famousCitizen"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.famousCitizen}
        />
      }
      {(famousCitizens && ((!data.famousCitizen && flag == "edit") || (!data.famousCitizen && flag == "view"))) &&
        <Autocomplete
          id={"famousCitizen"}
          name={"famousCitizen"}
          options={famousCitizens}
          getOptionLabel={option => option.description}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.famousCitizen}
              margin="normal"
              fullWidth
              name={"famousCitizen"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.famousCitizen}
        />
      }
      <TextField
        multiline={true}
        label={strings.mainBookOfBuried.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      // rowsMax={5}
      />
      <TextField
        multiline={true}
        label={strings.mainBookOfBuried.form.landmarkDescription}
        error={hasError(errors, 'landmarkDescription')}
        helperText={getError(errors, 'landmarkDescription')}
        fullWidth
        name='landmarkDescription'
        onChange={onChange}
        margin="normal"
        defaultValue={data.landmarkDescription}
        value={data.landmarkDescription}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      // rowsMax={5}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
            {strings.mainBookOfBuried.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.mainBookOfBuried.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default DeceasedMainBookOfBuriedForm;