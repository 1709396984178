import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getPrincipalById } from "../../services/principal/PrincipalService";
import PrincipalForm from "../../components/forms/principal/PrincipalForm";

class ViewPrincipal extends FormComponent {

      constructor(props) {
        super(props);
    
        this.state = {
          data: props.data ? props.data : {},
          errors: {}
        };
    
        this.props.changeFullScreen(false);
      }
    
      componentDidMount(){

        getPrincipalById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            });
        });
      }
    
      render() {

        return (
          <Grid id="page" item md={12}>
            <div className="header">
              <h1>{strings.principal.viewPageTitle}</h1>
            </div>
            
              <Paper className="paper">
                <PrincipalForm
                  onChange={this.changeData}
                  data={this.state.data}
                  errors={this.state.errors}
                  onCancel={this.props.onCancel}
                  isDisabled={this.props.isDisabled}
                />
              </Paper>
            
          </Grid>
        );
      }
    }
    
    function mapDispatchToProps(dispatch) {

      return bindActionCreators(
        {
          changeFullScreen: Actions.changeFullScreen
        },
        dispatch
      );
    }
    
    function mapStateToProps({ menuReducers, siteDataReducers }) {

      return { menu: menuReducers, siteData: siteDataReducers };
    }

    export default withSnackbar(
        withRouter(
          connect(
            mapStateToProps,
            mapDispatchToProps
          )(ViewPrincipal)
        )
      );
