function flattenObject(obj, parentKey = "", result = {}) {
    for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            flattenObject(obj[key], `${parentKey}${key}_`, result);
        } else {
            result[`${parentKey}${key}`] = obj[key];
        }
    }
    return result;
}

function generateCSV(data) {
    if (!data || data.length === 0) {
        return "";
    }

    const flatData = data.map(item => flattenObject(item));
    const headers = Object.keys(flatData[0]);
    const rows = flatData.map(item => headers.map(header => JSON.stringify(item[header] || "")));

    const csvContent = [headers.join(","), ...rows.map(row => row.join(","))].join("\n");
    return csvContent;
}

function downloadCSV(csvContent, fileName = "data.csv") {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadCSVFile(data, fileName = "data.csv") {
    const csvContent = generateCSV(data);
    downloadCSV(csvContent, fileName);
}
