import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getCitizens, deleteCitizen, getDeletedCitizens, restoreCitizen } from '../../services/citizen/CitizenService';
import AddCitizen from './AddCitizen';
import EditCitizen from './EditCitizen';
import ViewCitizen from './ViewCitizen';
import { addPostalAddressCode } from '../../services/postalAddressCode/PostalAddressCodeService';
import { Drawer, Grid, Paper, TextField } from '@material-ui/core';
import PostalAddressCodeForm from '../../components/forms/postalAddressCode/PostalAddressCodeForm';
import DrawerWrapper from '../../common/DrawerWrapper';
import PageState from '../../constants/PageState';
import { getPostalCodes } from '../../services/postalCode/PostalCodeService';
import { getSettlements } from '../../services/settlement/SettlementService';
import { getStates } from '../../services/state/StateService';
import Validators from '../../constants/ValidatorTypes';
import update from 'immutability-helper';


class CitizenList extends TablePage {

  tableDescription = [
    { key: 'personalNumber', label: strings.citizenList.personalNumber, sort: 'personalNumber' },
    { key: 'name', label: strings.citizenList.name, sort: 'name' },
    { key: 'surname', label: strings.citizenList.surname, sort: 'surname' },
    { key: 'fullAddress', label: strings.citizenList.address, sort: 'street' },
    { key: 'postalCodeImprovedZip', label: strings.postalAddressCodeImprovedList.cityAndZip, sort: 'postalCodeImprovedId' },
    
  ];

  validationList = {
    place: [{ type: Validators.REQUIRED }],
    street: [{ type: Validators.REQUIRED }],
    number: [{ type: Validators.REQUIRED }],
    pac: [{ type: Validators.REQUIRED }],
    postalNumber: [{ type: Validators.REQUIRED }],
    settlement: [{ type: Validators.REQUIRED }],
    state: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showPac: false,
      errors: {}
    };


    this.state.sortBy = "dateCreated,ASC";

    this.renderAddPac = this.renderAddPac.bind(this);
    this.changeStateShowPac = this.changeStateShowPac.bind(this);
    this.changeData = this.changeData.bind(this);
    this.submit = this.submit.bind(this);

    this.transferDataFromCitizenToPac = this.transferDataFromCitizenToPac.bind(this);
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getCitizens(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
        getDeletedCitizens(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Грађани"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'personalNumber', label: strings.citizenList.personalNumber, sort: 'personalNumber' },
      { key: 'name', label: strings.citizenList.name, sort: 'name' },
      { key: 'surname', label: strings.citizenList.surname, sort: 'surname' },
      { key: 'fullAddress', label: strings.citizenList.address, sort: 'street' },
      { key: 'postalCodeImprovedZip', label: strings.postalAddressCodeImprovedList.cityAndZip, sort: 'postalCodeImprovedId' },
      
    ];
  }

  getPageHeader() {
    return <h1>{strings.citizenList.pageTitle}</h1>;
  }

  renderAddPac() {
    this.setState({
      showPac: true,
    });
  }

  renderAddContent() {
    return <AddCitizen
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flagAddPac={this.renderAddPac}
      handler={this.transferDataFromCitizenToPac}
    />
  }

  renderEditContent() {
    return <EditCitizen
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flagAddPac={this.renderAddPac}
      handler={this.transferDataFromCitizenToPac}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewCitizen id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteCitizen(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.citizenDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreCitizen(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }


  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }

  transferDataFromCitizenToPac(someData) {

    let myString = someData.postalCode == undefined ? "" : someData.postalCode;
    myString = myString.substring(myString.indexOf(" ") + 1)

    this.setState({
      data: {
        street: someData.street,
        number: someData.streetNumber,
        place: myString
      }
    });
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addPostalAddressCode(this.transformRequest(this.state.data), this.props.auth.user.company.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.postalAddressCode.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);
        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.postalAddressCodeAdded, { variant: 'success' });
      this.hideDrawerLoader();
      this.changeStateShowPac();

    });
  }

  transformRequest(data) {

    return {
      ...data,
      userEmail: this.props.auth.user.email,
      companyId: this.props.auth.user.company.id,
      settlementId: this.state.data.settlement ? this.state.data.settlement.id : null,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      postalNumberId: this.state.data.postalNumber ? this.state.data.postalNumber.id : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL":
        this.setError("place", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL":
        this.setError("street", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL":
        this.setError("number", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL":
        this.setError("pac", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL":
        this.setError("postalNumber", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL":
        this.setError("settlement", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL":
        this.setError("state", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL);
        break;

    }
  }

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "postalNumber" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getPostalCodes(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              postalNumbers: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === 'settlement' && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getSettlements(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlementCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
  }

  renderPacForm() {
    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.postalAddressCode.addPageTitle}</h1>
      </div>

      <Paper className='paper'>
        <PostalAddressCodeForm
          onChange={this.changeData}
          onSubmit={this.submit}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowPac}
          isDisabled={this.props.isDisabled}
          onAutocompleteChange={this.onAutocompleteChange}
          handleChange={this.handleChange}
          handleTextInputChange={this.handleTextInputChange}
          flag={"add"}
          postalNumbers={this.state.postalNumbers == undefined ? [] : this.state.postalNumbers}
          settlementCodes={this.state.settlementCodes == undefined ? [] : this.state.settlementCodes}
          stateCodes={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
        />
      </Paper>

    </Grid>


  }

  changeStateShowPac() {
    this.setState({
      ...this.state,
      showPac: false,
      data: {
        number: "",
        street: "",
        place: "",
        pac: ""
      },
      errors: {},
    });
  }
  }


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CitizenList)));