import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import DeceasedFuneralSchedulingForm from '../../components/forms/funeralScheduling/DeceasedFuneralSchedulingForm';
import DeathReportFuneralSchedulingForm from '../../components/forms/funeralScheduling/DeathReportFuneralSchedulingForm';
import FuneralDetailsForm from '../../components/forms/funeralScheduling/FuneralDetailsForm';

import update from 'immutability-helper'

import { getAllTransporters } from '../../services/transporter/TransporterService';
import { addScheduledFuneral, generateDeathReportPdf } from '../../services/scheduledFuneral/ScheduledFuneralService';
import { getStates } from '../../services/state/StateService';
import { getOccupations } from '../../services/occupation/OccupationService';
import { getCauseOfDeaths } from '../../services/causeOfDeath/CauseOfDeathService';
import { getDoctors } from '../../services/doctor/DoctorService';

import { getCitizenByPersonalNumber, getCitizens, getLeaserByGraveSiteId } from '../../services/citizen/CitizenService';
import { getGraveyards } from '../../services/graveyard/GraveyardService';
import { getGraveSitesWithSelectedGraveyard } from '../../services/graveSite/GraveSiteService';
import { getFirms, getFirmById } from '../../services/firm/FirmService';
import { getDateFromIdentificationNumber, validatePersonalNumber } from "../../functions/Validation";
import { getAdditionalItems, getFarewellMusicTypes, getFuneralTypes, getNecessaryArrangements, getAppointmentTimesCityGraveyard, getAppointmentTimesExternalGraveyards, getFarewellTypes } from '../../util/ReviewsUtil';
import { dateToString2 } from '../../util/DateUtil';
import { addAppointment, getAppointmentsByDate, getAppointmentTimesByFuneralDetails} from '../../services/appointment/AppointmentService';


function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class AddScheduledFuneral extends FormComponent {

  validationList = {
    graveyard: [{ type: Validators.REQUIRED }],
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
    dateTimeFuneral: [{ type: Validators.REQUIRED }],
    note: [{ type: Validators.REQUIRED }],
    height: [{ type: Validators.IS_NEGATIVE_NUMBER }],
    weight: [{ type: Validators.IS_NEGATIVE_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      submitter: {},
      cost: {},
      firmCost: {},
      userGraveSite: {},
      costSameAsSubmitter: false,
      userSameAsCost: false,
      userSameAsSubmitter: false,
      disabled: false,
      costInfo: {},
      userInfo: {},
      firmInfo: {},
      dateOfBirthWhenValidIDNumber: [],
      funeralType: [],
      funeralTypes: {},
      farewellType: [],
      farewellTypes: {},
      necessaryArrangement: [],
      necessaryArrangements: {},
      additionalItem: [],
      additionalItems: {},
      musicTypes: {},
      selectedMusicType: { id: -1 },
      selectedAppointment: null,
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
    this.identificationSubmitterEqualsIdentificationCosts = this.identificationSubmitterEqualsIdentificationCosts.bind(this);
    this.identificationCostsEqualsIdentificationUser = this.identificationCostsEqualsIdentificationUser.bind(this);
    this.identificationSubmitterEqualsIdentificationUser = this.identificationSubmitterEqualsIdentificationUser.bind(this);

    this.handleGeneratePdf = this.handleGeneratePdf.bind(this);

    //this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });
    this.setState({
      data: {
        ...this.state.data,
        height: 0,
        weight: 0,
        funeralNumber: new Date().getFullYear(),
        usingChapel: "false",
      },
      funeralTypes: getFuneralTypes(),
      necessaryArrangements: getNecessaryArrangements(),
      additionalItems: getAdditionalItems(),
      musicTypes: getFarewellMusicTypes(),
      appointmentTimesCityGraveyard: getAppointmentTimesCityGraveyard(),
      appointmentTimesExternalGraveyards: getAppointmentTimesExternalGraveyards(),
      farewellTypes: getFarewellTypes()
    });
  }

//   keyPress(event) {
//     if (event.key == "Enter") {
//       event.preventDefault();

//       if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
//         this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
//           variant: "error"
//         });
//         return;
//       }
      
//       addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {
//         if (!response.ok) {

//           let messageKey = response.response.data.message;

//           this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
//             variant: "error"
//           });

//           this.handleError(messageKey);

//           return;
//         }

//         this.setState({
//           data: {
//             ...this.state.data,
//             graveSite: response.data,
//             graveSiteCode: response.data.code,
//             graveSiteId: response.data.id,
//           }
//         });

//         this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

//       });
//     }
//   }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

//   transformRequestGraveSite() {
//     return {
//       userEmail: this.props.uemail,
//       code: this.state.typedInGraveSite,
//       belongingGraveyardId: this.state.data.graveyard.id,
//       z: '0'
//     }
//   }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (!validPersonalNumber) {
      this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID);
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
    else {
      this.unsetError("identificationNumber");
      dateOfBirth = getDateFromIdentificationNumber(event.target.value);
      dateOfBirthArray = dateOfBirth.split('/');

      this.setState({
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
        dateFlag: "validIDNumber"
      });

    }
    this.changeData(event);
  }

  submit() {
    this.setState({ disabled: true });
  
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState({ disabled: false });
      return;
    }

    let validPersonalNumber = validatePersonalNumber(this.state.data.identificationNumber)

    if (!validPersonalNumber) {
      this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID);
      this.props.enqueueSnackbar(strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID, { variant: 'error' });
      this.setState({ disabled: false });
    }

    if (!this.state.selectedAppointment){
      this.props.enqueueSnackbar(strings.appointments.pickAppointmentMessage, { variant: 'error' });
      this.setState({ disabled: false });
      return;
    }

    if (this.state.data.usingChapel === "true") {
      const usingChapel = this.props.scheduledFunerals.some(sf => sf.usingChapel && sf.appointmentTime === this.state.selectedAppointment && dateToString2(sf.dateTimeFuneral) === dateToString2(this.state.data.dateTimeFuneral) && sf.graveyardId === this.state.data.graveyard.id);
      if (usingChapel) {
        this.props.enqueueSnackbar(strings.appointments.alreadyTakenAppointmentForTheChapel, { variant: 'error' });
        this.setState({ disabled: false });
        return;
      }     
    }

    this.showDrawerLoader();

    addAppointment(this.transformRequestAppointment(), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
  
        this.props.enqueueSnackbar(strings.appointments.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);
        this.setState({ disabled: false });
        return;
      }

      this.setState({
        appointmentId: response.data.id
      }, () => {
        addScheduledFuneral(this.transformRequest(this.state.data), this.props.cid).then(response => {
          if (!response.ok) {
            let messageKey = response.response.data.message;

            this.props.enqueueSnackbar(strings.funeralScheduling.messages[messageKey], {
              variant: "error"
            });

            this.handleError(messageKey);
            this.setState({ disabled: false });
            return;
          }

          this.props.enqueueSnackbar(strings.addCompany.scheduledFuneralAdded, { variant: 'success' });
          this.props.onFinish(response.data.scheduledFuneral);
          this.hideDrawerLoader();

          let funNumber = response.data.funeralNumber;

          this.handleGeneratePdf(funNumber);

          })
        })
      })
    }
  
  transformRequestAppointment() {
    return {
      appointmentTime: this.state.selectedAppointment,
      appointmentTaken: true,
      appointmentScheduled: true,
      appointmentDate: this.state.data.dateTimeFuneral ? this.state.data.dateTimeFuneral : "",
      responsiblePersonEmail: this.props.uemail,
      responsiblePersonId: this.props.responsiblePersonId,
      appointmentNumber: new Date().getFullYear(),
    };
  }

  transformRequest(data) {
    return {
      ...data,
      occupationId: this.state.data.occupation ? this.state.data.occupation.id : null,
      municipalityOfBirthId: this.state.data.municipalityOfBirth ? this.state.data.municipalityOfBirth.id : null,
      municipalityOfResidenceId: this.state.data.municipalityOfResidence ? this.state.data.municipalityOfResidence.id : null,
      stateOfResidenceId: this.state.data.stateOfResidence ? this.state.data.stateOfResidence.id : null,
      municipalityOfDeathId: this.state.data.municipalityOfDeath ? this.state.data.municipalityOfDeath.id : null,
      causeOfDeathId: this.state.data.causeOfDeath ? this.state.data.causeOfDeath.id : null,
      doctorId: this.state.data.doctor ? this.state.data.doctor.id : null,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      costId: this.state.data.cost ? this.state.data.cost.id : null,
      firmCostId: this.state.data.firmCost ? this.state.data.firmCost.id : null,
      userGraveSiteId: this.state.data.userGraveSite ? this.state.data.userGraveSite.id : null,
      municipalityOfFuneralId: this.state.data.municipalityOfFuneral ? this.state.data.municipalityOfFuneral.id : null,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      farewellType: this.state.farewellType ? this.state.farewellType.value : null,
      farewellTransitMunicipalityId: this.state.data.farewellTransitMunicipality ? this.state.data.farewellTransitMunicipality.id : null,
      transporterId: this.state.data.transporter ? this.state.data.transporter.id : null,
      funeralUserGraveSiteId: this.state.data.funeralUserGraveSite ? this.state.data.funeralUserGraveSite.id : null,
      funeralSubmitterId: this.state.data.funeralSubmitter ? this.state.data.funeralSubmitter.id : null,
      funeralArrangerId: this.state.data.funeralArranger ? this.state.data.funeralArranger.id : null,
      userEmail: this.props.uemail,

      funeralType: this.state.funeralType ? this.state.funeralType.value : null,
      removeAndReturnGravePlate: this.isSelectedNecessaryArrangement(strings.funeralScheduling.removeAndReturnGravePlate),
      dismantleTombstone: this.isSelectedNecessaryArrangement(strings.funeralScheduling.dismantleTombstone),
      breakTheExtentOfTheGraveSite: this.isSelectedNecessaryArrangement(strings.funeralScheduling.breakTheExtentOfTheGraveSite),
      hireServicesForArrangingAndMaintainingGreenSurfaces: this.isSelectedNecessaryArrangement(strings.funeralScheduling.hireServicesForArrangingAndMaintainingGreenSurfaces),
      farewellMusicType: this.state.selectedMusicType ? this.state.selectedMusicType.value : null,
      microphone: this.isSelectedAdditionalItem(strings.funeralScheduling.microphone),
      deathbed: this.isSelectedAdditionalItem(strings.funeralScheduling.deathbed),
      pillowsForDecorations: this.isSelectedAdditionalItem(strings.funeralScheduling.pillowsForDecorations),
      tableForAttendees: this.isSelectedAdditionalItem(strings.funeralScheduling.tableForAttendees),
      dateTimeFuneralString: this.state.data.dateTimeFuneral ? dateToString2(this.state.data.dateTimeFuneral) : "",
      responsiblePersonId: this.props.responsiblePersonId,
      appointmentId: this.state.appointmentId ? this.state.appointmentId : null
    }
  }

  isSelectedNecessaryArrangement = (option) => {
    return this.state.necessaryArrangement.some(item => item.value === option);
  } 

  isSelectedAdditionalItem = (option) => {
    return this.state.additionalItem.some(item => item.value === option);
  } 

  identificationSubmitterEqualsIdentificationCosts() {
    if (!this.state.costSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.submitter.personalNumber).then(response => {
        this.setState({
          costInfo: {
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone
          },
          data: {
            ...this.state.data,
            cost: response.data,
            costNameOfOneParent: this.state.data.submitterNameOfOneParent,
            costIDCardOrPassportNumber: this.state.data.submitterIDCardOrPassportNumber,
            costIDCardOrPassportIssuer: this.state.data.submitterIDCardOrPassportIssuer,
            costIDCardOrPassportIssueDate: this.state.data.submitterIDCardOrPassportIssueDate,
            costRelationToDeceased: this.state.data.submitterRelationToDeceased,
          },
          costSameAsSubmitter: !this.state.costSameAsSubmitter
        });
      });
    } else {
      this.setState({
        cost: null,
        data: {
          ...this.state.data,
          cost: null,
          costNameOfOneParent: "",
          costIDCardOrPassportNumber: "",
          costIDCardOrPassportIssuer: "",
          costIDCardOrPassportIssueDate: null,
          costRelationToDeceased: "",
        },
        costInfo: {
          nameSurnameCosts: "",
          addressCosts: "",
          municipalityCosts: "",
          telephoneCosts: ""
        },
        costSameAsSubmitter: !this.state.costSameAsSubmitter
      });
    }
  }

  identificationSubmitterEqualsIdentificationUser() {
    if (!this.state.userSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.submitter.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
            userNameOfOneParent: this.state.data.submitterNameOfOneParent,
            userIDCardOrPassportNumber: this.state.data.submitterIDCardOrPassportNumber,
            userIDCardOrPassportIssuer: this.state.data.submitterIDCardOrPassportIssuer,
            userIDCardOrPassportIssueDate: this.state.data.submitterIDCardOrPassportIssueDate,
            userRelationToDeceased: this.state.data.submitterRelationToDeceased,
          },
          userSameAsSubmitter: !this.state.userSameAsSubmitter
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
            ...this.state.data,
            userGraveSite: null,
            userNameOfOneParent: "",
            userIDCardOrPassportNumber: "",
            userIDCardOrPassportIssuer: "",
            userIDCardOrPassportIssueDate: null,
            userRelationToDeceased: "",
        },
        userSameAsSubmitter: !this.state.userSameAsSubmitter
      });
    }
  }

  identificationCostsEqualsIdentificationUser() {
    if (!this.state.userSameAsCost) {
      getCitizenByPersonalNumber(this.state.data.cost.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone,
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
            userNameOfOneParent: this.state.data.costNameOfOneParent,
            userIDCardOrPassportNumber: this.state.data.costIDCardOrPassportNumber,
            userIDCardOrPassportIssuer: this.state.data.costIDCardOrPassportIssuer,
            userIDCardOrPassportIssueDate: this.state.data.costIDCardOrPassportIssueDate,
            userRelationToDeceased: this.state.data.costRelationToDeceased,
          },
          userSameAsCost: !this.state.userSameAsCost
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
            ...this.state.data,
            userGraveSite: null,
            userNameOfOneParent: "",
            userIDCardOrPassportNumber: "",
            userIDCardOrPassportIssuer: "",
            userIDCardOrPassportIssueDate: null,
            userRelationToDeceased: "",
        },
        userSameAsCost: !this.state.userSameAsCost
      });
    }
  }

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
        this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
        break;
      case "MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  handleNecessaryArrangementCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.necessaryArrangement.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            necessaryArrangement: [...prevState.necessaryArrangement, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            necessaryArrangement: prevState.necessaryArrangement.filter(item => item.id !== id)
        }));
    }
  }

  handleAdditionalItemsCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.additionalItem.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            additionalItem: [...prevState.additionalItem, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            additionalItem: prevState.additionalItem.filter(item => item.id !== id)
        }));
    }
  }

  handleFuneralCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState({
        funeralType: { id, value: value, name: type}
      });
    }
  };

  handleFarewellTypeCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState({
        farewellType: { id, value: value, name: type}
      });
    }
  };

  handleFarewellMusicTypeChange = (event) => {
      const selectedMusicType = this.state.musicTypes.find(musicType => musicType.id === event.target.value);
      this.setState({
          selectedMusicType: selectedMusicType
      });
    }

    
  handleAppointmentTimeChange = (event) => {
    this.setState({
      selectedAppointment: event.target.value
    });
  }

  changeData = (event, data = 'data') => {
    const name = event.target && event.target.name;
    const value = event.target ? event.target.value : null;

    if (!name) {
      return;
    }

    this.setState(prevState => ({
      [data]: update(prevState[data], { [name]: { $set: value } }),
      errors: {
        ...prevState.errors,
        [name]: null,
      }
    }), () => {
      if (name === 'dateTimeFuneral' && value) {
        let data = {
          graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : 0,
          funeralDate: this.state.data.dateTimeFuneral ? dateToString2(this.state.data.dateTimeFuneral) : "",
        };

        getAppointmentTimesByFuneralDetails(data).then(response => {
          if (!response.ok) {
            return;
          }
          const notAvailableAppointments = response.data.entities;

          const availableAppointmentsCityGraveyard = this.state.appointmentTimesCityGraveyard.filter(item => !notAvailableAppointments.some(takenAppointment => takenAppointment.appointmentTime === item.value));
          const availableAppointmentsExternalGraveyards = this.state.appointmentTimesExternalGraveyards.filter(item => !notAvailableAppointments.some(takenAppointment => takenAppointment.appointmentTime === item.value));

          this.setState({
            availableAppointments: availableAppointmentsCityGraveyard,
            availableAppointmentsExternalGraveyards: availableAppointmentsExternalGraveyards
          })
        });

        let data2 = {
          filterByAppointmentDate: this.state.data.dateTimeFuneral ? dateToString2(this.state.data.dateTimeFuneral) : "",
          companyId: this.props.cid,
        };

        getAppointmentsByDate(data2).then(response => {
          if (!response.ok) {
            return;
          }

          this.setState({
            extraordinaryAppointments: response.data.entities,
          })
        });
      }
    });
  }

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            municipalitySubmitter: response.data.municipality,
            telephoneSubmitter: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "cost" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          costInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "userGraveSite" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          userInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "firmCost" && values != null) {
      getFirmById(values.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
          firmInfo: {
            taxNumberCost: response.data.taxNumber,
            headquarters: response.data.headquarters,
            telephoneFirm: response.data.telephone,
            contactPerson: response.data.contactPerson,
          }
        });
      });
    } else if (autocompleteName === "graveSite" && values != null) {
      this.setState({
        data: {
          ...this.state.data,
          graveSite: values,
          expiryDateString: values.expirationDateString,
          expiryDate: values.expirationDate,
        },
      }, () => {
        getLeaserByGraveSiteId(values.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              userGraveSiteId: response.data.id ? response.data.id : this.state.data.userGraveSite.id,
              userGraveSite: response.data.id ? response.data : this.state.data.userGraveSite,
            },
            userInfo: {
              ...this.state.userInfo,
              nameSurnameUser: response.data.name && response.data.surname ? response.data.name + " " + response.data.surname : null,
              addressUser: response.data.fullAddress ? response.data.fullAddress : null,
              municipalityUser: response.data.postalCodeImprovedZip ? response.data.postalCodeImprovedZip : null,
              telephoneUser: response.data.telephone ? response.data.telephone : null
            } 
          });
        });
      });
    } else if (autocompleteName === "graveyard" && values != null) {
      if (values.name != "GRADSKO GROBLJE U NOVOM SADU") {
        this.setState({
          data: {
            ...this.state.data,
            usingChapel: "true"
          }
        });
      }
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "municipality" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {

            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfFuneral" && e.target.value.trim().length >= 2) {
        let data = {
            searchParam: e.target.value,
            companyId: this.props.cid,
            sort: 'city',
            userId: 0
        };
    
        {
            this.props.cid != undefined &&
            getPostalCodesImproved(data).then(response => {
    
                if (!response.ok) {
                  return;
                }
                this.setState({
                    municipalitiesOfFuneral: response.data.entities,
                });
            });
        }
    } else if (e.target.name === "farewellTransitMunicipality" && e.target.value.trim().length >= 2) {
        let data = {
            searchParam: e.target.value,
            companyId: this.props.cid,
            sort: 'city',
            userId: 0
        };
    
        {
            this.props.cid != undefined &&
            getPostalCodesImproved(data).then(response => {
    
                if (!response.ok) {
                  return;
                }
                this.setState({
                    farewellTransitMunicipalities: response.data.entities,
                });
            });
        }
    } 
    else if (e.target.name === "stateOfBirth" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              states: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "stateOfResidence" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              states: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "occupation" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getOccupations(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              occupations: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "causeOfDeath" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCauseOfDeaths(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              causesOfDeath: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "doctor" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getDoctors(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              doctors: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("submitter", "");
        this.setState({
          flagMarginValid: false,
        });
      } else {
        this.unsetError("submitter");
        this.setState({
          flagMarginValid: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.submitters.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.submitters[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.submitters.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewSubmitter: true,
                });
              } else {
                this.setState({
                  flagAddNewSubmitter: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("cost", "");
        this.setState({
          flagMarginValid2: false,
        });
      } else {
        this.unsetError("cost");
        this.setState({
          flagMarginValid2: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              costs: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.costs.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.costs[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.costs.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewCost: true,
                });
              } else {
                this.setState({
                  flagAddNewCost: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "userGraveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              users: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfBirth" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfResidence" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfDeath" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {

      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            }, () => {
              this.setState({
                data: {
                  ...this.state.data,
                  dateTimeFuneral: null
                }
              })
            });
          });
      }
      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });
    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });
    } else if (e.target.name === "firmCost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getFirms(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              firms: response.data.entities,
            });
          });
      }
    }
  }

  handleGeneratePdf(funeralNumber) {
    this.setState({
      loading: true,
    });

    let data = {
      scheduledFunerals: [{
        ...this.transformRequest(this.state.data),
        funeralNumber: funeralNumber
      }]
    }

    generateDeathReportPdf(data).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      
      link.download = `Sifra-sahrane-${funeralNumber}`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      this.setState({
        loading: false,
      });
    });
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.funeralScheduling.addPageTitle}</h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.funeralScheduling.deceased} className="threeTabs" />
              <Tab label={strings.funeralScheduling.deathReport} className="threeTabs" />
              <Tab label={strings.funeralScheduling.funeralDetails} className="threeTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedFuneralSchedulingForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
              onChangePersonalNumberField={this.onChangePersonalNumberField}
              date={(new Date().getFullYear())}
              defaultFamousCitizen={this.state.data.defaultFamousCitizen}
              disabled={this.state.disabled}
              flagToClose={this.props.flagToClose}
              openCloseScheduledFuneralDrawer={this.props.openCloseScheduledFuneralDrawer}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportFuneralSchedulingForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              flagAddNewSubmitter={this.state.flagAddNewSubmitter}
              renderAddCitizen={this.props.flagAddCitizen}
              flagMarginValid={this.state.flagMarginValid}
              flagAddNewCost={this.state.flagAddNewCost}
              flagMarginValid2={this.state.flagMarginValid2}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              firmInfo={this.state.firmInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
              disabled={this.state.disabled}
              flagToClose={this.props.flagToClose}
              openCloseScheduledFuneralDrawer={this.props.openCloseScheduledFuneralDrawer}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <FuneralDetailsForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              municipalitiesOfFuneral={this.state.municipalitiesOfFuneral == undefined ? [] : this.state.municipalitiesOfFuneral}
              farewellTransitMunicipalities={this.state.farewellTransitMunicipalities == undefined ? [] : this.state.farewellTransitMunicipalities}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
              flag={this.props.flag}
              typedInGraveSite={this.state.typedInGraveSite}
              userInfo={this.state.userInfo}
              //keyPress={this.keyPress}
              keyPressBackspace={this.keyPressBackspace}
              disabled={this.state.disabled}
              transporters={this.state.transporters}
              handleFuneralCheckboxChange={this.handleFuneralCheckboxChange}
              funeralType={this.state.funeralType}
              funeralTypes={this.state.funeralTypes}
              handleNecessaryArrangementCheckboxChange={this.handleNecessaryArrangementCheckboxChange}
              necessaryArrangement={this.state.necessaryArrangement}
              necessaryArrangements={this.state.necessaryArrangements}
              handleAdditionalItemsCheckboxChange={this.handleAdditionalItemsCheckboxChange}
              additionalItem={this.state.additionalItem}
              additionalItems={this.state.additionalItems}
              musicTypes={this.state.musicTypes}
              selectedMusicType={this.state.selectedMusicType}
              handleFarewellMusicTypeChange={this.handleFarewellMusicTypeChange}
              filterByAppointmentDate={this.props.filterByAppointmentDate}
              handleAppointmentTimeChange={this.handleAppointmentTimeChange}
              selectedAppointment={this.state.selectedAppointment}
              flagToClose={this.props.flagToClose}
              openCloseScheduledFuneralDrawer={this.props.openCloseScheduledFuneralDrawer}
              appointmentTimesCityGraveyard={this.state.appointmentTimesCityGraveyard}
              appointmentTimesExternalGraveyards={this.state.appointmentTimesExternalGraveyards}
              availableAppointments={this.state.availableAppointments}
              availableAppointmentsExternalGraveyards={this.state.availableAppointmentsExternalGraveyards}
              handleFarewellTypeCheckboxChange={this.handleFarewellTypeCheckboxChange}
              farewellType={this.state.farewellType}
              farewellTypes={this.state.farewellTypes}
              extraordinaryAppointments={this.state.extraordinaryAppointments}
            />
          </TabContainer>}
        </div>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default (withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddScheduledFuneral))));