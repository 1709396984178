import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllStoragePlaces() {
  return await request("api/storage/all");
}

export async function getStoragePlaces(data) {
  return await request('api/storage/search', data);
}

export function getStoragePlaceById(id, userId, companyId) {
  return request("api/storage/" + id, {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function addStoragePlace(data, cid) {
  return await request('/api/storage/' + cid, data, HttpMethod.POST);
}

export async function editStoragePlace(data) {
  return await request("api/storage/" + data.id, data, HttpMethod.PUT);
}

export async function deleteStoragePlace(id, uid) {
  return await request('/api/storage/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedStoragePlaces(data){
  return await request('/api/storage/deleted', data)
}

export async function restoreStoragePlaces(data) {
  return await request("api/storage/restore/" + data.id, data, HttpMethod.PUT);
}
