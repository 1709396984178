import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getDeceasedReceptions(data) {
    return await request('/api/deceasedReception/search', data);
}

export async function addDeceasedReception(data, cid) {
    return await request('/api/deceasedReception/' + cid, data, HttpMethod.POST);
}

export function getDeceasedReceptionById(id, userId, companyId) {
    return request("api/deceasedReception/" + id, {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editDeceasedReception(data) {
    return await request("api/deceasedReception/" + data.id, data, HttpMethod.PUT);
}

export async function deleteDeceasedReception(id, uid) {
    return await request('/api/deceasedReception/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export function getAutocompleteObjectsById(data) {
    return request("api/deceasedReception/objects/" + data.id, data, HttpMethod.PUT);
}