import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getCitizenById } from "../../services/citizen/CitizenService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import { getGrassById } from "../../services/grass/GrassService";
import GrassForm from "../../components/forms/grass/GrassForm";

class ViewGrass extends FormComponent {

      constructor(props) {
        super(props);
    
        this.state = {
          data: props.data ? props.data : {},
          errors: {},
          submitter: {},
        };
    
        this.props.changeFullScreen(false);
      }
    
      componentDidMount(){

        getGrassById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                {this.state.data.mainBookOfBuriedId &&
                getMainBookOfBuriedById(this.state.data.mainBookOfBuriedId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            mainBookOfBuried: response.data
                        },
                    });
                });
                }
                {this.state.data.submitterId &&
                getCitizenById(this.state.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            submitter: response.data
                        },
                        submitter: {
                            nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                            addressSubmitter: response.data.street + " " + response.data.streetNumber,
                            municipalitySubmitter: response.data.postalCode,
                            telephoneSubmitter: response.data.telephone,
                        },
                    });
                });
                }
                {this.state.data.graveyardId && 
                getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            graveyard: response.data
                        },
                    });
                });
                }
                {this.state.data.graveSiteId &&
                    getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                graveSite: response.data
                            },
                        });
                    })
                }
            }); 
        });
      }
    
      render() {

        return (
          <Grid id="page" item md={12}>
            <div className="header">
              <h1>{strings.grass.viewPageTitle}</h1>
            </div>
            
              <Paper className='paper'>
                <GrassForm  
                    onChange={this.changeData}
                    onSubmit={this.submit}
                    data={this.state.data}
                    errors={this.state.errors}
                    onCancel={this.props.onCancel}
                    isDisabled={this.props.isDisabled}
                    handleChange={this.handleChange}
                    onAutocompleteChange={this.onAutocompleteChange}
                    handleTextInputChange={this.handleTextInputChange}
                    submitters={this.state.submitters == undefined ? [] : this.state.submitters}
                    mainBooksOfBuried={this.state.mainBooksOfBuried == undefined ? [] : this.state.mainBooksOfBuried}
                    submitter={this.state.submitter}
                    graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
                    graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
                    flag={this.props.flag}/>
              </Paper>
            
          </Grid>
        );
      }
    }
    
    function mapDispatchToProps(dispatch) {

      return bindActionCreators(
        {
          changeFullScreen: Actions.changeFullScreen
        },
        dispatch
      );
    }
    
    function mapStateToProps({ menuReducers, siteDataReducers }) {

      return { menu: menuReducers, siteData: siteDataReducers };
    }

    export default withSnackbar(
        withRouter(
          connect(
            mapStateToProps,
            mapDispatchToProps
          )(ViewGrass)
        )
      );
