import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getGraveSites(data) {
  return await request('/api/gravesite/search', data);
}

export async function getGraveNotTakenSites(data) {
  return await request('/api/gravesite/search/taken', data);
}

export async function getGraveSitesWithSelectedGraveyard(data) {
  return await request('/api/gravesite/search/takenWithSelectedGraveyard', data);
}

export async function addGraveSite(data, cid) {
  return await request('/api/gravesite/' + cid, data, HttpMethod.POST);
}

export function getGraveSiteById(id, userId, companyId) {
  return request("api/gravesite/" + id, {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editGraveSite(data) {
  return await request("api/gravesite/" + data.id, data, HttpMethod.PUT);
}

export async function deleteGraveSite(id, uid) {
  return await request('/api/gravesite/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedGraveSites(data){
  return await request('/api/gravesite/deleted', data)
}

export async function restoreGraveSite(data) {
  return await request("api/gravesite/restore/" + data.id, data, HttpMethod.PUT);
}