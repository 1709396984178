import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import React from 'react';
import ViewPostalCodeImproved from './ViewPostalCodeImproved';
import AddPostalCodeImproved from "./AddPostalCodeImproved";
import { deletePostalCodeImproved, getDeletedPostalCodesImproved, getPostalCodesImproved, restorePostalCodeImproved } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import EditPostalCodeImproved from "./EditPostalCodeImproved";

class PostalCodeImprovedList extends TablePage {

    tableDescription = [
        { key: 'zip', label: strings.postalCodeImprovedList.zip, sort: 'zip' },
        { key: 'stateName', label: strings.postalCodeImprovedList.state, sort: 'stateName' },
        { key: 'city', label: strings.postalCodeImprovedList.city, sort: 'city' },
        { key: 'dateCreated', label: strings.postalCodeImprovedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];


    constructor(props) {
        super(props);

        this.state.sortBy = "zip,ASC";
        this.state.showDeleted = false;

    }



    fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            companyId: this.state.companyId,
            sort: this.state.sortBy,
            userId: this.props.auth.user.id
        };
        {
            (this.state.companyId != undefined && !this.state.showDeleted) &&
                getPostalCodesImproved(data).then(response => {
                    if (!response.ok) {
                        return;
                    }
                    this.setState({
                        tableData: response.data.entities,
                        total: response.data.total,
                    });
                });
        }
        {
            (this.state.companyId != undefined && this.state.showDeleted) &&
              getDeletedPostalCodesImproved(data).then(response => {
                if (!response.ok) {
                  return;
                }
                this.setState({
                  tableData: response.data.entities,
                  total: response.data.total,
                });
              });
          }
    }

    handleDeletedChange(){
        this.setState({
          showDeleted : !this.state.showDeleted
        },() =>{ this.fetchData(); })
        
      }

      usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "Поштански бројеви и места"
        }
        this.getPermissionsS(data);
        this.showAdd(this.props.showAdd);
        this.showRemove(this.props.remove);
        this.showEdit(this.props.showEdit);
        this.showView(this.props.showView);
        this.removedItemsShow(this.props.removedItemsShow);
    
      }
    
      componentDidMount() {
        this.usePermissions()
        this.setState({
            companyId: this.props.auth.user.company.id
        }, () => { this.fetchData(); })
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: 'zip', label: strings.postalCodeImprovedList.zip, sort: 'zip' },
            { key: 'stateName', label: strings.postalCodeImprovedList.state, sort: 'stateName' },
            { key: 'city', label: strings.postalCodeImprovedList.city, sort: 'city' },
            { key: 'dateCreated', label: strings.postalCodeImprovedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
    }

    getPageHeader() {
        return <h1>{strings.postalCodeImprovedList.pageTitle}</h1>;
    }

    renderViewContent() {
        return <ViewPostalCodeImproved id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag="view" auth={this.props.auth} />
    }

    renderAddContent() {
        return <AddPostalCodeImproved cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} flag={"add"} />
    }

    renderEditContent() {
        return <EditPostalCodeImproved
            id={this.state.selectedItem.id}
            uemail={this.props.auth.user.email}
            cid={this.state.companyId}
            onCancel={this.onCancel}
            onFinish={this.onFinish}
            isDisabled={false}
            flag={"edit"}
            auth={this.props.auth}
        />
    }

    delete(item) {

        this.setState({
            lockTable: true
        });

        deletePostalCodeImproved(item.id, this.props.auth.user.id).then(response => {

            if (response && !response.ok) {
                this.onFinish(null);
                return;
            }

            this.props.enqueueSnackbar(strings.companyList.postalCodeImprovedDeleted, { variant: 'success' });

            this.onFinish(item);
            this.cancelDelete();

            this.setState({
                lockTable: false
            });
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.auth.user.email,
            companyId: this.state.companyId
        }
    }
      restore(item){
        
        restorePostalCodeImproved(this.transformRequest(item)).then(response => {
          if (response && !response.ok) {
            this.onFinish(null);
            return;
          }
          this.setState({
            showDeleted : !this.state.showDeleted,
          },() =>{ this.fetchData(); })
        })
      }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PostalCodeImprovedList)));