import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getStonecutterById } from "../../services/stonecutter/StonecutterService";
import { getReservationById } from "../../services/reservation/ReservationService";
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getCitizenById } from "../../services/citizen/CitizenService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import ReservationForm from "../../components/forms/reservation/ReservationForm";

class ViewReservation extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      submitter: {},
      submitter2: {},
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    if (this.props.modifiedData) {
      getReservationById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
        
          data: response.data
        }, () => {
          
          {
            
            this.props.data.submitterId &&
              getCitizenById(this.props.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
  
                
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter: response.data.postalCodeImprovedZip,
                    telephoneSubmitter: response.data.telephone,
                  },
                });
              });
          }
          {
            this.props.data.submitter2Id &&
              getCitizenById(this.props.data.submitter2Id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter2: response.data
                  },
                  submitter2: {
                    nameSurnameSubmitter2: response.data.name + " " + response.data.surname,
                    addressSubmitter2: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter2: response.data.postalCodeImprovedZip,
                    telephoneSubmitter2: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data
                  },
                });
              })
          }
          {
            this.state.data.stonecutterId &&
              getStonecutterById(this.state.data.stonecutterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    stonecutter: response.data
                  },
                });
              })
          }
        });
      });
    } else {
      getReservationById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
        
          data: response.data
        }, () => {
          
          {
            
            this.state.data.submitterId &&
              getCitizenById(this.state.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
  
                
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter: response.data.postalCodeImprovedZip,
                    telephoneSubmitter: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.submitter2Id &&
              getCitizenById(this.state.data.submitter2Id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter2: response.data
                  },
                  submitter2: {
                    nameSurnameSubmitter2: response.data.name + " " + response.data.surname,
                    addressSubmitter2: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter2: response.data.postalCodeImprovedZip,
                    telephoneSubmitter2: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data
                  },
                });
              })
          }
          {
            this.state.data.stonecutterId &&
              getStonecutterById(this.state.data.stonecutterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    stonecutter: response.data
                  },
                });
              })
          }
        });
      });
    }
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.reservation.viewPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <ReservationForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            submitters={this.state.submitters == undefined ? [] : this.state.submitters}
            submitters2={this.state.submitters2 == undefined ? [] : this.state.submitters2}
            submitter={this.state.submitter}
            submitter2={this.state.submitter2}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            stonecutters={this.state.stonecutters == undefined ? [] : this.state.stonecutters}
            flag={this.props.flag} />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewReservation)
  )
);
