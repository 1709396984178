import React from "react";
import * as Actions from "../../actions/Actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import TablePage from "../../common/TablePage";
import strings from "../../localization";
import PageState from "../../constants/PageState";
import DrawerWrapper from "../../common/DrawerWrapper";
import DatePickerControl from "../../components/controls/DatePickerControl";
import { withSnackbar } from "notistack";
import { Drawer, TableRow, TableBody, TableContainer, TableHead, TableCell, Table, Grid, Paper } from "@material-ui/core";
import { getError, hasError } from "../../functions/Validation";
import { dateToString2, dateToString3, getYearFromDate } from "../../util/DateUtil";
import update from "immutability-helper";
import AddAppointment from "./AddAppointment";
import AddScheduledFuneral from "../funeralScheduling/AddScheduledFuneral";
import { generateScheduledFuneralsExternalGraveyardsPdf, generateScheduledFuneralsCityGraveyardPdf, getScheduledFuneralsByAppointmentDateOnExternalGraveyards, getScheduledFuneralsByAppointmentDateOnCityGraveyard } from "../../services/scheduledFuneral/ScheduledFuneralService";
import { getFarewellMusicType } from "../../util/ReviewsUtil";

import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

class AppointmentList extends TablePage {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      checked: false,
      data: {
        filterByAppointmentDate: null,
        filterByDate: null
      },
      drawerOpen: false,
      showLoader: false,
      errors: {},
      companyId: this.props.auth.user.company.id,
    };

    this.state.sortBy = "dateCreated,DESC";

    this.changeAppointmentDate = this.changeAppointmentDate.bind(this);
    this.renderTableData = this.renderTableData.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.changeTypedInPersonalNumber = this.changeTypedInPersonalNumber.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData() {
    if (this.state?.data?.filterByDate !== null && this.state?.data?.filterByDate !== "") {
      this.setState({
        showLoader: true,
      });
      
      let data = {
        appointmentDate: this.state.data.filterByDate ? dateToString2(this.state.data.filterByDate) : "",
        companyId: this.props.auth.user.company.id,
      };

      getScheduledFuneralsByAppointmentDateOnCityGraveyard(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          scheduledFuneralCityGraveyard: response.data.entities,
          totalCityGraveyard: response.data.total,
        });

        this.setState({
          showLoader: false,
        });
      });

      getScheduledFuneralsByAppointmentDateOnExternalGraveyards(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          scheduledFuneralExternalGraveyards: response.data.entities,
          totalExternalGraveyards: response.data.total,
        });

        this.setState({
          showLoader: false,
        });
      });
    }
  }

  usePermissions() {
    let data = {
      userId: this.props.auth.user.id,
      category: "Главна књига сахрањених",
    };
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);
  }

  componentDidMount() {
    this.usePermissions();
    this.setState({
        companyId: this.props.auth.user.company.id,
      }, () => {
        this.fetchData();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }

  getScheduledFuneralHeader() {
    return <h1>{strings.scheduledFuneralList.pageTitleAppointments} {this.state.data.filterByDate ? dateToString3(this.state.data.filterByDate) : null}</h1>;
  }

  getCityGraveyardName() {
    return <h1>{strings.appointments.cityGraveyard}</h1>;
  }

  getExternalGraveyardName() {
    return <h1>{strings.appointments.externalGraveyard}</h1>;
  }

  changeData(event, data = "data") {
    const newState = {
      [data]: update(this.state[data], {
        [event.target.name]: { $set: event.target.value },
      }),
    };

    if (event.target.name === "filterByAppointmentDate") {
      newState.scheduledFuneralCityGraveyard = [];
      newState.scheduledFuneralExternalGraveyards = [];
      newState.data.filterByDate = null;
    }

    this.setState(newState, () => {
      if (event.target.name === "filterByAppointmentDate") {
        if (this.state.data.filterByAppointmentDate) {
          this.setState({ drawerOpen: true }, () => {
            this.fetchData();
          });
        } else {
          this.setState({ drawerOpen: true });
        }
      }
    });
  }

  changeAppointmentDate(event, data = "data") {
    this.setState({
        [data]: update(this.state[data], {
          [event.target.name]: { $set: event.target.value },
        }),
      }, () => {
        if (event.target.name === "filterByDate") {
          if (this.state?.data?.filterByDate) {
            this.fetchData();
          }
        }
      }
    );
  }

  closeDrawer() {
    this.setState({
      drawerOpen: false,
      data: {
        ...this.state.data,
        filterByAppointmentDate: null,
      },
    });
  }

  changeTypedInPersonalNumber(someValue) {
    this.setState({
      data: {
        personalNumber: someValue,
      },
    });
  }

  renderScheduledFuneralForm() {
    return (
      <AddScheduledFuneral
        cid={this.state.companyId}
        uemail={this.props.auth.user.email}
        responsiblePersonId={this.props.auth.user.id}
        openCloseScheduledFuneralDrawer={this.openCloseScheduledFuneralDrawer}
        onFinish={this.onFinish}
        flagToClose={"appointments"}
        flag={"add"}
        handler={this.changeTypedInPersonalNumber}
        flagAddCitizen={this.renderAddCitizen}
        filterByAppointmentDate={this.state.data.filterByAppointmentDate}
        appointments={this.state.data.appointments}
        auth={this.props.auth}
      />
    )
  }

  renderAddContent() {
    return (
      <AddAppointment
        data={this.state?.data ? this.state?.data : {}}
        cid={this.state.companyId}
        uemail={this.props.auth.user.email}
        onCancel={this.closeDrawer}
        onFinish={this.onFinish}
        flag={"add"}
        responsiblePersonId={this.props.auth.user.id}
        onFetchData={this.fetchData}
        filterByAppointmentDate={this.state.data.filterByAppointmentDate}
      />
    );
  }

  renderFilters() {
    return (
      <div className="renderFilters">
        <DatePickerControl
          label={strings.appointments.filterAppointmentsByDay}
          error={getError(this.state.errors, "filterByAppointmentDate")}
          hasError={hasError(this.state.errors, "filterByAppointmentDate")}
          helperText={getError(this.state.errors, "filterByAppointmentDate")}
          placeholder={strings.cremationScheduling.filterByAppointmentDate}
          name="filterByAppointmentDate"
          onChange={this.changeData}
          date={this.state.data.filterByAppointmentDate ? new Date(this.state.data.filterByAppointmentDate) : this.state.data.filterByAppointmentDate}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          disabled={false}
        />
      </div>
    );
  }

  renderFilterByAppointmentDateCalendar() {
    return (
      <div className="renderFilters">
        <DatePickerControl
          label={strings.appointments.showScheduledFunerals}
          error={getError(this.state.errors, "filterByDate")}
          hasError={hasError(this.state.errors, "filterByDate")}
          helperText={getError(this.state.errors, "filterByDate")}
          placeholder={strings.cremationScheduling.appointmentDate}
          name="filterByDate"
          onChange={this.changeAppointmentDate}
          date={this.state.data.filterByDate ? new Date(this.state.data.filterByDate) : this.state.data.filterByDate}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          disabled={false}
        />
      </div>
    );
  }

  renderTableData(data = []) {

  return (
    <>
      <TableContainer component={Paper}>
      <Table>
        <TableHead className="table-header">
          <TableRow>
            <TableCell>{strings.appointments.number}</TableCell>
            <TableCell align="left">{strings.funeralScheduling.deceasedNameSurname}</TableCell>
            <TableCell align="center">{strings.funeralScheduling.fathersName}</TableCell>
            <TableCell align="center">{strings.funeralScheduling.born}</TableCell>
            <TableCell align="center">{strings.funeralScheduling.appointmentTimeFuneral}</TableCell>
            <TableCell align="left">{strings.graveSiteList.graveyardName}</TableCell>
            <TableCell align="left">{strings.appointments.note}</TableCell>
            <TableCell align="left">{strings.appointments.transporter}</TableCell>
            <TableCell align="right">{strings.appointments.musicBell}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.map((item, index) => (
            <TableRow key={item.id} style={{ backgroundColor: item.usingChapel ? '#F5F5F5' : '' }} alignItems="left">
            <TableCell component="th" scope="row">{index + 1}</TableCell>
            <TableCell align="left">{item.deceasedNameSurname}</TableCell>
            <TableCell align="center">{item.fathersName}</TableCell>
            <TableCell align="center">{getYearFromDate(item.dateOfBirth)}</TableCell>
            <TableCell align="center">{item.appointmentTime}</TableCell>
            <TableCell align="left">{item.graveyardName}</TableCell>
            <TableCell align="left">{item.note}</TableCell>
            <TableCell align="left">{item.transporterShortenedName}</TableCell>
            <TableCell align="right">{this.getMusicType(item?.farewellMusicType, item?.usingChapel)}</TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
      </TableContainer>
    </>
    )
  }

  getMusicType(farewellMusicType, usingChapel) {
    if (farewellMusicType !== null) {
      if (usingChapel) {
        return getFarewellMusicType(farewellMusicType);
      } else {
        return strings.funeralScheduling.direct;
      }
    }
  }

  handleGeneratePdf() {
    this.setState({
        loading: true,
    });

    let dataCityGraveyard = {
      scheduledFunerals: this.state.scheduledFuneralCityGraveyard,
      scheduledFuneralsDate: this.state.data.filterByDate ? dateToString3(this.state.data.filterByDate) : "",
    }

    generateScheduledFuneralsCityGraveyardPdf(dataCityGraveyard).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        
        link.download = `Zakazane-sahrane-gradsko-groblje.pdf`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        this.setState({
            loading: false,
        });
    });

    let dataExternalGraveyards = {
      scheduledFunerals: this.state.scheduledFuneralExternalGraveyards,
      scheduledFuneralsDate: this.state.data.filterByDate ? dateToString3(this.state.data.filterByDate) : "",
    }

    generateScheduledFuneralsExternalGraveyardsPdf(dataExternalGraveyards).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        
        link.download = `Zakazane-sahrane-spoljna-groblja.pdf`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        this.setState({
            loading: false,
        });
    });
  }

  render() {
    return (
      <>
        <Grid id="table-page">
        {this.state.showScheduledFuneralForm &&
          <Drawer
            id="drawer"
            anchor="left"
            open={this.state.showScheduledFuneralForm}
            onClose={() => this.setState({ showScheduledFuneralForm: false }, this.setPageState(PageState.View))}
            ModalProps={{ disableBackdropClick: true }}
          >
            <DrawerWrapper onBack={() => this.openCloseScheduledFuneralDrawer()}>
              {this.renderScheduledFuneralForm()}
            </DrawerWrapper>
          </Drawer>
        }
          <div className="header">
            <div className="filter-controls">{this.renderFilters()}</div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '2rem', alignItems: 'center'}}>
            {this.getScheduledFuneralHeader()}
            {this.renderFilterByAppointmentDateCalendar()}
          </div>

          <div>

            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: 'auto' }}>
              {this.state.showLoader && <CircularProgress color="primary" />}
            </div>

            {!this.state.showLoader && (
              <>
              <div>
                {this.state.scheduledFuneralCityGraveyard && this.state.scheduledFuneralCityGraveyard.length > 0 && (
                <>
                  {this.getCityGraveyardName()}
                  <Paper md={12} id="table-page">
                    {this.renderTableData(this.state.scheduledFuneralCityGraveyard)}
                  </Paper>
                </>
                )}
              </div>

              <div>
                {this.state.scheduledFuneralExternalGraveyards && this.state.scheduledFuneralExternalGraveyards.length > 0 && (
                  <>
                  {this.getExternalGraveyardName()}
                  <Paper md={12} id="table-page">
                    {this.renderTableData(this.state.scheduledFuneralExternalGraveyards)}
                  </Paper>
                  </>
                )}
              </div>
              </>
            )}
          </div>

          <Drawer
            id="drawer"
            anchor="right"
            open={this.state.drawerOpen}
            onClose={() => this.setState({ drawerOpen: false }, this.setPageState(PageState.View))}
            ModalProps={{ disableBackdropClick: true }}
          >
            <DrawerWrapper onBack={() => this.closeDrawer()}>
              <div>{this.renderAddContent()}</div>
            </DrawerWrapper>
          </Drawer>


        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton 
            disabled={!this.state.scheduledFuneralExternalGraveyards|| !this.state.scheduledFuneralCityGraveyard} 
            onClick={() => this.handleGeneratePdf()}
          >
            {this.state.loading 
                ? (<CircularProgress size={24} color="inherit" />) 
                : (<InsertDriveFileIcon />)
            }
            <Typography variant="button">{strings.review.buttons.generatePDF}</Typography>
          </IconButton>
        </div>
        </Grid>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen,
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppointmentList))
);