import FormComponent from "../../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import Validators from "../../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { getDeceasedReceptionById, editDeceasedReception, getAutocompleteObjectsById } from "../../../services/deceasedReception/DeceasedReceptionService";
import DeceasedTakeoverRecordForm from "../../../components/forms/deceasedReception/deceasedTakeoverRecord/DeceasedTakeoverRecordForm";
import EquipmentTakeoverRecordForm from "../../../components/forms/deceasedReception/deceasedTakeoverRecord/EquipmentTakeoverRecordForm";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAllAids } from "../../../services/aid/AidService";
import { getAllTransporters } from "../../../services/transporter/TransporterService";
import { getAllPrincipals } from "../../../services/principal/PrincipalService";
import { getAllReasonBringingDeceassed } from "../../../services/reasonBringingDeceased/ReasonBringingDeceasedService";
import { getAllStoragePlaces } from "../../../services/storagePlace/StoragePlaceService";
import { getAllDeceasedBrought } from "../../../services/deceasedBrought/DeceasedBroughtService";
import { getAllCrates } from "../../../services/crate/CrateService";
import { getAllTombstones } from "../../../services/tombstone/TombstoneService";
import { getAllLetters } from "../../../services/letter/LetterService";
import { getAllLanguages } from "../../../services/language/LanguageService";
import { getAllTypefaces } from "../../../services/typeface/TypefaceService";


function TabContainer(props) {
  return (
    <Typography component="div" className="tabContainer">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class EditDeceasedTakeoverRecord extends FormComponent {

  validationList = {
    identificationNumber: [{ type: Validators.REQUIRED }],
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
    };

    this.state.sortBy = "name,ASC";
    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getDeceasedReceptionById(this.props.id, this.props.auth.user.id, this.auth.props.user.company.id).then(response => {

      this.setState({
        data: response.data,
      }, () => {
        getAutocompleteObjectsById(this.state.data).then(response => {

          this.setState({
            data: {
              ...this.state.data,
              deceasedAid: response.data.deceasedAid,
              transporter: response.data.transporter,
              principal: response.data.principal,
              reasonOfBrought: response.data.reasonOfBrought,
              storage: response.data.storage,
              deceasedBroughtIn: response.data.deceasedBroughtIn,
              coffinType: response.data.coffinType,
              tombstone: response.data.tombstone,
              letter: response.data.letter,
              language: response.data.language,
              letterType: response.data.letterType,
            }
          })
        });
      });
    });

    getAllAids().then(response => {
      this.setState({
        deceasedAids: response.data.entities
      });
    });

    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities
      });
    });

    getAllPrincipals().then(response => {
      this.setState({
        principals: response.data.entities
      });
    });

    getAllReasonBringingDeceassed().then(response => {
      this.setState({
        reasonsOfBrought: response.data.entities
      });
    });

    getAllStoragePlaces().then(response => {
      this.setState({
        storages: response.data.entities
      });
    });

    getAllDeceasedBrought().then(response => {
      this.setState({
        deceasedBroughtIns: response.data.entities
      });
    });
    getAllCrates().then(response => {
      this.setState({
        coffinTypes: response.data.entities
      })
    });
    getAllTombstones().then(response => {
      this.setState({
        tombstones: response.data.entities
      });
    });
    getAllLetters().then(response => {
      this.setState({
        letters: response.data.entities
      });
    });
    getAllLanguages().then(response => {
      this.setState({
        languages: response.data.entities
      });
    });
    getAllTypefaces().then(response => {
      this.setState({
        letterTypes: response.data.entities
      });
    });
  }


  submit() {

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    editDeceasedReception(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.deceasedReception.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.deceaseTakeoverRecordEdited, { variant: 'success' });
      this.props.onFinish(response.data.deceasedTakeoverRecord);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      id: this.props.id,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      deceasedReceptionType: 1,
      deceasedAidId: this.state.data.deceasedAid ? this.state.data.deceasedAid.id : null,
      transporterId: this.state.data.transporter ? this.state.data.transporter.id : null,
      principalId: this.state.data.principal ? this.state.data.principal.id : null,
      reasonOfBroughtId: this.state.data.reasonOfBrought ? this.state.data.reasonOfBrought.id : null,
      storageId: this.state.data.storage ? this.state.data.storage.id : null,
      deceasedBroughtInId: this.state.data.deceasedBroughtIn ? this.state.data.deceasedBroughtIn.id : null,
      coffinTypeId: this.state.data.coffinType ? this.state.data.coffinType.id : null,
      tombstoneId: this.state.data.tombstone ? this.state.data.tombstone.id : null,
      letterId: this.state.data.letter ? this.state.data.letter.id : null,
      languageId: this.state.data.language ? this.state.data.language.id : null,
      letterTypeId: this.state.data.letterType ? this.state.data.letterType.id : null,
      transporterName: this.state.data.transporter ? this.state.data.transporter.name : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
        this.setError("identificationNumber", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
        break;
      case "DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL":
        this.setError("identificationNumber", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL);
        break;
      case "DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };


  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  };

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.deceasedTakeoverRecord.editPageTitle}</h1>
        </div>

        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.deceasedTakeoverRecord.deceased} className="tab" />
              <Tab label={strings.deceasedTakeoverRecord.equipment} className="tab" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedTakeoverRecordForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              deceasedAids={this.state.deceasedAids}
              principals={this.state.principals}
              reasonsOfBrought={this.state.reasonsOfBrought}
              storages={this.state.storages}
              flag={this.props.flag}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <EquipmentTakeoverRecordForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              deceasedBroughtIns={this.state.deceasedBroughtIns}
              coffinTypes={this.state.coffinTypes}
              tombstones={this.state.tombstones}
              letters={this.state.letters}
              languages={this.state.languages}
              letterTypes={this.state.letterTypes}
              flag={this.props.flag}
            />

          </TabContainer>}
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditDeceasedTakeoverRecord)
  )
);
