import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getReservations, deleteReservation, 
  stornoReservation, undoStornoReservation, 
  getDeletedOReservations, restoreReservation } from '../../services/reservation/ReservationService';
import AddReservation from './AddReservation';
import EditReservation from './EditReservation';
import ViewReservation from './ViewReservation';
import { TableCell, Grid, Paper, Drawer, TextField, Table, TableRow, TableBody, LinearProgress, FormControlLabel, Switch } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import UndoIcon from '@material-ui/icons/Undo';
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UndoCancelIcon from '@material-ui/icons/Replay';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import SelectControl from '../../components/controls/SelectControl';
import DrawerWrapper from '../../common/DrawerWrapper';
import PageState from '../../constants/PageState';
import update from 'immutability-helper';
import { getReservationTypes, downloadReport } from '../../util/ReviewsUtil';
import { generateReservationReview } from '../../services/report/ReportService';
import AddCitizen from '../citizen/AddCitizen';
import CitizenForm from '../../components/forms/citizen/CitizenForm';
import Validators from '../../constants/ValidatorTypes';
import { addCitizen } from '../../services/citizen/CitizenService';
import { validate, isFormValid, validatePersonalNumber } from '../../functions/Validation';
import { addPostalAddressCode, getAllPostalAddressCodes } from '../../services/postalAddressCode/PostalAddressCodeService';
import PostalAddressCodeForm from '../../components/forms/postalAddressCode/PostalAddressCodeForm';
import { getPostalCodes } from '../../services/postalCode/PostalCodeService';
import { getSettlements } from '../../services/settlement/SettlementService';
import { getStates } from '../../services/state/StateService';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { getPermissionAdministration } from '../../services/permissionAdministration/PermissionAdministrationService';
import { getPostalAddressCodesWithParams } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';



class ReservationList extends TablePage {

  tableDescription = [
    { key: 'mainBookOfBuriedString', label: strings.reservationList.mainBookOfBuriedMainBookOfBuries, sort: 'mainBookOfBuriedString' },
    { key: 'submitterName', label: strings.reservationList.submitterName, sort: 'submitterName' },
    { key: 'submitterSurname', label: strings.reservationList.submitterSurname, sort: 'submitterSurname' },
    { key: 'graveSiteCode', label: strings.reservationList.graveSite, sort: 'graveSiteCode' },
    { key: 'tombstoneType', label: strings.reservationList.tombstoneType, sort: 'tombstoneType' },
    { key: 'expiryDate', label: strings.reservationList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
    { key: 'canceled', label: strings.reservationList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
    { key: 'dateCreated', label: strings.reservationList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];

  validationList = {
    personalNumber: [{ type: Validators.REQUIRED }],
    name: [{ type: Validators.REQUIRED }],
    surname: [{ type: Validators.REQUIRED }],
    street: [{ type: Validators.REQUIRED }],
    streetNumber: [{ type: Validators.REQUIRED }],
    // postalCode: [{ type: Validators.REQUIRED }],
  };

  validationListPac = {
    place: [{ type: Validators.REQUIRED }],
    number: [{ type: Validators.REQUIRED }],
    pac: [{ type: Validators.REQUIRED }],
    postalNumber: [{ type: Validators.REQUIRED }],
    settlement: [{ type: Validators.REQUIRED }],
    state: [{ type: Validators.REQUIRED }],
    street: [{ type: Validators.REQUIRED }],
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showCitizen: false,
      showPac: false,
      data: {
        graveyard: { id: -1 },
        reservationType: { value: -1 }
      },
      filter: {
        reservationTypes: [],
      },
      checked: false,
      errors: {},
      showAdd: false,
      showEdit: false,
      showView: false,
      remove: false,
      removedItemsShow: false,
      showCanceled: false,
      showCanceledUndo: false,
      removedItemsShow:false
    };

    this.state.sortBy = "dateCreated,DESC";
    this.changeData = this.changeData.bind(this);

    this.renderAddCitizen = this.renderAddCitizen.bind(this);
    this.renderAddPac = this.renderAddPac.bind(this);
    this.changeStateShowCitizen = this.changeStateShowCitizen.bind(this);
    this.submit = this.submit.bind(this);
    this.submitPac = this.submitPac.bind(this);
    this.changeStateShowPac = this.changeStateShowPac.bind(this);
    this.transferDataFromCitizenToPac = this.transferDataFromCitizenToPac.bind(this);

    this.changeTypedInPersonalNumber = this.changeTypedInPersonalNumber.bind(this);
  }

  changeTypedInPersonalNumber(someValue) {
    this.setState({
      data: {
        personalNumber: someValue,
      },
    });
  }

  checkIfDataIsValid(personalNumberInCitizen) {
    let validPersonalNumber = validatePersonalNumber(personalNumberInCitizen)

    if (!validPersonalNumber) {
      this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
    }
    else {
      this.unsetError("personalNumber");
    }
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      canceled: this.state.data.reservationType ? (this.state.data.reservationType.id == 1 ? "1" : "-1") : "-1",
      unRenewed: this.state.data.reservationType ? (this.state.data.reservationType.id == 2 ? "1" : "-1") : "-1",
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : -1,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getReservations(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
        getDeletedOReservations(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }
  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Резервације"
    }
    this.getPermissionsS1(data);

  }
  getPermissionsS1(data){
    
    getPermissionAdministration(data).then(response => {
      if(!response.ok){
      
        return;
      }
      this.setState({
        permissions: response.data.entities
      }, () =>{     
          this.state.permissions.forEach(element => {
            if(element.category != "Обнове" && element.code == "Додај"){
                this.setState({
                    showAdd : true
                  })
            }
                switch (element.code) {
                    case "Прегледај": this.setState({
                      showView : true
                    })
                    break;
                    case "Измени": this.setState({
                      showEdit: true
                    })
                    break;
                    case "Обриши": this.setState({
                      remove: true
                    })
                    break;
                    case "Поврати": this.setState({
                      showRestore: true
                    })
                    break;
                    case "Обрисане ставке": this.setState({
                      removedItemsShow: true
                    })
                    break;
                    case "Сторно": this.setState({
                      showCanceled: true
                    })
                    break;
                    case "Поврати сторно": this.setState({
                      showCanceledUndo: true
                    })
                    default:
                      return
                  }
          });

      })
    })
  }


  componentDidMount() {
    this.usePermissions()

    
    this.setState({
      companyId: this.props.auth.user.company.id,
      filter: {
        reservationTypes: getReservationTypes()
      },

    }, () => {
      this.state.filter.reservationTypes.unshift({ value: -1, name: strings.reservationTypes.all });
      this.fetchData();
    })

    
    getAllGraveyards().then(response => {
      this.setState({
        filter: {
          ...this.state.filter,
          graveyards: response.data.entities
        }
      }, () => this.state.filter.graveyards.unshift({ id: -1, name: strings.years.all }));
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'mainBookOfBuriedString', label: strings.reservationList.mainBookOfBuriedMainBookOfBuries, sort: 'mainBookOfBuriedString' },
      { key: 'submitterName', label: strings.reservationList.submitterName, sort: 'submitterName' },
      { key: 'submitterSurname', label: strings.reservationList.submitterSurname, sort: 'submitterSurname' },
      { key: 'graveSiteCode', label: strings.reservationList.graveSite, sort: 'graveSiteCode' },
      { key: 'tombstoneType', label: strings.reservationList.tombstoneType, sort: 'tombstoneType' },
      { key: 'expiryDate', label: strings.reservationList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
      { key: 'canceled', label: strings.reservationList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
      { key: 'dateCreated', label: strings.reservationList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

    changeData(event, data = 'data') {
      this.setState({
        [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
      }, () => {
        this.fetchData();
      });
    }

  handleChange = event => {
    this.setState({ checked: event.target.checked }, () => {
      let validPersonalNumber = validatePersonalNumber(this.state.data.personalNumber)

      if (!validPersonalNumber) {
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
      }
      else {
        this.unsetError("personalNumber");
      }
    })
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)

    if (!validPersonalNumber) {
      this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
    }
    else {
      this.unsetError("personalNumber");
    }
    this.changeData(event);
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    

    this.showDrawerLoader();
    
    addCitizen(this.transformRequestCitizen(this.state.data), this.state.companyId).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
        this.props.enqueueSnackbar(strings.citizen.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.citizenAdded, { variant: 'success' });

      this.hideDrawerLoader();
      this.changeStateShowCitizen();
    });
  }

  transformRequestCitizen(data) {
    
    return {
      ...data,
      deceased: this.state.checked,
      userEmail: this.props.auth.user.email,
      stateCode: this.state.data.state ? this.state.data.state.id : null ,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : null
    }
  }

  validatePac() {

    let errors = validate(this.state.data, this.validationListPac);

    this.setState({ errors });

    return isFormValid(errors);
  }

  submitPac() {
    if (!this.validatePac()) {
      return;
    }

    this.showDrawerLoader();

    addPostalAddressCode(this.transformRequestPac(this.state.data), this.props.auth.user.company.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.postalAddressCode.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);
        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.postalAddressCodeAdded, { variant: 'success' });
      this.hideDrawerLoader();
      this.changeStateShowPac();

    });
  }

  transformRequestPac(data) {

    return {
      ...data,
      userEmail: this.props.auth.user.email,
      companyId: this.props.auth.user.company.id,
      settlementId: this.state.data.settlement ? this.state.data.settlement.id : null,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      postalNumberId: this.state.data.postalNumber ? this.state.data.postalNumber.id : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "CITIZEN_PERSONAL_NUMBER_NOT_VALID":
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
        break;
      case "CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST":
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST);
        break;
      case "CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL":
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL);
        break;
      case "CITIZEN_NAME_CANT_BE_NULL":
        this.setError("name", strings.citizen.messages.CITIZEN_NAME_CANT_BE_NULL);
        break;
      case "CITIZEN_SURNAME_CANT_BE_NULL":
        this.setError("surname", strings.citizen.messages.CITIZEN_SURNAME_CANT_BE_NULL);
        break;
      case "CITIZEN_POSTAL_CODE_CANT_BE_NULL":
        this.setError("postalCode", strings.citizen.messages.CITIZEN_POSTAL_CODE_CANT_BE_NULL);
        break;
      case "CITIZEN_STREET_CANT_BE_NULL":
        this.setError("street", strings.citizen.messages.CITIZEN_STREET_CANT_BE_NULL);
        break;
      case "CITIZEN_STREET_NUMBER_CANT_BE_NULL":
        this.setError("streetNumber", strings.citizen.messages.CITIZEN_STREET_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL":
        this.setError("place", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL":
        this.setError("street", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL":
        this.setError("number", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL":
        this.setError("pac", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL":
        this.setError("postalNumber", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL":
        this.setError("settlement", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL":
        this.setError("state", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL);
        break;
    }
  }

  handleMenuStorno(item) {

    stornoReservation(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.reservation.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.reservationCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  handleMenuUndoStorno(item) {

    undoStornoReservation(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.reservation.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.undoReservationCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteReservation(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.reservationDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreReservation(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }


  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }


  generateReservationReviewReport(data) {

    generateReservationReview(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.reservationList.PDF, { variant: 'success' });
      downloadReport(response, strings.reservationList.reportName);
    })
  }

  getPageHeader() {
    return <h1>{strings.reservationList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddReservation
      cid={this.state.companyId}
      isDisabled={false}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber}
      flagAddPac={this.renderAddPac}
      handlerForPac={this.transferDataFromCitizenToPac}
    />
  }

  renderEditContent() {
    return <EditReservation
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      auth={this.props.auth}
    />
  }

  renderViewContent() {
    
    return <ViewReservation
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
      auth={this.props.auth}
    />
  }

  renderRowMenu(index, item) {
    let ariaOwns = 'action-menu-' + index;
    return (
      <TableCell>
        <IconButton
          size='small'
          aria-owns={this.state.anchorEl ? ariaOwns : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, ariaOwns)}
        >
          <MoreVert />
        </IconButton>
        {
          ariaOwns === this.state.ariaOwns &&
          <Menu
            id={ariaOwns}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleMenuClose()}
          >
           { (!this.state.showDeleted && this.state.showView) && 
            <MenuItem onClick={() => this.handleMenuView(item)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.view} />
              </MenuItem>
              }
            { (!this.state.showDeleted && this.state.showEdit)&&
              <MenuItem onClick={() => this.handleMenuEdit(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.edit} />
              </MenuItem>
              }
            {
              (!this.state.showDeleted && this.state.remove) &&
              <MenuItem onClick={() => this.handleMenuDelete(item)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.delete} />
              </MenuItem>
            }
            {
              (!item.canceled && !this.state.showDeleted && this.state.showCanceled) &&
              <MenuItem onClick={() => this.handleMenuStorno(item)}>
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.cancel} />
              </MenuItem>
            }
            {
              (item.canceled && !this.state.showDeleted && this.state.showCanceledUndo) &&
              <MenuItem onClick={() => this.handleMenuUndoStorno(item)}>
                <ListItemIcon>
                  <UndoCancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undoCancel} />
              </MenuItem>
            }
            {
              this.state.showDeleted &&
              <MenuItem onClick={() => this.handleRestore(item)}>
                <ListItemIcon>
                  <UndoIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undo} />
              </MenuItem>
            }

          </Menu>
        }

      </TableCell>
    );
  }

  renderButtons() {
    let data = {
      companyId: this.state.companyId,
      searchParam: this.state.searchData ? this.state.searchData.search : "",
      sort: this.state.sortBy,
    };
    return (
      <div className="report-button">
        <IconButton onClick={() => this.generateReservationReviewReport(data)}
        >
          {strings.mainBookOfBuried.buttons.generateReport}
        </IconButton>
      </div>
    );
  }

  renderFilters() {
    return (
      <div className="renderFilters">

      {this.state.removedItemsShow &&
        <FormControlLabel
          control = {<Switch
            checked={this.state.showDeleted}
            onChange={this.handleDeletedChange}
            inputProps={{ 'aria-label': 'controlled' }}
            />}
            labelPlacement="start"
            label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
        />}

        <SelectControl
          options={this.state.filter.reservationTypes}
          selected={this.state.data.reservationType}
          onChange={this.changeData}
          label={strings.reservationTypes.label}
          name={"reservationType"}
          nameKey={"name"}
          valueKey={"value"}
        />

        <SelectControl
          options={this.state.filter.graveyards}
          selected={this.state.data.graveyard}
          onChange={this.changeData}
          label={strings.mainBookOfBuriedList.graveyard}
          name={"graveyard"}
          nameKey={"name"}
          valueKey={"id"}
        />

      </div>
    );
  }

  renderTableRow(data = []) {

    let result = [];
    let expiryDate;
    let currentDate = new Date();
    let expiryDateIncremented;

    for (let item of data) {

      expiryDate = item.expiryDate != null ? new Date(item.expiryDate) : "";
      expiryDateIncremented = expiryDate;

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      if (expiryDate != "") {
        expiryDateIncremented.setDate(expiryDate.getDate() + 1);
        if (expiryDateIncremented.getTime() < currentDate.getTime()) {
          className = 'backgroundColorExpired';
        }
      }

      if (item.canceled) {
        className = 'backgroundColorCancel';
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );

  }

  renderTable(data) {

    return <Table>

      {
        this.state.lockTable &&
        <div className='lock-table'>
          <LinearProgress />
        </div>
      }

      {this.renderTableHeader()}
      {this.renderTableRow(data)}
      {this.renderTableFooter()}
    </Table>
  }

  renderAddCitizen() {
    this.setState({
      showCitizen: true,
    });
  }

  renderAddPac() {
    this.transferDataFromCitizenToPac(this.state.data);
    this.setState({
      showPac: true,
    });
  }

  areEqual() {
    var len = arguments.length;
    for (var i = 1; i < len; i++) {
      if (arguments[i] === null || arguments[i] !== arguments[i - 1])
        return false;
    }
    return true;
  }

  handleTextInputChangePac = (e) => {
    if (e.target.name === "postalNumber" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getPostalCodes(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              postalNumbers: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === 'settlement' && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getSettlements(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlementCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "code,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
  }

  handleTextInputChange = (e) => {
  
    if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "name,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "postalCodeImproved" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: 'city',
        userId: 0
      };

      {
        data.companyId != undefined &&
        getPostalCodesImproved(data).then(response => {

            if (!response.ok) {
              return;
            }
            this.setState({
              zip: response.data.entities,
            }, () => {
              this.getPucs()
            });
          });
      }


    }
    else if (e.target.name === "street") {
      this.setState({

        data: {
          ...this.state.data,
          street: e.target.value
        }

      }, () => {
        this.getPucs()
      });
    }
    else if (e.target.name === "streetNumber") {
      this.setState({

        data: {
          ...this.state.data,
          streetNumber: e.target.value
        }

      }, () => {
        this.getPucs()
      });
    }

    {
      this.state.data.street && this.state.data.streetNumber && this.state.data.postalCode &&
        getAllPostalAddressCodes().then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            ...this.state.data,
            paks: response.data.entities
          }, () => {
            var postalNumber = this.state.data.postalCode.split(" ")[0];
            var place = this.state.data.postalCode.substring(this.state.data.postalCode.indexOf(" ") + 1);

            var id1, id2, id3, id4;

            for (let i = 0; i < this.state.paks.length; i++) {
              if (this.state.data.street == this.state.paks[i].street) {
                id1 = this.state.paks[i].id;
              }
              if (this.state.data.streetNumber == this.state.paks[i].number) {
                id2 = this.state.paks[i].id;
              }
              if (postalNumber == this.state.paks[i].postalNumberCode) {
                id3 = this.state.paks[i].id;
              }
              if (place == this.state.paks[i].place) {
                id4 = this.state.paks[i].id;
              }

              if (id1 != undefined && id2 != undefined && id3 != undefined && id4 != undefined && this.areEqual(id1, id2, id3, id4)) {
                let citizenPak = this.state.paks[i].pac;

                this.setState({

                  data: {
                    ...this.state.data,
                    pac: citizenPak,
                  },
                  flagAddNewPac: false
                });
                break;
              }
              else {
                this.setState({

                  data: {
                    ...this.state.data,
                    pac: null,
                  },
                  flagAddNewPac: true
                })
              }
            }
          });
        });
    }

  }

  getPucs () {
    let d = {
      street: this.state.data.street ? this.state.data.street : "", 
      companyId: this.state.companyId,
      sort: "pac,ASC",
      userId: 0,
      stateId: this.state.data.state ? this.state.data.state.id : 0,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : 0,
      streetNumber: this.state.data.streetNumber ? this.state.data.streetNumber : "",
    }   
      d.companyId != undefined &&
        getPostalAddressCodesWithParams(d).then(response =>{
      
        if(!response.ok){
          return;
        }
         this.setState({
          data: {
            ...this.state.data,
            pac: response.data.pac,

          }
          
        })
      });
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    }, () => {
      this.getPucs()
    });
  }

  transferDataFromCitizenToPac(someData) {

    let myString = someData.postalCode == undefined ? "" : someData.postalCode;
    myString = myString.substring(myString.indexOf(" ") + 1);
    this.setState({
      data: {
        ...this.state.data,
        street: someData.street,
        number: someData.streetNumber,
        place: myString,
      }
    });

  }

  renderPacForm() {

    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.postalAddressCode.addPageTitle}</h1>
      </div>

      <Paper className='paper'>
        <PostalAddressCodeForm
          onChange={this.changeData}
          onSubmit={this.submitPac}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowPac}
          isDisabled={this.props.isDisabled}
          onAutocompleteChange={this.onAutocompleteChange}
          handleChange={this.handleChange}
          handleTextInputChange={this.handleTextInputChangePac}
          flag={"add"}
          postalNumbers={this.state.postalNumbers == undefined ? [] : this.state.postalNumbers}
          settlementCodes={this.state.settlementCodes == undefined ? [] : this.state.settlementCodes}
          stateCodes={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
        />
      </Paper>

    </Grid>


  }

  changeStateShowPac() {
    this.setState({
      ...this.state,
      showPac: false,
      data: {
        ...this.state.data,
        number: "",
        place: "",
        pac: "",
        state: undefined,
        settlement: undefined,
        postalNumber: undefined,
      },
      errors: {},
    });
  }


  renderCitizenForm() {
    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.citizen.pageTitle}</h1>
      </div>

      <Paper className='paper'>
        <CitizenForm
          onChange={this.changeData}
          onSubmit={this.submit}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowCitizen}
          handleChange={this.handleChange}
          onAutocompleteChange={this.onAutocompleteChange}
          handleTextInputChange={this.handleTextInputChange}
          onChangePersonalNumberField={this.onChangePersonalNumberField}
          flag={"add"}
          flagAddNewPac={this.state.flagAddNewPac}
          renderAddPac={this.renderAddPac}
          states={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
          citiesAndZips={this.state.zip == undefined ? [] : this.state.zip}

        />
      </Paper>

    </Grid>
  }

  changeStateShowCitizen() {
    this.setState({
      ...this.state,
      showCitizen: false,
      data: {
        personalNumber: "",
        name: "",
        postalCode: "",
        street: "",
        streetNumber: "",
        surname: "",
        telephone: "",
      },
      errors: {},
    });
  }

  render() {

    return (
      <Grid id='table-page'>
        {
          this.state.showCitizen &&

          <Drawer id='drawer' anchor='left' open={this.showDrawer()} onClose={() => this.changeStateShowCitizen()} ModalProps={{disableBackdropClick: true,}} >
            <DrawerWrapper onBack={() => this.changeStateShowCitizen()}>
              {this.renderCitizenForm()}
            </DrawerWrapper>
          </Drawer>

        }

        {
          this.state.showPac &&

          <Drawer id='drawer' anchor='left' open={this.showDrawer()} onClose={() => this.changeStateShowPac()} ModalProps={{disableBackdropClick: true,}} >
            <DrawerWrapper onBack={() => this.changeStateShowPac()}>
              {this.renderPacForm()}
            </DrawerWrapper>
          </Drawer>

        }
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {
              this.renderButtons()
            }
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>


        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>


      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReservationList)));