import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getCitizenById } from "../../services/citizen/CitizenService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import { getEditedRenewalById } from "../../services/renewalEdited/RenewalEditedService";
import RenewalEditedForm from '../../components/forms/renewalEdited/RenewalEditedForm';

class ViewRenewal extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      submitter: {},
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    if (this.props.modifiedData) {
      {
        this.state.data.submitterId &&
          getCitizenById(this.state.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                submitter: response.data
              },
              submitter: {
                nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                addressSubmitter: response.data.street + " " + response.data.streetNumber,
                postalCode: response.data.postalCodeImprovedZip,
                telephone: response.data.telephone,
                personalNumber: response.data.personalNumber,
              },
            });
          });
      }
      {
        this.state.data.costId &&
          getCitizenById(this.state.data.costId, this.props.auth.user.id, this.props.auth.user.company.id).then(response =>{
            this.setState({
              data:{ 
                ...this.state.data,
                cost: response.data
              },
              cost: {
                nameSurname: response.data.name + " " + response.data.surname,
                address: response.data.street + " " + response.data.streetNumber,
                municipality: response.data.postalCodeImprovedZip,
              }
            });
          });
      }
      {
        this.state.data.graveyardId &&
          getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                graveyard: response.data
              },
            });
          });
      }
      {
        this.state.data.graveSiteId &&
          getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                graveSite: response.data,
              },
            });
          })
      }
    } else {
      getEditedRenewalById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
          data: response.data
        }, () => {
          {
            this.state.data.submitterId &&
              getCitizenById(this.state.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    postalCode: response.data.postalCodeImprovedZip,
                    telephone: response.data.telephone,
                    personalNumber: response.data.personalNumber,
                  },
                });
              });
          }
          this.state.data.costId &&
          getCitizenById(this.state.data.costId, this.props.auth.user.id, this.props.auth.user.company.id).then(response =>{
          
            this.setState({
              data:{ ...this.state.data,
              cost: response.data},
              cost: {
                personalNumber: response.data.personalNumber,
                nameSurname: response.data.name + " " + response.data.surname,
                address: response.data.street + " " + response.data.streetNumber,
                municipality: response.data.postalCodeImprovedZip,
              }
            })
          })
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data
                  },
                });
              })
          }
        });
      });
    }
  }

  render() {
    
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.renewal.viewPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <RenewalEditedForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            submitters={this.state.submitters == undefined ? [] : this.state.submitters}
            submitter={this.state.submitter}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            flag={this.props.flag}
            cost={this.state.cost}
            searchTypeFlag={this.props.flag}
            graveyard={this.state.data.graveyard == undefined ? "" : this.state.data.graveyard.name}
            graveSite={this.state.data.graveSite == undefined ? "" : this.state.data.graveSite.code}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewRenewal)
  )
);
