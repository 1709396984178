import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAutocompleteObjectsById, getScheduledCremationById } from "../../services/scheduledCremation/ScheduledCremationService";
import { validatePersonalNumber, getDateFromIdentificationNumber } from "../../functions/Validation";
import { getAdditionalItems, getAttachedDocumentTypes, getFarewellMusicTypes, getFarewellTypes, getFuneralTypes, getNecessaryArrangements, getProofOfWishTypes, getUrnStorageTypes } from "../../util/ReviewsUtil";
import DeceasedCremationSchedulingForm from "../../components/forms/cremationScheduling/DeceasedCremationSchedulingForm";
import DeathReportCremationSchedulingForm from "../../components/forms/cremationScheduling/DeathReportCremationSchedulingForm";
import CremationDetailsForm from "../../components/forms/cremationScheduling/CremationDetailsForm";
import CremationRequestForm from "../../components/forms/cremationScheduling/CremationRequestForm";


function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewScheduledCremation extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
      funeralType: [],
      funeralTypes: {},
      farewellType: [],
      farewellTypes: {},
      necessaryArrangement: [],
      necessaryArrangements: {},
      additionalItem: [],
      additionalItems: {},
      musicTypes: {},
      selectedMusicType: { id: -1 },
      urnStorageType: [],
      urnStorageTypes: {},
      attachedDocument: [],
      attachedDocuments: {},
      proofOfWish: [],
      proofOfWishes: {},
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getScheduledCremationById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data,
        funeralTypes: getFuneralTypes(),
        farewellTypes: getFarewellTypes(),
        necessaryArrangements: getNecessaryArrangements(),
        additionalItems: getAdditionalItems(),
        musicTypes: getFarewellMusicTypes(),
        urnStorageTypes: getUrnStorageTypes(),
        attachedDocuments: getAttachedDocumentTypes(),
        proofOfWishes: getProofOfWishTypes(),
      }, () => {

        this.setState({
          funeralType: this.state.funeralTypes.find(type => type.value === this.state.data.funeralType),
          farewellType: this.state.farewellTypes.find(type => type.value === this.state.data.farewellType),
          selectedMusicType: this.state.musicTypes.find(type => type.value === this.state.data.farewellMusicType),
          urnStorageType: this.state.urnStorageTypes.find(type => type.value === this.state.data.urnStorageType),
          proofOfWish: this.state.proofOfWishes.find(type => type.value === this.state.data.cremationWishDocumentType),
        });

        this.populateNecessaryArrangements();

        this.populateAdditionalItems();

        this.populateAttachedDocuments();

        //this.populateFarewellType();

        this.checkIfValidatePersonalNumber(this.state.data.identificationNumber);

        getAutocompleteObjectsById(this.state.data).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              occupation: response.data.occupation,
              municipalityOfBirth: response.data.municipalityOfBirth,
              municipalityOfResidence: response.data.municipalityOfResidence,
              stateOfResidence: response.data.stateOfResidence,
              municipalityOfDeath: response.data.municipalityOfDeath,
              causeOfDeath: response.data.causeOfDeath,
              doctor: response.data.doctor,
              submitter: response.data.submitter,
              cost: response.data.cost,
              firmCost: response.data.firmCost,
              firmPays: this.state.data.firmPays,
              userGraveSite: response.data.userGraveSite,
              municipalityOfFuneral: response.data.municipalityOfFuneral,
              graveyard: response.data.graveyard,
              graveSite: response.data.graveSite,
              farewellTransitMunicipality: response.data.farewellTransitMunicipality,
              transporter: response.data.transporter,
              funeralUserGraveSite: response.data.funeralUserGraveSite,
              funeralSubmitter: response.data.funeralSubmitter,
              funeralArranger: response.data.funeralArranger,
              urn: response.data.urn,
            },
            submitter: {
              nameSurnameSubmitter: response.data.submitter ? response.data.submitter.name + " " + response.data.submitter.surname : "",
              addressSubmitter: response.data.submitter ? response.data.submitter.street + " " + response.data.submitter.streetNumber : "",
              municipalitySubmitter: response.data.submitter ? response.data.submitter.postalCodeImprovedZip : "",
              telephoneSubmitter: response.data.submitter ? response.data.submitter.telephone : "",
            },
            costInfo: {
              nameSurnameCosts: response.data.cost ? response.data.cost.name + " " + response.data.cost.surname : "",
              addressCosts: response.data.cost ? response.data.cost.street + " " + response.data.cost.streetNumber : "",
              municipalityCosts: response.data.cost ? response.data.cost.postalCodeImprovedZip : "",
              telephoneCosts: response.data.cost ? response.data.cost.telephone : "",
            },
            userInfo: {
              nameSurnameUser: response.data.userGraveSite ? response.data.userGraveSite.name + " " + response.data.userGraveSite.surname : "",
              addressUser: response.data.userGraveSite ? response.data.userGraveSite.street + " " + response.data.userGraveSite.streetNumber : "",
              municipalityUser: response.data.userGraveSite ? response.data.userGraveSite.postalCodeImprovedZip : "",
              telephoneUser: response.data.userGraveSite ? response.data.userGraveSite.telephone : "",
            },
            firmInfo: {
              taxNumberCost: response.data.firmCost ? response.data.firmCost.taxNumber : "",
              headquarters: response.data.firmCost ? response.data.firmCost.headquarters : "",
              telephoneFirm: response.data.firmCost ? response.data.firmCost.telephone : "",
              contactPerson: response.data.firmCost ? response.data.firmCost.contactPerson : "",
            },
          })
        });
      });
    });
  }

  populateNecessaryArrangements() {
    const filteredNecessaryArrangements = this.state.necessaryArrangements.filter(arrangement => {
      switch (arrangement.value) {
        case strings.funeralScheduling.removeAndReturnGravePlate:
          return this.state.data.removeAndReturnGravePlate ? arrangement : null;
        case strings.funeralScheduling.dismantleTombstone:
          return this.state.data.dismantleTombstone ? arrangement : null;
        case strings.funeralScheduling.breakTheExtentOfTheGraveSite:
          return this.state.data.breakTheExtentOfTheGraveSite ? arrangement : null;
        case strings.funeralScheduling.hireServicesForArrangingAndMaintainingGreenSurfaces:
          return this.state.data.hireServicesForArrangingAndMaintainingGreenSurfaces ? arrangement : null;
        default:
          return null;
      }
    });

    const selectedArrangements = filteredNecessaryArrangements.filter(item => item !== null);

    this.setState({
      necessaryArrangement: selectedArrangements
    });
  }

  populateAdditionalItems() {
    const filteredAdditionalItems = this.state.additionalItems.filter(item => {
      switch (item.value) {
        case strings.funeralScheduling.microphone:
          return this.state.data.microphone ? item : null;
        case strings.funeralScheduling.deathbed:
          return this.state.data.deathbed ? item : null;
        case strings.funeralScheduling.pillowsForDecorations:
          return this.state.data.pillowsForDecorations ? item : null;
        case strings.funeralScheduling.tableForAttendees:
          return this.state.data.tableForAttendees ? item : null;
        default:
          return null;
      }
    });

    const selectedItems = filteredAdditionalItems.filter(item => item !== null);

    this.setState({
      additionalItem: selectedItems
    });
  }

  populateAttachedDocuments() {
    const filteredAttachedDocuments = this.state.attachedDocuments.filter(item => {
        switch (item.value) {
            case strings.cremationScheduling.deathCertificate:
                return this.state.data.attachedDeathCertificate ? item : null;
            case strings.cremationScheduling.extractFromRegistryBookOfDead:
                return this.state.data.attachedExtractFromRegistryBookOfDead ? item : null;
            case strings.cremationScheduling.invoiceFromPurchasedFuneralEquipment:
                return this.state.data.attachedInvoiceFromPurchasedFuneralEquipment ? item : null;
            case strings.cremationScheduling.authorizationPowerOfAttorneyForLegalEntity:
                return this.state.data.attachedAuthorizationPowerOfAttorneyForLegalEntity ? item : null;
            case strings.cremationScheduling.approvalFromTheAuthority:
                return this.state.data.attachedApprovalFromTheAuthority ? item : null;
            case strings.cremationScheduling.contractForLeasedGraveSite:
                return this.state.data.attachedContractForLeasedGraveSite ? item : null;
            case strings.cremationScheduling.proofOfDeceasedWishToBeCremated:
                return this.state.data.attachedProofOfDeceasedWishToBeCremated ? item : null;
            default:
                return null;
        }
      });
  
      const selectedItems = filteredAttachedDocuments.filter(item => item !== null);
  
      this.setState({
        attachedDocument: selectedItems
      });
  }

  populateFarewellType() {
    const selectedFarewellType = this.state.data.farewellType ? (this.state.data.farewellType === "transit" ? "transit" : "cremation") : null

    this.setState({
        data: {
          ...this.state.data,
          farewellType: selectedFarewellType
        }
    });
  }

  checkIfValidatePersonalNumber(identificationNumber) {
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (validatePersonalNumber(identificationNumber)) {
      dateOfBirth = getDateFromIdentificationNumber(identificationNumber);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateFlag: "validIDNumber",
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
      });
    }
    else {
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleNecessaryArrangementCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.necessaryArrangement.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            necessaryArrangement: [...prevState.necessaryArrangement, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            necessaryArrangement: prevState.necessaryArrangement.filter(item => item.id !== id)
        }));
    }
}

handleAttachedDocumentsCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.attachedDocument.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            attachedDocument: [...prevState.attachedDocument, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            attachedDocument: prevState.attachedDocument.filter(item => item.id !== id)
        }));
    }
}

handleAdditionalItemsCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.additionalItem.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            additionalItem: [...prevState.additionalItem, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            additionalItem: prevState.additionalItem.filter(item => item.id !== id)
        }));
    }
}

handleFuneralCheckboxChange = (type, id, value) => (event) => {
  const checked = event.target.checked;
  if (checked) {
    this.setState({
      funeralType: { id, value: value, name: type}
    });
  }
};

handleFarewellTypeCheckboxChange = (type, id, value) => (event) => {
  const checked = event.target.checked;
  if (checked) {
    this.setState({
      farewellType: { id, value: value, name: type}
    });
  }
};

handleUrnStorageCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            urnStorageType: { id, value: value, name: type}
        });
    }
};

handleFarewellMusicTypeChange = (event) => {
    const selectedMusicType = this.state.musicTypes.find(musicType => musicType.id === event.target.value);
    this.setState({
        selectedMusicType: selectedMusicType
    });
}

handleProofOfWishCheckboxChange = (type, id, value) => (event) => {
  const checked = event.target.checked;
  if (checked) {
      this.setState({
        proofOfWish: { id, value: value, name: type}
      });
  }
};

  render() {
    return (
        <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.cremationScheduling.viewPageTitle}</h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.cremationScheduling.deceased} className="fourTabs" />
              <Tab label={strings.cremationScheduling.deathReport} className="fourTabs" />
              <Tab label={strings.cremationScheduling.cremationDetails} className="fourTabs" />
              <Tab label={strings.cremationScheduling.cremationRequest} className="fourTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedCremationSchedulingForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
              onChangePersonalNumberField={this.onChangePersonalNumberField}
              date={(new Date().getFullYear())}
              defaultFamousCitizen={this.state.data.defaultFamousCitizen}
              disabled={this.state.disabled}
              isDisabled={this.props.isDisabled}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportCremationSchedulingForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              flagAddNewSubmitter={this.state.flagAddNewSubmitter}
              renderAddCitizen={this.props.flagAddCitizen}
              flagMarginValid={this.state.flagMarginValid}
              flagAddNewCost={this.state.flagAddNewCost}
              flagMarginValid2={this.state.flagMarginValid2}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              firmInfo={this.state.firmInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
              disabled={this.state.disabled}
              isDisabled={this.props.isDisabled}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <CremationDetailsForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              municipalitiesOfFuneral={this.state.municipalitiesOfFuneral == undefined ? [] : this.state.municipalitiesOfFuneral}
              farewellTransitMunicipalities={this.state.farewellTransitMunicipalities == undefined ? [] : this.state.farewellTransitMunicipalities}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
              flag={this.props.flag}
              typedInGraveSite={this.state.typedInGraveSite}
              userInfo={this.state.userInfo}
              //keyPress={this.keyPress}
              keyPressBackspace={this.keyPressBackspace}
              disabled={this.state.disabled}
              transporters={this.state.transporters}
              handleFuneralCheckboxChange={this.handleFuneralCheckboxChange}
              funeralType={this.state.funeralType}
              funeralTypes={this.state.funeralTypes}
              handleNecessaryArrangementCheckboxChange={this.handleNecessaryArrangementCheckboxChange}
              necessaryArrangement={this.state.necessaryArrangement}
              necessaryArrangements={this.state.necessaryArrangements}
              handleAdditionalItemsCheckboxChange={this.handleAdditionalItemsCheckboxChange}
              additionalItem={this.state.additionalItem}
              additionalItems={this.state.additionalItems}
              musicTypes={this.state.musicTypes}
              selectedMusicType={this.state.selectedMusicType}
              handleFarewellMusicTypeChange={this.handleFarewellMusicTypeChange}
              isDisabled={this.props.isDisabled}
              handleFarewellTypeCheckboxChange={this.handleFarewellTypeCheckboxChange}
              farewellType={this.state.farewellType}
              farewellTypes={this.state.farewellTypes}
            />

          </TabContainer>}
          {this.state.value === 3 && <TabContainer>

                <CremationRequestForm
                    onChange={this.changeData}
                    onSubmit={this.submit}
                    data={this.state.data}
                    errors={this.state.errors}
                    onCancel={this.props.onCancel}
                    handleChange={this.handleChange}
                    onAutocompleteChange={this.onAutocompleteChange}
                    handleTextInputChange={this.handleTextInputChange}
                    municipalitiesOfFuneral={this.state.municipalitiesOfFuneral == undefined ? [] : this.state.municipalitiesOfFuneral}
                    farewellTransitMunicipalities={this.state.farewellTransitMunicipalities == undefined ? [] : this.state.farewellTransitMunicipalities}
                    graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
                    graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
                    stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
                    flag={this.props.flag}
                    typedInGraveSite={this.state.typedInGraveSite}
                    userInfo={this.state.userInfo}
                    urns={this.state.urns == undefined ? [] : this.state.urns}
                    municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
                    occupations={this.state.occupations == undefined ? [] : this.state.occupations}
                    causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
                    submitters={this.state.submitters == undefined ? [] : this.state.submitters}
                    firms={this.state.firms == undefined ? [] : this.state.firms}
                    submitter={this.state.submitter}
                    firmCost={this.state.firmCost}
                    firmInfo={this.state.firmInfo}
                    //keyPress={this.keyPress}
                    keyPressBackspace={this.keyPressBackspace}
                    disabled={this.state.disabled}
                    transporters={this.state.transporters}
                    handleUrnStorageCheckboxChange={this.handleUrnStorageCheckboxChange}
                    urnStorageType={this.state.urnStorageType}
                    urnStorageTypes={this.state.urnStorageTypes}
                    handleAttachedDocumentsCheckboxChange={this.handleAttachedDocumentsCheckboxChange}
                    attachedDocument={this.state.attachedDocument}
                    attachedDocuments={this.state.attachedDocuments}
                    handleProofOfWishCheckboxChange={this.handleProofOfWishCheckboxChange}
                    proofOfWish={this.state.proofOfWish}
                    proofOfWishes={this.state.proofOfWishes}
                    isDisabled={this.props.isDisabled}
                />
            </TabContainer>}
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewScheduledCremation)
  )
);
