import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeceasedFuneralSchedulingForm from '../../components/forms/funeralScheduling/DeceasedFuneralSchedulingForm';
import DeathReportFuneralSchedulingForm from '../../components/forms/funeralScheduling/DeathReportFuneralSchedulingForm';
import FuneralDetailsForm from '../../components/forms/funeralScheduling/FuneralDetailsForm';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { validatePersonalNumber, getDateFromIdentificationNumber } from "../../functions/Validation";
import { getAutocompleteObjectsById, getScheduledFuneralById } from "../../services/scheduledFuneral/ScheduledFuneralService";
import { getAdditionalItems, getFarewellMusicTypes, getFuneralTypes, getNecessaryArrangements, getFarewellTypes, getFarewellMusicType } from "../../util/ReviewsUtil";

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewScheduledFuneral extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
      funeralType: [],
      funeralTypes: {},
      farewellType: [],
      farewellTypes: {},
      necessaryArrangement: [],
      necessaryArrangements: {},
      additionalItem: [],
      additionalItems: {},
      musicTypes: {},
      selectedMusicType: { id: -1 }
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getScheduledFuneralById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data,
        funeralTypes: getFuneralTypes(),
        farewellTypes: getFarewellTypes(),
        necessaryArrangements: getNecessaryArrangements(),
        additionalItems: getAdditionalItems(),
        musicTypes: getFarewellMusicTypes(),
      }, () => {

        this.setState({
          funeralType: this.state.funeralTypes.find(type => type.value === this.state.data.funeralType),
          farewellType: this.state.farewellTypes.find(type => type.value === this.state.data.farewellType),
          selectedMusicType: this.state.musicTypes.find(type => type.value === this.state.data.farewellMusicType),
        });

        this.populateNecessaryArrangements();

        this.populateAdditionalItems();

        //this.populateFarewellType();

        this.checkIfValidatePersonalNumber(this.state.data.identificationNumber);

        getAutocompleteObjectsById(this.state.data).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              occupation: response.data.occupation,
              municipalityOfBirth: response.data.municipalityOfBirth,
              municipalityOfResidence: response.data.municipalityOfResidence,
              municipalityOfDeath: response.data.municipalityOfDeath,
              municipalityOfFuneral: response.data.municipalityOfFuneral,
              stateOfResidence: response.data.stateOfResidence,
              causeOfDeath: response.data.causeOfDeath,
              doctor: response.data.doctor,
              submitter: response.data.submitter,
              cost: response.data.cost,
              firmCost: response.data.firmCost,
              firmPays: this.state.data.firmPays,
              userGraveSite: response.data.userGraveSite,
              graveyard: response.data.graveyard,
              graveSite: response.data.graveSite,
              farewellTransitMunicipality: response.data.farewellTransitMunicipality,
              transporter: response.data.transporter,
              funeralUserGraveSite: response.data.funeralUserGraveSite,
              funeralSubmitter: response.data.funeralSubmitter,
              funeralArranger: response.data.funeralArranger,
              appointment: response.data.appointment
            },
            submitter: {
              nameSurnameSubmitter: response.data.submitter ? response.data.submitter.name + " " + response.data.submitter.surname : null,
              addressSubmitter: response.data.submitter ? response.data.submitter.street + " " + response.data.submitter.streetNumber : "",
              municipalitySubmitter: response.data.submitter ? response.data.submitter.postalCodeImprovedZip : "",
              telephoneSubmitter: response.data.submitter ? response.data.submitter.telephone : "",
            },
            costInfo: {
              nameSurnameCosts: response.data.cost ? response.data.cost.name + " " + response.data.cost.surname : "",
              addressCosts: response.data.cost ? response.data.cost.street + " " + response.data.cost.streetNumber : "",
              municipalityCosts: response.data.cost ? response.data.cost.postalCodeImprovedZip : "",
              telephoneCosts: response.data.cost ? response.data.cost.telephone : "",
            },
            userInfo: {
              nameSurnameUser: response.data.userGraveSite ? response.data.userGraveSite.name + " " + response.data.userGraveSite.surname : "",
              addressUser: response.data.userGraveSite ? response.data.userGraveSite.street + " " + response.data.userGraveSite.streetNumber : "",
              municipalityUser: response.data.userGraveSite ? response.data.userGraveSite.postalCodeImprovedZip : "",
              telephoneUser: response.data.userGraveSite ? response.data.userGraveSite.telephone : "",
            },
            firmInfo: {
              taxNumberCost: response.data.firmCost ? response.data.firmCost.taxNumber : "",
              headquarters: response.data.firmCost ? response.data.firmCost.headquarters : "",
              telephoneFirm: response.data.firmCost ? response.data.firmCost.telephone : "",
              contactPerson: response.data.firmCost ? response.data.firmCost.contactPerson : "",
            },
          })
        });
      });
    });
  }

  populateNecessaryArrangements() {
    const filteredNecessaryArrangements = this.state.necessaryArrangements.filter(arrangement => {
      switch (arrangement.value) {
        case strings.funeralScheduling.removeAndReturnGravePlate:
          return this.state.data.removeAndReturnGravePlate ? arrangement : null;
        case strings.funeralScheduling.dismantleTombstone:
          return this.state.data.dismantleTombstone ? arrangement : null;
        case strings.funeralScheduling.breakTheExtentOfTheGraveSite:
          return this.state.data.breakTheExtentOfTheGraveSite ? arrangement : null;
        case strings.funeralScheduling.hireServicesForArrangingAndMaintainingGreenSurfaces:
          return this.state.data.hireServicesForArrangingAndMaintainingGreenSurfaces ? arrangement : null;
        default:
          return null;
      }
    });

    const selectedArrangements = filteredNecessaryArrangements.filter(item => item !== null);

    this.setState({
      necessaryArrangement: selectedArrangements
    });
  }

  populateAdditionalItems() {
    const filteredAdditionalItems = this.state.additionalItems.filter(item => {
      switch (item.value) {
        case strings.funeralScheduling.microphone:
          return this.state.data.microphone ? item : null;
        case strings.funeralScheduling.deathbed:
          return this.state.data.deathbed ? item : null;
        case strings.funeralScheduling.pillowsForDecorations:
          return this.state.data.pillowsForDecorations ? item : null;
        case strings.funeralScheduling.tableForAttendees:
          return this.state.data.tableForAttendees ? item : null;
        default:
          return null;
      }
    });

    const selectedItems = filteredAdditionalItems.filter(item => item !== null);

    this.setState({
      additionalItem: selectedItems
    });
  }

  populateFarewellType() {
    // const selectedFarewellType = this.state.data.farewellType ? (this.state.data.farewellType === "transit" ? "transit" : "cremation") : null

    // this.setState({
    //     data: {
    //       ...this.state.data,
    //       farewellType: selectedFarewellType
    //     }
    // });
  }

  checkIfValidatePersonalNumber(identificationNumber) {
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (validatePersonalNumber(identificationNumber)) {
      dateOfBirth = getDateFromIdentificationNumber(identificationNumber);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateFlag: "validIDNumber",
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
      });
    }
    else {
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleNecessaryArrangementCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.necessaryArrangement.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            necessaryArrangement: [...prevState.necessaryArrangement, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            necessaryArrangement: prevState.necessaryArrangement.filter(item => item.id !== id)
        }));
    }
}

handleAdditionalItemsCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.additionalItem.some(item => item.id === id);

    if (checked && !exists) {
        this.setState(prevState => ({
            additionalItem: [...prevState.additionalItem, { id, value: type, name: type }]
        }));
    } else {
        this.setState(prevState => ({
            additionalItem: prevState.additionalItem.filter(item => item.id !== id)
        }));
    }
}

handleFuneralCheckboxChange = (type, id, value) => (event) => {
  const checked = event.target.checked;
  if (checked) {
    this.setState({
      funeralType: { id, value: value, name: type}
    });
  }
};

handleFarewellTypeCheckboxChange = (type, id, value) => (event) => {
  const checked = event.target.checked;
  if (checked) {
    this.setState({
      farewellType: { id, value: value, name: type}
    });
  }
};

handleFarewellMusicTypeChange = (event) => {
    const selectedMusicType = this.state.musicTypes.find(musicType => musicType.id === event.target.value);
    this.setState({
        selectedMusicType: selectedMusicType
    });
}

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
            <h1>{strings.funeralScheduling.viewPageTitle}</h1>
        </div>
        <div className="tabsDiv">
            <AppBar position="static">
                <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
                <Tab label={strings.funeralScheduling.deceased} className="threeTabs" />
                <Tab label={strings.funeralScheduling.deathReport} className="threeTabs" />
                <Tab label={strings.funeralScheduling.funeralDetails} className="threeTabs" />
                </Tabs>
            </AppBar>

            {this.state.value === 0 && <TabContainer>
                <DeceasedFuneralSchedulingForm
                    onChange={this.changeData}
                    onSubmit={this.submit}
                    data={this.state.data}
                    errors={this.state.errors}
                    onCancel={this.props.onCancel}
                    handleChange={this.handleChange}
                    onAutocompleteChange={this.onAutocompleteChange}
                    handleTextInputChange={this.handleTextInputChange}
                    transporters={this.state.transporters}
                    municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
                    states={this.state.states == undefined ? [] : this.state.states}
                    occupations={this.state.occupations == undefined ? [] : this.state.occupations}
                    causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
                    doctors={this.state.doctors == undefined ? [] : this.state.doctors}
                    flag={this.props.flag}
                    dateFlag={this.state.dateFlag}
                    dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
                    onChangePersonalNumberField={this.onChangePersonalNumberField}
                    date={(new Date().getFullYear())}
                    defaultFamousCitizen={this.state.data.defaultFamousCitizen}
                    disabled={this.state.disabled}
                    isDisabled={this.props.isDisabled}
                />
            </TabContainer>}

            {this.state.value === 1 && <TabContainer>
                <DeathReportFuneralSchedulingForm
                    onChange={this.changeData}
                    onSubmit={this.submit}
                    data={this.state.data}
                    errors={this.state.errors}
                    onCancel={this.props.onCancel}
                    handleChange={this.handleChange}
                    onAutocompleteChange={this.onAutocompleteChange}
                    handleTextInputChange={this.handleTextInputChange}
                    submitters={this.state.submitters == undefined ? [] : this.state.submitters}
                    submitter={this.state.submitter}
                    flagAddNewSubmitter={this.state.flagAddNewSubmitter}
                    renderAddCitizen={this.props.flagAddCitizen}
                    flagMarginValid={this.state.flagMarginValid}
                    flagAddNewCost={this.state.flagAddNewCost}
                    flagMarginValid2={this.state.flagMarginValid2}
                    identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
                    costs={this.state.costs == undefined ? [] : this.state.costs}
                    cost={this.state.cost}
                    identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
                    users={this.state.users == undefined ? [] : this.state.users}
                    userGraveSite={this.state.userGraveSite}
                    identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
                    costSameAsSubmitter={this.state.costSameAsSubmitter}
                    userSameAsCost={this.state.userSameAsCost}
                    userSameAsSubmitter={this.state.userSameAsSubmitter}
                    costInfo={this.state.costInfo}
                    userInfo={this.state.userInfo}
                    firmInfo={this.state.firmInfo}
                    flag={this.props.flag}
                    firms={this.state.firms == undefined ? [] : this.state.firms}
                    firmCost={this.state.firmCost}
                    disabled={this.state.disabled}
                    isDisabled={this.props.isDisabled}
                />
            </TabContainer>}

            {this.state.value === 2 && <TabContainer>
                <FuneralDetailsForm
                    onChange={this.changeData}
                    onSubmit={this.submit}
                    data={this.state.data}
                    errors={this.state.errors}
                    onCancel={this.props.onCancel}
                    handleChange={this.handleChange}
                    onAutocompleteChange={this.onAutocompleteChange}
                    handleTextInputChange={this.handleTextInputChange}
                    municipalitiesOfFuneral={this.state.municipalitiesOfFuneral == undefined ? [] : this.state.municipalitiesOfFuneral}
                    farewellTransitMunicipalities={this.state.farewellTransitMunicipalities == undefined ? [] : this.state.farewellTransitMunicipalities}
                    graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
                    graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
                    stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
                    flag={this.props.flag}
                    typedInGraveSite={this.state.typedInGraveSite}
                    userInfo={this.state.userInfo}
                    keyPressBackspace={this.keyPressBackspace}
                    disabled={this.state.disabled}
                    transporters={this.state.transporters}
                    handleFuneralCheckboxChange={this.handleFuneralCheckboxChange}
                    funeralType={this.state.funeralType}
                    funeralTypes={this.state.funeralTypes}
                    handleNecessaryArrangementCheckboxChange={this.handleNecessaryArrangementCheckboxChange}
                    necessaryArrangement={this.state.necessaryArrangement}
                    necessaryArrangements={this.state.necessaryArrangements}
                    handleAdditionalItemsCheckboxChange={this.handleAdditionalItemsCheckboxChange}
                    additionalItem={this.state.additionalItem}
                    additionalItems={this.state.additionalItems}
                    musicTypes={this.state.musicTypes}
                    selectedMusicType={this.state.selectedMusicType}
                    handleFarewellMusicTypeChange={this.handleFarewellMusicTypeChange}
                    isDisabled={this.props.isDisabled}
                    flagToClose={this.props.flagToClose}
                    openCloseScheduledFuneralDrawer={this.props.openCloseScheduledFuneralDrawer}
                    appointment={this.state.data.appointment}
                    handleFarewellTypeCheckboxChange={this.handleFarewellTypeCheckboxChange}
                    farewellType={this.state.farewellType}
                    farewellTypes={this.state.farewellTypes}
                />
            </TabContainer>}
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewScheduledFuneral)
  )
);
