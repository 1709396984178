import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { TableCell, Grid, TextField, Paper, Drawer, FormControlLabel, Switch } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UndoCancelIcon from '@material-ui/icons/Replay';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectControl from '../../components/controls/SelectControl';
import PageState from '../../constants/PageState';
import DrawerWrapper from '../../common/DrawerWrapper';
import UndoIcon from '@material-ui/icons/Undo';
import update from 'immutability-helper';
import { getRenewalSearches, getRenewalTypes } from '../../util/ReviewsUtil';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import { getYears } from '../../util/DateUtil';
import EditRenewal from './EditRenewal';
import ViewRenewal from './ViewRenewal';
import { deleteEditedRenewal, getDeletedRenewal, getEditedRenewals, restoreRenewal, stornoEditedRenewal, undoStornoEditedRenewal } from '../../services/renewalEdited/RenewalEditedService';
import { getPermissionAdministration } from '../../services/permissionAdministration/PermissionAdministrationService';


class RenewalList extends TablePage {

  tableDescription = [
    { key: 'renewalNumber', label: strings.renewalList.renewalNumber, sort: 'renewalNumber' },
    { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
    { key: 'graveSiteCode', label: strings.renewalList.canceled, sort: 'graveSiteCode' },
    { key: 'costName', label: strings.renewalList.canceled, sort: 'costName' },
    { key: 'costSurname', label: strings.renewalList.canceled, sort: 'costSurname' },
    { key: 'dateOfChange', label: strings.renewalList.dateOfChange, transform: 'renderColumnDate', sort: 'dateOfChange' },
    { key: 'expiryDate', label: strings.renewalList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'expiryDate' },
    { key: 'canceled', label: strings.renewalList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
    { key: 'dateOfCancellation', label: strings.renewalList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
    { key: 'dateCreated', label: strings.renewalList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];

  constructor(props) {
    super(props);

    const years = getYears();
    years.unshift({ value: -1, name: strings.years.all })

    this.state = {
      ...this.state,
      showAdd: false,
      selectRenewalType: [],
      data: {
        selectedRenewalType: { id: -1 },
        year: { value: "-1" },
        renewalType: { id: -1, value: "-1" },
        graveyard: { id: -1 },
      },
      filter: {
        renewalTypes: getRenewalTypes(),
        years: years,
      },
    };

    this.state.sortBy = "dateCreated,DESC";

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: 1,
      sort: this.state.sortBy,
      sNameSurname: this.state.data.selectedRenewalType.id == 1 ? this.state.searchData.search : "-1",
      deceasedNameSurname: this.state.data.selectedRenewalType.id == 2 ? this.state.searchData.search : "-1",
      graveSiteCode: this.state.data.selectedRenewalType.id == 3 ? this.state.searchData.search : "-1",
      graveyard: this.state.data.graveyard ? this.state.data.graveyard.id : -1,
      yearOfRenewal: this.state.data.year ? this.state.data.year.value : "-1",
      renewalType: this.state.data.renewalType ? this.state.data.renewalType.value : "-1",
      userId: this.props.auth.user.id,
      
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getEditedRenewals(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedRenewal(data).then(response =>{
        if (!response.ok) {
          return;
        }
        
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      })
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Обнове"
    }
    this.getPermissionsS1(data);
    this.removedItemsShow(this.props.removedItemsShow);

  }
  getPermissionsS1(data){
    
    getPermissionAdministration(data).then(response => {
      if(!response.ok){
      
        return;
      }
      this.setState({
        permissions: response.data.entities
      }, () =>{     
          this.state.permissions.forEach(element => {
            if(element.category != "Обнове" && element.code == "Додај"){
                this.setState({
                    showAdd : true
                  })
            }
                switch (element.code) {
                    case "Прегледај": this.setState({
                      showView : true
                    })
                    break;
                    case "Измени": this.setState({
                      showEdit: true
                    })
                    break;
                    case "Обриши": this.setState({
                      remove: true
                    })
                    break;
                    case "Поврати": this.setState({
                      showRestore: true
                    })
                    break;
                    case "Обрисане ставке": this.setState({
                      removedItemsShow: true
                    })
                    break;
                    case "Сторно": this.setState({
                      showCanceled: true
                    })
                    break;
                    case "Поврати сторно": this.setState({
                      showCanceledUndo: true
                    })
                    default:
                      return
                  }
          });

      })
    })
  }



  // usePermissions(){
  //   let data = {
  //     userId: this.props.auth.user.id,
  //     category: "Обнове"
  //   }
  //   this.getPermissionsS(data);
  //   this.showAdd(this.props.showAdd);
  //   this.showRemove(this.props.remove);
  //   this.showEdit(this.props.showEdit);
  //   this.showView(this.props.showView);
  //   this.removedItemsShow(this.props.removedItemsShow);

  // }

  componentDidMount() {

    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
      selectRenewalType: getRenewalSearches(),
    }, () => {
      this.state.selectRenewalType.unshift({ id: -1, name: strings.renewalTypes.all });
      this.state.filter.renewalTypes.unshift({ id: -1, value: "-1", name: strings.renewalTypes.all })
    });

    getAllGraveyards().then(response => {
      this.setState({
        filter: {
          ...this.state.filter,
          graveyards: response.data.entities
        }
      }, () => this.state.filter.graveyards.unshift({ id: -1, name: strings.years.all }));
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'renewalNumber', label: strings.renewalList.renewalNumber, sort: 'renewalNumber' },
      { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
      { key: 'graveSiteCode', label: strings.renewalList.graveSiteCode, sort: 'graveSiteCode' },
      { key: 'costName', label: strings.renewalList.costName, sort: 'costName' },
      { key: 'costSurname', label: strings.renewalList.costSurname, sort: 'costSurname' },
      { key: 'dateOfChange', label: strings.renewalList.dateOfChange, transform: 'renderColumnDate', sort: 'dateOfChange' },
      { key: 'expiryDate', label: strings.renewalList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
      { key: 'canceled', label: strings.renewalList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
      { key: 'dateOfCancellation', label: strings.renewalList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
      { key: 'dateCreated', label: strings.renewalList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
    
    this.setState({
      selectRenewalType: getRenewalSearches(),
      filter: {
        ...this.state.filter,
        renewalTypes: getRenewalTypes(),
      }
    }, () => {
      if (this.state.selectRenewalType[0].id != -1) {
        this.state.selectRenewalType.unshift({ id: -1, name: strings.renewalTypes.all });
      }

      this.fetchData();
    });
  }
  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }

  restore(item){
    
    restoreRenewal(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }

  transformRestoreRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }

  delete(item) {

    this.setState({
      lockTable: true
    });

    

    deleteEditedRenewal(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.renewalDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  handleMenuStorno(item) {

    stornoEditedRenewal(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.renewal.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.renewalCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  handleMenuUndoStorno(item) {

    undoStornoEditedRenewal(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.renewal.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.undoRenewalCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  getPageHeader() {
    return <br ></br>;
  }

  renderEditContent() {
    return <EditRenewal
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      searchTypeFlag={this.state.data.selectedRenewalType}
      auth={this.props.auth}
    />
  }

  renderViewContent() {
    return <ViewRenewal
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
      searchTypeFlag={this.props.selectedRenewalType}
      auth={this.props.auth}
    />
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.restartSelects(event.target.name);
      this.fetchData();
    });
  }

  restartSelects(eventName) {
    if (eventName == "selectedRenewalType") {
      this.setState({
        data: {
          ...this.state.data,
          year: { value: "-1" },
          renewalType: { id: -1, value: "-1" },
          graveyard: { id: -1 },
        }
      }, () => this.fetchData())
    }
  }

  renderFilters() {
    return (
      <div className="renderFilters">

{this.state.removedItemsShow &&
        <FormControlLabel
          control = {<Switch
            checked={this.state.showDeleted}
            onChange={this.handleDeletedChange}
            inputProps={{ 'aria-label': 'controlled' }}
            />}
            labelPlacement="start"
            label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
        />}
        <SelectControl
          options={this.state.selectRenewalType}
          selected={this.state.data.selectedRenewalType}
          onChange={this.changeData}
          label={strings.renewalType}
          name={"selectedRenewalType"}
          nameKey={"name"}
          valueKey={"id"}
        />
        {this.state.data.selectedRenewalType.id != -1 &&
          <React.Fragment>
            <SelectControl
              options={this.state.filter.years}
              selected={this.state.data.year}
              onChange={this.changeData}
              label={strings.renewalList.year}
              name={"year"}
              nameKey={"name"}
              valueKey={"value"}
            />

            <SelectControl
              options={this.state.filter.renewalTypes}
              selected={this.state.data.renewalType}
              onChange={this.changeData}
              label={strings.renewalList.renewalType}
              name={"renewalType"}
              nameKey={"name"}
              valueKey={"id"}
            />
          </React.Fragment>
        }

        {this.state.data.selectedRenewalType.id == 3 &&
          <SelectControl
            options={this.state.filter.graveyards}
            selected={this.state.data.graveyard}
            onChange={this.changeData}
            label={strings.renewalList.graveyard}
            name={"graveyard"}
            nameKey={"name"}
            valueKey={"id"}
          />
        }
      </div>
    );
  }


  renderRowMenu(index, item) {

    let ariaOwns = 'action-menu-' + index;
    return (
      <TableCell>
        <IconButton
          size='small'
          aria-owns={this.state.anchorEl ? ariaOwns : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, ariaOwns)}
        >
          <MoreVert />
        </IconButton>
        {
          ariaOwns === this.state.ariaOwns &&
          <Menu
            id={ariaOwns}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleMenuClose()}
          >
            {(!this.state.showDeleted && this.state.showView) && 
            <MenuItem onClick={() => this.handleMenuView(item)}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.view} />
            </MenuItem>}
            { (!this.state.showDeleted && this.state.showEdit)&&
            <MenuItem onClick={() => this.handleMenuEdit(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.edit} />
            </MenuItem>}
            {
             (!item[this.deletedField] && !this.state.showDeleted && this.state.remove) &&
              <MenuItem onClick={() => this.handleMenuDelete(item)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.delete} />
              </MenuItem>
            }
            {
              (!item.canceled && !this.state.showDeleted && this.state.showCanceled) &&
              <MenuItem onClick={() => this.handleMenuStorno(item)}>
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.cancel} />
              </MenuItem>
            }
            {
              (item.canceled && !this.state.showDeleted && this.state.showCanceledUndo) &&
              <MenuItem onClick={() => this.handleMenuUndoStorno(item)}>
                <ListItemIcon>
                  <UndoCancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undoCancel} />
              </MenuItem>
            }

          {
              this.state.showDeleted &&
              <MenuItem onClick={() => this.handleRestore(item)}>
                <ListItemIcon>
                  <UndoIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undo} />
              </MenuItem>
            }
          </Menu>
        }

      </TableCell>
    );
  }

  render() {
    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalList)));