import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getCauseOfDeaths(data) {
    return await request('/api/causeofdeaths/search', data);
}

export async function addCauseOfDeath(data, cid) {
    return await request('/api/causeofdeaths/' + cid, data, HttpMethod.POST);
}

export function getCauseOfDeathById(id, userId, companyId) {
    return  request("api/causeofdeaths/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editCauseOfDeath(data) {
    return await request("api/causeofdeaths/" + data.id, data, HttpMethod.PUT);
}

export async function deleteCauseOfDeath(id, uid) {
    return await request('/api/causeofdeaths/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedCauseOfDeath(data){
    return await request('/api/causeofdeaths/deleted', data)
}

export async function restoreCauseOfDeath(data) {
    return await request("api/causeofdeaths/restore/" + data.id, data, HttpMethod.PUT);
}