import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { getCitizenByPersonalNumber, getCitizens, getCitizenById } from '../../services/citizen/CitizenService';
import { getMainBooksOfBuried, getMainBookOfBuriedById } from '../../services/mainBookOfBuried/MainBookOfBuriedService';
import { getGraveyards, getGraveyardById } from '../../services/graveyard/GraveyardService';
import { getGraveSiteById, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard, addGraveSite, editGraveSite } from '../../services/graveSite/GraveSiteService';
import { getStonecutters, getStonecutterById } from '../../services/stonecutter/StonecutterService';
import Validators from '../../constants/ValidatorTypes';
import { editTombRent, getTombRentById } from '../../services/tombRent/TombRentService';
import TombRentForm from '../../components/forms/tombRent/TombRentForm';
import { stringToDate, stringToDate2 } from '../../util/DateUtil';
import { validateTypedInGraveSite } from "../../functions/Validation";


class EditTombRent extends FormComponent {

  validationList = {
    mainBookOfBuriedString: [{ type: Validators.REQUIRED }],
    dateOfRent: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      submitter: {},
      submitter2: {},
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
    this.keyPress = this.keyPress.bind(this);

  }

  componentDidMount() {
    if (this.props.modifiedData) {
      getTombRentById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
          data: response.data
        }, () => {
  
          {
            this.props.data.submitterId &&
              getCitizenById(this.props.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter: response.data.postalCodeImprovedZip,
                    telephoneSubmitter: response.data.telephone,
                  },
                });
              });
          }
          {
            this.props.data.submitter2Id &&
              getCitizenById(this.props.data.submitter2Id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter2: response.data
                  },
                  submitter2: {
                    nameSurnameSubmitter2: response.data.name + " " + response.data.surname,
                    addressSubmitter2: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter2: response.data.postalCodeImprovedZip,
                    telephoneSubmitter2: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data,
                    graveSiteExpiryDateString: response.data.expirationDateString,
                    graveSiteExpiryDate: this.state.data.graveSiteExpiryDate != null ? this.state.data.graveSiteExpiryDate : ((response.data.expirationDateString && response.data.expirationDate == null) ? (stringToDate2(response.data.expirationDateString)._d == 'Invalid Date' ? (stringToDate(response.data.expirationDateString)._d == 'Invalid Date' ? null : stringToDate(response.data.expirationDateString)._d) : stringToDate2(response.data.expirationDateString)._d) : response.data.expirationDate)
                  },
                });
              })
          }
          {
            this.state.data.stonecutterId &&
              getStonecutterById(this.state.data.stonecutterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    stonecutter: response.data
                  },
                });
              })
          }
        });
      });
    } else {
      getTombRentById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
          data: response.data
        }, () => {
  
          {
            this.state.data.submitterId &&
              getCitizenById(this.state.data.submitterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter: response.data.postalCodeImprovedZip,
                    telephoneSubmitter: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.submitter2Id &&
              getCitizenById(this.state.data.submitter2Id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter2: response.data
                  },
                  submitter2: {
                    nameSurnameSubmitter2: response.data.name + " " + response.data.surname,
                    addressSubmitter2: response.data.street + " " + response.data.streetNumber,
                    municipalitySubmitter2: response.data.postalCodeImprovedZip,
                    telephoneSubmitter2: response.data.telephone,
                  },
                });
              });
          }
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data,
                    graveSiteExpiryDateString: response.data.expirationDateString,
                    graveSiteExpiryDate: this.state.data.graveSiteExpiryDate != null ? this.state.data.graveSiteExpiryDate : ((response.data.expirationDateString && response.data.expirationDate == null) ? (stringToDate2(response.data.expirationDateString)._d == 'Invalid Date' ? (stringToDate(response.data.expirationDateString)._d == 'Invalid Date' ? null : stringToDate(response.data.expirationDateString)._d) : stringToDate2(response.data.expirationDateString)._d) : response.data.expirationDate)
                  },
                });
              })
          }
          {
            this.state.data.stonecutterId &&
              getStonecutterById(this.state.data.stonecutterId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    stonecutter: response.data
                  },
                });
              })
          }
        });
      });
    }
  }

  keyPress(event) {
    if (this.props.modifiedData) {
      return;
    }
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
          variant: "error"
        });
        return;
      }

      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {
        if (!response.ok) {

          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
            variant: "error"
          });

          this.handleError(messageKey);

          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            graveSite: response.data,
            graveSiteCode: response.data.code,
            graveSiteId: response.data.id
          }
        });

        this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

      });
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id
    }
  }

  transformRequestEditGraveSite(graveSite) {
    return {
      ...graveSite,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      belongingGraveyardId: this.state.data.graveyard.id,
      belongingGraveyardName: this.state.data.graveyard.name,
      expirationDate: this.state.data.expiryDate,
      identificationNumber: this.state.submitter.personalNumber,
    }
  }

  
  submit() {
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.tombRent.validationЕrror, { variant: 'error' });
      return;
    }

    if (this.state.data.mainBookOfBuriedString.length != 10) {
      this.setError("mainBookOfBuriedString", strings.mainBookOfBuriedString.errorLengthForEdit);
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    if (this.props.modifiedData) {
      this.props.enqueueSnackbar(strings.addCompany.tombRentEdited, { variant: 'success' });

      this.props.onFinish(this.transformModifiedData(this.state.data));

      this.hideDrawerLoader();
      return;
    }

    editGraveSite(this.transformRequestEditGraveSite(this.state.data.graveSite)).then(() => {
      editTombRent(this.transformRequest(this.state.data), this.props.cid).then(response => {
        if (!response.ok) {
          let messageKey = response.response.data.message;
  
          this.props.enqueueSnackbar(strings.tombRent.messages[messageKey], {
            variant: "error"
          });
  
          this.handleError(messageKey);
  
          return;
        }
        this.props.enqueueSnackbar(strings.addCompany.tombRentEdited, { variant: 'success' });
        this.props.onFinish(response.data.tombRent);
  
        this.hideDrawerLoader();
      });
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      submitter2Id: this.state.data.submitter2 ? this.state.data.submitter2.id : null,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      stonecutterId: this.state.data.stonecutter ? this.state.data.stonecutter.id : null,
    }
  }

  transformModifiedData(data) {
    return {
      ...data,
      dateOfChange: this.state.data.dateUpdated ? this.state.data.dateUpdated : this.props.data.dateOfChange,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      submitterNameSurname: this.state.data.submitter ? this.state.data.submitter.name + " " + this.state.data.submitter.surname : null,
      submitter2Id: this.state.data.submitter2 ? this.state.data.submitter2.id : null,
      graveyardName: this.state.data.graveyard ? this.state.data.graveyard.name : null,
      graveSiteCode: this.state.data.graveSite ? this.state.data.graveSite.code : null,
      contractExpiryDate: this.state.data.rentExpiryDate ? this.state.data.rentExpiryDate : this.props.data.contractExpiryDate,
      dateEdited: new Date(),
    }
  }

  handleError(message) {
    switch (message) {
      case "TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL":
        this.setError("mainBookOfBuriedString", strings.tombRent.messages.TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL);
        break;
      case "TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST":
        this.setError("mainBookOfBuriedString", strings.tombRent.messages.TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST);
        break;
    }
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            municipalitySubmitter: response.data.municipality,
            telephoneSubmitter: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "submitter2" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter2: {
            personalNumber2: response.data.personalNumber,
            nameSurnameSubmitter2: response.data.nameSurname,
            addressSubmitter2: response.data.address,
            municipalitySubmitter2: response.data.municipality,
            telephoneSubmitter2: response.data.telephone
          }
        });
      });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "personalNumber,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "submitter2" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "personalNumber,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters2: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });

    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "stonecutter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStonecutters(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stonecutters: response.data.entities,
            });
          });
      }
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.tombRent.editPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <TombRentForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            isDisabled={this.props.isDisabled}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            submitters={this.state.submitters == undefined ? [] : this.state.submitters}
            submitters2={this.state.submitters2 == undefined ? [] : this.state.submitters2}
            submitter={this.state.submitter}
            submitter2={this.state.submitter2}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            stonecutters={this.state.stonecutters == undefined ? [] : this.state.stonecutters}
            flag={this.props.flag}
            typedInGraveSite={this.state.typedInGraveSite}
            keyPress={this.keyPress}
            keyPressBackspace={this.keyPressBackspace}
          />
        </Paper>

      </Grid>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTombRent)));