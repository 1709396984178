import React from 'react'
import TablePage from "../../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import { withSnackbar } from "notistack";
import { getDeceasedReceptions, deleteDeceasedReception } from '../../../services/deceasedReception/DeceasedReceptionService';
import AddDeceasedTakeawayRecord from './AddDeceasedTakeawayRecord';
import EditDeceasedTakeawayRecord from './EditDeceasedTakeawayRecord';
import ViewDeceasedTakeawayRecord from './ViewDeceasedTakeawayRecord';


class DeceasedTakeawayRecordList extends TablePage {

  tableDescription = [
    { key: 'deceasedNameSurname', label: strings.deceasedTakeawayRecordList.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'dateCreated', label: strings.deceasedTakeawayRecordList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "deceasedNameSurname,ASC";

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      type: 3,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
        getDeceasedReceptions(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Записник о одношењу покојника"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'deceasedNameSurname', label: strings.deceasedTakeawayRecordList.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'dateCreated', label: strings.deceasedTakeawayRecordList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.deceasedTakeawayRecordList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddDeceasedTakeawayRecord
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
    />
  }

  renderEditContent() {
    return <EditDeceasedTakeawayRecord
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewDeceasedTakeawayRecord
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
      auth={this.props.auth}
    />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteDeceasedReception(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.deceasedTakeawayRecordDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(DeceasedTakeawayRecordList)));