import React from 'react';
import FormComponent from '../../common/FormComponent';
import Validators from '../../constants/ValidatorTypes';
import { getStoragePlaceById, editStoragePlace } from '../../services/storagePlace/StoragePlaceService';
import strings from "../../localization";
import StoragePlaceForm from '../../components/forms/storagePlace/StoragePlaceForm';
import { bindActionCreators } from "redux";
import { withSnackbar } from 'notistack';
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";

class EditStoragePlace extends FormComponent {

  validationList = {
    name: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getStoragePlaceById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data
      });
    });
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editStoragePlace(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.storagePlace.messages[messageKey], { variant: 'error' });
        this.handleError(messageKey);
        return;
      }

      this.props.enqueueSnackbar(strings.storagePlace.editForm.edited, { variant: 'success' });
      this.props.onFinish(response.data);

      this.hideDrawerLoader();

    });

  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid
    }
  }

  handleError(message) {
    switch (message) {
      case "STORAGE_WITH_NAME_ALREADY_EXIST":
        this.setError("name", strings.storagePlace.messages.STORAGE_WITH_NAME_ALREADY_EXIST);
        break;
      case "STORAGE_NOT_EXIST":
        this.setError("name", strings.storagePlace.messages.STORAGE_NOT_EXIST);
        break;
      case "STORAGE_NAME_CANT_BE_NULL":
        this.setError("name", strings.storagePlace.messages.STORAGE_NAME_CANT_BE_NULL);
        break;
    }
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.storagePlace.editPageTitle}</h1>
        </div>

        <Paper className="paper">
          <StoragePlaceForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
          />
        </Paper>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditStoragePlace)
  )
);