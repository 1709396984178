import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import DoctorForm from '../../components/forms/doctor/DoctorForm';
import { getDoctorById, editDoctor } from "../../services/doctor/DoctorService";

class EditDoctor extends FormComponent {

    validationList = {
        //  code: [{ type: Validators.REQUIRED }],
        name: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        getDoctorById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            });
        });
    }

    submit() {

        if (!this.validate()) {
            return;
        }

        this.showDrawerLoader();

        editDoctor(this.transformRequest(this.state.data)).then(response => {
            if (!response.ok) {
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.doctor.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.doctorEdited, { variant: 'success' });
            this.props.onFinish(response.data.state);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
            companyId: this.props.cid
        }
    }

    handleError(message) {
        switch (message) {
            // case "DOCTOR_WITH_CODE_ALREADY_EXIST":
            //     this.setError("code", strings.doctor.messages.DOCTOR_WITH_CODE_ALREADY_EXIST);
            //     break;
            // case "DOCTOR_CODE_CANT_BE_NULL":
            //     this.setError("code", strings.doctor.messages.DOCTOR_CODE_CANT_BE_NULL);
            //     break;
            case "DOCTOR_NAME_CANT_BE_NULL":
                this.setError("name", strings.doctor.messages.DOCTOR_NAME_CANT_BE_NULL);
                break;
            case "DOCTOR_WITH_NAME_ALREADY_EXIST":
                this.setError("name", strings.doctor.messages.DOCTOR_WITH_NAME_ALREADY_EXIST);
                break;
        }
    }

    render() {
        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.doctor.editPageTitle}</h1>
                </div>

                <Paper className="paper">
                    <DoctorForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                    />
                </Paper>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(EditDoctor)
    )
);
