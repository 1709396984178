import FormComponent from "../../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import { withSnackbar } from "notistack";
import { getDeceasedReceptionById, getAutocompleteObjectsById } from "../../../services/deceasedReception/DeceasedReceptionService";
import DeceasedTakeawayRecordForm from "../../../components/forms/deceasedReception/deceasedTakeawayRecord/DeceasedTakeawayRecordForm";
import { getAllTransporters } from "../../../services/transporter/TransporterService";

class ViewDeceasedTakeawayRecord extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
        };

        this.props.changeFullScreen(false);
    }

    componentDidMount() {

        getDeceasedReceptionById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                getAutocompleteObjectsById(this.state.data).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            transporterT: response.data.transporterT,
                        }
                    });
                });
            });
        });

        getAllTransporters().then(response => {
            this.setState({
                transporterTs: response.data.entities,
            });
        });
    }

    handleChangeTab = (event, value) => {
        this.setState({
            value: value
        });
    };

    render() {

        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.deceasedTakeawayRecord.viewPageTitle}</h1>
                </div>
                <Paper className="paper">
                    <DeceasedTakeawayRecordForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        isDisabled={this.props.isDisabled}
                        handleChange={this.handleChange}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        transporterTs={this.state.transporterTs}
                        flag={this.props.flag}
                    />
                </Paper>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ViewDeceasedTakeawayRecord)
    )
);
