import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { validateTypedInGraveSite } from "../../functions/Validation";
import { getAllGraveyards, getGraveyardById, getGraveyards } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById, editGraveSite, addGraveSite, getGraveSitesWithSelectedGraveyard } from "../../services/graveSite/GraveSiteService";
import GraveSiteForm from "../../components/forms/graveSite/GraveSiteForm";

class EditGraveSite extends FormComponent {

  validationList = {
    belongingGraveyard: [{ type: Validators.REQUIRED }],
    code: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
  
    getGraveSiteById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data,

      }, () => {
        getGraveyardById(this.state.data.belongingGraveyardId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              belongingGraveyard: response.data,
              renewal: this.state.data.renewal.toString(),
            },
          });
        });
      });
    });

    getAllGraveyards().then(response => {
      this.setState({
        graveyards: response.data.entities,
      });
    });
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id
    }
  }



  submit() {

    if (!this.validate()) {
  
      return;
    }



    if (this.state.typedInGraveSite != undefined && !validateTypedInGraveSite(this.state.typedInGraveSite)) {
      this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
        variant: "error"
      });
      return;
    }

    this.showDrawerLoader();

    editGraveSite(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.graveSiteEdited, { variant: 'success' });
      this.props.onFinish(response.data.graveSite);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      belongingGraveyardId: this.state.data.belongingGraveyard.id,
      belongingGraveyardName: this.state.data.belongingGraveyard.name,
    }
  }

  /*Handlers*/

  handleError(message) {
    switch (message) {
      case "GRAVE_SITE_GRAVEYARD_CANT_BE_NULL":
        this.setError("belongingGraveyard", strings.graveSite.messages.GRAVE_SITE_GRAVEYARD_CANT_BE_NULL);
        break;
      case "GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST":
        this.setError("belongingGraveyard", strings.graveSite.messages.GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST);
        break;
      case "GRAVE_SITE_ALREADY_EXIST":
        this.setError("code", strings.graveSite.messages.GRAVE_SITE_ALREADY_EXIST);
        break;

    }
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    });
    if (event.target.name == "renewal") {
      if (event.target.value == 'false') {
        this.setState({
          data: {
            ...this.state.data,
            expirationDate: null,
            [event.target.name]: event.target.value
          }
        });
      }
    } else if (event.target.name === "code") {
      let data = {
        searchParam: event.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.belongingGraveyard.id
      };

      this.setState({
        typedInGraveSite: event.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    }
  };

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */


  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.graveSite.editPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <GraveSiteForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            belongingGraveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            onAutocompleteChange={this.onAutocompleteChange}
            isDisabled={this.props.isDisabled}
            flag={this.props.flag}
            typedInGraveSite={this.state.typedInGraveSite}
            keyPressBackspace={this.keyPressBackspace}
          />
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditGraveSite)
  )
);
