import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getEquipmentOrders(data) {
    return await request('/api/equipmentOrders/search', data);
}

export async function addEquipmentOrder(data, cid) {
    return await request('/api/equipmentOrders/' + cid, data, HttpMethod.POST);
}

export function getEquipmentOrderById(id, userId, companyId) {
    return  request("api/equipmentOrders/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editEquipmentOrder(data) {
    return await request("api/equipmentOrders/" + data.id, data, HttpMethod.PUT);
}

export async function deleteEquipmentOrder(id, uid) {
    return await request('/api/equipmentOrders/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function restoreEquipmentOrder(data) {
    return await request("api/equipmentOrders/restore/" + data.id, data, HttpMethod.PUT);
}

export async function generateEquipmentOrderPdf(data) {
    return await request('/api/equipmentOrders/equipment-order/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function getDeletedEquipmentOrders(data) {
    return await request('/api/equipmentOrders/search/deleted', data);
}