import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getStonecutters(data) {
    return await request('/api/stonecutter/search', data);
}

export async function addStonecutter(data, cid) {
    return await request('/api/stonecutter/' + cid, data, HttpMethod.POST);
}

export function getStonecutterById(id, userId, companyId) {
    return  request("api/stonecutter/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editStonecutter(data) {
    return await request("api/stonecutter/" + data.id, data, HttpMethod.PUT);
}

export async function deleteStonecutter(id, uid) {
    return await request('/api/stonecutter/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedStonecutters(data){
    return await request('api/stonecutter/deleted', data)
}

export async function restoreStonecutter(data) {
    return await request("api/stonecutter/restore/" + data.id, data, HttpMethod.PUT);
}