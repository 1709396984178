import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { withSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import strings from "../../localization";
import { Paper } from "@material-ui/core";
import { getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import PostalCodeImprovedForm from "../../components/forms/postalCodeImproved/PostalCodeImprovedForm";
import { getStateById } from "../../services/state/StateService";

class ViewPostalCodeImproved extends FormComponent {


    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);


    }


    componentDidMount() {

        getPostalCodeImprovedById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                {
                    this.state.data.stateId &&
                        getStateById(this.state.data.stateId, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    state: response.data
                                },
                            });
                        });
                }
            });
        });


    }

    render() {

        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.postalCodeImproved.viewPageTitle}</h1>
                </div>

                <Paper className="paper">
                    <PostalCodeImprovedForm
                        onChange={this.changeData}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        isDisabled={this.props.isDisabled}
                        flag={this.props.flag}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        states={this.state.states == undefined ? [] : this.state.states}
                    />
                </Paper>
            </Grid>
        );
    }

}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ViewPostalCodeImproved)
    )
);