import React from 'react';
import Home from './pages/Home';
import Error from "./pages/Error";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";
import { Route } from 'react-router-dom';
import { isUserLoggedIn } from "./base/OAuth";
import Login from "./pages/user/Login";
import Lock from "./pages/user/Lock";
import EditProfile from './pages/user/EditProfile';
import UserList from './pages/user/UserList';
import CreatePassword from './pages/user/CreatePassword';
import ResetPassword from './pages/user/ResetPassword';
import ResetPasswordRequest from './pages/user/ResetPasswordRequest';
import ChangePassword from './pages/user/ChangePassword';
import CompanyList from './pages/company/CompanyList';
import AuditTrailList from './pages/auditTrail/AuditTrailList';
import OccupationList from './pages/occupation/OccupationList';
import StateList from './pages/state/StateList';
import CauseOfDeathList from './pages/causeOfDeath/CauseOfDeathList';
import TransporterList from './pages/transporter/TransporterList';
import DoctorList from './pages/doctor/DoctorList';
import PricelistList from './pages/pricelist/PricelistList';
import UrnList from './pages/urn/UrnList';
import FamousCitizenList from './pages/famousCitizen/FamousCitizenList';
import SettlementList from './pages/settlement/SettlementList';
import PostalCodeList from './pages/postalCode/PostalCodeList';
import StonecutterList from './pages/stonecutter/StonecutterList';
import GraveyardList from './pages/graveyard/GraveyardList';
import FirmList from './pages/firm/FirmList';
import CitizenList from './pages/citizen/CitizenList';
import AidList from './pages/aid/AidList';
import StoragePlaceList from './pages/storagePlace/StoragePlaceList';
import DeceasedBroughtList from './pages/deceasedBrought/DeceasedBroughtList';
import CrateList from './pages/crate/CrateList';
import TombstoneList from './pages/tombstone/TombstoneList';
import LetterList from './pages/letter/LetterList';
import LanguageList from './pages/language/LanguageList';
import TypefaceList from './pages/typeface/TypefaceList';
import DeceasedTakeoverRecordList from './pages/deceasedReception/deceasedTakeoverRecord/DeceasedTakeoverRecordList';
import UrnTakeoverRecordList from './pages/deceasedReception/urnTakeoverRecord/UrnTakeoverRecordList';
import DeceasedTakeawayRecordList from './pages/deceasedReception/deceasedTakeawayRecord/DeceasedTakeawayRecordList';
import MainBookOfBuriedList from './pages/mainBookOfBuried/MainBookOfBuriedList';
import GraveSiteList from './pages/graveSite/GraveSiteList';
import MainBookOfCrematedList from './pages/mainBookOfCremated/MainBookOfCrematedList';
import PrincipalList from './pages/principal/PrincipalList';
import ReasonBringingDeceasedList from './pages/reasonBringingDeceased/ReasonBringingDeceasedList';
import ExhumationList from './pages/exhumation/ExhumationList';
import StoneCuttingWorksList from './pages/stoneCuttingWorks/StoneCuttingWorksList';
import ReservationList from './pages/reservation/ReservationList';
import TombRentList from './pages/tombRent/TombRentList';
import CassetteRentList from './pages/cassetteRent/CassetteRentList';
import FuneralGraveyardAndUrnList from './pages/funeral/FuneralGraveyardAndUrnList';
import FuneralGraveFieldAndSelectedGraveyardList from './pages/funeral/FuneralGraveFieldAndSelectedGraveyardList';
import FuneralAllGraveyardsList from './pages/funeral/FuneralAllGraveyardsList';
import FuneralGraveFieldsAndGraveyardsForSelectedYearList from './pages/funeral/FuneralGraveFieldsAndGraveyardsForSelectedYearList';
import FuneralYearsAndSelectedGraveyardsList from './pages/funeral/FuneralYearsAndSelectedGraveyardsList';
import RenewalList from './pages/renewal/RenewalList';
import GrassList from './pages/grass/GrassList';
import ReviserReviewList from './pages/reviserReview/ReviserReviewList';
import ExpectedIncomeReviewList from './pages/expectedIncomeReview/ExpectedIncomeReviewList';
import ExpiryByGraveFieldReviewList from './pages/expiryByGraveFieldReview/ExpiryByGraveFieldReview';
import SoldGraveSitesForSelectedYearAndGraveyardList from './pages/reviews/tables/reservation/SoldGraveSitesForSelectedYearAndGraveyardList';
import SoldGraveSitesForSelectedYearAndGraveyardAndPlotList from './pages/reviews/tables/reservation/SoldGraveSitesForSelectedYearAndGraveyardAndPlotList';
import InheritedGraveSitesForCurrentYearPerMonthsList from './pages/reviews/tables/reservation/InheritedGraveSitesForCurrentYearPerMonthsList'
import InheritedGraveSitesForCurrentYearAndSelectedPlotPerMontsList from './pages/reviews/tables/reservation/InheritedGraveSitesForCurrentYearAndSelectedPlotPerMontsList';
import CardGraveSiteInputForm from './pages/reviews/reviewsRecords/CardGraveSiteInputForm';
import PostalAddressCodeList from './pages/postalAddressCode/PostalAddressCodeList';
import RenewalEditedInput from './pages/renewalEdited/RenewalEditedInput';
import PostalCodeImprovedList from './pages/postalCodeImproved/PostalCodeImprovedList';
import PostalAddressCodeImprovedList from './pages/postalAddressCodeImproved/PostalAddressCodeImprovedList';
import RolesList from './pages/roles/RolesList';
import PermissionsList from './pages/permissions/PermissionsList';
import UserRoleList from './pages/userRoles/UserRoleList';
import RolePermisionsList from './pages/rolePermissions/RolePermissionsList';
import RenewalProcessingList from './pages/renewalProcessing/RenewalProcessingList';
import LeaseProcessingList from './pages/leaseProcessing/LeaseProcessingList';
import ScheduledFuneralList from './pages/funeralScheduling/ScheduledFuneralList';
import ScheduledCremationList from './pages/cremationScheduling/ScheduledCremationList';
import AppointmentList from './pages/appointment/AppointmentList';
import EquipmentOrderList from './pages/equipmentOrder/EquipmentOrderList';
import ExhumationRequestList from './pages/exhumationRequest/ExhumationRequestList';
import CremationStatementList from './pages/cremationStatement/CremationStatementList';

let ROUTES = {
  Home: {
    path: '/',
    component: <Home />,
    auth: true
  },
  Error: {
    path: '/error',
    component: <Error />,
    auth: false
  },
  Forbidden: {
    path: '/forbidden',
    component: <Forbidden />,
    auth: false
  },
  NotFound: {
    path: '/not-found',
    component: <NotFound />,
    auth: false
  },
  Login: {
    path: '/login',
    component: <Login />,
    auth: false
  },
  Lock: {
    path: '/lock',
    component: <Lock />,
    auth: false
  },
  UserList: {
    path: '/users',
    component: <UserList showFilter={false} />,
    auth: true
  },
  Profile: {
    path: "/profile",
    component: <EditProfile />,
    auth: true
  },
  CreatePassword: {
    path: "/userConfirmation",
    component: <CreatePassword />,
    auth: false
  },
  ResetPasswordRequest: {
    path: "/resetPasswordRequest",
    component: <ResetPasswordRequest />,
    auth: false
  },
  ResetPassword: {
    path: "/resetPassword",
    component: <ResetPassword />,
    auth: false
  },
  ChangePassword: {
    path: "/changePassword",
    component: <ChangePassword />,
    auth: true
  },
  CompanyList: {
    path: '/companies',
    component: <CompanyList showFilter={false} />,
    auth: true
  },
  AidList: {
    path: '/aids',
    component: <AidList showFilter={false} />,
    auth: true
  },
  StoragePlaceList: {
    path: '/storages',
    component: <StoragePlaceList showFilter={false} />,
    auth: true
  },
  DeceasedBroughtList: {
    path: '/deceased',
    component: <DeceasedBroughtList showFilter={false} />,
    auth: true
  },
  CrateList: {
    path: '/crates',
    component: <CrateList showFilter={false} />,
    auth: true
  },
  TombstoneList: {
    path: '/tombstones',
    component: <TombstoneList showFilter={false} />,
    auth: true
  },
  LetterList: {
    path: '/letters',
    component: <LetterList showFilter={false} />,
    auth: true
  },
  LanguageList: {
    path: '/languages',
    component: <LanguageList showFilter={false} />,
    auth: true
  },
  TypefaceList: {
    path: '/typefaces',
    component: <TypefaceList showFilter={false} />,
    auth: true
  },

  FuneralGraveyardAndUrnList: {
    path: '/numberOfFuneralsAndUrnsPerMonthForSelectedYear',
    component: <FuneralGraveyardAndUrnList showFilter={false} />,
    auth: true,
  },

  FuneralGraveFieldAndSelectedGraveyardList: {
    path: '/numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite',
    component: <FuneralGraveFieldAndSelectedGraveyardList showFilter={false} />,
    auth: true,
  },

  FuneralAllGraveyardsList: {
    path: '/numberOfFuneralsForAllGraveyardsByTypeOfGraveSite',
    component: <FuneralAllGraveyardsList showFilter={false} />,
    auth: true,
  },

  FuneralGraveFieldsAndGraveyardsForSelectedYearList: {
    path: '/numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear',
    component: <FuneralGraveFieldsAndGraveyardsForSelectedYearList showFilter={false} />,
    auth: true,
  },

  FuneralYearsAndSelectedGraveyardsList: {
    path: '/numberOfFuneralsForYearsOfBurialAndSelectedGraveyards',
    component: <FuneralYearsAndSelectedGraveyardsList showFilter={false} />,
    auth: true,
  },

  SoldGraveSitesForSelectedYearAndGraveyardList: {
    path: '/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard',
    component: <SoldGraveSitesForSelectedYearAndGraveyardList showFilter={false} />,
    auth: true,
  },

  SoldGraveSitesForSelectedYearAndGraveyardAndPlotList: {
    path: '/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot',
    component: <SoldGraveSitesForSelectedYearAndGraveyardAndPlotList showFilter={false} />,
    auth: true,
  },

  InheritedGraveSitesForCurrentYearPerMonthsList: {
    path: '/numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths',
    component: <InheritedGraveSitesForCurrentYearPerMonthsList showFilter={false} />,
    auth: true,
  },

  InheritedGraveSitesForCurrentYearAndSelectedPlotPerMontsList: {
    path: '/numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths',
    component: <InheritedGraveSitesForCurrentYearAndSelectedPlotPerMontsList showFilter={false} />,
    auth: true,
  },

  CardGraveSiteInputForm: {
    path: '/cardGraveSiteInputForm',
    component: <CardGraveSiteInputForm showFilter={false} />,
    auth: true,
  },

  funeralSchedulingList: {
    path: '/funeralScheduling',
    component: <ScheduledFuneralList showFilter={false} />,
    auth: true
  },
  cremationSchedulingList: {
    path: '/cremationScheduling',
    component: <ScheduledCremationList showFilter={false} />,
    auth: true
  },
  appointmentList: {
    path: '/appointments',
    component: <AppointmentList showFilter={false} />,
    auth: true
  },
  equipmentOrderList: {
    path: '/equipmentOrders',
    component: <EquipmentOrderList showFilter={false} />,
    auth: true
  },
  exhumationRequestList: {
    path: '/exhumationRequests',
    component: <ExhumationRequestList showFilter={false} />,
    auth: true
  },




  PrincipalList: {
    path: '/principals',
    component: <PrincipalList showFilter={false} />,
    auth: true
  },
  ReasonBringingDeceasedList: {
    path: '/reasons',
    component: <ReasonBringingDeceasedList showFilter={false} />,
    auth: true
  },
  AuditTrailList: {
    path: '/auditTrails',
    component: <AuditTrailList showFilter={false} />,
    auth: true
  },
  Roles: {
    path: '/roles',
    component: <RolesList showFilter={false} />,
    auth: true
  },
  Permissions: {
    path: '/permissions',
    component: <PermissionsList showFilter={false}/>,
    auth: true
  },
  UserRoles: {
    path: '/user_roles',
    component: <UserRoleList showFilter={false} />,
    auth: true
  },
  RolePermissions:{
    path: '/role_permissions',
    component: <RolePermisionsList showFilter={false} />,
    auth: true
  },
  OccupationList: {
    path: '/occupations',
    component: <OccupationList showFilter={false} />,
    auth: true
  },
  StateList: {
    path: '/states',
    component: <StateList showFilter={false} />,
    auth: true
  },
  CauseOfDeathList: {
    path: '/causeOfDeaths',
    component: <CauseOfDeathList showFilter={false} />,
    auth: true
  },
  DoctorList: {
    path: '/doctors',
    component: <DoctorList showFilter={false} />,
    auth: true
  },
  TransporterList: {
    path: '/transporters',
    component: <TransporterList showFilter={false} />,
    auth: true
  },
  PricelistList: {
    path: '/pricelists',
    component: <PricelistList showFilter={false} />,
    auth: true
  },
  RenewalProcessing: {
    path: '/renewalProcessing',
    component: <RenewalProcessingList showFilter={false} />,
    auth: true
  },
  LeaseProcessing: {
    path: '/leaseProcessing',
    component: <LeaseProcessingList showFilter={false} />,
    auth: true
  },
  UrnList: {
    path: '/urns',
    component: <UrnList showFilter={false} />,
    auth: true
  },
  FamousCitizenList: {
    path: '/famousCitizens',
    component: <FamousCitizenList showFilter={false} />,
    auth: true
  },
  SettlementList: {
    path: '/settlements',
    component: <SettlementList showFilter={false} />,
    auth: true
  },
  PostalCodeList: {
    path: '/postalCodes',
    component: <PostalCodeList showFilter={false} />,
    auth: true
  },
  PostalCodeImprovedList: {
    path: '/postalCodesImproved',
    component: <PostalCodeImprovedList showFilter={false} />,
    auth: true
  },

  PostalAddressCodeList: {
    path: '/postalAddressCodes',
    component: <PostalAddressCodeList showFilter={false} />,
    auth: true
  },

  PostalAddressCodeImprovedList: {
    path: '/postalAddressCodesImproved',
    component: <PostalAddressCodeImprovedList showFilter={false} />,
    auth: true
  },

  StonecutterList: {
    path: '/stonecutters',
    component: <StonecutterList showFilter={false} />,
    auth: true
  },
  GraveyardList: {
    path: '/graveyards',
    component: <GraveyardList showFilter={false} />,
    auth: true
  },
  FirmList: {
    path: '/firms',
    component: <FirmList showFilter={false} />,
    auth: true
  },
  CitizenList: {
    path: '/citizens',
    component: <CitizenList showFilter={false} />,
    auth: true
  },
  DeceasedTakeoverRecordList: {
    path: '/takeoverDeceased',
    component: <DeceasedTakeoverRecordList showFilter={false} />,
    auth: true
  },
  UrnTakeoverRecordList: {
    path: '/takeoverUrn',
    component: <UrnTakeoverRecordList showFilter={false} />,
    auth: true
  },
  DeceasedTakeawayRecordList: {
    path: '/takeawayDeceased',
    component: <DeceasedTakeawayRecordList showFilter={false} />,
    auth: true
  },
  MainBookOfBuriedList: {
    path: '/mainBookOfBuried',
    component: <MainBookOfBuriedList showFilter={false} />,
    auth: true
  },
  GraveSiteList: {
    path: '/graveSites',
    component: <GraveSiteList showFilter={false} />,
    auth: true
  },
  MainBookOfCrematedList: {
    path: '/mainBookOfCremated',
    component: <MainBookOfCrematedList showFilter={false} />,
    auth: true
  },
  ExhumationList: {
    path: '/exhumations',
    component: <ExhumationList showFilter={false} />,
    auth: true
  },
  StoneCuttingWorksList: {
    path: '/stoneCuttingWorks',
    component: <StoneCuttingWorksList showFilter={false} />,
    auth: true
  },
  ReservationList: {
    path: '/reservations',
    component: <ReservationList showFilter={false} />,
    auth: true
  },
  TombRentList: {
    path: '/tombRent',
    component: <TombRentList showFilter={false} />,
    auth: true
  },
  CassetteRentList: {
    path: '/cassetteRent',
    component: <CassetteRentList showFilter={false} />,
    auth: true
  },
  RenewalList: {
    path: '/renewals',
    component: <RenewalList showFilter={false} />,
    auth: true
  },
  GrassList: {
    path: '/grass',
    component: <GrassList showFilter={false} />,
    auth: true
  },
  RenewalEditedInput: {
    path: '/renewalsEdited',
    component: <RenewalEditedInput showFilter={false} />,
    auth: true
  },
  ReviserReviewList: {
    path: '/reviserReview',
    component: <ReviserReviewList showFilter={false} />,
    auth: true
  },
  ExpectedIncomeReviewList: {
    path: '/expectedIncome',
    component: <ExpectedIncomeReviewList showFilter={false} />,
    auth: true
  },
  ExpiryByGraveFieldReviewList: {
    path: '/expiryByGraveField',
    component: <ExpiryByGraveFieldReviewList showFilter={false} />,
    auth: true
  },
  CremationStatementList: {
    path: '/cremationStatements',
    component: <CremationStatementList showFilter={false} />,
    auth: true
  }
};

export default ROUTES;

function getRoute(path) {

  for (const [key, value] of Object.entries(ROUTES)) {

    if (value.path === path) {
      return value;
    }
  }

  return null;
}

export function checkPath(path) {

  let pathObject = getRoute(path);

  if (!pathObject) {
    return true;
  }

  if (pathObject.auth) {
    return !isUserLoggedIn();
  }

  return false;
}

export function getRoutes() {

  let result = [];

  for (const [key, value] of Object.entries(ROUTES)) {

    result.push(
      <Route key={'route-' + result.length} exact path={value.path} render={() => (
        value.component
      )} />
    )
  }

  return result;
}