import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getDoctors(data) {
    return await request('/api/doctor/search', data);
}

export async function addDoctor(data, cid) {
    return await request('/api/doctor/' + cid, data, HttpMethod.POST);
}

export function getDoctorById(id, userId, companyId) {
    return  request("api/doctor/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editDoctor(data) {
    return await request("api/doctor/" + data.id, data, HttpMethod.PUT);
}

export async function deleteDoctor(id, uid) {
    return await request('/api/doctor/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedDoctors(data){
    return await request('/api/doctor/deleted', data);
}

export async function restoreDoctor(data) {
    return await request("api/doctor/restore/" + data.id, data, HttpMethod.PUT);
}