import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPricelists(data) {
    return await request('/api/pricelist/search', data);
}

export async function addPricelist(data, cid) {
    return await request('/api/pricelist/' + cid, data, HttpMethod.POST);
}

export function getPricelistById(id, userId, companyId) {
    return  request("api/pricelist/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editPricelist(data) {
    return await request("api/pricelist/" + data.id, data, HttpMethod.PUT);
}

export async function deletePricelist(id, uid) {
    return await request('/api/pricelist/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedPriceList(data) {
    return await request('api/pricelist/deleted',data);
}

export async function restorePricelist(data) {
    return await request("api/pricelist/restore/" + data.id, data, HttpMethod.PUT);
}