import FormComponent from "../../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import { withSnackbar } from "notistack";
import { getDeceasedReceptionById, getAutocompleteObjectsById } from "../../../services/deceasedReception/DeceasedReceptionService";
import DeceasedTakeoverRecordForm from "../../../components/forms/deceasedReception/deceasedTakeoverRecord/DeceasedTakeoverRecordForm";
import EquipmentTakeoverRecordForm from "../../../components/forms/deceasedReception/deceasedTakeoverRecord/EquipmentTakeoverRecordForm";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAllTransporters } from "../../../services/transporter/TransporterService";
import { getAllPrincipals } from "../../../services/principal/PrincipalService";
import { getAllAids } from "../../../services/aid/AidService";
import { getAllReasonBringingDeceassed } from "../../../services/reasonBringingDeceased/ReasonBringingDeceasedService";
import { getAllStoragePlaces } from "../../../services/storagePlace/StoragePlaceService";
import { getAllDeceasedBrought } from "../../../services/deceasedBrought/DeceasedBroughtService";
import { getAllCrates } from "../../../services/crate/CrateService";
import { getAllTombstones } from "../../../services/tombstone/TombstoneService";
import { getAllLetters } from "../../../services/letter/LetterService";
import { getAllLanguages } from "../../../services/language/LanguageService";
import { getAllTypefaces } from "../../../services/typeface/TypefaceService";

function TabContainer(props) {
    return (
        <Typography component="div" className="tabContainer">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class ViewDeceasedTakeoverRecord extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
            value: 0,
        };

        this.props.changeFullScreen(false);
    }

    componentDidMount() {

        getDeceasedReceptionById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                getAutocompleteObjectsById(this.state.data).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            deceasedAid: response.data.deceasedAid,
                            transporter: response.data.transporter,
                            principal: response.data.principal,
                            reasonOfBrought: response.data.reasonOfBrought,
                            storage: response.data.storage,
                            deceasedBroughtIn: response.data.deceasedBroughtIn,
                            coffinType: response.data.coffinType,
                            tombstone: response.data.tombstone,
                            letter: response.data.letter,
                            language: response.data.language,
                            letterType: response.data.letterType,
                        }
                    });
                });
            });
        });
        getAllAids().then(response => {
            this.setState({
                deceasedAids: response.data.entities
            });
        });

        getAllTransporters().then(response => {
            this.setState({
                transporters: response.data.entities,
            });
        });

        getAllPrincipals().then(response => {
            this.setState({
                principals: response.data.entities
            });
        });
        getAllReasonBringingDeceassed().then(response => {
            this.setState({
                reasonsOfBrought: response.data.entities
            });
        });
        getAllStoragePlaces().then(response => {
            this.setState({
                storages: response.data.entities
            });
        });
        getAllDeceasedBrought().then(response => {
            this.setState({
                deceasedBroughtIns: response.data.entities
            });
        });
        getAllCrates().then(response => {
            this.setState({
                coffinTypes: response.data.entities
            });
        });
        getAllTombstones().then(response => {
            this.setState({
                tombstones: response.data.entities
            });
        });
        getAllLetters().then(response => {
            this.setState({
                letters: response.data.entities
            })
        });
        getAllLanguages().then(response => {
            this.setState({
                languages: response.data.entities
            });
        });
        getAllTypefaces().then(response => {
            this.setState({
                letterTypes: response.data.entities
            });
        });
    }

    handleChangeTab = (event, value) => {
        this.setState({
            value: value
        });
    };

    render() {

        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.deceasedTakeoverRecord.viewPageTitle}</h1>
                </div>

                <div className="tabsDiv">
                    <AppBar position="static">
                        <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
                            <Tab label={strings.deceasedTakeoverRecord.deceased} className="tab" />
                            <Tab label={strings.deceasedTakeoverRecord.equipment} className="tab" />
                        </Tabs>
                    </AppBar>
                    {this.state.value === 0 && <TabContainer>

                        <DeceasedTakeoverRecordForm
                            onChange={this.changeData}
                            onSubmit={this.submit}
                            data={this.state.data}
                            errors={this.state.errors}
                            onCancel={this.props.onCancel}
                            isDisabled={this.props.isDisabled}
                            handleChange={this.handleChange}
                            onAutocompleteChange={this.onAutocompleteChange}
                            handleTextInputChange={this.handleTextInputChange}
                            transporters={this.state.transporters}
                            deceasedAids={this.state.deceasedAids}
                            principals={this.state.principals}
                            reasonsOfBrought={this.state.reasonsOfBrought}
                            storages={this.state.storages}
                            flag={this.props.flag}
                        />

                    </TabContainer>}
                    {this.state.value === 1 && <TabContainer>

                        <EquipmentTakeoverRecordForm
                            onChange={this.changeData}
                            onSubmit={this.submit}
                            data={this.state.data}
                            errors={this.state.errors}
                            onCancel={this.props.onCancel}
                            isDisabled={this.props.isDisabled}
                            handleChange={this.handleChange}
                            onAutocompleteChange={this.onAutocompleteChange}
                            handleTextInputChange={this.handleTextInputChange}
                            deceasedBroughtIns={this.state.deceasedBroughtIns}
                            coffinTypes={this.state.coffinTypes}
                            tombstones={this.state.tombstones}
                            letters={this.state.letters}
                            languages={this.state.languages}
                            letterTypes={this.state.letterTypes}
                            flag={this.props.flag}
                        />

                    </TabContainer>}
                </div>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ViewDeceasedTakeoverRecord)
    )
);
