import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import {
  getCitizenById,
  editCitizen,
} from "../../services/citizen/CitizenService";
import CitizenForm from "../../components/forms/citizen/CitizenForm";
import { validatePersonalNumber } from "../../functions/Validation";
import { getStateById, getStates } from "../../services/state/StateService";
import {
  getCitiesAndZipsWithSelectedState,
  getPostalAddressCodesWithParams,
} from "../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved";
import { getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService";

class EditCitizen extends FormComponent {
  validationList = {
    personalNumber: [{ type: Validators.REQUIRED }],
    name: [{ type: Validators.REQUIRED }],
    surname: [{ type: Validators.REQUIRED }],
    street: [{ type: Validators.REQUIRED }],
    streetNumber: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getCitizenById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then((response) => {
      this.setState(
        {
          data: response.data,
          checked: response.data.deceased,
        },
        () => {
          let validPersonalNumber = validatePersonalNumber(
            this.state.data.personalNumber
          );

          if (!validPersonalNumber) {
            this.setError(
              "personalNumber",
              strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID
            );
          } else {
            this.unsetError("personalNumber");
          }

          getStateById(this.state.data.stateCode.trim(), this.props.auth.user.id, this.props.auth.user.company.id).then((response) => {
            this.setState({
              data: {
                ...this.state.data,
                state: response.data,
              },
            });
            getPostalCodeImprovedById(
              this.state.data.postalCodeImprovedId, this.props.auth.user.id, this.props.auth.user.company.id
            ).then((response) => {
              this.setState({
                data: {
                  ...this.state.data,
                  postalCodeImproved: response.data,
                },
              });
            });
          });
        }
      );
    });
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];

    this.setState(
      {
        data: {
          ...this.state.data,
          [autocompleteName]: values,
        },
      },
      () => {
        this.getPucs();
      }
    );
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value);

    if (!validPersonalNumber) {
      this.setError(
        "personalNumber",
        strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID
      );
    } else {
      this.unsetError("personalNumber");
    }
    this.changeData(event);
  };

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editCitizen(this.transformRequest(this.state.data)).then((response) => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.citizen.messages[messageKey], {
          variant: "error",
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.citizenEdited, {
        variant: "success",
      });
      this.props.onFinish(response.data.citizen);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      deceased: this.state.checked,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      postalCodeImprovedId: this.state.data.postalCodeImproved
        ? this.state.data.postalCodeImproved.id
        : null,
    };
  }

  handleError(message) {
    switch (message) {
      case "CITIZEN_PERSONAL_NUMBER_NOT_VALID":
        this.setError(
          "personalNumber",
          strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID
        );
        break;
      case "CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST":
        this.setError(
          "personalNumber",
          strings.citizen.messages.CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST
        );
        break;
      case "CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL":
        this.setError(
          "personalNumber",
          strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL
        );
        break;
      case "CITIZEN_NAME_CANT_BE_NULL":
        this.setError(
          "name",
          strings.citizen.messages.CITIZEN_NAME_CANT_BE_NULL
        );
        break;
      case "CITIZEN_SURNAME_CANT_BE_NULL":
        this.setError(
          "surname",
          strings.citizen.messages.CITIZEN_SURNAME_CANT_BE_NULL
        );
        break;
      case "CITIZEN_STREET_CANT_BE_NULL":
        this.setError(
          "street",
          strings.citizen.messages.CITIZEN_STREET_CANT_BE_NULL
        );
        break;
      case "CITIZEN_STREET_NUMBER_CANT_BE_NULL":
        this.setError(
          "streetNumber",
          strings.citizen.messages.CITIZEN_STREET_NUMBER_CANT_BE_NULL
        );
        break;
    }
  }

  areEqual() {
    var len = arguments.length;
    for (var i = 1; i < len; i++) {
      if (arguments[i] === null || arguments[i] !== arguments[i - 1])
        return false;
    }
    return true;
  }

  handleTextInputChange = (e) => {
    if (e.target.name === "state" && e.target.value.trim().length === 0) {
      this.setState(
        {
          data: {
            ...this.state.data,
            state: null,
            postalCodeImproved: null,
          },
        }, () => {
          this.getPucs();
        }
      );
    }

    if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0,
      };

      {
        this.props.cid != undefined &&
          getStates(data).then((response) => {
            if (!response.ok) {
              return;
            }

            this.setState({
              states: response.data.entities,
              disabledCity: false,
            });
          });
      }
    } else if (
      e.target.name === "postalCodeImproved" &&
      e.target.value.trim().length >= 2
    ) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "city,ASC",
        userId: 0,
        stateId: this.state.data.state ? this.state.data.state.id : null,
      };

      {
        this.props.cid != undefined &&
          getCitiesAndZipsWithSelectedState(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState(
              {
                citiesAndZips: response.data.entities,
              },
              () => {
                this.getPucs();
              }
            );
          });
      }
    } else if (e.target.name === "street") {
      this.setState(
        {
          disabledNumber: false,
          data: {
            ...this.state.data,
            street: e.target.value,
          },
        },
        () => {
          this.getPucs();
        }
      );
    } else if (e.target.name === "streetNumber") {
      this.setState(
        {
          data: {
            ...this.state.data,
            streetNumber: e.target.value,
          },
        },
        () => {
          this.getPucs();
        }
      );
    }
  };

  getPucs() {
    let d = {
      street: this.state.data.street ? this.state.data.street : "",
      companyId: this.props.cid,
      sort: "pac,ASC",
      userId: 0,
      stateId: this.state.data.state ? this.state.data.state.id : 0,
      postalCodeImprovedId: this.state.data.postalCodeImproved
        ? this.state.data.postalCodeImproved.id
        : 0,
      streetNumber: this.state.data.streetNumber
        ? this.state.data.streetNumber
        : "",
    };
    this.props.cid != undefined &&
      getPostalAddressCodesWithParams(d).then((response) => {
        if (!response.ok) {
          return;
        }
        if (response.data.pac) {
          this.setState({
            data: {
              ...this.state.data,
              pac: response.data.pac,
            },
          });
        } else {
          this.setState({
            data: {
              ...this.state.data,
              pac: null,
            },
          });
        }
      });
  }
  render() {
    this.props.handler(this.state.data);

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.citizen.editPageTitle}</h1>
        </div>

        <Paper className="paper">
          {this.state.checked != undefined && (
            <CitizenForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              onAutocompleteChange={this.onAutocompleteChange}
              handleChange={this.handleChange}
              handleTextInputChange={this.handleTextInputChange}
              checked={this.state.checked}
              onChangePersonalNumberField={this.onChangePersonalNumberField}
              flag={"edit"}
              flagAddNewPac={this.state.flagAddNewPac}
              renderAddPac={this.props.flagAddPac}
              states={this.state.states == undefined ? [] : this.state.states}
              citiesAndZips={
                this.state.citiesAndZips == undefined
                  ? []
                  : this.state.citiesAndZips
              }
            />
          )}
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen,
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCitizen))
);
