import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getAllCompanies } from "../../services/company/CompanyService";
import UserForm from "../../components/forms/user/UserForm";
import { getUser } from "../../services/admin/AdminService";

class ViewUser extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      companies: null,
    };

    this.props.changeFullScreen(false);
  }

  fetchData() {

    getUser(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data
      });
    });

    getAllCompanies().then(response => {
      if (!response.ok) {
        return;
      }
      this.setState({
        companies: response.data.entities,
      });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.user.pageTitleView}</h1>
        </div>

        <Paper className="paper">
          <UserForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            companies={this.state.companies}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            role={this.props.role}
            isDisabled={this.props.isDisabled}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewUser)
  )
);
