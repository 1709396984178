import FormComponent from "../../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import { withSnackbar } from "notistack";
import { getDeceasedReceptionById, getAutocompleteObjectsById } from "../../../services/deceasedReception/DeceasedReceptionService";
import UrnTakeoverRecordForm from "../../../components/forms/deceasedReception/urnTakeoverRecord/UrnTakeoverRecordForm";
import { getAllTransporters } from "../../../services/transporter/TransporterService";
import { getAllReasonBringingDeceassed } from "../../../services/reasonBringingDeceased/ReasonBringingDeceasedService";
import { getAllStoragePlaces } from "../../../services/storagePlace/StoragePlaceService";
import { getAllTombstones } from "../../../services/tombstone/TombstoneService";
import { getAllLetters } from "../../../services/letter/LetterService";
import { getAllLanguages } from "../../../services/language/LanguageService";
import { getAllTypefaces } from "../../../services/typeface/TypefaceService";

class ViewUrnTakeoverRecord extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
        };

        this.props.changeFullScreen(false);
    }

    componentDidMount() {

        getDeceasedReceptionById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                getAutocompleteObjectsById(this.state.data).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            transporter: response.data.transporter,
                            reasonOfBrought: response.data.reasonOfBrought,
                            storage: response.data.storage,
                            tombstone: response.data.tombstone,
                            letter: response.data.letter,
                            language: response.data.language,
                            letterType: response.data.letterType,
                        }
                    });
                });
            });
        });

        getAllTransporters().then(response => {
            this.setState({
                transporters: response.data.entities,
            });
        });

        getAllReasonBringingDeceassed().then(response => {
            this.setState({
                reasonsOfBrought: response.data.entities
            });
        })

        getAllStoragePlaces().then(response => {
            this.setState({
                storages: response.data.entities
            });
        });

        getAllTombstones().then(response => {
            this.setState({
                tombstones: response.data.entities
            });
        });

        getAllLetters().then(response => {
            this.setState({
                letters: response.data.entities
            })
        });

        getAllLanguages().then(response => {
            this.setState({
                languages: response.data.entities
            })
        });

        getAllTypefaces().then(response => {
            this.setState({
                letterTypes: response.data.entities
            });
        });

    }

    handleChangeTab = (event, value) => {
        this.setState({
            value: value
        });
    };

    render() {

        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.urnTakeoverRecord.viewPageTitle}</h1>
                </div>
                <Paper className="paper">
                    <UrnTakeoverRecordForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        isDisabled={this.props.isDisabled}
                        handleChange={this.handleChange}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        transporters={this.state.transporters}
                        reasonsOfBrought={this.state.reasonsOfBrought}
                        storages={this.state.storages}
                        tombstones={this.state.tombstones}
                        letters={this.state.letters}
                        languages={this.state.languages}
                        letterTypes={this.state.letterTypes}
                        flag={this.props.flag}

                    />
                </Paper>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ViewUrnTakeoverRecord)
    )
);
