import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

  en: {
    menu: {
      Home: 'Home',
      Products: 'Products',
      Services: 'Services',
      OnlineGoods: 'Online Goods',
      EmeraldDragon: 'Emerald Dragon',
      Features: 'Features'

    },

    languages: {
      rs: 'rs',
      rsname: 'Српски',
      en: 'en',
      enname: 'English',
    },

    selectControl: {
      select: "Select...",
      showDeleted: "Show deleted",
      showVisible: "Show visible",
    },

    navigation: {
      contract: 'Contract',
      codebook: 'Codebook',
      review: 'Review',
      reviewsRecords: 'Reviews records',
      tables: 'Tables',
      funerals: 'Funerals',
      review_tables: {
        reservations: 'Reservations',
        renewals: 'Renewals',
        tombs: 'Tombs',
        cassettes: 'Cassettes',
        cremations: 'Cremations',
      },
      stonecutterWorks: 'Stonecutter works',
      exhumations: 'Exhumations',
      primaryEvidence: 'Primary evidence',
      deceasedReception: 'Deceased reception',
      listCodebook: "List of codebook",
      scheduling: "Scheduling",
      funeralScheduling: "Funeral scheduling",
      title: {
        administration: 'Administration',
      },
      renewals: "Renewals and warnings",
      renewalFeesForUsingAndMaintaningGraveyards: "Renewal fees for using and maintaning graveyards"
    },




    review: {
      buttons: {
        generateReport: "Generate report",
        find: "Find",
        back: "Back",
        redefineSearch: "Redefine search",
        generatePDF: "Generate PDF",
        generatePDFDiscount: "Generate report without discount",
        loadProcessingReport: "Load report",
        saveProcessingReport: "Save report",
        addDiscount: "Add discount",
        years: "Years:",
        percent: "Percent:",
      },
      reviewEvidence: 'Review Evidence',
      reviewsRecords: {
        cardGraveSite: 'Grave site card',
        graveyardCode: 'Graveyard code:',
      },
      tables: {
        funerals: {
          funeralsForSelectedYear: 'Funerals for selected year:',
          funeralsListPDF: 'Pdf will be soon generated',
          funeralsListPDFName: 'Funerals for selected year',

          numberOfFuneralsAndUrnsPerMonthForSelectedYear: 'Number of funerals and urns per month and for selected year',

          numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear: 'Number of funerals for grave fields and graveyards per month and for selected year',
          funeralsForGraveFieldsAndGraveyardsForSelectedYearPDFName: 'Funerals for selected year by grave fields and graveyards',

          funeralsForSelectedGraveyard: 'Funerals for selected graveyard:',
          selectedGraveyard: 'Graveyard:',
          funeralsListPDFNameForSelectedGraveyard: 'Funerals for selected graveyard',
          funeralsListPDFNameForSelectedGraveyards: 'Funerals for selected graveyards',

          numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite: 'Number of funerals for grave fields and selected graveyard by type of grave site',

          numberOfFuneralsForAllGraveyardsByTypeOfGraveSites: 'Number of funerals for all graveyards by type of grave sites',
          funeralsByTypeOfGraveSitesAndGraveyards: 'Funerals by type of grave sites and graveyards',
          funeralsListPDFNameForAllGraveyards: 'Funerals for all graveyards by types of grave sites',

          numberOfFuneralsForYearsOfBurialAndSelectedGraveyards: 'Number of funerals for years of burial and for selected graveyards',
          funeralsForSelectedGraveyards: 'Funerals for selected graveyards:',
          selectSomeGraveyards: 'Select some graveyards...',
          allGraveyardsAreSelected: 'All graveyards are selected',
          selectAll: 'Select all',
          search: 'Search',
          multiSelectError: 'At the moment, you have chosen max number of graveyards which is 10',

        },
        reservations: {
          numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard: 'Number of sold arranged grave sites according to size and months of sale for selected year and graveyard',
          soldArrangedGraveSitesForSelectedYear: 'Sold arranged grave sites for selected year:',
          soldArrangedGraveSitesListPDFName: 'Sold arranged grave sites for selected year',

          numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot: 'Number of sold arranged grave sites according to size and months of sale for selected year, graveyard and plot',
          numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths: 'Number of inherited arranged grave sites for current year per months',
          numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths: 'Number of inherited arranged grave sites for current year and selected plot per months',

        },
        renewals: {},
        tombs: {},
        cassettes: {},
        cremations: {},

      },
    },

    registration: {
      passwordDoNotMatch: 'Passwords dont match!',
    },

    table: {
      actions: 'Actions',
      view: 'View',
      edit: 'Edit',
      delete: 'Delete',
      cancel: "Cancel",
      confirmDelete: 'Confirm delete',
      undoCancel: 'Undo cancel',
      undo:'Restore',
      no: 'No',
      yes: 'Yes',
      search: 'Search',
      total: 'Total: ',
      footer: "Rows per page: ",
      exportData: 'Export data',
    },

    header: {
      profile: 'Profile',
      lock: 'Lock',
      logout: 'Logout',
      serbian: 'Српски',
      english: 'English'
    },

    filter: {
      search: 'Search'
    },

    validation: {
      RequiredErrorMessage: 'Required',
      AllRequiredErrorMessage: 'All fields are required',
      MinLengthErrorMessage: 'Minimal length is ',
      MaxLengthErrorMessage: 'Maximal length is ',
      EmailErrorMessage: 'Please enter valid email',
      PasswordErrorMessage: 'Password must contain at least 6 letters, one upper case, one lower case and one number, and one special character.',
      UserExistsErrorMessage: 'User with this email address already exists',
      OldPasswordDidNotMatch: 'Old password did not match',
      PasswordsNotEqual: 'Passwords do not match',
      notNumber: 'Not number',
      isNegativeNumber: 'Negative number',
      yearNotValid: 'Input a year between 1000 and 9999',
    },

    notFound: {
      notFound: 'Not found!',
      dashboard: 'Dashboard'
    },

    forbidden: {
      forbidden: 'Forbidden!',
      dashboard: 'Dashboard'
    },

    error: {
      error: 'Error!',
      dashboard: 'Dashboard',
      requiredFields: 'Please fill out all required fields!',
      dateOfDeathandTakeoverDate: "Date of death must be before date of takeover",
    },

    login: {
      email: 'Email',
      password: 'Password',
      login: 'Login',
      wrongCredentials: 'Wrong Credentials',
      resetPassword: 'Forgot password?',
      showPassword: "Show password"
    },

    lock: {
      password: 'Password',
      login: 'Login',
      wrongCredentials: 'Wrong Credentials',
      unlock: 'Unlock'
    },

    reviewMainBookOfBuried: {
      all: "Without",
      deceasedNameSurnameSubmitter: "Review deceased by name surname of submitter grave site",
      deceasedNameSurnameDeceased: "Review deceased by name surname of deceased",
      doctorTransporter: "Review by doctor and transporter",
      graveField: "Review deceased by grave field"
    },

    reviewEvidentation: "Review evidentation",

    renewalType: "Renewal type",

    renewalTypes: {
      all: "Without",
      submitterNameSurname: "Submitter",
      deceasedNameSurname: "Deceased",
      graveSite: "Grave site",
      grave: "Grave",
      reservation: "Reservation",
      tombrent: "Tomb rent",
      cassetterent: "Cassette rent",
      childGrave: "Child grave",
      arrangedPlot: "Arranged plot",
    },

    leaseTypes: {
      tombRent: "Tomb rent",
      cassetteRent: "Cassette rent",
      arrangedPlot: "Arranged plot",
      reservation: "Reservation",
    },

    reservationTypes: {
      label: "Filter",
      all: "Without",
      canceled: "Canceled",
      unRenewed: "Unrenewed",
    },

    graveSiteTypes: {
      label: "Types",
      all: "Without",
      raka: "Raka",
      tomb: "Tomb",
      cassette: "Cassette"
    },

    graveSiteRenewalTypes: {
      label: "Renewal types",
      all: "Without",
      true: "With contract",
      false: "No contract",
    },

    months: {
      all: 'All',
      jan: 'January',
      feb: 'February',
      mar: 'March',
      apr: 'April',
      may: 'May',
      jun: 'June',
      jul: 'July',
      aug: 'August',
      sep: 'September',
      oct: 'October',
      nov: 'November',
      dec: 'December',
    },

    years: {
      all: 'All'
    },

    userList: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      isDeleted: 'Is deleted',
      dateCreated: 'Date Created',
      pageTitle: 'Users',
      enabled: 'Enabled',
      userDelete: 'User deleted',
      userRestored: 'User restored',
      registrationConfirmed: 'Reg. Confirmed?',
      adminPageTitle: 'Administrators',
      company: 'Company',
    },

    companyList: {
      name: 'Name',
      email: 'Email',
      isDeleted: 'Is deleted',
      dateCreated: 'Date Created',
      pageTitle: 'Companies',
      registrationNumber: 'Registration number',
      companyDelete: 'Company deleted',
      companyRestored: 'Company restored',
      website: 'Website',
      city: 'City',
      state: 'State',
      occupationDeleted: 'Occupation deleted',
      stateDeleted: 'State deleted',
      causeOfDeathDeleted: 'Cause of death deleted',
      doctorDeleted: 'Doctor deleted',
      transporterDeleted: 'Transporter deleted',
      pricelistDeleted: 'Pricelist deleted',
      urnDeleted: 'Urn deleted',
      famousCitizenDeleted: 'Famous citizen deleted',
      cremationStatementDeleted: 'Cremation statement deleted',
      settlementDeleted: 'Settlement deleted',
      postalCodeDeleted: 'Postal code deleted',
      postalCodeImprovedDeleted: 'Postal code deleted',
      stonecutterDeleted: 'Stone cutter deleted',
      graveyardDeleted: 'Graveyard deleted',
      firmDeleted: 'Firm deleted',
      citizenDeleted: 'Citizen deleted',
      deceasedTakeoverRecordDeleted: 'Deceased takeover record deleted',
      urnTakeoverRecordDeleted: 'Urn takeover record deleted',
      deceasedTakeawayRecordDeleted: 'Deceased takeaway record deleted',
      mainBookOfBuriedDeleted: 'Main book of buried deleted',
      graveSiteDeleted: "Grave site deleted",
      mainBookOfCrematedDeleted: "Main book of cremated deleted",
      exhumationDeleted: "Exhumation deleted",
      stoneCuttingWorkDeleted: "Stone cutting work deleted",
      reservationDeleted: "Reservation deleted",
      tombRentDeleted: "Tomb rent deleted",
      cassetteRentDeleted: "Cassette rent deleted",
      renewalDeleted: "Renewal deleted",
      grassDeleted: "Grass deleted",
      scheduledFuneralDeleted: 'Scheduled funeral deleted',
      scheduledCremationDeleted: 'Scheduled cremation deleted',
      equipmentOrderDeleted: 'Equipment order deleted',
      exhumationRequestDeleted: 'Exhumation request deleted',
    },

    roles: {
      pageTitle: 'Roles',
      name: "Name",
      deleted: "Role deleted",
      addRole: "Role added",
      deleteRole: "Role deleted",
      editRole: "Role edited"
    },
    permissions: {
      pageTitle: 'Permissions',
      category: "Category",
      code: "Type",
      permission: "Permission"
    },
    userRole: {
      pageTitle: 'User Role',
      name: 'Name',
      surname: 'Surname',
      role: 'Role',
      user: 'User',
      userRoleAdded: 'User role added',
      userRoleEdited: 'User role edited',
      userRoleDeleted: 'User role deleted'
      
    },
    rolePermission: {
      pageTitle: 'Role Permisision',
      role: "Role",
      category: "Category",
      code: "Type",
      deleted: "Permission deleted",
      addRolePermission: "Permission added",
      permission: "Permission",
      rolePermissionDeleted: "Role permission deleted"

    },
    auditTrailList: {
      actionName: 'Action name',
      objectId: 'Object identificator',
      email: 'User',
      date: 'Date and Time',
      pageTitle: 'Audit trails',
      companies: 'Companies',
      actions: {
        VIEW: "VIEW",
        REPORT: "REPORT",

        ADD_USER: "ADD USER",
        EDIT_USER: "EDIT USER",
        DELETE_USER: "DELETE USER",
        ADD_COMPANY: "ADD COMPANY",
        EDIT_COMPANY: "EDIT COMPANY",
        DELETE_COMPANY: "DELETE COMPANY",
        ADD_OCCUPATION: "ADD OCCUPATION",
        EDIT_OCCUPATION: "EDIT OCCUPATION",
        DELETE_OCCUPATION: "DELETE OCCUPATION",
        ADD_STATE: "ADD STATE",
        EDIT_STATE: "EDIT STATE",
        DELETE_STATE: "DELETE STATE",
        ADD_CAUSE_OF_DEATH: "ADD CAUSE OF DEATH",
        EDIT_CAUSE_OF_DEATH: "EDIT CAUSE OF DEATH",
        DELETE_CAUSE_OF_DEATH: "DELETE CAUSE OF DEATH",
        ADD_DOCTOR: "ADD DOCTOR",
        EDIT_DOCTOR: "EDIT DOCTOR",
        DELETE_DOCTOR: "DELETE DOCTOR",
        ADD_TRANSPORTER: "ADD TRANSPORTER",
        EDIT_TRANSPORTER: "EDIT TRANSPORTER",
        DELETE_TRANSPORTER: "DELETE TRANSPORTER",
        ADD_PRICELIST: "ADD PRICELIST",
        EDIT_PRICELIST: "EDIT PRICELIST",
        DELETE_PRICELIST: "DELETE PRICELIST",
        ADD_URN: "ADD URN",
        EDIT_URN: "EDIT URN",
        DELETE_URN: "DELETE URN",
        ADD_FAMOUS_CITIZEN: "ADD FAMOUS CITIZEN",
        EDIT_FAMOUS_CITIZEN: "EDIT FAMOUS CITIZEN",
        DELETE_FAMOUS_CITIZEN: "DELETE FAMOUS CITIZEN",
        ADD_SETTLEMENT: "ADD SETTLEMENT",
        EDIT_SETTLEMENT: "EDIT SETTLEMENT",
        DELETE_SETTLEMENT: "DELETE SETTLEMENT",
        ADD_POSTAL_CODE: "ADD POSTAL CODE",
        EDIT_POSTAL_CODE: "EDIT POSTAL CODE",
        DELETE_POSTAL_CODE: "DELETE POSTAL CODE",
        ADD_POSTAL_ADDRESS_CODE: "ADD POSTAL ADDRESS CODE",
        EDIT_POSTAL_ADDRESS_CODE: "EDIT POSTAL ADDRESS CODE",
        DELETE_POSTAL_ADDRESS_CODE: "DELETE POSTAL ADDRESS CODE",
        ADD_STONECUTTER: "ADD STONECUTTER",
        EDIT_STONECUTTER: "EDIT STONECUTTER",
        DELETE_STONECUTTER: "DELETE STONECUTTER",
        ADD_GRAVEYARD: "ADD GRAVEYARD",
        EDIT_GRAVEYARD: "EDIT GRAVEYARD",
        DELETE_GRAVEYARD: "DELETE GRAVEYARD",
        ADD_FIRM: "ADD FIRM",
        EDIT_FIRM: "EDIT FIRM",
        DELETE_FIRM: "DELETE FIRM",
        ADD_CITIZEN: "ADD CITIZEN",
        EDIT_CITIZEN: "EDIT CITIZEN",
        DELETE_CITIZEN: "DELETE CITIZEN",
        ADD_DECEASED_RECEPTION: "ADD DECEASED RECEPTION",
        EDIT_DECEASED_RECEPTION: "EDIT DECEASED RECEPTION",
        DELETE_DECEASED_RECEPTION: "DELETE DECEASED RECEPTION",
        ADD_MAIN_BOOK_BURIED: "ADD MAIN BOOK BURIED",
        EDIT_MAIN_BOOK_BURIED: "EDIT MAIN BOOK BURIED",
        DELETE_MAIN_BOOK_BURIED: "DELETE MAIN BOOK BURIED",
        ADD_GRAVE_SITE: "ADD GRAVE SITE",
        EDIT_GRAVE_SITE: "EDIT GRAVE SITE",
        DELETE_GRAVE_SITE: "DELETE GRAVE SITE",
        ADD_MAIN_BOOK_CREMATED: "ADD MAIN BOOK CREMATED",
        EDIT_MAIN_BOOK_OF_CREMATED: "EDIT MAIN BOOK OF CREMATED",
        DELETE_MAIN_BOOK_OF_CREMATED: "DELETE MAIN BOOK OF CREMATED",
        ADD_EXHUMATION: "ADD EXHUMATION",
        EDIT_EXHUMATION: "EDIT EXHUMATION",
        DELETE_EXHUMATION: "DELETE EXHUMATION",
        ADD_AID: "ADD AID",
        EDIT_AID: "EDIT AID",
        DELETE_AID: "DELETE AID",
        ADD_PRINCIPAL: "ADD PRINCIPAL",
        EDIT_PRINCIPAL: "EDIT PRINCIPAL",
        DELETE_PRINCIPAL: "DELETE PRINCIPAL",
        ADD_REASON_BRINGING_DECEASED: "ADD REASON BRINGING DECEASED",
        EDIT_REASON_BRINGING_DECEASED: "EDIT REASON BRINGING DECEASED",
        DELETE_REASON_BRINGING_DECEASED: "DELETE REASON BRINGING DECEASED",
        ADD_STORAGE_PLACE: "ADD STORAGE PLACE",
        EDIT_STORAGE_PLACE: "EDIT STORAGE PLACE",
        DELETE_STORAGE_PLACE: "DELETE STORAGE PLACE",
        ADD_DECEASED_BROUGHT: "ADD DECEASED BROUGHT",
        EDIT_DECEASED_BROUGHT: "EDIT DECEASED BROUGHT",
        DELETE_DECEASED_BROUGHT: "DELETE DECEASED BROUGHT",
        ADD_CRATE: "ADD CRATE",
        EDIT_CRATE: "EDIT CRATE",
        DELETE_CRATE: "DELETE CRATE",
        ADD_TOMBSTONE: "ADD TOMBSTONE",
        EDIT_TOMBSTONE: "EDIT TOMBSTONE",
        DELETE_TOMBSTONE: "DELETE TOMBSTONE",
        ADD_LETTER: "ADD LETTER",
        EDIT_LETTER: "EDIT LETTER",
        DELETE_LETTER: "DELETE LETTER",
        ADD_LANGUAGE: "ADD LANGUAGE",
        EDIT_LANGUAGE: "EDIT LANGUAGE",
        DELETE_LANGUAGE: "DELETE LANGUAGE",
        ADD_TYPEFACE: "ADD TYPEFACE",
        EDIT_TYPEFACE: "EDIT TYPEFACE",
        DELETE_TYPEFACE: "DELETE TYPEFACE",
        ADD_STONE_CUTTING_WORK: "ADD STONE CUTTING WORK",
        EDIT_STONE_CUTTING_WORK: "EDIT STONE CUTTING WORK",
        DELETE_STONE_CUTTING_WORK: "DELETE STONE CUTTING WORK",
        ADD_RESERVATION: "ADD RESERVATION",
        EDIT_RESERVATION: "EDIT RESERVATION",
        DELETE_RESERVATION: "DELETE RESERVATION",
        CANCEL_RESERVATION: "CANCEL RESERVATION",
        UNDO_CANCEL_RESERVATION: "UNDO CANCEL RESERVATION",
        ADD_TOMB_RENT: "ADD TOMB RENT",
        EDIT_TOMB_RENT: "EDIT TOMB RENT",
        DELETE_TOMB_RENT: "DELETE TOMB RENT",
        CANCEL_TOMB_RENT: "CANCEL TOMB RENT",
        UNDO_CANCEL_TOMB_RENT: "UNDO CANCEL TOMB RENT",
        ADD_CASSETTE_RENT: "ADD CASSETTE RENT",
        EDIT_CASSETTE_RENT: "EDIT CASSETTE RENT",
        DELETE_CASSETTE_RENT: "DELETE CASSETTE RENT",
        CANCEL_CASSETTE_RENT: "CANCEL CASSETTE RENT",
        UNDO_CANCEL_CASSETTE_RENT: "UNDO CANCEL CASSETTE RENT",
        EDIT_RENEWAL: "EDIT RENEWAL",
        DELETE_RENEWAL: "DELETE RENEWAL",
        CANCEL_RENEWAL: "CANCEL RENEWAL",
        UNDO_CANCEL_RENEWAL: "UNDO CANCEL RENEWAL",
        ADD_GRASS: "ADD GRASS",
        EDIT_GRASS: "EDIT GRASS",
        DELETE_GRASS: "DELETE GRASS",
        CANCEL_GRASS: "CANCEL GRASS",
        UNDO_CANCEL_GRASS: "UNDO CANCEL GRASS",
      },
      objects: {
        Aids: "Aids"
      }
    },

    cardGraveSiteList: {
      indicator: "Indicator",
      contractNumber: "Contract number",
      leaser: "Leaseholder",
      cost: "Payer",
      mainBookOfBuried: "MBOB",
      tombstone: "Tombstone",
      lastChange: "Last change",
      expiryDate: "Expiry date",
      deceased: "Deceased",
      name: "User",
      street: "Street",
      place: "Place",
      previousGraveSite: "Previous GS",
      comment: "Comment",
      expiryDateContract: "Contract expiry date",
      rentTombsOrCassettes: 'Rent tombs or cassettes',
      renewaler: "Renewaler",
      graveSiteUser: "Grave site user",
      canceledRentContract: 'Canceled rent contract',
      graveSiteForLifeUser: 'Grave site user for life',
      paidVerticalReservation: 'Paid vertical reservation',
      rentArrangedGraveSite: 'Rent of an arranged grave site',
      deceasedAtObservedGraveSite: 'Deceased at the observed grave site',
      deceasedExhumatedFromObservedGraveSite: 'Deceased who was exhumed from the observed grave site',
      select: 'Select...',
    },

    occupationList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Occupations',
    },

    stateList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'States',
    },


    causeOfDeathList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Cause of death',
    },

    doctorList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Doctors',
    },

    transporterList: {
      code: "Code",
      name: "Name",
      shortenedName: "Shortened name",
      yearFrom: "Year from",
      yearTo: "Year to",
      monthFrom: "Month from",
      monthTo: "Month to",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Transporters',
    },

    pricelistList: {
      code: "Code",
      name: "Name",
      price: "Price",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Pricelists',
    },

    urnList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Urns',
    },

    famousCitizenList: {
      code: "Code",
      description: "Description",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Famous citizens',
      famousCitizen: 'Famous citizen',
    },

    settlementList: {
      code: "Code",
      name: "Name",
      municipalityCode: "Municipality code",
      municipalityName: "Municipality name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Settlements',
    },

    postalCodeList: {
      code: "Code",
      name: "Name",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Postal codes',
    },

    postalCodeImprovedList: {
      zip: "Zip",

      city: "City",
      settlement: "Settlement",
      dateCreated: "Date created",
      pageTitle: 'Postal codes',
    },

    postalAddressCodeList: {
      pageTitle: 'Postal address codes',
      city: 'Place',
      street: 'Street',
      number: 'Number',
      pac: 'PAC',
      postalNumber: 'Postal number',
      settlementCode: 'Settlement code',
      stateCode: 'State code',
      postalAddressCodeDeleted: 'PAC deleted',
    },

    postalAddressCodeImprovedList: {
      zip: "Postal number",
      state: "State",
      pac: "PAC",
      cityAndZip: "City and zip",
      street: "Street",
      number: "Number",
      dateCreated: "Date created",
      pageTitle: 'Postal address code',
      postalAddressCodeDeleted: 'PAC deleted',
    },

    stonecutterList: {
      code: "Code",
      name: "Name",
      type: "Type",
      address: "Address",
      note: "Note",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Stonecutters',

    },

    graveyardList: {
      code: "Code",
      name: "Name",
      place: "Place",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Graveyards',
    },

    firmList: {
      taxNumber: "Tax number",
      name: "Name",
      headquarters: "Headquarters",
      street: "Street",
      streetNumber: "Street number",
      contactPerson: "Contact person",
      telephone: "Telephone",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Firms',
    },

    citizenList: {
      personalNumber: "Personal number",
      name: "Name",
      surname: "Surname",
      postalCode: "Postal code",
      street: "Street",
      streetNumber: "Street number",
      address: "Address", 
      telephone: "Telephone",
      deceased: "Deceased",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Citizens',
    },

    deceasedTakeoverRecordList: {
      deceasedNameSurname: "Deceased name and surname",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Deceased takeover record',
    },

    urnTakeoverRecordList: {
      deceasedNameSurname: "Deceased name and surname",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Urn takeover record',
    },

    deceasedTakeawayRecordList: {
      deceasedNameSurname: "Deceased name and surname",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Deceased takeaway record',
    },

    mainBookOfBuriedList: {
      mainBookOfBuries: "MBOB",
      deceasedNameSurname: "Deceased name and surname",
      dateCreated: "Date created",
      transporter: "Transporter",
      doctor: "Doctor",
      graveyard: "Graveyard",
      graveSite: "Grave site",
      funeral: "Funeral",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      isDeleted: 'Is deleted',
      pageTitle: 'Main book of buried',
      PDF: "Pdf will be soon generated",
      year: "Year",
      month: "Month",
      dateOfBirth: "Date of birth",
      dateOfDeath: "Date of death",
      fathersName: "Fathers name",
    },

    graveSiteList: {
      graveyardName: "Graveyard name",
      graveSite: "Grave site",
      expirationDate: "Expiration date",
      dateUpdated: "Date updated",
      dateCreated: "Date created",
      arrangedPlot: "Arranged plot",
      used: "Used",
      isDeleted: 'Is deleted',
      pageTitle: 'Grave sites',
      size: 'Size GS',
    },

    mainBookOfCrematedList: {
      deceasedNameSurname: "Deceased name and surname",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      userGraveSiteName: "User grave site name",
      userGraveSiteSurname: "User grave site surname",
      urnName: "Urn",
      dateTimeOfCremation: "Cremation date",
      dateTimeUrnTakeover: "Urn takeover",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Main book of cremated',
      mainBookOfCremated: 'MBoC'
    },

    exhumationList: {
      exhumation: "Exhumation",
      mainBookOfBuried: "Main book of buried",
      deceasedNameSurname: "Deceased",
      graveSiteCode: "Grave site new",
      graveyardCode: "Graveyard new",
      graveyardName: "Graveyard new",
      tombstoneCode: "Tombstone code",
      nameSurnameCost: "Name and surname cost",
      dateTimeFuneral: "Funeral",
      expiryDate: "Expiry date",
      oldGraveyardCode: "Graveyard old",
      oldGraveyardName: "Graveyard old",
      oldGraveSiteCode: "Grave site old",
      mainBookOfBuriedSubmitterName: "Submitter name",
      mainBookOfBuriedSubmitterSurname: "Submitter surname",
      isHasNote: "Note",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Exhumation',
    },

    stoneCuttingWorksList: {
      code: "Code",
      stonecutterName: "Stone cutter name",
      mainBookOfBuriedDeceasedNameSurname: "Deceased",
      citizenName: "Submitter name",
      citizenSurname: "Submitter surname",
      graveyardCode: "Graveyard",
      graveSiteCode: "Gravesite",
      jobType: "Job type",
      permissionNumber: "Permission nmb.",
      permissionStartDate: "Permission date",
      tombstoneType: "Tombstone type",
      hasNote: "Note",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Stone cutting works',
    },

    reservationList: {
      mainBookOfBuriedMainBookOfBuries: "Evidention number",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      graveSite: "Grave site",
      tombstoneType: "Tombstone type",
      expiryDate: "Expiry date",
      dateCreated: "Date created",
      isDeleted: 'Is deleted',
      pageTitle: 'Reservations',
      canceled: "Canceled",
      PDF: "PDF",
      reportName: "Reservation review"
    },

    tombRentList: {
      mainBookOfBuriedMainBookOfBuries: "Evidention number",
      dateCreated: "Date created",
      dateOfCancellation: "Date of cancellation",
      isDeleted: 'Is deleted',
      pageTitle: 'Tombs - rents',
      canceled: "Canceled",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      graveSiteCode: "Grave site",
      tombstoneType: "Tombstone",
      expiryDate: "Expiry date"
    },

    cassetteRentList: {
      mainBookOfBuriedMainBookOfBuries: "Evidention number",
      dateCreated: "Date created",
      dateOfCancellation: "Date of cancellation",
      isDeleted: 'Is deleted',
      pageTitle: 'Cassettes - rents',
      canceled: "Canceled",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      graveSiteCode: "Grave site",
      expiryDate: "Expiry date",
    },

    renewalList: {
      renewalNumber: "Renewal number",
      graveSiteCode: "Grave site code",
      dateUpdated: "Date updated",
      dateOfChange: "Date of change",
      submitterName: "Submitter name",
      submitterSurname: "Submitter surname",
      costName: "Payer name",
      costSurname: "Payer surname",
      dateCreated: "Date created",
      expiryDate: "Expiry date",
      dateOfCancellation: "Date of cancellation",
      isDeleted: 'Is deleted',
      pageTitle: 'Renewals',
      canceled: "Canceled",
      year: "Year",
      renewalType: "Renewal type",
      graveyard: "Graveyard",
      deceasedNameSurname: "Deceased",
      submitterNameSurname: "Submitter",
      deceasedAndSubmittersList: "Deceased and submitters list",
      renewalsForProcessingList: "Renewals for processing list",
      addNewRenewal: "Add new renewal",
      processingReports: "Processing reports",
      reportSaved: "Processing report saved",
    },

    leaseRenewalProcessingList: {
      leaseRenewalType: "Lease type",
      headerTitleTombRent: "Tomb rents  for processing list",
      headerTitleCassetteRent: "Cassette rents for processing list",
      headerTitleArrangedPlot: "Reservations for processing list",
      contractNumber: "Contract number",
      dateOfChange: "Date of change",
      submitterNameSurname: "Submitter name and surname",
      graveyardName: "Graveyard name",
      graveSiteCode: "Grave site code",
      contractExpiryDate: "Contract expiry date",
    },

    grassList: {
      grassNumber: "Grass number",
      dateCreated: "Date created",
      dateOfCancellation: "Date of cancellation",
      isDeleted: 'Is deleted',
      pageTitle: 'Grasses',
      canceled: "Canceled",
    },

    userForm: {
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      ok: 'Ok',
      cancel: 'Cancel'
    },

    addUser: {
      pageTitle: 'Add user',
      errorAddClub: 'Error adding user',
      clubAdded: 'User added',
      errorAddingUser: 'Error adding user',
      userAdded: 'User added'
    },

    addCompany: {
      pageTitle: 'Add company',
      errorAddCompany: 'Error adding company',
      errorAddingCompany: 'Error adding company',
      companyAdded: 'Company added',
      occupationAdded: 'Occupation added',
      occupationEdited: 'Occupation edited',
      stateAdded: 'State added',
      stateEdited: 'State edited',
      causeOfDeathAdded: 'Cause of death added',
      causeOfDeatEdited: 'Cause of death edited',
      doctorAdded: 'Doctor added',
      doctorEdited: 'Doctor edited',
      transporterAdded: 'Transporter added',
      transporterEdited: 'Transporer edited',
      pricelistAdded: 'Pricelist added',
      pricelistEdited: 'Pricelist edited',
      urnAdded: 'Urn added',
      urnEdited: 'Urn edited',
      famousCitizenAdded: 'Famous citizen added',
      famousCitizenEdited: 'Famous citizen edited',
      cremationStatementAdded: 'Cremation statement added',
      cremationStatementEdited: 'Cremation statement edited',
      settlementAdded: 'Settlement added',
      settlementEdited: 'Settlement edited',
      postalCodeAdded: 'Postal code added',
      postalCodeEdited: 'Postal code edited',
      postalCodeImprovedEdited: 'Postal code edited',
      postalAddressCodeAdded: 'Postal address code added',
      postalAddressCodeEdited: 'Postal address code edited',
      postalAddressCodeImprovedAdded: 'Postal address code added',
      postalAddressCodeImprovedEdited: 'Postal address code edited',
      stonecutterAdded: 'Stonecutter added',
      stonecutterEdited: 'Stonecutter edited',
      graveyardAdded: 'Graveyard added',
      graveyardEdited: 'Graveyard edited',
      firmAdded: 'Firm added',
      firmEdited: 'Firm edited',
      citizenAdded: 'Citizen added',
      citizenEdited: 'Citizen edited',
      deceaseTakeoverRecordAdded: 'Deceased takeover record added',
      deceaseTakeoverRecordEdited: 'Deceased takeover record edited',
      urnTakeoverRecordAdded: 'Urn takeover record added',
      urnTakeoverRecordEdited: 'Urn takeover record edited',
      deceaseTakeawayRecordAdded: 'Deceased takeover record added',
      deceaseTakeawayRecordEdited: 'Deceased takeover record edited',
      graveSiteAdded: "Grave site added",
      graveSiteEdited: "Grave site edited",
      mainBookOfBuriedAdded: "Main book of buried added",
      mainBookOfBuriedEdited: "Main book of buried edited",
      mainBookOfCrematedAdded: "Main book of cremated added",
      mainBookOfCrematedEdited: "Main book of cremated edited",
      exhumationAdded: "Exhumation added",
      exhumationEdited: "Exhumation edited",
      stoneCuttingWorkAdded: "Stone cutting work added",
      stoneCuttingWorkEdited: "Stone cutting work edited",
      reservationAdded: "Reservation added",
      reservationEdited: "Reservation edited",
      reservationCanceled: "Reservation canceled",
      undoReservationCanceled: "Undo reservation cancellation",
      tombRentAdded: "Tomb rent added",
      tombRentEdited: "Tomb rent edited",
      tombRentCanceled: "Tomb rent canceled",
      undoTombRentCanceled: "Undo tomb rent cancellation",
      cassetteRentAdded: "Cassette rent added",
      cassetteRentEdited: "Cassette rent edited",
      cassetteRentCanceled: "Cassette rent canceled",
      undoCassetteRentCanceled: "Undo cassette rent cancellation",
      renewalEdited: "Renewal edited",
      renewalCanceled: "Renewal canceled",
      undoRenewalCanceled: "Undo renewal cancellation",
      grassAdded: "Grass added",
      grassEdited: "Grass edited",
      grassCanceled: "Grass canceled",
      undoGrassCanceled: "Undo grass cancellation",
      scheduledFuneralAdded: "Scheduled funeral added",
      scheduledFuneralEdited: "Scheduled funeral edited",
      scheduledCremationAdded: "Scheduled cremation added",
      scheduledCremationEdited: "Scheduled cremation edited",
      equipmentOrderAdded: "Equipment order added",
      equipmentOrderEdited: "Equipment order edited",
      exhumationRequestAdded: "Exhumation request added",
      exhumationRequestEdited: "Exhumation request edited",
    },

    resetPassword: {
      email: 'Email',
      resetPassword: 'Reset password',
      password: 'Password',
      passwordRepeat: 'Password repeat',
      mailSent: 'E-mail with reset link sent!',
    },

    createPassword: {
      email: 'Email',
      createPassword: 'Create password',
      password: 'Password',
      passwordRepeat: 'Password repeat',
    },

    changePassword: {
      email: 'Email',
      changePassword: 'Change password',
      password: 'Password',
      passwordRepeat: 'Password repeat',
      oldPassword: "Old password",
    },

    profile: {
      profile: "Profile",
      pageTitle: "My profile",
      login: "Login",
      changePassword: "Change password",
      
    },

    company: {
      pageTitle: "Add comapny",
      editPageTitle: "Edit company",
      viewPageTitle: "View company",
      headerTitle: "Companies",
      all: "All",
      messages: {
        COMPANY_WITH_NAME_OR_EMAIL_OR_REGNMB_ALREADY_EXIST: "Company with that name or email or reg nmb already exist. Check input and try again!",
        LOGIN_AS_SUPERADMIN: "Only superadministrators of company, login as superadministrator",
        COMPANY_NOT_EXIST: "Company doesn't exist",
        COMPANY_WITH_NAME_ALREADY_EXIST: "Company with name already exist",
        COMPANY_WITH_EMAIL_ALREADY_EXIST: "Company with email already exist",
        COMPANY_WITH_REGISTRATION_NUMBER_ALREADY_EXIST: "Company with reg. number already exists",
      },
      form: {
        name: "Name",
        email: "E-mail",
        phone: "Phone number",
        address: "Address",
        city: "City",
        state: "State",
        zipCode: "Zip code",
        registrationNumber: "Registration number",
        taxNumber: "Tax number",
        codeNumber: "Code number",
        website: "Website",
        fax: "Fax",
        errorAdding: "Error adding company",
        added: "Company added",
        deleted: "Company deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing company",
        edited: "Company edited"
      },
    },

    aid: {
      pageTitle: "Add aid",
      editPageTitle: "Edit aid",
      viewPageTitle: "View aid",
      headerTitle: "Aids",
      all: "All",
      messages: {
        AID_WITH_NAME_ALREADY_EXIST: "Aid with that name already exist",
        AID_NAME_CANT_BE_NULL: "Aid name can't be null",
        AID_NOT_EXIST: "Aid doesn't exist"

      },
      form: {
        name: "Name",
        errorAdding: "Error adding aid",
        added: "Aid added",
        deleted: "Aid deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing Aid",
        edited: "Aid edited"
      },
    },

    aidList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Aids",
      deleted: "Aid deleted"
    },

    addAid: {
      pageTitle: "Add Aid",
      added: "Aid added"
    },

    storagePlace: {
      pageTitle: "Add storage place",
      editPageTitle: "Edit storage place",
      viewPageTitle: "View storage place",
      headerTitle: "Storage places",
      messages: {
        STORAGE_WITH_NAME_ALREADY_EXIST: "Storage place with that name already exist",
        STORAGE_NOT_EXIST: "Storage place doesn't exist",
        STORAGE_NAME_CANT_BE_NULL: "Storage place name can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding storage place",
        added: "Storage place added",
        deleted: "Storage place deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing storage place",
        edited: "Storage place edited"
      }
    },

    storagePlaceList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Storage places",
      deleted: "Storage place deleted"
    },

    addStoragePlace: {
      pageTitle: "Add storage place",
      added: "Storage place added"
    },

    deceasedBrought: {
      pageTitle: "Add deceased brought",
      editPageTitle: "Edit deceased brought",
      viewPageTitle: "View deceased brought",
      headerTitle: "Deceased brought",
      messages: {
        DECEASED_WITH_NAME_ALREADY_EXIST: "Deceased brought with that name already exist",
        DECEASED_NOT_EXIST: "Deceased brought doesn't exist",
        DECEASED_NAME_CANT_BE_NULL: "Deceased brought can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding deceased brought",
        added: "Deceased brought added",
        deleted: "Deceased brought deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing deceased brought",
        edited: "Deceased brought edited"
      }

    },

    deceasedBroughtList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Deceased brought",
      deleted: "Deceased brought deleted"
    },

    addDeceasedBrought: {
      pageTitle: "Add deceased brought",
      added: "Deceased brought added"
    },

    crate: {
      pageTitle: "Add crate",
      editPageTitle: "Edit crate",
      viewPageTitle: "View crate",
      headerTitle: "Crates",
      messages: {
        CRATE_WITH_NAME_ALREADY_EXIST: "Crate with that name already exist",
        CRATE_NOT_EXIST: "Crate doesn't exist",
        CRATE_NAME_CANT_BE_NULL: "Crate can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding crate",
        added: "Crate added",
        deleted: "Crate deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing crate",
        edited: "Crate edited"
      }
    },

    crateList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Crates",
      deleted: "Crate deleted"
    },

    addCrate: {
      pageTitle: "Add crate",
      added: "Crate added"
    },

    tombstone: {
      pageTitle: "Add tombstone",
      editPageTitle: "Edit tombstone",
      viewPageTitle: "View tombstone",
      headerTitle: "Tombstones",
      messages: {
        TOMBSTONE_WITH_NAME_ALREADY_EXIST: "Tombstone with that name already exist",
        TOMBSTONE_NOT_EXIST: "Tombstone doesn't exist",
        TOMBSTONE_NAME_CANT_BE_NULL: "Tombstone can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding tombstone",
        added: "Tombstone added",
        deleted: "Tombstone deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing tombstone",
        edited: "Tombstone edited"
      }
    },

    tombstoneList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Tombstones",
      deleted: "Tombstone deleted"
    },

    addTombstone: {
      pageTitle: "Add tombstone",
      added: "Tombstone added"
    },

    letter: {
      pageTitle: "Add letter",
      editPageTitle: "Edit letter",
      viewPageTitle: "View letter",
      headerTitle: "Letters",
      messages: {
        LETTER_WITH_NAME_ALREADY_EXIST: "Letter with that name already exist",
        LETTER_NOT_EXIST: "Letter doesn't exist",
        LETTER_NAME_CANT_BE_NULL: "Letter can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding letter",
        added: "Letter added",
        deleted: "Letter deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing letter",
        edited: "Letter edited"
      }
    },

    letterList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Letters",
      deleted: "Letter deleted"
    },

    addLetter: {
      pageTitle: "Add letter",
      added: "Letter added"
    },

    language: {
      pageTitle: "Add language",
      editPageTitle: "Edit language",
      viewPageTitle: "View language",
      headerTitle: "Languages",
      messages: {
        LANGUAGE_WITH_NAME_ALREADY_EXIST: "Language with that name already exist",
        LANGUAGE_NOT_EXIST: "Language doesn't exist",
        LANGUAGE_NAME_CANT_BE_NULL: "Language can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding language",
        added: "Language added",
        deleted: "Language deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing language",
        edited: "Language edited"
      }
    },

    languageList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Languages",
      deleted: "Language deleted"
    },

    addLanguage: {
      pageTitle: "Add language",
      added: "Language added"
    },

    typeface: {
      pageTitle: "Add typeface",
      editPageTitle: "Edit typeface",
      viewPageTitle: "View typeface",
      headerTitle: "Typefaces",
      messages: {
        TYPEFACE_WITH_NAME_ALREADY_EXIST: "Typeface with that name already exist",
        TYPEFACE_NOT_EXIST: "Typeface doesn't exist",
        TYPEFACE_NAME_CANT_BE_NULL: "Typeface can't be null"
      },

      form: {
        name: "Name",
        errorAdding: "Error adding typeface",
        added: "Typeface added",
        deleted: "Typeface deleted",
        ok: "Ok",
        cancel: "Cancel"
      },

      editForm: {
        errorEditing: "Error editing typeface",
        edited: "Typeface edited"
      }
    },

    typefaceList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Typefaces",
      deleted: "Typeface deleted"
    },

    addTypeface: {
      pageTitle: "Add typeface",
      added: "Typeface added"
    },

    principal: {
      pageTitle: "Add principal",
      editPageTitle: "Edit principal",
      viewPageTitle: "View principal",
      headerTitle: "Principals",
      messages: {
        PRINCIPAL_WITH_NAME_ALREADY_EXIST: "Principal with name already exist",
        PRINCIPAL_NOT_EXIST: "Principal doesn't exist",
        PRINCIPAL_NAME_CANT_BE_NULL: "Principal can't be null"

      },
      form: {
        name: "Name",
        errorAdding: "Error adding principal",
        added: "Principal added",
        deleted: "Principal deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing principal",
        edited: "Principal edited"
      },
    },

    principalList: {
      name: 'Name',
      dateCreated: 'Date created',
      pageTitle: "Principals",
      deleted: "Principal deleted"
    },

    addPrincipal: {
      pageTitle: "Add Principal",
      added: "Principal added"
    },

    reasonBringingDeceased: {
      pageTitle: "Add Reason for bringing deceased",
      editPageTitle: "Edit Reason for bringing deceased",
      viewPageTitle: "View Reason for bringing deceased",
      headerTitle: "Reason for bringing deceased",
      messages: {
        REASON_WITH_NAME_ALREADY_EXIST: "Reason for bringing deceased with name already exist",
        REASON_NOT_EXIST: "Reason for bringing deceased doesn't exist",
        REASON_NAME_CANT_BE_NULL: "Reason for bringing deceased can't be null",
      },
      form: {
        name: "Name",
        errorAdding: "Error adding Reason for bringing deceased",
        added: "Reason for bringing deceased added",
        deleted: "Reason for bringing deceased deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing Reason for bringing deceased",
        edited: "Reason for bringing deceased edited"
      },
    },

    reasonBringingDeceasedList: {
      pageTitle: "Reason for bringing deceased",
      deleted: "Reason for bringing deceased deleted",
      name: "Name",
      dateCreated: "Date created"
    },

    addReasonBringingDeceased: {
      pageTitle: "Add Reason for bringing deceased",
      added: "Reason for bringing deceased added"
    },

    occupation: {
      pageTitle: "Add occupation",
      editPageTitle: "Edit occupation",
      viewPageTitle: "View occupation",
      headerTitle: "Occupations",
      messages: {
        OCCUPATION_WITH_CODE_ALREADY_EXIST: "Occupation with that code already exist. Check input and try again!",
        OCCUPATION_CODE_CANT_BE_NULL: "Code cannot be empty",
        OCCUPATION_NAME_CANT_BE_NULL: "Name cannot be empty",
        OCCUPATION_WITH_NAME_ALREADY_EXIST: "Occupation with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        code: "Code",
        errorAdding: "Error adding occupation",
        added: "Occupation added",
        deleted: "Occupation deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing occupation",
        edited: "Occupation edited"
      },
    },

    state: {
      pageTitle: "Add state",
      editPageTitle: "Edit state",
      viewPageTitle: "View state",
      headerTitle: "States",
      messages: {
        STATE_WITH_CODE_ALREADY_EXIST: "State with that code already exist. Check input and try again!",
        STATE_CODE_CANT_BE_NULL: "Code cannot be empty",
        STATE_NAME_CANT_BE_NULL: "Name cannot be empty",
        STATE_WITH_NAME_ALREADY_EXIST: "State with that name already exist. Check input and try again!",

      },
      form: {
        name: "Name",
        code: "Code",
        errorAdding: "Error adding state",
        added: "State added",
        deleted: "State deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing state",
        edited: "State edited"
      },
    },

    causeOfDeath: {
      pageTitle: "Add cause of death",
      editPageTitle: "Edit cause of death",
      viewPageTitle: "View cause of death",
      headerTitle: "Causes of deaths",
      infectious: "Infectious disase",
      messages: {
        // CAUSE_OF_DEATH_WITH_CODE_ALREADY_EXIST: "Cause of death with that code already exist. Check input and try again!",
        CAUSE_OF_DEATH_CODE_CANT_BE_NULL: "Code cannot be empty",
        CAUSE_OF_DEATH_NAME_CANT_BE_NULL: "Name cannot be empty",
        CAUSE_OF_DEATH_WITH_NAME_ALREADY_EXIST: "Cause of death with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        code: "Code",
        errorAdding: "Error adding cause of death",
        added: "Cause of death added",
        deleted: "Cause of death deleted",
        ok: "Ok",
        infectious: "Infectious disase",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing cause of death",
        edited: "Cause of death edited"
      },
    },

    doctor: {
      pageTitle: "Add doctor",
      editPageTitle: "Edit doctor",
      viewPageTitle: "View doctor",
      headerTitle: "Doctors",
      messages: {
        //DOCTOR_WITH_CODE_ALREADY_EXIST: "Doctor with that code already exist. Check input and try again!",
        DOCTOR_CODE_CANT_BE_NULL: "Code cannot be empty",
        DOCTOR_NAME_CANT_BE_NULL: "Name cannot be empty",
        DOCTOR_WITH_NAME_ALREADY_EXIST: "Doctor with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        code: "Code",
        address: "Address",
        errorAdding: "Error adding doctor",
        added: "Doctor added",
        deleted: "Doctor deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing doctor",
        edited: "Doctor edited"
      },
    },

    transporter: {
      pageTitle: "Add transporter",
      editPageTitle: "Edit transporter",
      viewPageTitle: "View transporter",
      headerTitle: "Transporters",
      messages: {
        // TRANSPORTER_WITH_CODE_ALREADY_EXIST: "Transporter with that code already exist. Check input and try again!",
        TRANSPORTER_CODE_CANT_BE_NULL: "Code cannot be empty",
        TRANSPORTER_NAME_CANT_BE_NULL: "Name cannot be empty",
        TRANSPORTER_SHORTENED_NAME_CANT_BE_NULL: "Shortened name cannot be empty",
        TRANSPORTER_WITH_NAME_ALREADY_EXIST: "Transporter with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        shortenedName: "Shortened name",
        code: "Code",
        address: "Address",
        errorAdding: "Error adding transporter",
        added: "Transporter added",
        deleted: "Transporter deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing transporter",
        edited: "Transporter edited"
      },
      buttons: {
        generateReport: "Generate report",
      }
    },

    pricelist: {
      pageTitle: "Add pricelist",
      editPageTitle: "Edit pricelist",
      viewPageTitle: "View pricelist",
      headerTitle: "Pricelists",
      messages: {
        //PRICELIST_WITH_CODE_ALREADY_EXIST: "Pricelist with that code already exist. Check input and try again!",
        PRICELIST_CODE_CANT_BE_NULL: "Code cannot be empty",
        PRICELIST_NAME_CANT_BE_NULL: "Name cannot be empty",
        PRICELIST_PRICE_CANT_BE_NULL: "Price cannot be empty",
        PRICELIST_WITH_NAME_ALREADY_EXIST: "Pricelist with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        price: "Price",
        code: "Code",
        address: "Address",
        errorAdding: "Error adding price",
        added: "Price added",
        deleted: "Price deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing price",
        edited: "Price edited"
      },
    },

    urn: {
      pageTitle: "Add urn",
      editPageTitle: "Edit urn",
      viewPageTitle: "View urn",
      headerTitle: "Urns",
      messages: {
        // URN_WITH_CODE_ALREADY_EXIST: "Urn with that code already exist. Check input and try again!",
        URN_CODE_CANT_BE_NULL: "Code cannot be empty",
        URN_NAME_CANT_BE_NULL: "Name cannot be empty",
        URN_WITH_NAME_ALREADY_EXIST: "Urn with that name already exist. Check input and try again!",
      },
      form: {
        name: "Name",
        code: "Code",
        errorAdding: "Error adding urn",
        added: "Urn added",
        deleted: "Urn deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing urn",
        edited: "Urn edited"
      },
    },

    famousCitizen: {
      pageTitle: "Add famous citizen",
      editPageTitle: "Edit famous citizen",
      viewPageTitle: "View famous citizen",
      headerTitle: "Famous citizens",
      messages: {
        FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST: "Famous citizen with that code already exist. Check input and try again!",
        FAMOUS_CITIZEN_CODE_CANT_BE_NULL: "Code cannot be empty",
        FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL: "Description cannot be empty",
      },
      form: {
        code: "Code",
        description: "Description",
        errorAdding: "Error adding famous citizen",
        added: "Famous citizen added",
        deleted: "Famous citizen deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing famous citizen",
        edited: "Famous citizen edited"
      },
    },

    settlement: {
      pageTitle: "Add settlement",
      editPageTitle: "Edit settlement",
      viewPageTitle: "View settlement",
      headerTitle: "Settlements",
      messages: {
        SETTLEMENT_WITH_CODE_ALREADY_EXIST: "Settlement with that code already exist. Check input and try again!",
        SETTLEMENT_CODE_CANT_BE_NULL: "Code cannot be empty",
        SETTLEMENT_NAME_CANT_BE_NULL: "Name cannot be empty",
        SETTLEMENT_MUNICIPALITY_CODE_CANT_BE_NULL: "Municipality code cannot be empty",
        SETTLEMENT_MUNICIPALITY_NAME_CANT_BE_NULL: "Municipality name cannot be empty",
      },
      form: {
        code: "Code",
        name: "Name",
        municipalityCode: "Municipality code",
        municipalityName: "Municipality name",
        errorAdding: "Error adding settlement",
        added: "Settlement added",
        deleted: "Settlement deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing settlement",
        edited: "Settlement edited"
      },
    },

    postalCode: {
      pageTitle: "Add postal code",
      editPageTitle: "Edit postal code",
      viewPageTitle: "View postal code",
      headerTitle: "Postal codes",
      messages: {
        POSTAL_CODE_WITH_CODE_ALREADY_EXIST: "Postal code with that code already exist. Check input and try again!",
        POSTAL_CODE_CODE_CANT_BE_NULL: "Code cannot be empty",
        POSTAL_CODE_NAME_CANT_BE_NULL: "Name cannot be empty",
      },
      form: {
        name: "Name",
        code: "Code",
        errorAdding: "Error adding postal code",
        added: "Postal code added",
        deleted: "Postal code deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing postal code",
        edited: "Postal code edited"
      },
    },

    postalCodeImproved: {
      pageTitle: "Add postal code",
      editPageTitle: "Edit postal code",
      viewPageTitle: "View postal code",
      headerTitle: "Postal codes",
      messages: {
        POSTAL_CODE_IMPROVED_ZIP_CANT_BE_NULL: "Zip cannot be empty",
        POSTAL_CODE_IMPROVED_CITY_CANT_BE_NULL: "City cannot be empty",
        POSTAL_CODE_IMPROVED_STATE_CANT_BE_NULL: "State cannot be empty",
        POSTAL_CODE_IMPROVED_ALREADY_EXIST: "Postal code with state, city and zip combination already exist",
      },
      form: {
        state: "State",
        city: "City",
        settlement: "Settlement",
        zip: "Zip number",
        errorAdding: "Error while adding",
        added: "City added",
        deleted: "City deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing postal code",
        edited: "Postal code edited"
      },
    },

    postalAddressCode: {
      headerTitle: "Postal adress codes",
      addPageTitle: "Add postal adress code",
      editPageTitle: "Edit postal adress code",
      viewPageTitle: "View postal adress code",
      messages: {
        POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL: "Place cannot be empty",
        POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL: "Street cannot be empty",
        POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL: "Number cannot be empty",
        POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL: "PAC cannot be empty",
        POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL: "Postal number cannot be empty",
        POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL: "Settlement code cannot be empty",
        POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL: "State code cannot be empty",
      },
      form: {
        place: 'Place',
        street: 'Street',
        number: 'Number',
        PAC: 'PAC',
        postalNumber: 'Postal number',
        settlementCode: 'Settlement code',
        stateCode: 'State code',
        errorAdding: 'Error adding PAC',
        added: 'PAC added',
        errorEditing: "Error editing PAC",
        edited: "PAC edited",
        deleted: 'PAC deleted',
        ok: 'Ok',
        cancel: 'Cancel',
      },
    },

    postalAddressCodeImproved: {
      headerTitle: "Postal adress codes",
      addPageTitle: "Add postal adress code",
      editPageTitle: "Edit postal adress code",
      viewPageTitle: "View postal adress code",
      messages: {
        POSTAL_ADDRESS_CODE_IMPROVED_CITY_AND_ZIP_CANT_BE_NULL: "City and zip cannot be empty",
        POSTAL_ADDRESS_CODE_IMPROVED_STREET_CANT_BE_NULL: "Street cannot be empty",
        POSTAL_ADDRESS_CODE_IMPROVED_NUMBER_CANT_BE_NULL: "Number cannot be empty",
        POSTAL_ADDRESS_CODE_IMPROVED_PAC_CANT_BE_NULL: "PAC cannot be empty",
        POSTAL_ADDRESS_CODE_IMPROVED_STATE_CANT_BE_NULL: "State cannot be empty",
        POSTAL_ADDRESS_CODE_IMPROVED_ALREADY_EXIST: "PAC with this state, city, zip, street, number and pac combination already exist"
      },
      form: {
        cityAndZip: 'City and zip',
        street: 'Street',
        number: 'Number',
        PAC: 'PAC',
        zip: 'Postal number',
        state: 'State',
        errorAdding: 'Error adding PAC',
        added: 'PAC added',
        errorEditing: "Error editing PAC",
        edited: "PAC edited",
        deleted: 'PAC deleted',
        ok: 'Ok',
        cancel: 'Cancel',
      },
    },

    stonecutter: {
      pageTitle: "Add stonecutter",
      editPageTitle: "Edit stonecutter",
      viewPageTitle: "View stonecutter",
      headerTitle: "Stonecutters",
      messages: {
        STONECUTTER_WITH_CODE_ALREADY_EXIST: "Stone cutter with that code already exist. Check input and try again!",
        STONECUTTER_CODE_CANT_BE_NULL: "Code cannot be empty",
        STONECUTTER_NAME_CANT_BE_NULL: "Name cannot be empty",
        STONECUTTER_TYPE_CANT_BE_NULL: "Type cannot be empty",
      },
      form: {
        name: "Name",
        code: "Code",
        type: "Type",
        address: "Address",
        note: "Note",
        errorAdding: "Error adding stonecutter",
        added: "Stonecutter added",
        deleted: "Stonecutter deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing stonecutter",
        edited: "Stonecutter edited"
      },
    },

    graveyard: {
      pageTitle: "Add graveyard",
      editPageTitle: "Edit graveyard",
      viewPageTitle: "View graveyard",
      headerTitle: "Graveyards",
      messages: {
        GRAVEYARD_WITH_CODE_ALREADY_EXIST: "Graveyard with that code already exist. Check input and try again!",
        GRAVEYARD_CODE_CANT_BE_NULL: "Code cannot be empty",
        GRAVEYARD_NAME_CANT_BE_NULL: "Name cannot be empty",
        GRAVEYARD_ADDRESS_CANT_BE_NULL: "Address cannot be empty",
        GRAVEYARD_PLACE_CANT_BE_NULL: "Place cannot be empty",
        GRAVEYARD_SURFACE_CANT_BE_NULL: "Surface cannot be empty",
        GRAVEYARD_STATUS_CANT_BE_NULL: "Status cannot be empty",
        GRAVEYARD_DENOMINATION_CANT_BE_NULL: "Denomination cannot be empty",
        GRAVEYARD_YEAR_OF_ESTABLISHMENT_CANT_BE_NULL: "Year of establishment cannot be empty",
        GRAVEYARD_TAKEOVER_DATE_CANT_BE_NULL: "Takeover date cannot be empty",
      },
      form: {
        name: "Name",
        code: "Code",
        address: "Address and number",
        place: "Place",
        surface: "Surface",
        status: "Status",
        denomination: "Denomination",
        yearOfEstablishment: "Year of establishment",
        takeoverDate: "Takeover date",
        takeoverDateString: "Takeover date import string",
        errorAdding: "Error adding graveyard",
        added: "Graveyard added",
        deleted: "Graveyard deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing graveyard",
        edited: "Graveyard edited",
      },
    },

    firm: {
      pageTitle: "Add firm",
      editPageTitle: "Edit firm",
      viewPageTitle: "View firm",
      headerTitle: "Firms",
      messages: {
        FIRM_WITH_TAX_NUMBER_ALREADY_EXIST: "Firm with that tax number already exist. Check input and try again!",
        FIRM_WITH_CODE_ALREADY_EXIST: "Firm with that code already exist. Check input and try again!",
        FIRM_TAX_NUMBER_CANT_BE_NULL: "Tax number cannot be empty",
        FIRM_NAME_CANT_BE_NULL: "Name cannot be empty",
        FIRM_HEADQUARTERS_CANT_BE_NULL: "Headquarters cannot be empty",
        FIRM_STREET_CANT_BE_NULL: "Street cannot be empty",
        FIRM_STREET_NUMBER_CANT_BE_NULL: "Street number cannot be empty",
      },
      form: {
        taxNumber: "Tax number",
        name: "Name",
        headquarters: "Postal code",
        street: "Street",
        streetNumber: "Street number",
        contactPerson: "Contact person",
        telephone: "Telephone",
        errorAdding: "Error adding firm",
        added: "Firm added",
        deleted: "Firm deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing firm",
        edited: "Firm edited",
      },
    },

    citizen: {
      pageTitle: "Add citizen",
      editPageTitle: "Edit citizen",
      viewPageTitle: "View citizen",
      headerTitle: "Citizens",
      messages: {
        CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST: "Citizen with that personal number already exist. Check input and try again!",
        CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL: "Personal number cannot be empty",
        CITIZEN_NAME_CANT_BE_NULL: "Name cannot be empty",
        CITIZEN_SURNAME_CANT_BE_NULL: "Surname cannot be empty",
        CITIZEN_POSTAL_CODE_CANT_BE_NULL: "Postal code cannot be empty",
        CITIZEN_STREET_CANT_BE_NULL: "Street cannot be empty",
        CITIZEN_STREET_NUMBER_CANT_BE_NULL: "Street number cannot be empty",
        CITIZEN_PERSONAL_NUMBER_NOT_VALID: "Personal number is not valid"

      },
      form: {
        oldAddresses: "Old addresses",
        personalNumber: "Personal number",
        name: "Name",
        surname: "Surname",
        postalCode: "Postal code and place",
        street: "Street",
        streetNumber: "Street number",
        deceased: "Deceased",
        telephone: "Telephone",
        errorAdding: "Error adding citizen",
        added: "Citizen added",
        deleted: "Citizen deleted",
        ok: "Ok",
        cancel: "Cancel",
        settlement: "Settlement"
      },
      editForm: {
        errorEditing: "Error editing citizen",
        edited: "Citizen edited"
      },
    },

    deceasedReception: {
      headerTitle: "Deceased receptions",
      messages: {
        DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Deceased reception with that identification number already exist. Check input and try again!",
        DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL: "Name surname cannot be empty",
        DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL: "Identification number cannot be empty",
      },
      form: {
        personalNumber: "Personal number",
        identificationNumber: "Identification number*",
        equippingOrder: 'Equipping order',
        deceasedNameSurname: 'Deceased name and surname',
        placeOfDeath: 'Place of death',
        dateOfDeath: 'Date of death',
        deliveryDateTime: 'Delivery date and time',
        takeawayDateTime: 'Takeaway date and time',
        bodyState: 'Body state',
        bodyStateGood: 'Good',
        bodyStateBad: 'Bad',
        dressedUp: 'Dressed up',
        preparationPreformedBy: 'Preparation perfomed by',
        suitBrought: 'Suit brought',
        noteDeceased: 'Note deceased',
        noteJewelry: 'Note jewelry',
        deceasedAids: 'Deceased aids',
        transporter: 'Transporter',
        driverNameSurname: 'Driver name and surname',
        registrationPlates: 'Registration plates',
        principal: 'Principal',
        principalAddress: 'Principal address',
        reasonOfBrought: 'Reason of brought',
        reportedFuneral: 'Reported funeral',
        storage: 'Storage',
        urnReasonOfBrought: 'Reason of urn brought',
        urnStorage: 'Urn storage',
        urnLetter: 'Urn letter',
        urnDamages: 'Urn damages',
        urnTookOverBy: 'Urn took over by',
        urnBroughtBy: 'Urn brought by',
        boxNumber: 'Box number',
        urnLaidInStorage: 'Urn laid in storage',
        deceasedBroughtIn: 'Deceased brought in',
        coffinBroughtAfter: 'Coffin brought after',
        dateTimeBroughtAfter: 'Date and time brought after',
        standardCoffin: 'Standard coffin',
        coffinType: 'Coffin type',
        coffinNote: 'Coffin note',
        tombstones: 'Tombstones',
        letter: 'Letter',
        language: 'Language',
        letterType: 'Letter type',
        damages: 'Damages',
        numberArtificialWreaths: 'Number of artificial wreaths',
        numberNaturalWreaths: 'Number of natural wreaths',
        numberFlowersBouquet: 'Number of bouquet flowers',
        numberArrangements: 'Number of arrangements',
        numberOrders: 'Number of orders',
        numberRings: 'Number of rings',
        numberJewelry: 'Number of jewelry',
        dateOfTakeover: 'Date of takeover',
        deceasedTookOverBy: 'Deceased tookover by',
        deceasedBroughtBy: 'Deceased brought by',
        note: 'Note',
        principalT: 'Principal',
        carrier: 'Carrier',
        reasonOfTaking: 'Reason of taking',
        transporterT: 'Transporter',
        registrationPlateT: 'Registration plates',
        approvedBy: 'Approved by',
        deceasedTookOverByT: 'Deceased took over by',
        errorAdding: "Error adding deceased reception",
        added: "Deceased reception added",
        deleted: "Deceased reception deleted",
        ok: "Ok",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        reported: "Reported",
        notReported: "Not reported",
      },
      editForm: {
        errorEditing: "Error editing deceased reception",
        edited: "Deceased reception edited"
      },
    },

    deceasedTakeoverRecord: {
      pageTitle: "Add deceased takeover record",
      editPageTitle: "Edit deceased takeover record",
      viewPageTitle: "View deceased takeover record",
      headerTitle: "Deceased takeover record",
      deceased: "Deceased",
      equipment: "Equipment",
    },

    urnTakeoverRecord: {
      pageTitle: "Add urn takeover record",
      editPageTitle: "Edit urn takeover record",
      viewPageTitle: "View urn takeover record",
      headerTitle: "Urn takeover record",
    },

    deceasedTakeawayRecord: {
      pageTitle: "Add deceased takeaway record",
      editPageTitle: "Edit deceased takeaway record",
      viewPageTitle: "View deceased takeaway record",
      headerTitle: "Deceased takeaway record",
    },

    mainBookOfBuried: {
      pageTitle: "Add main book of buried",
      editPageTitle: "Edit main book of buried",
      viewPageTitle: "View main book of buried",
      headerTitle: "Main book of burried",
      messages: {
        MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Main book of buried with that identification number already exist. Check input and try again!",
        MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL: "Name surname cannot be empty",
        MAIN_BOOK_OF_BURIED_EXIST: "Main book of buries already exists",
        MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID: "Identification number is not valid",
      },
      form: {
        unknownAddress: "Unknown address",
        mainBookOfBuries: "Main book of buries",
        identificationNumber: "Identification number",
        deceasedNameSurname: "Deceased name surname",
        gender: "Gender",
        fathersName: "Fathers name",
        address: "Address",
        municipality: "Municipality",
        republic: "Republic",
        stateOfBirth: "State",
        occupation: "Occupation",
        dateOfBirth: "Date of birth",
        dateOfBirthString: "Date of birth import string",
        municipalityOfBirth: "Municipality of birth",
        dateTimeOfDeath: "Date time of death",
        dateTimeOfDeathString: "Date time of death import string",
        causeOfDeath: "Cause of death",
        doctor: "Doctor",
        registryBookOfDeath: "Registry book of death",
        takeoverDateTime: "Takeover date time",
        takeoverDateTimeString: "Takeover date time import string",
        transporter: "Transporter",
        weigth: "Weight",
        height: "Height",
        famousCitizen: "Famous citizen",
        hasNote: "Has note",
        note: "Note",
        identificationNumberSubmitter: "Identification number submitter",
        nameSurnameSubmitter: "Name surname submitter",
        addressSubmitter: "Address submitter",
        municipalitySubmitter: "Municipality submitter",
        telephoneSubmitter: "Telephone",
        identificationNumberCosts: "Identification number costs",
        nameSurnameCosts: "Name surname costs",
        addressCosts: "Address costs",
        municipalityCosts: "Municipality costs",
        telephoneCosts: "Telephone costs",
        identificationNumberUser: "Identification number user",
        nameSurnameUser: "Name surname user",
        addressUser: "Address user",
        municipalityUser: "Municipality user",
        telephoneUser: "Telephone user",
        firmPays: "Firm pays",
        graveyard: "Graveyard",
        dateTimeFuneralString: "Date time funeral import string",
        dateTimeFuneral: "Date time funeral",
        graveSite: "Grave site",
        create: "Create ",
        paymentNumber: "Payment number",
        expiryDate: "Expiry date",
        expiryDateString: "Expiry date import string",
        tombstoneCode: "Tomestone code",
        graveField: "Grave field",
        stoneCutter: "Stone cutter",
        graveyard2: "Graveyard 2",
        dateTimeFuneral2: "Date time funeral 2",
        dateTimeFuneral2String: "Date time funeral 2 import string",
        graveSite2: "Grave site 2",
        paymentNumber2: "Payment number 2",
        graveyard3: "Graveyard 3",
        dateTimeFuneral3: "Date time funeral 3",
        dateTimeFuneral3String: "Date time funeral 3 import string",
        graveSite3: "Grave site 3",
        paymentNumber3: "Payment number 3",
        graveyard4: "Graveyard 4",
        dateTimeFuneral4: "Date time funeral 4",
        dateTimeFuneral4String: "Date time funeral 4 import string",
        graveSite4: "Grave site 4",
        paymentNumber4: "Payment number 4",
        plot: "Plot 4",
        landmarkDescription: "Landmark description",
        male: "Male",
        female: "Female",
        ok: "Ok",
        cancel: "Cancel",
        submitter: "Submitter",
        cost: "Costs pays",
        grUser: "Grave user",
        shorthenedSubmitter: "Subm.",
        shorthenedCosts: "Cost pays",
        currentPlaceOfRest: "Current place of rest",
        previousPlaceOfRest: "Previous place of rest",
        taxNumberCosts: "Tax number",
        firmCost: "Firm",
        addNewSubmitter: "Add new submitter",
        addNewCost: "Add new cost bearer",
        scheduledBurialNumber: "Scheduled burial number",
      },
      editForm: {
        errorEditing: "Error editing main book of buried",
        edited: "Main book of buried edited"
      },
      deceased: "Deceased",
      deathReport: "Death report",
      funeral: "Funeral",
      loadFromScheduledFuneral: "Load from scheduled funeral",
      errorLength: "6 digit number required",
      birthYearError: "Year of birth is required",
      errorLengthForEdit: "10 digit number required",
      buttons: {
        generateReport: "Generate report",
      }
    },

    graveSite: {
      pageTitle: "Add grave site",
      editPageTitle: "Edit grave site",
      viewPageTitle: "View grave site",
      headerTitle: "Grave sites",
      messages: {
        GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST: "Grave site with that name and gravesite kombination already exist. Check input and try again!",
        GRAVE_SITE_GRAVEYARD_CANT_BE_NULL: "Grave site graveyard cannot be empty",
        GRAVE_SITE_GRAVE_SITE_CANT_BE_NULL: "Grave site cannot be empty",
        GRAVE_SITE_NOT_EXIST: "Grave site doesn't exist",
        GRAVE_SITE_ALREADY_EXIST: "Grave site with that code already exists",
        GRAVE_SITE_INPUT_NOT_VALID: "Grave site input is not valid!",
      },
      form: {
        belongingGraveyard: "Graveyard",
        code: "Grave site",
        paymentDate: "Grave site creation date",
        paymentDateString: "Payment date import string",
        arrangedPlot: "Is plot arranged",
        os: "Is plot osarium",
        childPlot: "Is child plot",
        placeNumberFrom: "Number plot from",
        placeNumberTo: "Number plot to",
        labelGraveSite: "Grave site label",
        typeGraveSite: "Grave site type",
        graveSiteNumber: "Grave site number",
        renewal: "Renewal",
        taken: "Taken",
        arrangedDate: "Arranged date",
        identificationNumber: "Identification number",
        expirationDate: "Expiriation date",
        expirationDateString: "Expiriation date import string",
        ok: "Ok",
        cancel: "Cancel",
        graveField: "Grave field",
        gravePlot: "Grave plot",
        dimensions: "Dimensions",
        length: "Length (cm)",
        width: "Width (cm)",
        depth: "Depth (cm)",
        addMoreThanOne: "Add more than one",
        size: "Size",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      errorMinLength: "Must be 3 digit number (eg. 001)",
      errorInvalidFormat: "Only numbers",
      errorToLessThanFrom: "Place number from must be lower than palce number to",
    },

    mainBookOfCremated: {
      pageTitle: "Add main book of cremated",
      editPageTitle: "Edit main book of cremated",
      viewPageTitle: "View main book of cremated",
      headerTitle: "Main book of cremated",
      messages: {
        MAIN_BOOK_OF_CREMATED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Main book of cremated with that identification number already exist. Check input and try again!",
        MAIN_BOOK_OF_CREMATED_WITH_NAME_SURNAME_CANT_BE_NULL: "Name surname cannot be empty",
        MAIN_BOOK_OF_CREMATED_EXIST: "Main book of cremated already exists",
        MAIN_BOOK_OF_CREMATED_IDENTIFICATION_NUMBER_NOT_VALID: "Identification number is not valid",

      },
      form: {
        unknownAddress: "Unknown address",
        mainBookOfCremated: "Main book of cremated",
        identificationNumber: "Identification number",
        deceasedNameSurname: "Deceased name surname",
        gender: "Gender",
        fathersName: "Fathers name",
        address: "Address",
        municipality: "Municipality",
        republic: "Republic",
        occupation: "Occupation",
        dateOfBirth: "Date of birth",
        municipalityOfBirth: "Municipality of birth",
        dateTimeOfDeath: "Date time of death",
        dateTimeOfDeathString: "Date time of death import string",
        causeOfDeath: "Cause of death",
        doctor: "Doctor",
        registryBookOfDeath: "Registry book of death",
        dateTimeOfTakeIn: "Take in date time",
        dateTimeOfTakeInString: "Take in date time import string",
        dateTimeSayGoodbye: "Say goodbye date time",
        dateTimeSayGoodbyeString: "Say goodbye date time import string",
        dateTimeOfCremation: "Cremation date time",
        dateTimeOfCremationString: "Cremation date time import string",
        dateTimeUrnTakeover: "Urn takeover date time",
        dateTimeUrnTakeoverString: "Urn takeover date time import string",
        urn: "Urn",
        transporter: "Transporter",
        weigth: "Weight",
        height: "Height",
        famousCitizen: "Famous citizen",
        hasNote: "Has note",
        note: "Note",
        identificationNumberSubmitter: "Identification number submitter",
        nameSurnameSubmitter: "Name surname submitter",
        addressSubmitter: "Address submitter",
        municipalitySubmitter: "Municipality submitter",
        telephoneSubmitter: "Telephone",
        identificationNumberCosts: "Identification number costs",
        nameSurnameCosts: "Name surname costs",
        addressCosts: "Address costs",
        municipalityCosts: "Municipality costs",
        telephoneCosts: "Telephone costs",
        identificationNumberUser: "Identification number user",
        nameSurnameUser: "Name surname user",
        addressUser: "Address user",
        municipalityUser: "Municipality user",
        telephoneUser: "Telephone user",
        firmPays: "Firm pays",
        p: "P",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        paymentNumber: "Payment number",
        expiryDate: "Expiry date",
        male: "Male",
        female: "Female",
        ok: "Ok",
        cancel: "Cancel",
        submitter: "Submitter",
        cost: "Costs pays",
        grUser: "Grave user",
        shorthenedSubmitter: "Subm.",
        shorthenedCosts: "Cost pays",
        cremationCurrentPlaceOfRest: "Cremation and current place of rest",
        previousPlaceOfRest: "Previous place of rest",
        taxNumberCosts: "Tax number",
        firmCost: "Firm"
      },
      editForm: {
        errorEditing: "Error editing main book of cremated",
        edited: "Main book of cremated edited"
      },
      deceased: "Deceased",
      deathReport: "Death report",
      cremation: "Cremation",
      errorLength: "5 digit number required",
      errorLengthForEdit: "9 digit number required"
    },

    exhumation: {
      pageTitle: "Add exhumation",
      editPageTitle: "Edit exhumation",
      viewPageTitle: "View exhumation",
      headerTitle: "Exhumations",
      messages: {
        EXHUMATION_WITH_EXHUMATION_NUMBER_ALREADY_EXIST: "Exhumation already exist. Check input and try again!",
        EXHUMATION_WITH_NAME_SURNAME_CANT_BE_NULL: "Name surname cannot be empty",
        EXHUMATION_EXIST: "Exhumation with that number already exists",
      },
      form: {
        exhumation: "Exhumation",
        deceasedNameSurname: "Deceased",
        mainBookOfBuried: "Main book of buried",
        expiryDate: "Expiry date",
        expiryDateString: "Expiry date import string",
        dateOfExhumation: "Date of exhumation",
        graveyard: "Graveyard - new",
        graveSite: "Grave site - new",
        costPersonalNumber: "Personal number cost",
        costNameSurname: "Name and surname cost",
        costAddress: "Address cost",
        costCity: "City cost",
        costTelephone: "Telephone",
        paymentNumber: "Payment number - new",
        tombstoneCode: "Tombostone code",
        socialCase: "Tombostone code",
        oldGraveSiteCode: "Grave site - old",
        oldGraveyard: "Graveyard - old",
        note: "Note",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      errorLength: "6 digit number required"
    },

    stoneCuttingWorks: {
      pageTitle: "Add stone cutting work",
      editPageTitle: "Edit stone cutting work",
      viewPageTitle: "View stone cutting work",
      headerTitle: "Stone cutting works",
      messages: {
        STONE_CUTTING_WORK_WITH_CODE_ALREADY_EXIST: "Stone cutting work already exist. Check input and try again!",
        STONE_CUTTING_WORK_CODE_CANT_BE_NULL: "Code cannot be empty",
      },
      form: {
        code: "Code",
        stonecutter: "Stonecutter",
        jobType: "Job type",
        mainBookOfBuried: "Main book of buried",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        tombstoneType: "Tombstone type",
        permissionNumber: "Permission number",
        permissionStartDate: "Permission start date",
        permissionsStartDateString: "Permission start date import string",
        citizen: "Citizen",
        citizenNameSurname: "Citizen name surname",
        citizenAddress: "Citizen address",
        citizenSettlement: "Citizen settlement",
        citizenTelephone: "Citizen telephone",
        payment: "Payment",
        amount: "Amount",
        note: "Note",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited",
        errorLengthForEdit: "8 digit number required",
      },
      errorLength: "4 digit number required",
      validationError: "Check if all fields are correct",
    },

    reservation: {
      pageTitle: "Add reservation",
      editPageTitle: "Edit reservation",
      viewPageTitle: "View reservation",
      headerTitle: "Reservations",
      messages: {
        RESERVATION_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Reservation already exist. Check input and try again!",
        RESERVATION_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Number in evidence cannot be empty",
      },
      form: {
        addNewPac: "Add new PAC",
        addNewSubmitter: "Add new submitter",
        mainBookOfBuried: "Number in evidence",
        personalNumber: "Personal number",
        submitterName: "Submitter name",
        submitterAddress: "Address",
        submitterMunicipality: "Municipality",
        submitterTelephone: "Telephone",
        personalNumber2: "Personal number",
        submitterName2: "Submitter name",
        submitterAddress2: "Address",
        submitterMunicipality2: "Municipality",
        submitterTelephone2: "Telephone",
        horizontal: "Horizontal",
        vertical: "Vertical",
        horizontalNumber: "Horizontal number",
        verticalNumber: "Vertical number",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        deceasedInside: "Already reserved",
        tombstoneType: "Tombstone type",
        ban:"Ban",
        tombstoneFromDate: "Tombstone from date",
        tombstoneFromDateString: "Tombstone from date import string",
        stonecutter: "Stonecutter",
        expiryDate: "Expiry date",
        expiryDateString: "Expiry date import string",
        contractNumber: "Contract number",
        contractExpiryDate: "Contract expiry date",
        contractDate: "Contract expiry date",
        contractDateString: "Contract expiry date import string",
        paymentNumber: "Payment number",
        dateOfRent: "Date of rent",
        dateOfRentString: "Date of rent import string",
        amount: "Amount",
        canceled: "Canceled",
        dateOfCancellation: "Date of cancellation",
        correctionNumber: "Correction number",
        note: "Note",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      validationЕrror: "Check if all required fields are typed in",
    },


    tombRent: {
      pageTitle: "Add tomb rent",
      editPageTitle: "Edit tomb rent",
      viewPageTitle: "View tomb rent",
      headerTitle: "Tomb - rent",
      messages: {
        TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Tomb rent cannot be empty. Check input and try again!",
        TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Number in evidence already exist",
        RENEWAL_PAYMENT_DATE_CANT_BE_NULL: "Payment date cannot be empty"
      },
      form: {
        mainBookOfBuried: "Number in evidence",
        personalNumber: "1. Submitter personal nmb.",
        submitterName: "Submitter",
        submitterAddress: "Address",
        submitterMunicipality: "Municipality",
        submitterTelephone: "Telephone",
        personalNumber2: "2. Submitter personal nmb.",
        submitterName2: "Submitter",
        submitterAddress2: "Address",
        submitterMunicipality2: "Municipality",
        submitterTelephone2: "Telephone",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        graveSiteSize: "Grave site size",
        deceasedInside: "Deceased inside",
        tombstoneType: "Tombstone type",
        tombstoneFromDate: "Tombstone from date",
        tombstoneFromDateString: "Tombstone from date import string",
        stonecutter: "Stonecutter",
        contractNumber: "Contract number",
        paymentNumber: "Payment number",
        dateOfRent: "Date of rent",
        dateOfRentString: "Date of rent import string",
        rentExpiryDate: "Rent expiry date",
        rentExpiryDateString: "Rent expiry date import string",
        graveSiteExpiryDate: "Grave site expiry date",
        graveSiteExpiryDateString: "Grave site expiry date import string",
        rentAmount: "Rent amount",
        graveSiteAmount: "Grave site amount",
        totalAmount: "Total amount",
        canceled: "Canceled",
        dateOfCancellation: "Date of cancellation",
        correctionNumber: "Correction number",
        note: "Note",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      validationЕrror: "Check if all required fields are typed in",
    },

    cassetteRent: {
      pageTitle: "Add cassette rent",
      editPageTitle: "Edit cassette rent",
      viewPageTitle: "View cassette rent",
      headerTitle: "Cassette - rent",
      messages: {
        CASSETTE_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Cassette rent cannot be empty. Check input and try again!",
        CASSETTE_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Number in evidence already exist",
      },
      form: {
        mainBookOfBuried: "Number in evidence",
        personalNumber: "1. Submitter personal nmb.",
        submitterName: "Submitter",
        submitterAddress: "Address",
        submitterMunicipality: "Municipality",
        submitterTelephone: "Telephone",
        personalNumber2: "2. Submitter personal nmb.",
        submitterName2: "Submitter",
        submitterAddress2: "Address",
        submitterMunicipality2: "Municipality",
        submitterTelephone2: "Telephone",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        graveSiteSize: "Grave site size",
        deceasedInside: "Deceased inside",
        tombstoneType: "Tombstone type",
        contractNumber: "Contract number",
        paymentNumber: "Payment number",
        dateOfRent: "Date of rent",
        dateOfRentString: "Date of rent import string",
        rentExpiryDate: "Rent expiry date",
        rentExpiryDateString: "Rent expiry date import string",
        graveSiteExpiryDate: "Grave site expiry date",
        graveSiteExpiryDateString: "Grave site expiry date import string",
        rentAmount: "Rent amount",
        graveSiteAmount: "Grave site amount",
        totalAmount: "Total amount",
        canceled: "Canceled",
        dateOfCancellation: "Date of cancellation",
        correctionNumber: "Correction number",
        note: "Note",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      validationЕrror: "Check if all required fields are typed in",
    },

    renewal: {
      addPageTitle: "Add renewal",
      editPageTitle: "Edit renewal",
      viewPageTitle: "View renewal",
      headerTitle: "Renewals",
      headerTitleEdited: "RenewalsEdited",
      messages: {
        RENEWAL_DATE_TIME_OF_DEATH_CANT_BE_NULL: "Renewal date and time cannot be empty. Check input and try again!",
        RENEWAL_NOT_EXIST: "Renewal doesn't exist",
      },
      form: {
        renewalNumber: "Renewal number",
        personalNumber: "Submitter personal nmb.",
        submitterName: "Submitter",
        deceasedName: "Deceased",
        deceasedPersonalNumber: "Deceased personal number",
        submitterAddress: "Address",
        submitterMunicipality: "Municipality",
        submitterTelephone: "Telephone",
        paymentNumber: "Payment number",
        amount: "Amount",
        graveyard: "Graveyard",
        tombstoneType: "Tombstone type",
        graveSite: "Grave site",
        expiryDate: "Expiry date",
        expiryDateString: "Expiry date import string",
        newExpirationDate: "Еxpiry date - new",
        dateOfChange: "Date of change",
        dateOfChangeString: "Date of change import string",
        note: "Note",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
        renewalYears: "Renewal years",
        costPersonalNumber: "Personal number cost",
        costNameSurname: "Name cost",
        costStreet: "Address cost",
        costCity: "City cost"
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      validationЕrror: "Check if all required fields are typed in",
    },

    grass: {
      pageTitle: "Add grass",
      editPageTitle: "Edit grass",
      viewPageTitle: "View grass",
      headerTitle: "Grasses",
      messages: {
        GRASS_NUMBER_CANNOT_BE_NULL: "Grass number cannot be empty. Check input and try again!",
        GRASS_NOT_EXIST: "Grass doesn't exist",
        GRASS_WITH_GRASS_NUMBER_ALREADY_EXIST: "Grass with grass number already exists!"
      },
      form: {
        grassNumber: "Grass number",
        personalNumber: "Submitter personal nmb.",
        submitterName: "Submitter",
        submitterAddress: "Address",
        submitterMunicipality: "Municipality",
        submitterTelephone: "Telephone",
        paymentDate: "Payment date",
        paymentDateString: "Payment date import string",
        amount: "Amount",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        expiryDate: "Expiry date",
        expiryDateString: "Expiry date import string",
        deceasedInside: "Deceased inside",
        mainBookOfBuried: "Contract for reservation",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
      },
      editForm: {
        errorEditing: "Error editing",
        edited: "Edited"
      },
      validationЕrror: "Check if all required fields are typed in",
      errorLength: "Please enter valid year"
    },

    renewalProcessing: {
      headerTitle: "Renewal processing",
      periodFrom: "From date",
      periodTo: "To date",
      field: "Field",
      plot: "Plot",
      graveSiteNumber: "Gravesite number"
    },

    leaseProcessing: {
      headerTitle: "Lease processing",
      periodFrom: "From date",
      periodTo: "To date",
      field: "Field",
      plot: "Plot",
      graveSiteNumber: "Gravesite number",
    },

    funeralScheduling: {
      headerTitle: "Funeral scheduling",
      addPageTitle: "Schedule new funeral",
      editPageTitle: "Edit scheduled funeral",
      viewPageTitle: "View scheduled funeral",
      filterDate: "Filter date",
      funeralNumber: "Funeral number",
      deceasedNameSurname: "Deceased",
      fathersName: "Fathers name",
      born: "Born",
      appointmentTimeFuneral: "Time of funeral",
      graveyard: "Graveyard",
      graveSite: "Grave site",
      responsiblePerson: "Funeral scheduled by",
      responsiblePersonЕditedBy: "Funeral edited by",
      dateCreated: "Date created",
      dateTimeFuneral: "Date and time of funeral",
      deceased: "Deceased",
      deathReport: "Death report, cost...",
      funeralDetails: "Funeral details",
      tomb: "Tomb",
      grave: "Grave",
      cassette: "Cassette",
      funeral: "Funeral",
      urnStorage: "Urn storage",
      farewell: "Farewell",
      cremation: "Cremation",
      removeAndReturnGravePlate: "Remove and return grave plate",
      dismantleTombstone: "Dismantle tombstone",
      breakTheExtentOfTheGraveSite: "Break the extent of the grave site",
      hireServicesForArrangingAndMaintainingGreenSurfaces: "Hire services for arranging and maintaining green surfaces",
      deathbed: "Deathbed",
      microphone: "Microphone",
      pillowsForDecorations: "Pillows for decorations",
      tableForAttendees: "Table for attendees",
      bell: "Bell",
      silent: "Silent",
      beethoven: "Beethoven",
      lenin: "Lenin",
      schopen: "Schopen",
      chorusOfTheJews: "Chorus of the Jews",
      tambourinePlayers: "Tambourine players",
      quietNight: "Quiet night",
      cd: "CD",
      music_live: "Music-live",
      direct: "Direct",

      form: {
        funeralNumber: "Funeral number",
        identificationNumber: "Personal number",
        deceasedNameSurname: "Deceased",
        gender: "Gender",
        male: "Male",
        female: "Female",
        fathersName: "Father's name",
        occupation: "Occupation",
        dateOfBirth: "Date of birth",
        municipalityOfBirth: "Municipality of birth",
        lastAddressOfResidence: "Last known address",
        permanentResidence: "Type of residence",
        permanent: "Permanent",
        temporary: "Temporary",
        stateOfResidence: "State of residence",
        municipalityOfResidence: "Municipality of residence",
        dateTimeOfDeath: "Date and time of death",
        municipalityOfDeath: "Municipality of death",
        autopsy: "Autopsy",
        yes: "Yes",
        no: "No",
        causeOfDeath: "Cause of death",
        causeOfDeathCode: "Cause of death code",
        doctor: "Doctor",
        weight: "Weight",
        height: "Height",
        ok: "Ok",
        cancel: "Cancel",
        submitter: "Submitter",
        addNewSubmitter: "Add new submitter",
        nameSurnameSubmitter: "Name and surname submitter",
        addressSubmitter: "Address submitter",
        municipalitySubmitter: "Municipality submitter",
        telephoneSubmitter: "Telephone submitter",
        submitterNameOfOneParent: "Name of one parent",
        IDCardOrPassportNumber: "Id card number / passport number",
        IDCardOrPassportIssuer: "Document issuer",
        IDCardOrPassportIssueDate: "Date of issue",
        employed: "Employed",
        submitterCompanyName: "Company name",
        submitterCompanyAddress: "Company address",
        relationToDeceased: "Relation to deceased",
        cost: "Cost",
        firmCost: "Firm cost",
        firmPays: "Firm pays",
        addNewCost: "Add new cost",
        taxNumberCosts: "Tax number costs",
        headquarters: "Headquarters",
        telephoneCosts: "Telephone",
        contactPerson: "Contact person",
        nameSurnameCosts: "Name and surname cost",
        costNameOfOneParent: "Name of one parent",
        addressCosts: "Address costs",
        municipalityCosts: "Municipality costs",
        costCompanyName: "Company name",
        costCompanyAddress: "Company address",
        shorthenedSubmitter: "Subm.",
        shorthenedCosts: "Cost pays",
        graveSiteUser: "Gravesite user",
        nameSurnameUser: "Name and surname user",
        userNameOfOneParent: "Name of one parent",
        addressUser: "Address user",
        municipalityUser: "Municipality user",
        telephoneUser: "Telephone",
        userCompanyName: "Company name",
        userCompanyAddress: "Company address",
        funeralType: "Funeral type",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        municipalityOfFuneral: "Municipality of funeral",
        create: "Add new ",
        dateFuneral: "Date of funeral",
        timeFuneral: "Time of funeral",
        usingChapel: "Using chapel",
        exhumationRequired: "Exhumation required",
        exhumationRequiredNote: "Exhumation note",
        farewellType: "Farewell type",
        reservedGraveSite: "Reserved grave site",
        reservedGraveSiteExpiryDate: "Rezerved till date: ",
        necessaryArrangements: "Necessary arrangements",
        solderATinCasket: "Solder a tin casket",
        verticalReservation: "Vertical reservation",
        horizontalReservation: "Horizontal reservation",
        reservationNumber: "Reservation number",
        farewellCremation: "Farewell cremation",
        farewellTransit: "Farewell transit",
        farewellTransitMunicipality: "Farewell transit municipality",
        new: "New",
        reserved: "Reserved",
        additionalItems: "Additional items",
        reservedHall: "Reserved hall",
        numberOfAttendees: "Number of attendees",
        menu: "Menu",
        hallApplicationReceivedBy: "Application received by",
        transporter: "Transporter",
        informationAboutLeasedGraveSitesAndTombs: "Information about leased grave sites and tombs where funeral needs to be performed",
        funeralEquipment: "Funeral equipment",
        previouslyBuriedDeceasedAndYearsOfDeath: "Previously buried deceased and years of death",
        graveSiteHasTombstone: "Grave site has tombstone",
        tombstoneType: "Tombstone type",
        announceThroughMedia: "Announce through media",
        specialTerms: "Special terms which submitter accepts by signature",
        note: "Note",
        farewellMusicType: "Farewell music",
        cremation: "Cremation",
        transit: "Transit"
      },

      messages: {
        SCHEDULED_FUNERAL_WITH_NAME_SURNAME_CANT_BE_NULL: "Deceased must have name and surname.",
        SCHEDULED_FUNERAL_DOES_NOT_EXIST: "Funeral does not exist."
      },

      values: {
        funeral: "funeral",
        urnStorage: "urn_storage",
        farewell: "farewell",
        cremation: "cremation",
      }
    },

    cremationScheduling: {
      headerTitle: "Cremation scheduling",
      addPageTitle: "Schedule new cremation",
      editPageTitle: "Edit scheduled cremation",
      viewPageTitle: "View scheduled cremation",
      filterDate: "Filter date",
      funeralNumber: "Funeral number",
      deceasedNameSurname: "Deceased",
      graveyard: "Graveyard",
      graveSite: "Grave site",
      dateCreated: "Date created",
      dateTimeFuneral: "Date and time of funeral",
      deceased: "Deceased",
      deathReport: "Death report, cost...",
      cremationDetails: "Cremation details",
      cremationRequest: "Cremation request",
      urnStorage: "Urn storage",
      farewell: "Farewell",
      cremation: "Cremation",
      removeAndReturnGravePlate: "Remove and return grave plate",
      dismantleTombstone: "Dismantle tombstone",
      breakTheExtentOfTheGraveSite: "Break the extent of the grave site",
      hireServicesForArrangingAndMaintainingGreenSurfaces: "Hire services for arranging and maintaining green surfaces",
      deathbed: "Deathbed",
      microphone: "Microphone",
      pillowsForDecorations: "Pillows for decorations",
      tableForAttendees: "Table for attendees",
      bell: "Bell",
      silent: "Silent",
      beethoven: "Beethoven",
      lenin: "Lenin",
      schopen: "Schopen",
      chorusOfTheJews: "Chorus of the Jews",
      tambourinePlayers: "Tambourine players",
      quietNight: "Quiet night",
      cd: "CD",
      music_live: "Music live",
      direct: "Direct",
      columbarium: "Columbarium",
      rosarium: "Rosarium",
      osarium: "Osarium",
      other: "Other",
      otherComment: "(Comment)",
      gardenOfMemories: "Garden of memories",
      deathCertificate: "Death certificate",
      extractFromRegistryBookOfDead: "Extract from registry book of dead",
      invoiceFromPurchasedFuneralEquipment: "Invoice from purchased funeral equipment",
      authorizationPowerOfAttorneyForLegalEntity: "Authorization - power of attorney for legal entity",
      approvalFromTheAuthority: "Approval from the authority",
      contractForLeasedGraveSite: "Contract for leased grave site",
      proofOfDeceasedWishToBeCremated: "Proof of deceased wish to be cremated",
      will: "Will",
      crematistsAssociation: "Crematists association ”Oganj”",
      lifetimeSupportAgreement: "Lifetime support agreement",
      certifiedStatementOfTheDeceased: "Certified statement of the deceased",
      certifiedDeclarationOfRelatives: "Certified declaration of relatives",
      responsiblePerson: "Cremation scheduled by",
      responsiblePersonЕditedBy: "Cremation edited by",

      form: {
        cremationNumber: "Cremation number",
        identificationNumber: "Personal number",
        deceasedNameSurname: "Deceased",
        gender: "Gender",
        male: "Male",
        female: "Female",
        fathersName: "Father's name",
        occupation: "Occupation",
        dateOfBirth: "Date of birth",
        municipalityOfBirth: "Municipality of birth",
        lastAddressOfResidence: "Last known address",
        permanentResidence: "Type of residence",
        permanent: "Permanent",
        temporary: "Temporary",
        stateOfResidence: "State of residence",
        municipalityOfResidence: "Municipality of residence",
        dateTimeOfDeath: "Date and time of death",
        municipalityOfDeath: "Municipality of death",
        autopsy: "Autopsy",
        yes: "Yes",
        no: "No",
        causeOfDeath: "Cause of death",
        causeOfDeathCode: "Cause of death code",
        doctor: "Doctor",
        weight: "Weight",
        height: "Height",
        ok: "Ok",
        cancel: "Cancel",
        submitter: "Submitter",
        addNewSubmitter: "Add new submitter",
        nameSurnameSubmitter: "Name and surname submitter",
        addressSubmitter: "Address submitter",
        municipalitySubmitter: "Municipality submitter",
        telephoneSubmitter: "Telephone submitter",
        submitterNameOfOneParent: "Name of one parent",
        IDCardOrPassportNumber: "Id card number / passport number",
        IDCardOrPassportIssuer: "Document issuer",
        IDCardOrPassportIssueDate: "Date of issue",
        employed: "Employed",
        submitterCompanyName: "Company name",
        submitterCompanyAddress: "Company address",
        relationToDeceased: "Relation to deceased",
        cost: "Cost",
        firmCost: "Firm cost",
        firmPays: "Firm pays",
        addNewCost: "Add new cost",
        taxNumberCosts: "Tax number costs",
        headquarters: "Headquarters",
        telephoneCosts: "Telephone",
        contactPerson: "Contact person",
        nameSurnameCosts: "Name and surname cost",
        costNameOfOneParent: "Name of one parent",
        addressCosts: "Address costs",
        municipalityCosts: "Municipality costs",
        costCompanyName: "Company name",
        costCompanyAddress: "Company address",
        shorthenedSubmitter: "Subm.",
        shorthenedCosts: "Cost pays",
        graveSiteUser: "Gravesite user",
        nameSurnameUser: "Name and surname user",
        userNameOfOneParent: "Name of one parent",
        addressUser: "Address user",
        municipalityUser: "Municipality user",
        telephoneUser: "Telephone",
        userCompanyName: "Company name",
        userCompanyAddress: "Company address",
        funeralType: "Funeral type",
        graveyard: "Graveyard",
        graveSite: "Grave site",
        municipalityOfFuneral: "Municipality of funeral",
        create: "Add new ",
        dateTimeFuneral: "Date and time of funeral",
        usingChapel: "Using chapel",
        exhumationRequired: "Exhumation required",
        exhumationRequiredNote: "Exhumation note",
        farewellType: "Farewell type",
        reservedGraveSite: "Reserved grave site",
        reservedGraveSiteExpiryDate: "Rezerved till date: ",
        necessaryArrangements: "Necessary arrangements",
        solderATinCasket: "Solder a tin casket",
        verticalReservation: "Vertical reservation",
        horizontalReservation: "Horizontal reservation",
        reservationNumber: "Reservation number",
        farewellCremation: "Farewell cremation",
        farewellTransit: "Farewell transit",
        farewellTransitMunicipality: "Farewell transit municipality",
        new: "New",
        reserved: "Reserved",
        additionalItems: "Additional items",
        reservedHall: "Reserved hall",
        numberOfAttendees: "Number of attendees",
        menu: "Menu",
        hallApplicationReceivedBy: "Application received by",
        transporter: "Transporter",
        informationAboutLeasedGraveSitesAndTombs: "Information about leased grave sites and tombs where funeral needs to be performed",
        funeralEquipment: "Funeral equipment",
        previouslyBuriedDeceasedAndYearsOfDeath: "Previously buried deceased and years of death",
        graveSiteHasTombstone: "Grave site has tombstone",
        tombstoneType: "Tombstone type",
        announceThroughMedia: "Announce through media",
        specialTerms: "Special terms which submitter accepts by signature",
        note: "Note",
        farewellMusicType: "Farewell music",
        urn: "Urn",
        hasTombstone: "Has tombstone",
        noTombstone: "Does not have tombstone",
        burnTombstone: "Burn tombstone",
        urnStorageType: "Urn storage type",
        otherGraveSiteNote: "Other grave site notes",
        attachedDocuments: "Attached documents",
        contractFinalized: "Contract for leased grave site",
        finalized: "Finalized",
        notFinalized: "Not finalized",
        legalEntity: "Legal entity",
        individual: "Individual",
        requestSubmitter: "Request submitter",
        nameSurnameDriver: "Driver's name and surname",
        nameOfLegalEntity: "Name of legal entity",
        legalEntityHeadquarters: "Headquarters",
        expressCremation: "Express Cremation"
      },

      messages: {
        SCHEDULED_CREMATION_WITH_NAME_SURNAME_CANT_BE_NULL: "Deceased must have name and surname.",
        SCHEDULED_CREMATION_DOES_NOT_EXIST: "Cremation does not exist."
      },
    },

    appointments: {
      headerTitle: "Appointments",
      filterAppointmentsByDay: "Filter appointments by date",
      showScheduledFunerals: "Show scheduled funerals",
      appointmentDeleted: "Appointment deleted",
      appointmentAdded: "Appointment added",
      appointmentReserved: "Appointment reserved",
      appointmentCanceled: "Appointment canceled",
      addAppointment: "Add appointment",
      appointmentsList: "Appointments",
      addPageTitle: "Add new appointment",
      appointmentsNotFoundMessage: "No appointments for day",
      scheduledFuneralsNotFoundMessage: "No scheduled funerals for day",
      cancelButton: "Cancel",
      reservationButton: "Reserve",
      reservedButton: "Reserved",
      makeAnAppointmentButton: "Make an appointment",
      deleteButton: "Delete",
      appointmentHours: "Hours",
      appointmentMinutes: "Minutes",
      note: "Note",
      reservedBy: "Reserved by",
      transporter: "Transporter",
      number: "Number",
      cityGraveyard: "City graveyard",
      externalGraveyard: "External graveyards",
      municipalityOfDeath: "Place of death",
      musicBell: "Music",
      letterM: "M",
      letterD: "D",
      letterZ: "Z",
      appointmentTakenMessage: "Appointment free",
      messages: {
        APPOINTMENT_TAKEN: "Appointment is taken",
        APPOINTMENT_DOES_NOT_EXIST: "Appointment does not exist",
        APPOINTMENT_SCHEDULED: "Appointment is scheduled"
      },
    },

    equipmentOrder: {
      headerTitle: "Equipment orders",
      addPageTitle: "Add new equipment order",
      editPageTitle: "Edit equipment order",
      viewPageTitle: "View equipment order",
      deceased: "Deceased",
      equipmentDetails: "Required equipment", 
      orderNumber: "Order number",
      deceasedNameSurname: "Deceased",
      responsiblePerson: "Order created by",
      responsiblePersonEditedBy: "Order edited by",
      dateCreated: "Date created",
      latin: "Latin",
      cyrillic: "Cyrillic",
      metalLetters: "Metal letters",
      plasticLetters: "Plastic letters",
      cross: "Cross",
      pyramid: "Pyramid",
      reformationBoard: "Reformation board",
      washDeceased: "Wash deceased",
      shaveDeceased: "Shave deceased",
      dressDeceased: "Dress deceased",
      withinCityLimits: "Within city limits, up to 30 km",
      outsideCityLimitsWithinState: "Outside city limits within state",
      outsideCityLimitsOutsideState: "Outside city limits outside state",

      form: {
        orderNumber: "Order number",
        identificationNumber: "Personal number",
        deceasedNameSurname: "Deceased name and surname",
        dateOfBirth: "Date of birth",
        dateTimeOfDeath: "Date and time of death",
        yearsOfLife: "Years of life",
        weight: "Weight",
        height: "Height",
        letterType: "Letter type",
        currentLocationOfDeceased: "Current location of deceased",
        dateTimeOfTakeOut: "Date and time of take out",
        placeOfTakeOut: "Place of take out",
        deceasedIdNumber: "ID number of deceased",
        dateTimeOfFuneral: "Date and time of funeral",
        graveyardFuneral: "Graveyard where funeral is taking place",
        ok: "Ok",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        casket: "Casket",
        casketAmount: "Casket amount",
        cover: "Cover",
        coverType: "Cover type",
        coverAmount: "Cover amount",
        crossAmount: "Cross amount",
        pyramidAmount: "Pyramid amount",
        reformationBoardAmount: "Reformation board amount",
        tombstones: "Tombstones",
        tombstoneType: "Tombstone type",
        letters: "Letters",
        numberOfLetters: "Number of letters",
        metalLettersAmount: "Metal letters amount",
        plasticLettersAmount: "Plastic letters amount",
        obituaryNotice: "Obituary notice",
        obituaryAmount: "Obituary amount",
        numberOfNotices: "Number of notices",
        tilesAndBadges: "Tiles and badges",
        tilesAndBadgesAmount: "Amount",
        numberOfTilesAndBadges: "Number of tiles and badges",
        floralTribute: "Floral tribute",
        numberOfFloralTributes: "Number of floral tributes",
        floralTributeAmount: "Floral tributes amount",
        scarves: "Scarves",
        numberOfScarves: "Number of scarves",
        scarvesAmount: "Scarves amount",
        towels: "Towels",
        towelsAmount: "Towels amount",
        numberOfTowels: "Number of towels",
        urn: "Urn",
        urnType: "Urn type",
        urnAmount: "Urn amount",
        deceasedPreparations: "Deceased preparations",
        deceasedPreparationsAmount: "Preparations amount",
        furnishCoffin: "Furnish coffin",
        furnishCoffinAmount: "Furnish coffin amount",
        transportDeceased: "Transport deceased",
        transportOptions: "Transport options",
        numberOfKilometers: "Number of kilometers for transport",
        transportDeceasedAmount: "Transport deceased amount",
        funeralCosts: "Funeral costs",
        funeralCostsAmount: "Funeral costs amount",
        typeOfGraveSite: "Type of grave site",
        cremationCosts: "Cremation costs",
        cremationCostsAmount: "Cremation costs amount",
        typeOfCremation: "Type of cremation",
        pvcInsert: "PVC insert",
        pvcInsertAmount: "PVC insert amount",
        pvcBagForDeceasedTakeOut: "PVC bag for deceased take out",
        pvcBagForDeceasedTakeOutAmount: "PVC bag amount",
        tinInsert: "Tin insert",
        tinInsertAmount: "Tin insert amount",
        deceasedTakeOutFromApartment: "Deceased take out from apartment",
        deceasedTakeOutFromApartmentAmount: "Deceased take out from apartment amount",
        additionalFieldOne: "Additional field no.1",
        additionalFieldOneAmount: "Additional field no.1 amount",
        additionalFieldTwo: "Additional field no.2",
        additionalFieldTwoAmount: "Aditional field no.2 amount",
        additionalFieldThree: "Additional field no.3",
        additionalFieldThreeAmount: "Additional field no.3 amount",
        amount: "Amount",
        refundAmount: "Refund amount",
        totalAmount: "Total amount",
      },

      messages: {
        EQUIPMENT_ORDER_WITH_NAME_SURNAME_CANT_BE_NULL: "Deceased must have name and surname.",
        EQUIPMENT_ORDER_DOES_NOT_EXIST: "Order does not exist."
      },
    },

    exhumationRequest: {
      headerTitle: "Exhumation requests",
      addPageTitle: "Add new exhumation request",
      editPageTitle: "Edit exhumation request",
      viewPageTitle: "View exhumation request",
      exhumationRequestNumber: "Request number",
      deceasedNameSurname: "Deceased",
      fromGraveyard: "Old graveyard",
      toGraveyard: "New graveyard",
      fromGraveSite: "Old grave site",
      toGraveSite: "New grave site",
      dateTimeOfExhumation: "Date and time of exhumation",
      dateCreated: "Date created",
      tomb: "Tomb",
      grave: "Grave",
      cassette: "Cassette",
      columbarium: "Columbarium",
      rosarium: "Rosarium",
      gardenOfMemories: "Garden of memories",
      other: "Other",

      form: {
        exhumationRequestNumber: "Exhumation request number",
        gender: "Gender",
        male: "Male",
        female: "Female",
        deceasedNameSurname: "Deceased name and surname",
        placeOfRest: "Place of rest",
        fromGraveyard: "From graveyard",
        fromGraveSite: "From grave site",
        dateOfFuneralString: "Date of funeral",
        field: "Field",
        placeOfTakeover: "Place of takeover",
        toGraveyard: "To graveyard",
        toGraveSite: "To grave site",
        graveOrTomb: "Grave type",
        grave: "Grave",
        tomb: "Tomb",
        numberOfCasketsForTomb: "Number of caskets",
        graveSiteType: "Type of grave site",
        tombEntrance: "Side of entrance: ",
        entranceFromTop: "Entrance from top",
        entranceFromSide: "Entrance from side",
        graveSiteTypeOtherNotes: "Comment",
        hasTombstone: "Tombstone",
        withTombstone: "With tombstone",
        noTombstone: "No tombstone",
        hasDeceased: "Deceased",
        withDeceased: "Has deceased",
        noDeceased: "No deceased",
        deceasedList: "Deceased names and surnames, along with years of death",
        requestSubmitterNameSurname: "Request submitter name and surname",
        requestSubmitterMunicipality: "Request submitter municipality",
        requestSubmitterAddress: "Request submitter address",
        requestSubmitterIDCardOrPassportNumber: "Request submitter id card / passport number",
        requestSubmitterIdentificationNumber: "Request submitter personal number",
        requestSubmitterTelephone: "Request submitter telephone",
        paymentNumber: "Payment number",
        paymentDate: "Payment date",
        dateTimeOfExhumation: "Date and time of exhumation",
        supervisorNameSurname: "Supervisor name and surname",
        specialNote: "Special note",
        ok: "Ok",
        cancel: "Cancel",
      },

      messages: {
        EXHUMATION_REQUEST_WITH_NAME_SURNAME_CANT_BE_NULL: "Deceased must have name and surname.",
        EXHUMATION_REQUEST_DOES_NOT_EXIST: "Request does not exist."
      },
    },

    scheduledFuneralList: {
      pageTitle: "Scheduled funerals",
      showActive: "Show active",
      showPassed: "Show passed",
    },

    scheduledCremationList: {
      pageTitle: "Scheduled cremations",
    },

    equipmentOrderList: {
      pageTitle: "Equipment orders",
    },

    exhumationRequestList: {
      pageTitle: "Exhumation requests",
    },

    reviserReview: {
      headerTitle: "Revisers review",
      pageTitle: "Revisers reviews",
      generateReport: "Generate report"
    },

    expectedIncomeReview: {
      headerTitle: "Expected income review",
      pageTitle: "Expected income",
      generateReport: "Generate report",
      graveSiteTypes: "Grave site types",
      year: "Year",
      monthFrom: "Month from",
      monthTo: "Month to",
    },

    expiryByGraveFieldReview: {
      headerTitle: "Expiry by grave field review",
      pageTitle: "Expiry by grave filed review",
      generateReport: "Generate report",
      graveSiteTypes: "Grave site types",
      graveyard: "Graveyard",
    },

    user: {
      user: "User",
      addUser: "ADD",
      viewAddr: "VIEW",
      userHomePage: "Home Page",
      pageTitle: "Add User",
      pageTitleEdit: "Edit User",
      pageTitleView: "View User",
      headerTitle: "Users",
      changePassword: "Password successfuly changed",
      form: {
        name: "name",
        surname: "surname",
        email: "e-mail",
        phoneNumber: "phone number",
        address: "address",
        birthDate: "birth date",
        selectBirthDate: "select date",
        company: "Company",
        errorAdding: "Error adding user",
        added: "User added",
        deleted: "User deleted",
        ok: "Ok",
        cancel: "Cancel"
      },
      editForm: {
        errorEditing: "Error editing user",
        edited: "User edited"
      },
      userList: {
        name: 'Name',
        surname: 'Surname',
        phoneNumber: 'Phone Number',
        address: 'Address',
        email: 'Email',
        birthDate: "Birth Date",
        isDeleted: 'Is deleted',
        dateCreated: 'Date Created',
        pageTitle: 'Users',
        enabled: 'Enabled',
        userDelete: 'User deleted',
        userRestored: 'User restored'
      },
      messages: {
        USER_WITH_EMAIL_ALREADY_EXIST: "User with that e-mail already exist. Pick another e-mail",
        LOGIN_AS_ADMIN: "Only administrators of company, login as administrator",
        USER_NOT_EXIST: "User doens't exist",
        USER_COMPANY_CANT_BE_NULL: "User company cannot be empty",
        USER_EMAIL_CANT_BE_NULL: "User email cannot be empty",
        USER_NAME_CANT_BE_NULL: "User name cannot be empty",
        USER_SURNAME_CANT_BE_NULL: "User surname cannot be empty",
        USER_PHONE_CANT_BE_NULL: "User phone cannot be empty"
      },
      homePage: {
        fullName: "Full name: ",
        email: "Email: ",
        address: "Address: ",
      }
    },

    delete: {
      confirmation: "Are you sure you want to delete?",
    },

    cremationStatement: {
      messages: {
        CREMATION_STATEMENT_DOES_NOT_EXIST: "Cremation statement does not exist.",
        CREMATION_STATEMENT_FULLNAME_CANT_BE_NULL: "Full name cannot be empty.",
        CREMATION_STATEMENT_JMBG_CANT_BE_NULL: "JMBG cannot be empty.",
        CREMATION_STATEMENT_MUNICIPALITY_CANT_BE_NULL: "Municipality cannot be empty.",
        CREMATION_STATEMENT_PHONENUMBER_CANT_BE_NULL: "Phone number cannot be empty.",
        CREMATION_STATEMENT_STATE_CANT_BE_NULL: "State cannot be empty.",
        CREMATION_STATEMENT_REFERENCENUMBER_CANT_BE_NULL: "Reference number cannot be empty.",
        CREMATION_STATEMENT_COMMENTS_CANT_BE_NULL: "Comments cannot be empty.",
      }
    },

    cremationStatementList: {
      pageTitle: "Cremation statements",
      fullName: "Name",
      jmbg: "JMBG",
      municipality: "Municipality",
      phoneNumber: "Phone number",
      country: "Country",
      referenceNumber: "Reference number",
      comments: "Comments",
      dateCreated: "Date created",
      dateUpdated: "Date updated",
    },
  },

  rs: {
    languages: {
      rs: 'rs',
      rsname: 'Српски',
      en: 'en',
      enname: 'English',
    },

    selectControl: {
      select: "Изабери...",
      showDeleted: "Прикажи обрисане",
      showVisible: "Прикажи необрисане",
    },

    navigation: {
      contract: 'Уговор',
      codebook: 'Одржавање шифарника',
      review: 'Прегледи',
      reviewsRecords: 'Прегледи евиденција',
      tables: 'Табеле',
      funerals: 'Сахране',
      review_tables: {
        reservations: 'Резервације',
        renewals: 'Обнове',
        tombs: 'Гробнице',
        cassettes: 'Касете',
        cremations: 'Кремације',
      },
      stonecutterWorks: 'Stonecutter works',
      exhumations: 'Exhumations',
      primaryEvidence: 'Основне евиденције',
      listCodebook: "Шифарник",
      scheduling: "Заказивање",
      funeralScheduling: "Заказивање сахрана",
      deceasedReception: 'Доношење и одношење покојника',
      renewals: "Обнове и опомене",
      renewalFeesForUsingAndMaintaningGraveyards: "Обнове накнаде за коришћење и одржавање гробаља",
      title: {
        administration: 'Администрација',
      },
    },

    review: {
      buttons: {
        generateReport: "Извештај",
        find: "Пронађи",
        back: "Назад",
        redefineSearch: "Редефиниши претрагу",
        generatePDF: "Генериши ПДФ",
        generatePDFDiscount: "Генериши ПДФ без попуста",
        loadProcessingReport: "Учитај извештај",
        saveProcessingReport: "Сачувај извештај",
        addDiscount: "Додај попуст",
        years: "Године:",
        percent: "Проценат:",
      },
      reviewEvidence: 'Прегледи евиденција',
      reviewsRecords: {
        cardGraveSite: 'Картон гробног места',
        graveyardCode: 'Шифра гробља:',
      },
      tables: {
        funerals: {
          funeralsForSelectedYear: 'Сахране за годину:',
          funeralsListPDF: 'PDF документ ће ускоро бити генерисан',
          funeralsListPDFName: 'Сахране за изабрану годину',

          numberOfFuneralsAndUrnsPerMonthForSelectedYear: 'Број сахрана за гробља по месецима и урне за одабрану календарску годину',

          numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear: 'Број сахрана за поља и гробља по месецима за одабрану календарску годину',
          funeralsForGraveFieldsAndGraveyardsForSelectedYearPDFName: 'Сахране за изабрану годину по пољима и гробљима',

          funeralsForSelectedGraveyard: 'Сахране за гробље:',
          selectedGraveyard: 'Гробље:',
          funeralsListPDFNameForSelectedGraveyard: 'Сахране за изабрано гробље',
          funeralsListPDFNameForSelectedGraveyards: 'Сахране за изабрана гробља',

          numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite: 'Број сахрана за поља и одабрано гробље по врсти гробних места',

          numberOfFuneralsForAllGraveyardsByTypeOfGraveSites: 'Број сахрана за сва гробља по врсти гробних места',
          funeralsByTypeOfGraveSitesAndGraveyards: 'Сахране по врсти гробних места и гробљима',
          funeralsListPDFNameForAllGraveyards: 'Сахране за сва гробља по врсти гробних места',

          numberOfFuneralsForYearsOfBurialAndSelectedGraveyards: 'Број сахрана за године сахрањивања и одабрана гробља',
          funeralsForSelectedGraveyards: 'Сахране за изабрана гробља:',
          selectSomeGraveyards: 'Изаберите гробља...',
          allGraveyardsAreSelected: 'Сва гробља су изабрана',
          selectAll: 'Изаберите све',
          search: 'Претрага',
          multiSelectError: 'Тренутно је изабран максималан број гробља који износи 10',

        },
        reservations: {
          numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard: 'Број продатих уређених гробних места по величини и месецима продаје за изабрану годину и гробље',
          soldArrangedGraveSitesForSelectedYear: 'Продата уређена гробна места за годину:',
          soldArrangedGraveSitesListPDFName: 'Продата уређена гробна места за изабрану годину',



          numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot: 'Број продатих уређених гробних места по величини и месецима продаје за изабрану годину, гробљe и парцелу',
          numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths: 'Број наслеђених уређених гробних места за текућу годину по месецима',
          numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths: 'Број наслеђених уређених гробних места за текућу годину и одабрану парцелу по месецима',

        },
        renewals: {},
        tombs: {},
        cassettes: {},
        cremations: {},

      },
    },

    registration: {
      passwordDoNotMatch: 'Лозинке се не поклапају, покушајте поново!',
    },

    table: {
      actions: 'Акције',
      edit: 'Измени',
      view: 'Прегледај',
      delete: 'Обриши',
      cancel: 'Сторно',
      confirmDelete: 'Потврди брисање',
      undoCancel: 'Поништи сторно',
      undo:'Врати',
      no: 'Не',
      yes: 'Да',
      search: 'Претрага',
      total: 'Укупно: ',
      footer: "Бр. редова по страници: ",
      exportData: "Експорт података",
    },

    header: {
      profile: 'Профил',
      lock: 'Lock',
      logout: 'Одјави се',
      serbian: 'Српски',
      english: 'English'
    },

    filter: {
      search: 'Претрага'
    },

    validation: {
      RequiredErrorMessage: 'Обавезно',
      AllRequiredErrorMessage: 'Сва поља су обавезна',
      MinLengthErrorMessage: 'Минимална дужина је ',
      MaxLengthErrorMessage: 'Максимална дужина је ',
      EmailErrorMessage: 'Молимо унесите валидан имејл',
      PasswordErrorMessage: 'Шифра мора садржати најмање 6 карактера, једно велико слово, једно мало слово и један број и један специјални карактер.',
      UserExistsErrorMessage: 'Корисник са овом имејл адресом већ постоји',
      OldPasswordDidNotMatch: 'Не слаже се са старом шифром',
      PasswordsNotEqual: 'Шифре се не поклапају',
      notNumber: 'Није број',
      isNegativeNumber: 'Негативан број',
      yearNotValid: 'Унесите годину између 1000 и 9999',
    },

    notFound: {
      notFound: 'Није пронађено!',
      dashboard: 'Контролна табла'
    },

    forbidden: {
      forbidden: 'Забрањено!',
      dashboard: 'Контролна табла'
    },

    error: {
      error: 'Грешка!',
      dashboard: 'Контролна табла',
      requiredFields: 'Обавезна поља нису исправно попуњена, проверите још једном и пробајте поново!',
      dateOfDeathandTakeoverDate: "Датум смрти мора бити пре датума доношења покојника",
    },

    login: {
      email: 'Имејл',
      password: 'Шифра',
      login: 'Пријава',
      wrongCredentials: 'Погрешни креденцијали',
      resetPassword: 'Заборављена шифра?',
      showPassword: "Прикажи шифру"
    },

    lock: {
      password: 'Шифра',
      login: 'Пријава',
      wrongCredentials: 'Погрешни креденцијали',
      unlock: 'Откључај'
    },

    reviewMainBookOfBuried: {
      all: "Без",
      deceasedNameSurnameSubmitter: "Преглед покојника избор по презимену и имену закупца гробног места",
      deceasedNameSurnameDeceased: "Преглед покојника избор по презимену и имену покојника",
      doctorTransporter: "Преглед покојника по лекару и транспортеру",
      graveField: "Преглед покојника избор по одабраној парцели"
    },

    reviewEvidentation: "Прегледи евиденција",

    renewalType: "Тип претраге обнова",

    renewalTypes: {
      all: "Без",
      submitterNameSurname: "Закупац",
      deceasedNameSurname: "Покојник",
      graveSite: "Гробно место",
      grave: "Гроб",
      reservation: "Резервација",
      tombrent: "Гробница",
      cassetterent: "Касета",
      childGrave: "Дечији гроб",
      arrangedPlot: "Уређена парцела",
    },

    leaseTypes: {
      tombRent: "Гробница",
      cassetteRent: "Касета",
      arrangedPlot: "Уређена парцела",
      reservation: "Резервација",
    },

    reservationTypes: {
      label: "Филтер",
      all: "Без",
      canceled: "Сторниране",
      unRenewed: "Необновљене",
    },

    graveSiteTypes: {
      label: "Типови",
      all: "Без",
      raka: "Рака",
      tomb: "Гробница",
      cassette: "Касета"
    },

    graveSiteRenewalTypes: {
      label: "Уговор",
      all: "Без",
      true: "Са уговором",
      false: "Без уговора",
    },

    months: {
      all: 'Сви',
      jan: 'Јануар',
      feb: 'Фебруар',
      mar: 'Март',
      apr: 'Април',
      may: 'Мај',
      jun: 'Јун',
      jul: 'Јул',
      aug: 'Август',
      sep: 'Септембар',
      oct: 'Октобар',
      nov: 'Новембар',
      dec: 'Децембар',
    },

    years: {
      all: 'Све'
    },

    userList: {
      firstName: 'Име',
      lastName: 'Презиме',
      email: 'Имејл',
      isDeleted: 'Је обрисано',
      dateCreated: 'Датум креирања',
      pageTitle: 'Корисници',
      enabled: 'Омогућено',
      userDelete: 'Корисник обрисан',
      userRestored: 'Корисник враћен',
      registrationConfirmed: 'Регистрација потврђена?',
      adminPageTitle: 'Администратори',
      company: 'Компанија',
    },

    companyList: {
      name: 'Назив',
      email: 'Имејл',
      isDeleted: 'Је обрисана',
      dateCreated: 'Датум креирања',
      pageTitle: 'Компаније',
      registrationNumber: 'Матични број',
      companyDelete: 'Компанија обрисана',
      companyRestored: 'Компанија враћена',
      website: 'Веб сајт',
      city: 'Град',
      state: 'Држава',
      occupationDeleted: 'Занимање обрисано',
      stateDeleted: 'Држава обрисана',
      causeOfDeathDeleted: 'Узрок смрти обрисан',
      doctorDeleted: 'Доктор обрисан',
      transporterDeleted: 'Транспортер обрисан',
      pricelistDeleted: 'Ценовник обрисан',
      urnDeleted: 'Урна обрисана',
      famousCitizenDeleted: 'Знаменити грађанин обрисан',
      cremationStatementDeleted: 'Изјава о кремацији обрисана',
      settlementDeleted: 'Насеље обрисано',
      postalCodeDeleted: 'Поштански број обрисан',
      postalCodeImprovedDeleted: 'Поштански број обрисан',
      stonecutterDeleted: 'Каменорезац обрисан',
      graveyardDeleted: 'Гробље обрисано',
      firmDeleted: 'Фирма обрисана',
      citizenDeleted: 'Грађанин обрисан',
      deceasedTakeoverRecordDeleted: 'Записник о преузимању покојника обрисан',
      urnTakeoverRecordDeleted: 'Записник о преузимању урне обрисан',
      deceasedTakeawayRecordDeleted: 'Записник о одношењу покојника обрисан',
      mainBookOfBuriedDeleted: 'Главна књига сахрањених обрисана',
      graveSiteDeleted: "Гробно место обрисано",
      mainBookOfCrematedDeleted: "Главна књига кремираних обрисана",
      exhumationDeleted: "Ексхумација обрисана",
      stoneCuttingWorkDeleted: "Каменорезачки рад обрисан",
      reservationDeleted: "Резервација обрисана",
      tombRentDeleted: "Гробница - закуп обрисан",
      cassetteRentDeleted: "Касета - закуп обрисан",
      renewalDeleted: "Обнова обрисана",
      grassDeleted: "Трава обрисана",
      scheduledFuneralDeleted: 'Заказана сахрана обрисана',
      scheduledCremationDeleted: 'Заказана кремација обрисана',
      equipmentOrderDeleted: 'Налог за опрему обрисан',
      exhumationRequestDeleted: 'Захтев за ексхумацију обрисан',
    },

    roles: {
      pageTitle: 'Роле',
      name: "Назив",
      roles: "Рола обрисана",
      addRole: "Рола додата",
      deleteRole: "Рола обрисана",
      editRole: "Рола измењена"
    },
    permissions: {
      pageTitle: 'Дозволе',
      category: "Категорије",
      code: "Тип",
      permission: 'Дозвола'
    },
    userRole: {
      pageTitle: 'Рола корисника',
      name: 'Име',
      surname: 'Презиме',
      role: 'Рола',
      user: 'Корисник',
      userRoleAdded: 'Рола корисника додата',
      userRoleEdited: 'Рола корисника измењена',
      userRoleDeleted: 'Рола корисника обрисана'
      
    },
    rolePermission: {
      pageTitle: 'Дозволе рола',
      role: "Рола",
      category: "Категорија",
      code: "Тип",
      deleted: "Пермисија обрисана",
      addRolePermission: "Дозвола рола додата",
      permission:"Пермисија",
      rolePermissionDeleted: "Пермисија обрисана"
    },
    auditTrailList: {
      actionName: 'Назив акције',
      objectId: 'Идентификатор објекта',
      email: 'Корисник',
      date: 'Датум и време',
      pageTitle: 'Трагови коришћења',
      companies: 'Компаније',
      actions: {
        VIEW: "ПРЕГЛЕД",
        REPORT: "ИЗВЕШТАЈ",

        ADD_USER: "ДОДАТ КОРИСНИК",
        EDIT_USER: "ИЗМЕЊЕН КОРИСНИК",
        DELETE_USER: "ОБРИСАН КОРИСНИК",
        ADD_COMPANY: "ДОДАТА КОМПАНИЈА",
        EDIT_COMPANY: "ИЗМЕЊЕНА КОМПАНИЈА",
        DELETE_COMPANY: "ОБРИСАНА КОМПАНИЈА",
        ADD_OCCUPATION: "ДОДАТО ЗАНИМАЊЕ",
        EDIT_OCCUPATION: "ИЗМЕЊЕНО ЗАНИМАЊЕ",
        DELETE_OCCUPATION: "ОБРИСАНО ЗАНИМАЊЕ",
        ADD_STATE: "ДОДАТА ДРЖАВА",
        EDIT_STATE: "ИЗМЕЊЕНА ДРЖАВА",
        DELETE_STATE: "ОБРИСАНА ДРЖАВА",
        ADD_CAUSE_OF_DEATH: "ДОДАТ УЗРОК СМРТИ",
        EDIT_CAUSE_OF_DEATH: "ИЗМЕЊЕН УЗРОК СМРТИ",
        DELETE_CAUSE_OF_DEATH: "ОБРИСАН УЗРОК СМРТИ",
        ADD_DOCTOR: "ДОДАТ ДОКТОР",
        EDIT_DOCTOR: "ИЗМЕЊЕН ДОКТОР",
        DELETE_DOCTOR: "ОБРИСАН ДОКТОР",
        ADD_TRANSPORTER: "ДОДАТ ТРАНСПОРТЕР",
        EDIT_TRANSPORTER: "ИЗМЕЊЕН ТРАНСПОРТЕР",
        DELETE_TRANSPORTER: "ОБРИСАН ТРАНСПОРТЕР",
        ADD_PRICELIST: "ДОДАТ ЦЕНОВНИК",
        EDIT_PRICELIST: "ИЗМЕЊЕН ЦЕНОВНИК",
        DELETE_PRICELIST: "ОБРИСАН ЦЕНОВНИК",
        ADD_URN: "ДОДАТА УРНА",
        EDIT_URN: "ИЗМЕЊЕНА УРНА",
        DELETE_URN: "ОБРИСАНА УРНА",
        ADD_FAMOUS_CITIZEN: "ДОДАТ ПОЗНАТИ ГРАЂАНИН",
        EDIT_FAMOUS_CITIZEN: "ИЗМЕЊЕН ПОЗНАТИ ГРАЂАНИН",
        DELETE_FAMOUS_CITIZEN: "ОБРИСАН ПОЗНАТИ ГРАЂАНИН",
        ADD_SETTLEMENT: "ДОДАТО НАСЕЉЕ",
        EDIT_SETTLEMENT: "ИЗМЕЊЕНО НАСЕЉЕ",
        DELETE_SETTLEMENT: "ОБРИСАНО НАСЕЉЕ",
        ADD_POSTAL_CODE: "ДОДАТ ПОШТАНСКИ БРОЈ И МЕСТО",
        EDIT_POSTAL_CODE: "ИЗМЕЊЕН ПОШТАНСКИ БРОЈ И МЕСТО",
        DELETE_POSTAL_CODE: "ОБРИСАН ПОШТАНСКИ БРОЈ И МЕСТО",
        ADD_POSTAL_ADDRESS_CODE: "ДОДАТ ПАК",
        EDIT_POSTAL_ADDRESS_CODE: "ИЗМЕЊЕН ПАК",
        DELETE_POSTAL_ADDRESS_CODE: "ОБРИСАН ПАК",
        ADD_STONECUTTER: "ДОДАТ КАМЕНОРЕЗАЦ",
        EDIT_STONECUTTER: "ИЗМЕЊЕН КАМЕНОРЕЗАЦ",
        DELETE_STONECUTTER: "ОБРИСАН КАМЕНОРЕЗАЦ",
        ADD_GRAVEYARD: "ДОДАТО ГРОБЉЕ",
        EDIT_GRAVEYARD: "ИЗМЕЊЕНО ГРОБЉЕ",
        DELETE_GRAVEYARD: "ОБРИСАНО ГРОБЉЕ",
        ADD_FIRM: "ДОДАТА ФИРМА",
        EDIT_FIRM: "ИЗМЕЊЕНА ФИРМА",
        DELETE_FIRM: "ОБРИСАНА ФИРМА",
        ADD_CITIZEN: "ДОДАТ ГРАЂАНИН",
        EDIT_CITIZEN: "ИЗМЕЊЕН ГРАЂАНИН",
        DELETE_CITIZEN: "ОБРИСАН ГРАЂАНИН",
        ADD_DECEASED_RECEPTION: "ДОДАТ ПРИЈЕМ ПОКОЈНИКА",
        EDIT_DECEASED_RECEPTION: "ИЗМЕЊЕН ПРИЈЕМ ПОКОЈНИКА",
        DELETE_DECEASED_RECEPTION: "ОБРИСАН ПРИЈЕМ ПОКОЈНИКА",
        ADD_MAIN_BOOK_BURIED: "ДОДАТА ГЛАВНА КЊИГА САХРАЊЕНИХ",
        EDIT_MAIN_BOOK_BURIED: "ИЗМЕЊЕНА ГЛАВНА КЊИГА САХРАЊЕНИХ",
        DELETE_MAIN_BOOK_BURIED: "ОБРИСАНА ГЛАВНА КЊИГА САХРАЊЕНИХ",
        ADD_GRAVE_SITE: "ДОДАТО ГРОБНО МЕСТО",
        EDIT_GRAVE_SITE: "ИЗМЕЊЕНО ГРОБНО МЕСТО",
        DELETE_GRAVE_SITE: "ОБРИСАНО ГРОБНО МЕСТО",
        ADD_MAIN_BOOK_CREMATED: "ДОДАТА ГЛАВНА КЊИГА КРЕМИРАНИХ",
        EDIT_MAIN_BOOK_OF_CREMATED: "ИЗМЕЊЕНА ГЛАВНА КЊИГА КРЕМИРАНИХ",
        DELETE_MAIN_BOOK_OF_CREMATED: "ОБРИСАНА ГЛАВНА КЊИГА КРЕМИРАНИХ",
        ADD_EXHUMATION: "ДОДАТА ЕКСХУМАЦИЈА",
        EDIT_EXHUMATION: "ИЗМЕЊЕНА ЕКСХУМАЦИЈА",
        DELETE_EXHUMATION: "ОБРИСАНА ЕКСХУМАЦИЈА",
        ADD_AID: "ДОДАТО ПОМАГАЛО",
        EDIT_AID: "ИЗМЕЊЕНО ПОМАГАЛО",
        DELETE_AID: "ОБРИСАНО ПОМАГАЛО",
        ADD_PRINCIPAL: "ДОДАТ НАЛОГОДАВАЦ",
        EDIT_PRINCIPAL: "ИЗМЕЊЕН НАЛОГОДАВАЦ",
        DELETE_PRINCIPAL: "ОБРИСАН НАЛОГОДАВАЦ",
        ADD_REASON_BRINGING_DECEASED: "ДОДАТ РАЗЛОГ ДОВОЖЕЊА ПОКОЈНИКА",
        EDIT_REASON_BRINGING_DECEASED: "ИЗМЕЊЕН РАЗЛОГ ДОВОЖЕЊА ПОКОЈНИКА",
        DELETE_REASON_BRINGING_DECEASED: "ОБРИСАН РАЗЛОГ ДОВОЖЕЊА ПОКОЈНИКА",
        ADD_STORAGE_PLACE: "ДОДАТО МЕСТО ЗА ЧУВАЊЕ",
        EDIT_STORAGE_PLACE: "ИЗМЕЊЕНО МЕСТО ЗА ЧУВАЊЕ",
        DELETE_STORAGE_PLACE: "ОБРИСАНО МЕСТО ЗА ЧУВАЊЕ",
        ADD_DECEASED_BROUGHT: "ДОДАТ ДОВЕЖЕНИ ПОКОЈНИК",
        EDIT_DECEASED_BROUGHT: "ИЗМЕЊЕН ДОВЕЖЕНИ ПОКОЈНИК",
        DELETE_DECEASED_BROUGHT: "ОБРИСАН ДОВЕЖЕНИ ПОКОЈНИК",
        ADD_CRATE: "ДОДАТ САНДУК",
        EDIT_CRATE: "ИЗМЕЊЕН САНДУК",
        DELETE_CRATE: "ОБРИСАН САНДУК",
        ADD_TOMBSTONE: "ДОДАТ НАДГРОБНИ СПОМЕНИК",
        EDIT_TOMBSTONE: "ИЗМЕЊЕН НАДГРОБНИ СПОМЕНИК",
        DELETE_TOMBSTONE: "ОБРИСАН НАДГРОБНИ СПОМЕНИК",
        ADD_LETTER: "ДОДАТО ПИСМО",
        EDIT_LETTER: "ИЗМЕЊЕНО ПИСМО",
        DELETE_LETTER: "ОБРИСАНО ПИСМО",
        ADD_LANGUAGE: "ДОДАТ ЈЕЗИК",
        EDIT_LANGUAGE: "ИЗМЕЊЕН ЈЕЗИК",
        DELETE_LANGUAGE: "ОБРИСАН ЈЕЗИК",
        ADD_TYPEFACE: "ДОДАТА ВРСТА СЛОВА",
        EDIT_TYPEFACE: "ИЗМЕЊЕНА ВРСТА СЛОВА",
        DELETE_TYPEFACE: "ОБРИСАНА ВРСТА СЛОВА",
        ADD_STONE_CUTTING_WORK: "ДОДАТ КАМЕНОРЕЗАЧКИ РАД",
        EDIT_STONE_CUTTING_WORK: "ИЗМЕЊЕН КАМЕНОРЕЗАЧКИ РАД",
        DELETE_STONE_CUTTING_WORK: "ОБРИСАН КАМЕНОРЕЗАЧКИ РАД",
        ADD_RESERVATION: "ДОДАТА РЕЗЕРВАЦИЈА",
        EDIT_RESERVATION: "ИЗМЕЊЕНА РЕЗЕРВАЦИЈА",
        DELETE_RESERVATION: "ОБРИСАНА РЕЗЕРВАЦИЈА",
        CANCEL_RESERVATION: "СТОРНИРАНА РЕЗЕРВАЦИЈА",
        UNDO_CANCEL_RESERVATION: "ПОНИШТЕНО СТОРНИРАЊЕ РЕЗЕРВАЦИЈЕ",
        ADD_TOMB_RENT: "ДОДАТ ЗАКУП ГРОБНИЦЕ",
        EDIT_TOMB_RENT: "ИЗМЕЊЕН ЗАКУП ГРОБНИЦЕ",
        DELETE_TOMB_RENT: "ОБРИСАН ЗАКУП ГРОБНИЦЕ",
        CANCEL_TOMB_RENT: "СТОРНИРАН ЗАКУП ГРОБНИЦЕ",
        UNDO_CANCEL_TOMB_RENT: "ПОНИШТЕНО СТОРНИРАЊЕ ЗАКУПА ГРОБНИЦЕ",
        ADD_CASSETTE_RENT: "ДОДАТ ЗАКУП КАСЕТЕ",
        EDIT_CASSETTE_RENT: "ИЗМЕЊЕН ЗАКУП КАСЕТЕ",
        DELETE_CASSETTE_RENT: "ОБРИСАН ЗАКУП КАСЕТЕ",
        CANCEL_CASSETTE_RENT: "СТОРНИРАН ЗАКУП КАСЕТЕ",
        UNDO_CANCEL_CASSETTE_RENT: "ПОНИШТЕНО СТОРНИРАЊЕ ЗАКУПА КАСЕТЕ",
        EDIT_RENEWAL: "ИЗМЕЊЕНА ОБНОВА",
        DELETE_RENEWAL: "ОБРИСАНА ОБНОВА",
        CANCEL_RENEWAL: "СТОРНИРАНА ОБНОВА",
        UNDO_CANCEL_RENEWAL: "ПОНИШТЕНО СТОРНИРАЊЕ ОБНОВЕ",
        ADD_GRASS: "ДОДАТА ТРАВА",
        EDIT_GRASS: "ИЗМЕЊЕНА ТРАВА",
        DELETE_GRASS: "ОБРИСАНА ТРАВА",
        CANCEL_GRASS: "СТОРНИРАНА ТРАВА",
        UNDO_CANCEL_GRASS: "ПОНИШТЕНО СТОРНИРАЊЕ ТРАВЕ",
      },
      objects: {
        Aids: "Pomagala"
      }
    },

    cardGraveSiteList: {
      indicator: "Индикатор",
      contractNumber: "Број уговора",
      leaser: "Закупац",
      cost: "Уплатилац",
      mainBookOfBuried: "ГКС",
      tombstone: "Споменик",
      lastChange: "Задња промена",
      expiryDate: "Истек ГМ",
      deceased: "Покојник",
      name: "Корисник",
      street: "Улица",
      place: "Место",
      previousGraveSite: "Претходно ГМ",
      comment: "Коментар",
      expiryDateContract: "Истек уговора",
      rentTombsOrCassettes: 'Закуп гробница, односно касета',
      renewaler: "Обновитељ",
      graveSiteUser: "Корисник гробног места",
      canceledRentContract: 'Сторниран уговор о закупу',
      graveSiteForLifeUser: 'Корисник гробног места за живота',
      paidVerticalReservation: 'Уплаћена вертикална резервација',
      rentArrangedGraveSite: 'Закупа уређеног гробног места',
      deceasedAtObservedGraveSite: 'Покојник на посматраном гробном месту',
      deceasedExhumatedFromObservedGraveSite: 'Покојник који је ексхумиран са посматраног гробног места',
      select: 'Изаберите...',
    },

    occupationList: {
      code: "Шифра занимања",
      name: "Назив занимања",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Занимања',
    },

    stateList: {
      code: "Шифра државе",
      name: "Назив државе",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Државе',
    },

    causeOfDeathList: {
      code: "Шифра узрока смрти",
      name: "Назив узрока смрти",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Узроци смрти',
    },

    doctorList: {
      code: "Шифра доктора",
      name: "Назив доктора",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Доктори',
    },

    transporterList: {
      code: "Шифра транспортера",
      name: "Назив транспортера",
      shortenedName: "Скраћени назив транспортера",
      yearFrom: "Година од",
      yearTo: "Година до",
      monthFrom: "Месец од",
      monthTo: "Месец до",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Транспортери',
    },

    pricelistList: {
      code: "Шифра услуге",
      name: "Назив услуге",
      price: "Цена услуге",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Ценовници',
    },

    urnList: {
      code: "Шифра урне",
      name: "Назив урне",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Урне',
    },

    famousCitizenList: {
      code: "Шифра",
      description: "Опис",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Знаменити грађани',
      famousCitizen: 'Знаменити грађанин',
    },

    settlementList: {
      code: "Шифра насеља",
      name: "Назив насеља",
      municipalityCode: "Шифра општине",
      municipalityName: "Назив општине",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Насеља',
    },

    postalCodeList: {
      code: "Шифра поштанског броја",
      name: "Назив места",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Поштански бројеви и места',
    },

    postalCodeImprovedList: {
      zip: "Поштански број",
      state: "Држава",
      municipality: "Општина",
      city: "Град",
      settlement: "Насеље",
      dateCreated: "Датум креирања",
      pageTitle: 'Поштански бројеви и места'
    },

    postalAddressCodeList: {
      pageTitle: 'ПАК',
      place: 'Место',
      street: 'Улица',
      number: 'Број',
      city: "Град",
      pac: 'ПАК',
      postalNumber: 'Поштански број',
      settlementCode: 'Шифра насеља',
      stateCode: 'Шифра државе',
      postalAddressCodeDeleted: 'ПАК обрисан',

    },

    postalAddressCodeImprovedList: {
      zip: "Поштански број",
      pac: 'ПАК',
      state: "Држава",
      cityAndZip: "Град и поштански број",
      street: "Улица",
      number: "Број",
      dateCreated: "Датум креирања",
      pageTitle: 'Поштански адресни код',
      postalAddressCodeDeleted: 'Поштански адресни код избрисан',
    },

    stonecutterList: {
      code: "Шифра каменоресца",
      name: "Назив каменоресца",
      type: "Врста каменоресца",
      address: "Адреса",
      note: "Напомена",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Каменоресци',
    },

    graveyardList: {
      code: "Шифра гробља",
      name: "Назив гробља",
      place: "Место",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Гробља',
    },

    firmList: {
      taxNumber: "ПИБ",
      name: "Назив фирме",
      headquarters: "Седиште",
      street: "Улица",
      streetNumber: "Број",
      contactPerson: "Контакт лице",
      telephone: "Телефон",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Фирме',
    },

    citizenList: {
      personalNumber: "Матични број",
      name: "Име",
      surname: "Презиме",
      postalCode: "Поштански број и место",
      street: "Улица",
      streetNumber: "Број",
      address: "Адреса",
      telephone: "Телефон",
      deceased: "Покојник",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Грађани',
    },

    deceasedTakeoverRecordList: {
      deceasedNameSurname: "Презиме и име покојника",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Записник о преузимању покојника',
    },

    urnTakeoverRecordList: {
      deceasedNameSurname: "Презиме и име покојника",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Записник о преузимању урне',
    },

    deceasedTakeawayRecordList: {
      deceasedNameSurname: "Презиме и име покојника",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисано',
      pageTitle: 'Записник о одношењу покојника',
    },

    mainBookOfBuriedList: {
      mainBookOfBuries: "ГКС",
      deceasedNameSurname: "Покојник",
      dateCreated: "Датум креирања",
      transporter: "Транспортер",
      doctor: "Лекар",
      graveyard: "Гробље",
      graveSite: "Гробно место",
      funeral: "Сахрана",
      submitterName: "Име подносиоца",
      submitterSurname: "Презиме подносиоца",
      isDeleted: 'Је обрисана',
      pageTitle: 'Главна књига сахрањених',
      PDF: "PDF документ ће ускоро бити генерисан",
      year: "Година",
      month: "Месец",
      dateOfBirth: "Датум рођења",
      dateOfDeath: "Датум смрти",
      fathersName: "Име оца",
    },

    graveSiteList: {
      graveyardName: "Гробље",
      graveSite: "Гробно место",
      expirationDate: "Датум истека",
      dateUpdated: "Датум промене",
      dateCreated: "Датум креирања",
      arrangedPlot: "Уређена парцела",
      used: "Искоришћена",
      isDeleted: 'Је обрисано',
      pageTitle: 'Гробна места',
      size: 'Величина ГМ',
    },

    mainBookOfCrematedList: {
      deceasedNameSurname: "Покојник",
      submitterName: "Име подносиоца захтева",
      submitterSurname: "Презиме подносиоца захтева",
      userGraveSiteName: "Преузео урну име",
      userGraveSiteSurname: "Преузео урну презиме",
      urnName: "Урна",
      dateTimeOfCremation: "Датум кремирања",
      dateTimeUrnTakeover: "Датум предаје урне",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Главна књига кремираних',
      mainBookOfCremated: 'ГКК'
    },

    exhumationList: {
      exhumation: "Екс",
      mainBookOfBuried: "Гкс",
      deceasedNameSurname: "Покојника",
      graveSiteCode: "Гробно м. ново",
      graveyardCode: "Гробље ново",
      graveyardName: "Гробље ново",
      tombstoneCode: "Споменик",
      nameSurnameCost: "Име и презиме уплатиоца",
      expiryDate: "Истек",
      dateTimeFuneral: "Сахрана",
      oldGraveyardCode: "Гробље старо",
      oldGraveyardName: "Гробље старо",
      oldGraveSiteCode: "Гробно м. старо",
      mainBookOfBuriedSubmitterName: "Име закупца",
      mainBookOfBuriedSubmitterSurname: "Прз закупца",
      isHasNote: "Нап.",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисана',
      pageTitle: 'Ексхумације',
    },

    stoneCuttingWorksList: {
      code: "Број у евиденцији радова",
      stonecutterName: "Каменорезац",
      mainBookOfBuriedDeceasedNameSurname: "Покојник",
      citizenName: "Име закупца",
      citizenSurname: "Прз закупца",
      graveyardCode: "Гробље",
      graveSiteCode: "Гробно место",
      jobType: "Врста посла",
      permissionNumber: "Број дозволе за рад",
      permissionStartDate: "Дозвола вази од дана",
      tombstoneType: "Врста споменика",
      hasNote: "Има напомена",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Каменорезачки радови',
    },

    reservationList: {
      mainBookOfBuriedMainBookOfBuries: "Број закупа",
      submitterName: "Закупац име",
      submitterSurname: "Закупац прз",
      graveSite: "Гробно место",
      tombstoneType: "Шифра споменика",
      expiryDate: "Датум истека",
      dateCreated: "Датум креирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Резервације',
      canceled: "Сторнирано",
      PDF: "Извештај",
      reportName: "Преглед резервација са истеком"
    },

    tombRentList: {
      mainBookOfBuriedMainBookOfBuries: "Број закупа",
      dateCreated: "Датум креирања",
      dateOfCancellation: "Датум сторнирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Гробнице - закупи',
      canceled: "Сторнирано",
      submitterName: "Закупац име",
      submitterSurname: "Закупац презиме",
      graveSiteCode: "Гробно место",
      tombstoneType: "Споменик",
      expiryDate: "Датум истека"
    },

    cassetteRentList: {
      mainBookOfBuriedMainBookOfBuries: "Број закупа",
      dateCreated: "Датум креирања",
      dateOfCancellation: "Датум сторнирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Касете - закупи',
      canceled: "Сторнирано",
      submitterName: "Закупац име",
      submitterSurname: "Закупац презиме",
      graveSiteCode: "Гробно место",
      expiryDate: "Датум истека",
    },

    renewalList: {
      renewalNumber: "Број обнове",
      graveSiteCode: "Гр. место",
      dateUpdated: "Датум измене",
      dateOfChange: "Датум промене",
      submitterName: "Закупац име",
      submitterSurname: "Закупац прз.",
      costName: "Уплатилац име",
      costSurname: "Уплатилац прз.",
      expiryDate: "Датум истека",
      dateCreated: "Датум креирања",
      dateOfCancellation: "Датум сторнирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Obnove',
      canceled: "Сторнирано",
      year: "Године",
      renewalType: "Врста обнове",
      graveyard: "Гробље",
      deceasedNameSurname: "Покојник",
      submitterNameSurname: "Закупац",
      deceasedAndSubmittersList: "Списак покојника и корисника",
      renewalsForProcessingList: "Списак обновa за обраду",
      addNewRenewal: "Додај нову обнову",
      processingReports: "Сачувани извештаји",
      reportSaved: "Извештај сачуван",
    },

    leaseRenewalProcessingList: {
      leaseRenewalType: "Врста закупа",
      headerTitleTombRent: "Списак гробница за обраду",
      headerTitleCassetteRent: "Списак касета за обраду",
      headerTitleArrangedPlot: "Списак уређених парцела за обраду",
      contractNumber: "Број уговора",
      dateOfChange: "Датум задње промене",
      submitterNameSurname: "Име и презиме закупца",
      graveyardName: "Гробље",
      graveSiteCode: "Гробно место",
      contractExpiryDate: "Датум истека",
    },

    grassList: {
      grassNumber: "ТраваИд",
      dateCreated: "Датум креирања",
      dateOfCancellation: "Датум сторнирања",
      isDeleted: 'Је обрисан',
      pageTitle: 'Trave',
      canceled: "Сторнирано",
    },

    userForm: {
      email: 'Имејл',
      firstName: 'Име',
      lastName: 'Презиме',
      ok: 'Ок',
      cancel: 'Одустани'
    },

    addUser: {
      pageTitle: 'Додај корисника',
      errorAddClub: 'Грешка приликом додавања корисника',
      clubAdded: 'Корисник додат',
      errorAddingUser: 'Грешка приликом додавања корисника',
      userAdded: 'Корисник додат'
    },

    addCompany: {
      pageTitle: 'Додај компанију',
      errorAddCompany: 'Грешка приликом додавања компаније',
      errorAddingCompany: 'Грешка приликом додавања компаније',
      companyAdded: 'Компанија додата',
      occupationAdded: 'Занимање додато',
      occupationEdited: 'Занимање измењено',
      stateAdded: 'Држава додата',
      stateEdited: 'Држава измењена',
      causeOfDeathAdded: 'Узрок смрти додат',
      causeOfDeatEdited: 'Узрок смрти измењен',
      doctorAdded: 'Доктор додат',
      doctorEdited: 'Доктор измењен',
      transporterAdded: 'Транспортер додат',
      transporterEdited: 'Транспортер измењен',
      pricelistAdded: 'Ценовник додат',
      pricelistEdited: 'Ценовник измењен',
      urnAdded: 'Урна додата',
      urnEdited: 'Урна измењена',
      famousCitizenAdded: 'Знаменити грађанин додат',
      famousCitizenEdited: 'Знаменити грађанин измењен',
      cremationStatementAdded: 'Изјава о кремацији додата',
      cremationStatementEdited: 'Изјава о кремацији измењена',
      settlementAdded: 'Насеље додато',
      settlementEdited: 'Насеље измењено',
      postalCodeAdded: 'Поштански број додат',
      postalCodeEdited: 'Поштански број измењен',
      postalCodeImprovedEdited: 'Поштански број измењен',
      postalAddressCodeAdded: 'ПАК додат',
      postalAddressCodeEdited: 'ПАК измењен',
      postalAddressCodeImprovedAdded: 'ПАК додат',
      postalAddressCodeImprovedEdited: 'ПАК измењен',
      stonecutterAdded: 'Каменорезац додат',
      stonecutterEdited: 'Каменорезац измењен',
      graveyardAdded: 'Гробље додато',
      graveyardEdited: 'Гробље измењено',
      firmAdded: 'Фирма додата',
      firmEdited: 'Фирма измењена',
      citizenAdded: 'Грађанин додат',
      citizenEdited: 'Грађанин измењен',
      deceaseTakeoverRecordAdded: 'Записник о преузимању покојника додат',
      deceaseTakeoverRecordEdited: 'Записник о преузимању покојника измењен',
      urnTakeoverRecordAdded: 'Записник о преузимању урне додат',
      urnTakeoverRecordEdited: 'Записник о преузимању урне измењен',
      deceaseTakeawayRecordAdded: 'Записник о одношењу покојника додат',
      deceaseTakeawayRecordEdited: 'Записник о одношењу покојника измењен',
      graveSiteAdded: "Гробно место додато",
      graveSiteEdited: "Гробно место измењено",
      mainBookOfBuriedAdded: "Главна књига умрлих додата",
      mainBookOfBuriedEdited: "Главна књига умрлих измењена",
      mainBookOfCrematedAdded: "Главна књига кремираних додата",
      mainBookOfCrematedEdited: "Главна књига кремираних измењена",
      exhumationAdded: "Ексхумација додата",
      exhumationEdited: "Ексхумација измењена",
      stoneCuttingWorkAdded: "Каменорезачки рад додат",
      stoneCuttingWorkEdited: "Каменорезачки рад измењен",
      reservationAdded: "Резервација додата",
      reservationEdited: "Резервација измењена",
      reservationCanceled: "Резервација сторнирана",
      undoReservationCanceled: "Сторнирање резервације поништено",
      tombRentAdded: "Гробница - закуп додат",
      tombRentEdited: "Гробница - закуп измењен",
      tombRentCanceled: "Гробница - закуп сторнирана",
      undoTombRentCanceled: "Сторнирање закупа гробнице поништено",
      cassetteRentAdded: "Касета - закуп додат",
      cassetteRentEdited: "Касета - закуп измењен",
      cassetteRentCanceled: "Касета - закуп сторнирана",
      undoCassetteRentCanceled: "Сторнирање закупа касете поништено",
      renewalEdited: "Обнова измењена",
      renewalCanceled: "Обнова сторнирана",
      undoRenewalCanceled: "Сторнирање обнове поништено",
      grassAdded: "Трава додата",
      grassEdited: "Трава измењена",
      grassCanceled: "Трава сторнирана",
      undoGrassCanceled: "Сторнирање траве поништено",
      scheduledFuneralAdded: "Успешно заказана сахрана",
      scheduledFuneralEdited: "Успешно измењена сахрана",
      scheduledCremationAdded: "Успешно заказана кремација",
      scheduledCremationEdited: "Успешно измењена кремација",
      equipmentOrderAdded: "Успешно додат налог за опрему",
      equipmentOrderEdited: "Успешно измењен налог за опрему",
      exhumationRequestAdded: "Успешно додат захтев за ексхумацију",
      exhumationRequestEdited: "Успешно измењен захтев за ексхумацију",
    },

    aid: {
      pageTitle: "Додај помагало",
      editPageTitle: "Измени помагало",
      viewPageTitle: "Преглед помагала",
      headerTitle: "Помагала",
      all: "Све",
      messages: {
        AID_WITH_NAME_ALREADY_EXIST: "Помагало већ постоји са тим називом",
        AID_NOT_EXIST: "Помагало не постоји",
        AID_NAME_CANT_BE_NULL: "Назив помагала не може бити празан"

      },
      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања помагала",
        added: "Помагало додато",
        deleted: "Помагало обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене помагала",
        edited: "Помагало измењено"
      },
    },

    aidList: {
      pageTitle: "Помагала",
      deleted: "Помагало обрисано",
      name: 'Назив',
      dateCreated: 'Датум креирања'
    },

    addAid: {
      pageTitle: "Додај помагало",
      added: "Помагало додато"
    },

    storagePlace: {
      pageTitle: "Додај место за чување",
      editPageTitle: "Измени место за чување",
      viewPageTitle: "Преглед места за чување",
      headerTitle: "Места за чување",
      messages: {
        STORAGE_WITH_NAME_ALREADY_EXIST: "Место за чување већ постоји са тим називом",
        STORAGE_NOT_EXIST: "Место за чување не постоји",
        STORAGE_NAME_CANT_BE_NULL: "Назив места за чување не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Место за чување додато",
        deleted: "Место за чување обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене места за чување",
        edited: "Место за чување измењено"
      }
    },

    storagePlaceList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Места за чување",
      deleted: "Место за чување обрисано"
    },

    addStoragePlace: {
      pageTitle: "Додај место за чување",
      added: "Место за чување додато"
    },

    deceasedBrought: {
      pageTitle: "Додај довеженог покојника",
      editPageTitle: "Измени довеженог покојника",
      viewPageTitle: "Преглед довеженог покојника",
      headerTitle: "Довежени покојници",
      messages: {
        DECEASED_WITH_NAME_ALREADY_EXIST: "Довежени покојник већ постоји са тим именом",
        DECEASED_NOT_EXIST: "Довежени покојник не постоји",
        DECEASED_NAME_CANT_BE_NULL: "Име довеженог покојника не може бити празно"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Довежени покојник додат",
        deleted: "Довежени покојник обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене довеженог покојника",
        edited: "Довежени покојник измењен"
      }

    },

    deceasedBroughtList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Довежени покојници",
      deleted: "Довежени покојник обрисан"
    },

    addDeceasedBrought: {
      pageTitle: "Додај довеженог покојника",
      added: "Довежени покојник додат"
    },

    crate: {
      pageTitle: "Додај списак сандука",
      editPageTitle: "Измени списак сандука",
      viewPageTitle: "Преглед списка сандука",
      headerTitle: "Списак сандука",
      messages: {
        CRATE_WITH_NAME_ALREADY_EXIST: "Списак сандука већ постоји са тим називом",
        CRATE_NOT_EXIST: "Списак сандука не постоји",
        CRATE_NAME_CANT_BE_NULL: "Назив списка сандука не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Списак сандука додат",
        deleted: "Списак сандука обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене списка сандука",
        edited: "Списак сандука измењен"
      }
    },

    crateList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Списак сандука",
      deleted: "Списак сандука обрисан"
    },

    addCrate: {
      pageTitle: "Додај списак сандука",
      added: "Списак сандука додат"
    },

    tombstone: {
      pageTitle: "Додај надгробно обележје",
      editPageTitle: "Измени надгробно обележје",
      viewPageTitle: "Преглед надгробног обележја",
      headerTitle: "Надгробна обележја",
      messages: {
        TOMBSTONE_WITH_NAME_ALREADY_EXIST: "Надгробно обележје већ постоји са тим називом",
        TOMBSTONE_NOT_EXIST: "Надгробно обележје не постоји",
        TOMBSTONE_NAME_CANT_BE_NULL: "Назив надгробног обележја не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Надгробно обележје додато",
        deleted: "Надгробно обележје обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене надгробног обележја",
        edited: "Надгробно обележје измењено"
      }
    },

    tombstoneList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Надгробна обележја",
      deleted: "Надгробно обележје обрисано"
    },

    addTombstone: {
      pageTitle: "Додај надгробно обележје",
      added: "Надгробно обележје додато"
    },

    letter: {
      pageTitle: "Додај списак писама",
      editPageTitle: "Измени списак писама",
      viewPageTitle: "Преглед списка писама",
      headerTitle: "Списак писама",
      messages: {
        LETTER_WITH_NAME_ALREADY_EXIST: "Списак писама већ постоји са тим називом",
        LETTER_NOT_EXIST: "Списак писама не постоји",
        LETTER_NAME_CANT_BE_NULL: "Назив списка писама не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Списак писама додат",
        deleted: "Списак писама обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене списка писама",
        edited: "Списак писама измењен"
      }
    },

    letterList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Списак писама",
      deleted: "Списак писама обрисан"
    },

    addLetter: {
      pageTitle: "Додај списак писама",
      added: "Списак писама додат"
    },

    language: {
      pageTitle: "Додај списак језика",
      editPageTitle: "Измени списак језика",
      viewPageTitle: "Преглед списка језика",
      headerTitle: "Списак језика",
      messages: {
        LANGUAGE_WITH_NAME_ALREADY_EXIST: "Списак језика већ постоји са тим називом",
        LANGUAGE_NOT_EXIST: "Списак језика не постоји",
        LANGUAGE_NAME_CANT_BE_NULL: "Назив списка језика не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Списак језика додат",
        deleted: "Списак језика обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене списка језика",
        edited: "Списак језика измењен"
      }
    },

    languageList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Списак језика",
      deleted: "Списак језика обрисан"
    },

    addLanguage: {
      pageTitle: "Додај списак језика",
      added: "Списак језика додат"
    },

    typeface: {
      pageTitle: "Додај врсту слова",
      editPageTitle: "Измени врсту слова",
      viewPageTitle: "Преглед врсте слова",
      headerTitle: "Врсте слова",
      messages: {
        TYPEFACE_WITH_NAME_ALREADY_EXIST: "Врста слова већ постоји са тим називом",
        TYPEFACE_NOT_EXIST: "Врста слова не постоји",
        TYPEFACE_NAME_CANT_BE_NULL: "Назив врсте слова не може бити празан"
      },

      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања",
        added: "Врста слова додата",
        deleted: "Врста слова обрисана",
        ok: "Ок",
        cancel: "Одустани"
      },

      editForm: {
        errorEditing: "Грешка приликом измене врсте слова",
        edited: "Врста слова измењена"
      }
    },

    typefaceList: {
      name: 'Назив',
      dateCreated: 'Датум креирања',
      pageTitle: "Врсте слова",
      deleted: "Врста слова обрисана"
    },

    addTypeface: {
      pageTitle: "Додај врсту слова",
      added: "Врста слова додата"
    },

    principal: {
      pageTitle: "Додај налогодавца",
      editPageTitle: "Измени налогодавца",
      viewPageTitle: "Преглед налогодавца",
      headerTitle: "Налогодавци",
      messages: {
        PRINCIPAL_WITH_NAME_ALREADY_EXIST: "Налогодавац већ постоји са тим именом",
        PRINCIPAL_NAME_CANT_BE_NULL: "Име налогодавца не може бити празно",
        PRINCIPAL_NOT_EXIST: "Налогодавац не постоји"

      },
      form: {
        name: "Име",
        errorAdding: "Грешка приликом додавања налогодавца",
        added: "Налогодавац додат",
        deleted: "Налогодавац обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене налогодавца",
        edited: "Налогодавац измењен"
      },
    },

    principalList: {
      name: 'Име',
      dateCreated: 'Датум креирања',
      pageTitle: "Налогодавци",
      deleted: "Налогодавац обрисан"
    },

    addPrincipal: {
      pageTitle: "Додај налогодавца",
      added: "Налогодавац додат"
    },

    reasonBringingDeceased: {
      pageTitle: "Додај разлог довожења покојника",
      editPageTitle: "Измени разлог довожења покојника",
      viewPageTitle: "Преглед разлог довожења покојника",
      headerTitle: "Разлози довожења покојника",
      messages: {
        REASON_WITH_NAME_ALREADY_EXIST: "Већ постоји разлог довожења покојника са истим називом",
        REASON_NOT_EXIST: "Разлог довожења покојника не постоји",
        REASON_NAME_CANT_BE_NULL: "Назив разлога довожења покојника не може бити празан",
      },
      form: {
        name: "Назив",
        errorAdding: "Грешка приликом додавања разлога довожења покојника",
        added: "Разлог довожења покојника додат",
        deleted: "Разлог довожења покојника обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измени разлога довожења покојника",
        edited: "Разлог довожења покојника измењен"
      },
    },

    reasonBringingDeceasedList: {
      pageTitle: "Разлози довожења покојника",
      deleted: "Разлог довожења покојника обрисан",
      name: "Назив",
      dateCreated: "Датум креирања"
    },

    addReasonBringingDeceased: {
      pageTitle: "Додај разлог довожења покојника",
      added: "Разлог довожења покојника додат"
    },

    resetPassword: {
      email: 'Имејл',
      resetPassword: 'Ресетуј шифру',
      password: 'Шифра',
      passwordRepeat: 'Поновите шифру',
      mailSent: 'Имејл са линком за ресетовање шифре послат!',
    },

    createPassword: {
      email: 'Имејл',
      createPassword: 'Креирај шифру',
      password: 'Шифра',
      passwordRepeat: 'Поновите шифру',
    },

    changePassword: {
      email: 'Имејл',
      changePassword: 'Промени шифру',
      password: 'Шифра',
      passwordRepeat: 'Поновите шифру',
      oldPassword: "Претходна шифра",
    },

    profile: {
      profile: "Профил",
      pageTitle: "Мој профил",
      login: "Пријава",
      changePassword: "Промени шифру",
    },

    company: {
      pageTitle: "Додај компанију",
      editPageTitle: "Измени компанију",
      viewPageTitle: "Преглед компаније",
      headerTitle: "Компанија",
      all: "Све",
      messages: {
        COMPANY_WITH_NAME_OR_EMAIL_OR_REGNMB_ALREADY_EXIST: "Компанија са имејлом или именом или мат. бројем већ постоји.",
        LOGIN_AS_SUPERADMIN: "Пријави се као суперадмин",
        COMPANY_NOT_EXIST: "Компанија не постоји",
        COMPANY_WITH_NAME_ALREADY_EXIST: "Компанија са именом већ постоји",
        COMPANY_WITH_EMAIL_ALREADY_EXIST: "Компанија са имејлом већ постоји",
        COMPANY_WITH_REGISTRATION_NUMBER_ALREADY_EXIST: "Компанија са матичним бројем већ постоји",
      },
      form: {
        name: "Назив",
        email: "Имејл",
        phone: "Бр. телефона",
        address: "Адреса",
        city: "Град",
        state: "Држава",
        zipCode: "Поштански број",
        registrationNumber: "Матични број",
        taxNumber: "ПИБ",
        codeNumber: "Кодни број",
        website: "Веб сајт",
        fax: "Факс",
        errorAdding: "Грешка приликом додавања компаније",
        added: "Компанија додата",
        deleted: "Компанија обрисана",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене компаније",
        edited: "Компанија измењена"
      },
    },

    occupation: {
      pageTitle: "Додај занимање",
      editPageTitle: "Измени занимање",
      viewPageTitle: "Преглед занимања",
      headerTitle: "Занимања",
      messages: {
        OCCUPATION_WITH_CODE_ALREADY_EXIST: "Занимање са том шифром већ постоји",
        OCCUPATION_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        OCCUPATION_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        OCCUPATION_WITH_NAME_ALREADY_EXIST: "Занимање са тим именом већ постоји",
      },
      form: {
        name: "Назив занимања",
        code: "Шифра занимања",
        errorAdding: "Грешка приликом додавања",
        added: "Занимање додато",
        deleted: "Занимање обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Занимање измењено"
      },
    },

    state: {
      pageTitle: "Додај државу",
      editPageTitle: "Измени државу",
      viewPageTitle: "Преглед државе",
      headerTitle: "Државе",
      messages: {
        STATE_WITH_CODE_ALREADY_EXIST: "Држава са том шифром већ постоји",
        STATE_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        STATE_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        STATE_WITH_NAME_ALREADY_EXIST: "Држава са тим именом већ постоји",


      },
      form: {
        name: "Назив државе",
        code: "Шифра државе",
        errorAdding: "Грешка приликом додавања",
        added: "Држава додата",
        deleted: "Држава обрисана",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Држава измењена"
      },
    },

    causeOfDeath: {
      pageTitle: "Додај узрок смрти",
      editPageTitle: "Измени узрок смрти",
      viewPageTitle: "Преглед узрока смрти",
      headerTitle: "Узроци смрти",
      infectious: "Заразна болест",
      messages: {
        // CAUSE_OF_DEATH_WITH_CODE_ALREADY_EXIST: "Узрок смрти са том шифром већ постоји",
        CAUSE_OF_DEATH_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        CAUSE_OF_DEATH_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        CAUSE_OF_DEATH_WITH_NAME_ALREADY_EXIST: "Узрок смрти са тим именом већ постоји",
      },
      form: {
        name: "Назив узрока смрти",
        code: "Шифра узрока смрти",
        errorAdding: "Грешка приликом додавања",
        added: "Узрок смрти додат",
        deleted: "Узрок смрти обрисан",
        infectious: "Заразна болест",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Узрок смрти измењен"
      },
    },

    doctor: {
      pageTitle: "Додај доктора",
      editPageTitle: "Измени доктора",
      viewPageTitle: "Преглед доктора",
      headerTitle: "Доктори",
      messages: {
        //DOCTOR_WITH_CODE_ALREADY_EXIST: "Доктор са том шифром већ постоји",
        DOCTOR_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        DOCTOR_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        DOCTOR_WITH_NAME_ALREADY_EXIST: "Доктор са тим именом већ постоји",
      },
      form: {
        name: "Назив доктора",
        code: "Шифра доктора",
        address: "Адресни подаци(место, улица, телефон и сл.)",
        errorAdding: "Грешка приликом додавања",
        added: "Доктор додат",
        deleted: "Доктор обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Доктор измењен"
      },
    },

    transporter: {
      pageTitle: "Додај транспортера",
      editPageTitle: "Измени транспортера",
      viewPageTitle: "Преглед транспортера",
      headerTitle: "Транспортери",
      messages: {
        //  TRANSPORTER_WITH_CODE_ALREADY_EXIST: "Транспортер са том шифром већ постоји",
        TRANSPORTER_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        TRANSPORTER_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        TRANSPORTER_SHORTENED_NAME_CANT_BE_NULL: "Поље скраћени назив не може бити празно",
        TRANSPORTER_WITH_NAME_ALREADY_EXIST: "Транспортер са тим именом већ постоји",
      },
      form: {
        name: "Назив транспортера",
        shortenedName: "Скраћени назив транспортера",
        code: "Шифра транспортера",
        address: "Адресни подаци(место, улица, телефон и сл.)",
        errorAdding: "Грешка приликом додавања",
        added: "Транспортер додат",
        deleted: "Транспортер обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Транспортер измењен"
      },
      buttons: {
        generateReport: "Извештај према времену",
      }
    },

    pricelist: {
      pageTitle: "Додај ценовник",
      editPageTitle: "Измени ценовник",
      viewPageTitle: "Преглед ценовник",
      headerTitle: "Ценовници",
      messages: {
        //PRICELIST_WITH_CODE_ALREADY_EXIST: "Ценовник са том шифром већ постоји",
        PRICELIST_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        PRICELIST_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        PRICELIST_PRICE_CANT_BE_NULL: "Поље цена назив не може бити празно",
        PRICELIST_WITH_NAME_ALREADY_EXIST: "Ценовник са тим именом већ постоји",
      },
      form: {
        name: "Назив услуге",
        price: "Цена услуге",
        code: "Шифра услуге",
        address: "Адреса",
        errorAdding: "Грешка приликом додавања",
        added: "Ценовник додат",
        deleted: "Ценовник обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Ценовник измењен"
      },
    },

    urn: {
      pageTitle: "Додај урну",
      editPageTitle: "Измени урну",
      viewPageTitle: "Преглед урне",
      headerTitle: "Урне",
      messages: {
        //URN_WITH_CODE_ALREADY_EXIST: "Урна са том шифром већ постоји",
        URN_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        URN_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        URN_WITH_NAME_ALREADY_EXIST: "Урна са тим именом већ постоји",
      },
      form: {
        name: "Назив урне",
        code: "Шифра урне",
        errorAdding: "Грешка приликом додавања",
        added: "Урна додата",
        deleted: "Урна обрисана",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Урна измењена"
      },
    },

    famousCitizen: {
      pageTitle: "Додај знаменитог грађанина",
      editPageTitle: "Измени знаменитог грађанина",
      viewPageTitle: "Преглед знаменитог грађанина",
      headerTitle: "Знаменити грађани",
      messages: {
        FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST: "Знаменити грађанин са том шифром већ постоји",
        FAMOUS_CITIZEN_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL: "Поље опис не може бити празно",
      },
      form: {
        code: "Шифра",
        description: "Опис",
        errorAdding: "Грешка приликом додавања",
        added: "Знаменити грађанин додат",
        deleted: "Знаменити грађанин обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Знаменити грађанин измењен"
      },
    },

    settlement: {
      pageTitle: "Додај насеље",
      editPageTitle: "Измени насеље",
      viewPageTitle: "Преглед насеља",
      headerTitle: "Насеља",
      messages: {
        SETTLEMENT_WITH_CODE_ALREADY_EXIST: "Насеље са том шифром већ постоји",
        SETTLEMENT_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        SETTLEMENT_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        SETTLEMENT_MUNICIPALITY_CODE_CANT_BE_NULL: "Поље шифра општине не може бити празно",
        SETTLEMENT_MUNICIPALITY_NAME_CANT_BE_NULL: "Поље назив општине не може бити празно",
      },
      form: {
        code: "Шифра насеља",
        name: "Назив насеља",
        municipalityCode: "Шифра општине",
        municipalityName: "Назив општине",
        errorAdding: "Грешка приликом додавања",
        added: "Насеље додато",
        deleted: "Насеље обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Насеље измењено"
      },
    },

    postalCode: {
      pageTitle: "Додај поштански број",
      editPageTitle: "Измени поштански број",
      viewPageTitle: "Преглед поштански број",
      headerTitle: "Поштански бројеви и места ",
      messages: {
        POSTAL_CODE_WITH_CODE_ALREADY_EXIST: "Поштански број са том шифром већ постоји",
        POSTAL_CODE_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        POSTAL_CODE_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
      },
      form: {
        name: "Назив места",
        code: "Шифра поштанског броја",
        errorAdding: "Грешка приликом додавања",
        added: "Поштански број додат",
        deleted: "Поштански број обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Поштански број измењена"
      },
    },

    postalCodeImproved: {
      pageTitle: "Додај поштански број",
      editPageTitle: "Измени поштански број",
      viewPageTitle: "Преглед поштански број",
      headerTitle: "Поштански бројеви и места",
      messages: {
        POSTAL_CODE_IMPROVED_ZIP_CANT_BE_NULL: "Поље поштански број не може бити празно",
        POSTAL_CODE_IMPROVED_CITY_CANT_BE_NULL: "Поље град не може бити празно",
        POSTAL_CODE_IMPROVED_STATE_CANT_BE_NULL: "Поље држава не може бити празно",
        POSTAL_CODE_IMPROVED_ALREADY_EXIST: "Поштански број, место и држава са том комбинацијом већ постоји",

        // POSTAL_CODE_WITH_CODE_ALREADY_EXIST: "Поштански број са том шифром већ постоји",
        // POSTAL_CODE_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        // POSTAL_CODE_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
      },
      form: {
        state: "Држава",
        city: "Град",
        municipality: "Општина",
        settlement: "Насеље",
        zip: "Поштански број",
        errorAdding: "Грешка приликом додавања",
        added: "Поштански број додат",
        deleted: "Поштански број обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Поштански број измењена"
      },
    },

    postalAddressCode: {
      headerTitle: "ПАК",
      addPageTitle: "Додај ПАК",
      editPageTitle: "Измени ПАК",
      viewPageTitle: "Преглед ПАК",
      messages: {
        POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL: "Поље место не може бити празно",
        POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL: "Поље улица не може бити празно",
        POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL: "Поље број не може бити празно",
        POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL: "Поље ПАК не може бити празно",
        POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL: "Поље поштански број не може бити празно",
        POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL: "Поље шифра насеља не може бити празно",
        POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL: "Поље шифра државе не може бити празно",
      },
      form: {
        place: 'Место',
        street: 'Улица',
        number: 'Број',
        PAC: 'ПАК',
        postalNumber: 'Поштански број',
        settlementCode: 'Шифра насеља',
        stateCode: 'Шифра државе',
        errorAdding: 'Грешка приликом додавања',
        added: 'ПАК додат',
        errorEditing: "Грешка приликом измене",
        edited: "ПАК измењен",
        deleted: 'ПАК обрисан',
        ok: 'Ок',
        cancel: 'Одустани',
      },
    },

    postalAddressCodeImproved: {
      headerTitle: "ПАК",
      addPageTitle: "Додај ПАК",
      editPageTitle: "Измени ПАК",
      viewPageTitle: "Прегледaj ПАК",
      messages: {
        POSTAL_ADDRESS_CODE_IMPROVED_CITY_AND_ZIP_CANT_BE_NULL: "Поље место и поштански број не може бити празно",
        POSTAL_ADDRESS_CODE_IMPROVED_STREET_CANT_BE_NULL: "Поље улица не може бити празно",
        POSTAL_ADDRESS_CODE_IMPROVED_NUMBER_CANT_BE_NULL: "Поље број не може бити празно",
        POSTAL_ADDRESS_CODE_IMPROVED_PAC_CANT_BE_NULL: "Поље ПАК не може бити празно",
        POSTAL_ADDRESS_CODE_IMPROVED_STATE_CANT_BE_NULL: "Поље државa не може бити празно",
        POSTAL_ADDRESS_CODE_IMPROVED_ALREADY_EXIST: "ПАК са овом комбинацијом обележја већ постоји"
      },
      form: {
        cityAndZip: 'Град и поштански број',
        street: 'Улица',
        number: 'Број',
        PAC: 'ПАК',
        zip: 'Поштански број',
        state: 'Држава',
        settlementCode: 'Шифра насеља',
        stateCode: 'Шифра државе',
        errorAdding: 'Грешка приликом додавања',
        added: 'ПАК додат',
        errorEditing: "Грешка приликом измене",
        edited: "ПАК измењен",
        deleted: 'ПАК обрисан',
        ok: 'Ок',
        cancel: 'Одустани',
      },
    },


    stonecutter: {
      pageTitle: "Додај каменоресца",
      editPageTitle: "Измени каменоресца",
      viewPageTitle: "Преглед каменоресца",
      headerTitle: "Каменоресци",
      messages: {
        STONECUTTER_WITH_CODE_ALREADY_EXIST: "Каменорезац са том шифром већ постоји",
        STONECUTTER_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        STONECUTTER_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        STONECUTTER_TYPE_CANT_BE_NULL: "Поље тип не може бити празно",
      },
      form: {
        name: "Назив каменоресца",
        code: "Шифра каменоресца",
        type: "Врста каменоресца",
        address: "Адресни подаци(место, улица, телефон и сл.)",
        note: "Напомена",
        errorAdding: "Грешка приликом додавања",
        added: "Каменорезац додат",
        deleted: "Каменорезац обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Каменорезац измењен"
      },
    },

    graveyard: {
      pageTitle: "Додај гробље",
      editPageTitle: "Измени гробље",
      viewPageTitle: "Преглед гробља",
      headerTitle: "Гробља",
      messages: {
        GRAVEYARD_WITH_CODE_ALREADY_EXIST: "Гробље са том шифром већ постоји",
        GRAVEYARD_CODE_CANT_BE_NULL: "Поље шифра не може бити празно",
        GRAVEYARD_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        GRAVEYARD_ADDRESS_CANT_BE_NULL: "Поље адреса не може бити празно",
        GRAVEYARD_PLACE_CANT_BE_NULL: "Поље место не може бити празно",
        GRAVEYARD_SURFACE_CANT_BE_NULL: "Поље површина не може бити празно",
        GRAVEYARD_STATUS_CANT_BE_NULL: "Поље статус не може бити празно",
        GRAVEYARD_DENOMINATION_CANT_BE_NULL: "Поље конфесија не може бити празно",
        GRAVEYARD_YEAR_OF_ESTABLISHMENT_CANT_BE_NULL: "Поље година оснивања не може бити празно",
        GRAVEYARD_TAKEOVER_DATE_CANT_BE_NULL: "Поље датум преузимања не може бити празно",
      },
      form: {
        name: "Назив гробља",
        code: "Шифра гробља",
        address: "Улица и број",
        place: "Место",
        surface: "Површина (хектари)",
        status: "Статус",
        denomination: "Конфесија",
        yearOfEstablishment: "Година оснивања",
        takeoverDate: "Датум преузимања",
        takeoverDateString: "Датум преузимања - импорт",
        errorAdding: "Грешка приликом додавања",
        added: "Гробље додато",
        deleted: "Гробље обрисано",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Гробље измењено"
      },
    },

    firm: {
      pageTitle: "Додај фирму",
      editPageTitle: "Измени фирму",
      viewPageTitle: "Прегледај фирму",
      headerTitle: "Фирме",
      messages: {
        FIRM_WITH_TAX_NUMBER_ALREADY_EXIST: "Фирма са тим ПИБ-ом већ постоји",
        FIRM_WITH_CODE_ALREADY_EXIST: "Фирма са том шифром већ постоји",
        FIRM_TAX_NUMBER_CANT_BE_NULL: "Поље ПИБ не може бити празно",
        FIRM_NAME_CANT_BE_NULL: "Поље назив не може бити празно",
        FIRM_HEADQUARTERS_CANT_BE_NULL: "Поље седиште не може бити празно",
        FIRM_STREET_CANT_BE_NULL: "Поље улица не може бити празно",
        FIRM_STREET_NUMBER_CANT_BE_NULL: "Поље број не може бити празно",
      },
      form: {
        taxNumber: "ПИБ",
        name: "Назив фирме",
        headquarters: "Поштански број",
        street: "Улица",
        streetNumber: "Број",
        contactPerson: "Лице за контакт",
        telephone: "Телефон",
        errorAdding: "Грешка приликом додавања",
        added: "Фирма додата",
        deleted: "Фирма обрисана",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Фирма измењена"
      },
    },

    citizen: {
      pageTitle: "Додај грађанина",
      editPageTitle: "Измени грађанина",
      viewPageTitle: "Прегледај грађанина",
      headerTitle: "Грађани",
      messages: {
        CITIZEN_WITH_PERSONAL_NUMBER_ALREADY_EXIST: "Грађанин са тим матичним бројем већ постоји",
        CITIZEN_PERSONAL_NUMBER_CANT_BE_NULL: "Поље матични број не може бити празно",
        CITIZEN_NAME_CANT_BE_NULL: "Поље име не може бити празно",
        CITIZEN_SURNAME_CANT_BE_NULL: "Поље презиме не може бити празно",
        CITIZEN_POSTAL_CODE_CANT_BE_NULL: "Поље поштански број и место не може бити празно",
        CITIZEN_STREET_CANT_BE_NULL: "Поље улица не може бити празно",
        CITIZEN_STREET_NUMBER_CANT_BE_NULL: "Поље број не може бити празно",
        CITIZEN_PERSONAL_NUMBER_NOT_VALID: "Матични број није валидан"
      },
      form: {
        oldAddresses: "Старе адресе",
        personalNumber: "Матични број",
        name: "Име",
        surname: "Презиме",
        postalCode: "Поштански број и место",
        street: "Улица",
        streetNumber: "Број",
        deceased: "Покојник",
        telephone: "Телефон",
        errorAdding: "Грешка приликом додавања",
        added: "Грађанин додат",
        deleted: "Грађанин обрисан",
        ok: "Ок",
        cancel: "Одустани",
        settlement: "Место"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Грађанин измењен"
      },
    },

    deceasedReception: {
      headerTitle: "Доношење и одношење покојника",
      messages: {
        DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Записник са тим идентификационим бројем покојника већ постоји",
        DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL: "Идентификациони број не може бити празан",
      },
      form: {
        personalNumber: "Покојников идентификациони број",
        identificationNumber: "Ид бр.*",
        equippingOrder: 'Налог за опремање',
        deceasedNameSurname: 'Презиме и име покојника',
        placeOfDeath: 'Место смрти',
        dateOfDeath: 'Датум смрти',
        deliveryDateTime: 'Датум и време доношења',
        takeawayDateTime: 'Датум и време одношења',
        bodyState: 'Стање тела покојника',
        bodyStateGood: 'Добро',
        bodyStateBad: 'Лоше',
        dressedUp: 'Покојник обучен',
        preparationPreformedBy: 'Припремање тела извршио / прање, бријање, облачење',
        suitBrought: 'Одело донето',
        noteDeceased: 'Напомена у вези са покојником',
        noteJewelry: 'Накит и друге вредности које су донете уз покојника',
        deceasedAids: 'Покојник поседује следећа помагала',
        transporter: 'Покојника довезао транспортер',
        driverNameSurname: 'Презиме и име возача',
        registrationPlates: 'Регистарске таблице',
        principal: 'Покојник довежен по налогу',
        principalAddress: 'Адресни подаци налогодавца',
        reasonOfBrought: 'Покојник довежен ради',
        reportedFuneral: 'Сахрана',
        storage: 'Покојник примљен на чување',
        urnReasonOfBrought: 'Урна са покојником довежена ради',
        urnStorage: 'Урна са пепелом покојника примљена на чување',
        urnLetter: 'Писмо на обележју и урни',
        urnDamages: 'Видљива оштећења урне или обележја',
        urnTookOverBy: 'Лице које је преузело урну',
        urnBroughtBy: 'Лице које је предало урну',
        boxNumber: 'Бокс број',
        urnLaidInStorage: 'Урна положена у спремиште',
        deceasedBroughtIn: 'Покојник довежен у',
        coffinBroughtAfter: 'Сандук накнадно донет',
        dateTimeBroughtAfter: 'Датум и Време накнадног доношења',
        standardCoffin: 'Сандук стандардно опремљен',
        coffinType: 'Тип сандука',
        coffinNote: 'Напомена у вези са сандуком',
        tombstones: 'Надгробна обележја',
        letter: 'Писмо на обележју и сандуку',
        language: 'Језик',
        letterType: 'Врста слова',
        damages: 'Видљива оштећења сандука или обележја',
        numberArtificialWreaths: 'Донето венаца од вештачког цвећа комада',
        numberNaturalWreaths: 'Донето венаца од природног цвећа комада',
        numberFlowersBouquet: 'Донето букета комада',
        numberArrangements: 'Донето аранжмана комада',
        numberOrders: 'Ордење комада',
        numberRings: 'Прстење комада',
        numberJewelry: 'Накит комада',
        dateOfTakeover: 'Датум преузимања',
        deceasedTookOverBy: 'Лице које је преузело покојника',
        deceasedBroughtBy: 'Лице које је предало покојника',
        note: 'Напомена',
        principalT: 'Покојник је одвежен по налогу',
        carrier: 'Довозилац',
        reasonOfTaking: 'Разлог одношења',
        transporterT: 'Транспортер',
        registrationPlateT: 'Регистарске таблице',
        approvedBy: 'Преузимање покојника одобрио',
        deceasedTookOverByT: 'Покојника преузео',
        errorAdding: "Грешка приликом додавања",
        added: "Додато",
        deleted: "Обрисано",
        ok: "Ок",
        cancel: "Одустани",
        yes: "Да",
        no: "Не",
        reported: "Пријављена",
        notReported: "Непријављена",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
    },

    deceasedTakeoverRecord: {
      pageTitle: "Додај записник о преузимању покојника",
      editPageTitle: "Измени записник о преузимању покојника",
      viewPageTitle: "Прегледај записник о преузимању покојника",
      headerTitle: "Записник о преузимању покојника",
      deceased: "Покојник",
      equipment: "Опрема",
    },

    urnTakeoverRecord: {
      pageTitle: "Додај записник о преузимању урне",
      editPageTitle: "Измени записник о преузимању урне",
      viewPageTitle: "Прегледај записник о преузимању урне",
      headerTitle: "Записник о преузимању урне",
    },

    deceasedTakeawayRecord: {
      pageTitle: "Додај записник о одношењу покојника",
      editPageTitle: "Измени записник о одношењу покојника",
      viewPageTitle: "Прегледај записник о одношењу покојника",
      headerTitle: "Записник о одношењу покојника",
    },

    mainBookOfBuried: {
      pageTitle: "Додај главну књигу сахрањених",
      editPageTitle: "Измени главну књигу сахрањених",
      viewPageTitle: "Прегледај главну књигу сахрањених",
      headerTitle: "Главна књига сахрањених",
      messages: {
        MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Главна књига са тим матичним бројем већ постоји",
        MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        MAIN_BOOK_OF_BURIED_EXIST: "Главна књига са тим ГКС-ом већ постоји",
        MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID: "Матични број није валидан",
      },
      form: {
        unknownAddress: "Непозната адреса",
        mainBookOfBuries: "ГКС",
        identificationNumber: "Матични број",
        deceasedNameSurname: "Покојник",
        gender: "Пол",
        fathersName: "Очево име",
        address: "Улица и број",
        municipality: "Место становања",
        republic: "Република",
        stateOfBirth: "Држава",
        occupation: "Занимање",
        dateOfBirth: "Датум рођења",
        dateOfBirthString: "Датум рођења - импорт",
        municipalityOfBirth: "Место рођења",
        dateTimeOfDeath: "Датум и време смрти",
        dateTimeOfDeathString: "Датум и време смрти - импорт",
        causeOfDeath: "Узрок смрти",
        doctor: "Лекар",
        registryBookOfDeath: "Матична књига умрлих",
        takeoverDateTime: "Покојник донет",
        takeoverDateTimeString: "Покојник донет - импорт",
        transporter: "Транспортер",
        weigth: "Тежина",
        height: "Висина",
        famousCitizen: "Знаменити грађани",
        hasNote: "Има напомену",
        note: "Напомена",
        identificationNumberSubmitter: "Матични број",
        nameSurnameSubmitter: "Презиме и име",
        addressSubmitter: "Улица и број",
        municipalitySubmitter: "Место становања",
        telephoneSubmitter: "Телефон",
        identificationNumberCosts: "Матични број",
        nameSurnameCosts: "Презиме и име",
        addressCosts: "Улица и број",
        municipalityCosts: "Место становања",
        telephoneCosts: "Телефон",
        identificationNumberUser: "Матични број",
        nameSurnameUser: "Презиме и име",
        addressUser: "Улица и број",
        municipalityUser: "Место становања",
        telephoneUser: "Телефон",
        firmPays: "Фирма плаћа",
        graveyard: "Гробље",
        dateTimeFuneralString: "Датум и време сахране - импорт",
        dateTimeFuneral: "Датум и време сахране",
        graveSite: "Гробно место",
        create: "Додај ",
        paymentNumber: "Број  уплате",
        expiryDate: "Истек обнове",
        expiryDateString: "Истек обнове - импорт",
        tombstoneCode: "Шифра споменика",
        graveField: "Grave field",
        stoneCutter: "Каменорезац",
        graveyard2: "Гробље",
        dateTimeFuneral2: "Датум и време сахране",
        dateTimeFuneral2String: "Датум и време сахране - импорт",
        graveSite2: "Гробно место",
        paymentNumber2: "Број уплате",
        graveyard3: "Гробље",
        dateTimeFuneral3: "Датум и време сахране",
        dateTimeFuneral3String: "Датум и време сахране - импорт",
        graveSite3: "Гробно место",
        paymentNumber3: "Број уплате",
        graveyard4: "Гробље",
        dateTimeFuneral4: "Датум и време сахране",
        dateTimeFuneral4String: "Датум и време сахране - импорт",
        graveSite4: "Гробно место",
        paymentNumber4: "Број уплате",
        plot: "Парцела 4",
        landmarkDescription: "Опис знаменитости",
        male: "Мушко",
        female: "Женско",
        ok: "Ок",
        cancel: "Одустани",
        submitter: "Подносилац пријаве",
        cost: "Трошкове сноси",
        grUser: "Закупац гробног места",
        shorthenedSubmitter: "Подн. при.",
        shorthenedCosts: "Трош. сно.",
        currentPlaceOfRest: "Садашње место почивања покојника",
        previousPlaceOfRest: "Ранија покојникова места почињања",
        taxNumberCosts: "ПИБ",
        firmCost: "Назив фирме",
        addNewSubmitter: "Додај новог подносиоца пријаве",
        addNewCost: "Додај новог носиоца трошкова",
        scheduledBurialNumber: "Шифра заказане сахране",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      deceased: "Покојник",
      deathReport: "Пријава смрти, трошкови...",
      funeral: "Сахрана",
      loadFromScheduledFuneral: "Учитај из заказаног сахрањивања",
      errorLength: "Није шестоцифрен број (година + врста записа у евиденцији)",
      errorLengthForEdit: "Није десетоцифрен број (година + врста записа у евиденцији)",
      birthYearError: "Година рођења је обавезна",
      buttons: {
        generateReport: "Извештај",
      }
    },

    graveSite: {
      pageTitle: "Додај гробно место",
      editPageTitle: "Измени гробно место",
      viewPageTitle: "Прегледај гробно место",
      headerTitle: "Гробна места",
      messages: {
        GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST: "Гробно место са комбинацијом гробља и гробног места већ постоји",
        GRAVE_SITE_GRAVEYARD_CANT_BE_NULL: "Поље гробље не може бити празно",
        GRAVE_SITE_GRAVE_SITE_CANT_BE_NULL: "Поље гробно место не може бити празно",
        GRAVE_SITE_NOT_EXIST: "Гробно место не постоји",
        GRAVE_SITE_ALREADY_EXIST: "Гробно место са том шифром већ постоји",
        GRAVE_SITE_INPUT_NOT_VALID: "Унос гробног места није валидан!",
      },
      form: {
        belongingGraveyard: "Гробље",
        code: "Гробно место",
        paymentDate: "Датум креирања гробног места",
        paymentDateString: "Датум уплате - импорт",
        arrangedPlot: "Да ли је парцела УРЕЂЕНА",
        os: "Да ли је парцела ОСАРИЈУМ",
        childPlot: "Да ли је парцела ДЕЧИЈА",
        placeNumberFrom: "Редни број места од",
        placeNumberTo: "Редни број места до",
        labelGraveSite: "Ознака уз редни бр. гр. места",
        typeGraveSite: "Тип гробног места",
        graveSiteNumber: "Бр. гробног места",
        renewal: "Обнова",
        taken: "Заузето",
        arrangedDate: "Датум увођења",
        identificationNumber: "Матични број",
        expirationDate: "Датум истека",
        expirationDateString: "Датум истека - импорт",
        ok: "Ок",
        cancel: "Одустани",
        graveField: "Поље",
        gravePlot: "Парцела",
        dimensions: "Димензије гробног места",
        length: "Дужина (cm)",
        width: "Ширина (cm)",
        depth: "Дубина (cm)",
        addMoreThanOne: "Додај више од једног",
        size: "Величина",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      errorMinLength: "Мора бити троцифрен број (нпр. 001)",
      errorInvalidFormat: "Није број",
      errorToLessThanFrom: "Редни бр. гробног места од мора бити мањи од редни бр. гробног места до",
    },

    mainBookOfCremated: {
      pageTitle: "Додај главну књигу кремираних",
      editPageTitle: "Измени главну књигу кремираних",
      viewPageTitle: "Прегледај главну књигу кремираних",
      headerTitle: "Главна књига кремираних",
      messages: {
        MAIN_BOOK_OF_CREMATED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST: "Главна књига са тим матичним бројем покојника већ постоји",
        MAIN_BOOK_OF_CREMATED_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        MAIN_BOOK_OF_CREMATED_EXIST: "Главна књига са тим ГКК-ом већ постоји",
        MAIN_BOOK_OF_CREMATED_IDENTIFICATION_NUMBER_NOT_VALID: "Матични број није валидан",

      },
      form: {
        unknownAddress: "Непозната адреса",
        mainBookOfCremated: "ГКК",
        identificationNumber: "Матични број",
        deceasedNameSurname: "Покојник",
        gender: "Пол",
        fathersName: "Очево име",
        address: "Адреса",
        municipality: "Место становања",
        republic: "Република",
        occupation: "Занимање",
        dateOfBirth: "Датум рођења",
        municipalityOfBirth: "Место и општина рођења",
        dateTimeOfDeath: "Датум и време смрти",
        dateTimeOfDeathString: "Датум и време смрти - импорт",
        causeOfDeath: "Узрок смрти",
        doctor: "Лекар",
        registryBookOfDeath: "Мку/орган",
        dateTimeOfTakeIn: "Покојник донет",
        dateTimeOfTakeInString: "Покојник донет - импорт",
        dateTimeSayGoodbye: "Испраћај дан/време",
        dateTimeSayGoodbyeString: "Испраћај дан/време - импорт",
        dateTimeOfCremation: "Кремације дан/време",
        dateTimeOfCremationString: "Кремације дан/време - импорт",
        dateTimeUrnTakeover: "Урна преузета дан/време",
        dateTimeUrnTakeoverString: "Урна преузета дан/време - импорт",
        urn: "Тип урне",
        transporter: "Транспортер",
        weigth: "Тежина",
        height: "Висина",
        famousCitizen: "Знаменити грађани",
        hasNote: "Има напомену",
        note: "Напомена",
        identificationNumberSubmitter: "Матични број",
        nameSurnameSubmitter: "Презиме и име",
        addressSubmitter: "Улица и број",
        municipalitySubmitter: "Место становања",
        telephoneSubmitter: "Телефон",
        identificationNumberCosts: "Матични број",
        nameSurnameCosts: "Презиме и име",
        addressCosts: "Улица и број",
        municipalityCosts: "Место становања",
        telephoneCosts: "Телефон",
        identificationNumberUser: "Матични број",
        nameSurnameUser: "Презиме и име",
        addressUser: "Улица и број",
        municipalityUser: "Место становања",
        telephoneUser: "Телефон",
        firmPays: "Фирма плаћа",
        p: "П",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        paymentNumber: "Број уплате",
        expiryDate: "Истек обнове",
        male: "Мушко",
        female: "Женско",
        ok: "Ок",
        cancel: "Одустани",
        submitter: "Пријавилац кремације",
        cost: "Трошкове сноси",
        grUser: "Урну преузео",
        shorthenedSubmitter: "Подн. при.",
        shorthenedCosts: "Трош. сно.",
        cremationCurrentPlaceOfRest: "Кремирање и место почивања покојника",
        previousPlaceOfRest: "Ранија покојникова места почињања",
        taxNumberCosts: "ПИБ",
        firmCost: "Назив фирме"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      deceased: "Покојник",
      deathReport: "Пријава смрти, трошкови...",
      cremation: "Кремација",
      errorLength: "Није петоцифрен број (година + врста записа у евиденцији)",
      errorLengthForEdit: "Није деветоцифрен број (година + врста записа у евиденцији)",
    },

    exhumation: {
      pageTitle: "Додај ексхумацију",
      editPageTitle: "Измени ексхумацију",
      viewPageTitle: "Прегледај ексхумацију",
      headerTitle: "Ексхумације",
      messages: {
        EXHUMATION_WITH_EXHUMATION_NUMBER_ALREADY_EXIST: "Ексхумација са тим бројем ексхумације већ постоји",
        EXHUMATION_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        EXHUMATION_EXIST: "Ексхумација са тим бројем већ постоји",
      },
      form: {
        exhumation: "Ексхумација",
        deceasedNameSurname: "Покојник",
        mainBookOfBuried: "ГКС",
        expiryDate: "Истек обнове",
        expiryDateString: "Истек обнове - импорт",
        dateOfExhumation: "Датум ексхумације",
        graveyard: "Гробље - ново",
        graveSite: "Гробно место - ново",
        costPersonalNumber: "Матични број уплатиоца",
        costNameSurname: "Име и презиме уплатиоца",
        costAddress: "Адреса уплатиоца",
        costCity: "Место уплатиоца",
        costTelephone: "Телефон",
        paymentNumber: "Број уплате - нови",
        tombstoneCode: "Шифра споменика",
        socialCase: "Шифра споменика",
        oldGraveSiteCode: "Гробно место - старо",
        oldGraveyard: "Гробље - старо",
        note: "Белешка",
        ok: "Ок",
        cancel: "Одустани",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      errorLength: "Није шестоцифрен број (година + врста записа у евиденцији)"
    },

    stoneCuttingWorks: {
      pageTitle: "Додај каменорезачки рад",
      editPageTitle: "Измени каменорезачки рад",
      viewPageTitle: "Прегледај каменорезачки рад",
      headerTitle: "Каменорезачки радови",
      messages: {
        STONE_CUTTING_WORK_WITH_CODE_ALREADY_EXIST: "Каменорезачки рад са тим бројем већ постоји",
        STONE_CUTTING_WORK_CODE_CANT_BE_NULL: "Поље број у евдиденцији радова не може бити празно",
      },
      form: {
        code: "Број у евиденцији радова",
        stonecutter: "Назив каменорезца",
        jobType: "Врста посла",
        mainBookOfBuried: "Број из евиденције закупа или ГКС (ЗА САД САМО ГКС)",
        graveyard: "Назив гробља",
        graveSite: "Гробно место",
        tombstoneType: "Врста споменика",
        permissionNumber: "Број дозволе за рад",
        permissionStartDate: "Дозвола важи од дана",
        permissionsStartDateString: "Дозвола важи од дана - импорт",
        citizen: "Матични број корисника",
        citizenNameSurname: "Презиме и име корисника",
        citizenAddress: "Улица и број корисника",
        citizenSettlement: "Место корисника",
        citizenTelephone: "Телефон корисника",
        payment: "Уплата",
        amount: "Сума",
        note: "Напомена",
        ok: "Ок",
        cancel: "Одустани",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено",
        errorLengthForEdit: "Није осмоцифрен број (година + врста записа у евиденцији)",
      },
      validationError: "Проверите да ли су сва поља исправно унета",
      errorLength: "Није четвороцифрен број (година)"
    },

    reservation: {
      pageTitle: "Додај резервацију",
      editPageTitle: "Измени резервацију",
      viewPageTitle: "Прегледај резервацију",
      headerTitle: "Резервације",
      messages: {
        RESERVATION_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Резервација са тим бројем закупа већ постоји",
        RESERVATION_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Број закупа не може бити празан",
      },
      form: {
        addNewPac: "Додај нови ПАК",
        addNewSubmitter: "Додај новог закупца",
        mainBookOfBuried: "Број закупа",
        personalNumber: "Матични број",
        submitterName: "Закупац",
        submitterAddress: "Адреса",
        submitterMunicipality: "Место",
        submitterTelephone: "Телефон",
        personalNumber2: "Матични број",
        submitterName2: "Закупац",
        submitterAddress2: "Адреса",
        submitterMunicipality2: "Место",
        submitterTelephone2: "Телефон",
        horizontal: "Хоризонтална",
        vertical: "Вертикална",
        horizontalNumber: "Бр.хор.резервације",
        verticalNumber: "Бр.вер.резервације",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        deceasedInside: "Искоришћена резервација",
        ban: "Забрана",
        tombstoneType: "Врста споменика",
        tombstoneFromDate: "Споменик од дана",
        tombstoneFromDateString: "Споменик од дана - импорт",
        stonecutter: "Кеменорезац",
        expiryDate: "Датум истека резервације",
        expiryDateString: "Датум истека резервације - импорт",
        contractNumber: "Број уговора",
        contractExpiryDate: "Уговор важи до",
        contractDate: "Уговор важи до",
        contractDateString: "Уговор важи до - импорт",
        paymentNumber: "Бр.уплате/датум",
        dateOfRent: "Датум закупа",
        dateOfRentString: "Датум закупа - импорт",
        amount: "Износ",
        canceled: "Отказано",
        dateOfCancellation: "Датум сторнирања",
        correctionNumber: "Бр. исправке",
        note: "Белешка",
        yes: "Да",
        no: "Не",
        ok: "Ок",
        cancel: "Откажи",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      validationЕrror: "Грешка! Проверите да ли су сва обавезна поља исправно унета",
    },

    tombRent: {
      pageTitle: "Додај закуп гробнице",
      editPageTitle: "Измени закуп гробнице",
      viewPageTitle: "Прегледај закуп гробнице",
      headerTitle: "Гробнице - закупи",
      messages: {
        TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Гробница - закуп број закупа не може бити празан",
        TOMB_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Гробница - закуп са тим бројем већ постоји",
        RENEWAL_PAYMENT_DATE_CANT_BE_NULL: "Поље датум закупа не може бити празно"
      },
      form: {
        mainBookOfBuried: "Број закупа",
        personalNumber: "1. Закупац матични број",
        submitterName: "Закупац",
        submitterAddress: "Адреса",
        submitterMunicipality: "Место",
        submitterTelephone: "Телефон",
        personalNumber2: "2. Закупац матични број",
        submitterName2: "Закупац",
        submitterAddress2: "Адреса",
        submitterMunicipality2: "Место",
        submitterTelephone2: "Телефон",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        graveSiteSize: "Величина гробнице(бр.ков.)",
        deceasedInside: "Искоришћена резервација",
        tombstoneType: "Врста споменика",
        tombstoneFromDate: "Споменик од дана",
        tombstoneFromDateString: "Споменик од дана - импорт",
        stonecutter: "Каменорезац",
        contractNumber: "Број уговора",
        paymentNumber: "Бр.уплате/датум",
        dateOfRent: "Датум закупа",
        dateOfRentString: "Датум закупа - импорт",
        rentExpiryDate: "Закуп важи до",
        rentExpiryDateString: "Закуп важи до - импорт",
        graveSiteExpiryDate: "Датум истека ГМ",
        graveSiteExpiryDateString: "Датум истека ГМ - импорт",
        rentAmount: "Износ за закуп",
        graveSiteAmount: "Износ гробно место",
        totalAmount: "Свега",
        canceled: "Отказано",
        dateOfCancellation: "Датум сторнирања",
        correctionNumber: "Бр. исправке",
        note: "Белешка",
        yes: "Да",
        no: "Не",
        ok: "Ок",
        cancel: "Откажи",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      validationЕrror: "Грешка! Проверите да ли су сва обавезна поља исправно унета",
    },

    cassetteRent: {
      pageTitle: "Додај закуп касете",
      editPageTitle: "Измени закуп касете",
      viewPageTitle: "Прегледај закуп касете",
      headerTitle: "Касете - закупи",
      messages: {
        CASSETTE_RENT_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL: "Касета - закуп број закупа не може бити празан",
        CASSETTE_RENT_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST: "Касета - закуп са тим бројем већ постоји",
      },
      form: {
        mainBookOfBuried: "Број закупа",
        personalNumber: "1. Закупац матични број",
        submitterName: "Закупац",
        submitterAddress: "Адреса",
        submitterMunicipality: "Место",
        submitterTelephone: "Телефон",
        personalNumber2: "2. Закупац матични број",
        submitterName2: "Закупац",
        submitterAddress2: "Адреса",
        submitterMunicipality2: "Место",
        submitterTelephone2: "Телефон",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        graveSiteSize: "Величина касете(бр.урна.)",
        deceasedInside: "Искоришћена касета",
        tombstoneType: "Врста споменика",
        contractNumber: "Број уговора",
        paymentNumber: "Бр.уплате/датум",
        dateOfRent: "Датум закупа",
        dateOfRentString: "Датум закупа - импорт",
        rentExpiryDate: "Закуп важи до",
        rentExpiryDateString: "Закуп важи до - импорт",
        graveSiteExpiryDate: "Датум истека ГМ",
        graveSiteExpiryDateString: "Датум истека ГМ - импорт",
        rentAmount: "Износ за закуп",
        graveSiteAmount: "Износ гробно место",
        totalAmount: "Свега",
        canceled: "Отказано",
        dateOfCancellation: "Датум сторнирања",
        correctionNumber: "Бр. исправке",
        note: "Белешка",
        yes: "Да",
        no: "Не",
        ok: "Ок",
        cancel: "Откажи",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      validationЕrror: "Грешка! Проверите да ли су сва обавезна поља исправно унета",
    },

    renewal: {
      addPageTitle: "Додај обнову",
      editPageTitle: "Измени обнову",
      viewPageTitle: "Прегледај обнову",
      headerTitle: "Обнове",
      headerTitleEdited: "Обнове измењене",
      messages: {
        RENEWAL_DATE_TIME_OF_DEATH_CANT_BE_NULL: "Датум смрти у обнови не може бити празан!",
        RENEWAL_NOT_EXIST: "Обнова не постоји",
      },
      form: {
        renewalNumber: "Шифра обнове",
        personalNumber: "1. Закупац матични број",
        submitterName: "Закупац",
        deceasedName: "Покојник",
        deceasedPersonalNumber: "Матични број покојника",
        submitterAddress: "Адреса закупца",
        submitterMunicipality: "Место закупца",
        submitterTelephone: "Телефон",
        paymentNumber: "Број уплатице/датум нн ннннн/дд.мм.гггг.",
        amount: "Износ",
        graveyard: "Гробље",
        tombstoneType: "Шифра споменика",
        graveSite: "Гробно место",
        expiryDate: "Датум истека",
        expiryDateString: "Датум истека - импорт",
        newExpirationDate: "Датум истека - нови",
        dateOfChange: "Датум промене",
        dateOfChangeString: "Датум промене - импорт",
        note: "Белешка",
        yes: "Да",
        no: "Не",
        ok: "Ок",
        cancel: "Откажи",
        renewalYears: "Број година за обнављање",
        costPersonalNumber: "Матични број уплатиоца",
        costNameSurname: "Име и презиме уплатиоца",
        costStreet: "Адреса уплатиоца",
        costCity: "Место уплатиоца"
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      validationЕrror: "Грешка! Проверите да ли су сва обавезна поља исправно унета",
    },

    grass: {
      pageTitle: "Додај траву",
      editPageTitle: "Измени траву",
      viewPageTitle: "Прегледај траву",
      headerTitle: "Трава",
      messages: {
        GRASS_NUMBER_CANNOT_BE_NULL: "ТраваИд не може бити празно!",
        GRASS_NOT_EXIST: "Трава не постоји",
        GRASS_WITH_GRASS_NUMBER_ALREADY_EXIST: "Трава са уентим траваИд већ постоји!"
      },
      form: {
        grassNumber: "ТраваИд",
        personalNumber: "Матични број",
        submitterName: "Име и презиме",
        submitterAddress: "Улица и број",
        submitterMunicipality: "Место",
        submitterTelephone: "Телефон",
        paymentDate: "Датум уплате",
        paymentDateString: "Датум уплате - импорт",
        amount: "Износ",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        expiryDate: "Датум истека",
        expiryDateString: "Датум истека - импорт",
        deceasedInside: "Искоришћено гробно место",
        mainBookOfBuried: "Уговор о резервацији уређеног гр. места",
        yes: "Да",
        no: "Не",
        ok: "Ок",
        cancel: "Откажи",
      },
      editForm: {
        errorEditing: "Грешка приликом измене",
        edited: "Измењено"
      },
      validationЕrror: "Грешка! Проверите да ли су сва обавезна поља исправно унета",
      errorLength: "Невалидан унос године. Унесите четвороцифрену годину"
    },

    renewalProcessing: {
      headerTitle: "Обрада обнова",
      periodFrom: "Датум од",
      periodTo: "До датума",
      field: "Поље",
      plot: "Парцела",
      graveSiteNumber: "Број гробног места"
    },

    leaseProcessing: {
      headerTitle: "Обрада закупа",
      periodFrom: "Датум од",
      periodTo: "До датума",
      field: "Поље",
      plot: "Парцела",
      graveSiteNumber: "Број гробног места"
    },

    funeralScheduling: {
      headerTitle: "Заказивање сахрана",
      addPageTitle: "Закажи нову сахрану",
      editPageTitle: "Измени заказану сахрану",
      viewPageTitle: "Прегледај заказану сахрану",
      filterDate: "Филтрирај по датуму",
      funeralNumber: "Шифра сахране",
      deceasedNameSurname: "Покојник",
      fathersName: "Име оца",
      born: "Рођен",
      appointmentTimeFuneral: "Време сахране",
      graveyard: "Гробље",
      graveSite: "Гробно место",
      responsiblePerson: "Сахрану заказао",
      responsiblePersonEditedBy: "Сахрану изменио",
      dateCreated: "Датум креирања",
      dateTimeFuneral: "Датум и време сахране",
      deceased: "Подаци о покојнику",
      deathReport: "Пријава смрти, трошкови...",
      funeralDetails: "Детаљи сахране",
      tomb: "Гробница",
      grave: "Рака",
      cassette: "Касета",
      funeral: "Сахрана",
      urnStorage: "Похрана урне",
      farewell: "Испраћај",
      cremation: "Кремација",
      removeAndReturnGravePlate: "Скидање и враћање плоче",
      dismantleTombstone: "Демонтажа споменика",
      breakTheExtentOfTheGraveSite: "Разбијање опсега",
      hireServicesForArrangingAndMaintainingGreenSurfaces: "Ангажовање Службе за уређење и одржавање зелених површина",
      deathbed: "Одар",
      microphone: "Микрофон",
      pillowsForDecorations: "Јастуче за ордење",
      tableForAttendees: "Сто за грађане",
      bell: "Звоно",
      silent: "Тишина",
      beethoven: "Бетовен",
      lenin: "Лењин",
      schopen: "Шопен",
      chorusOfTheJews: "Хор Јевреја",
      tambourinePlayers: "Тамбураши",
      quietNight: "Тихо ноћи",
      cd: "ЦД",
      music_live: "Музика-уживо",
      direct: "Директна",

      form: {
        funeralNumber: "Шифра сахране",
        identificationNumber: "Матични број",
        deceasedNameSurname: "Покојник",
        gender: "Пол",
        male: "Мушко",
        female: "Женско",
        fathersName: "Очево име",
        occupation: "Занимање",
        dateOfBirth: "Датум рођења",
        municipalityOfBirth: "Mесто рођена",
        lastAddressOfResidence: "Задња адреса становања",
        permanentResidence: "Пребивалиште",
        permanent: "Стално",
        temporary: "Привремено",
        stateOfResidence: "Држава становања",
        municipalityOfResidence: "Место становања",
        dateTimeOfDeath: "Датум и време смрти",
        municipalityOfDeath: "Место смрти",
        autopsy: "Обдукција",
        yes: "Да",
        no: "Не",
        causeOfDeath: "Узрок смрти",
        causeOfDeathCode: "Шифра узрока",
        doctor: "Лекар",
        weight: "Тежина",
        height: "Висина",
        ok: "Ок",
        cancel: "Одустани",
        submitter: "Подносилац пријаве",
        addNewSubmitter: "Додај новог подносиоца пријаве",
        nameSurnameSubmitter: "Име и презиме",
        addressSubmitter: "Улица и број",
        municipalitySubmitter: "Место становања",
        telephoneSubmitter: "Телефон",
        submitterNameOfOneParent: "Име једног од родитеља",
        IDCardOrPassportNumber: "Бр. личне карте / бр. пасоша",
        IDCardOrPassportIssuer: "Документ издат од стране",
        IDCardOrPassportIssueDate: "Датум издавања",
        employed: "Запослен",
        submitterCompanyName: "Назив фирме",
        submitterCompanyAddress: "Адреса фирме",
        relationToDeceased: "Сродство са умрлим",
        cost: "Трошкове сноси",
        firmCost: "Назив фирме",
        firmPays: "Фирма плаћа трошкове",
        addNewCost: "Додај новог уплатиоца",
        taxNumberCosts: "ПИБ",
        headquarters: "Седиште",
        telephoneCosts: "Телефон",
        contactPerson: "Особа за контакт",
        nameSurnameCosts: "Име и презиме",
        costNameOfOneParent: "Име једног од родитеља",
        addressCosts: "Улица и број",
        municipalityCosts: "Место становања",
        costCompanyName: "Назив фирме",
        costCompanyAddress: "Адреса фирме",
        shorthenedSubmitter: "Подн. при.",
        shorthenedCosts: "Трош. сно.",
        graveSiteUser: "Закупац гробног места",
        nameSurnameUser: "Презиме и име",
        userNameOfOneParent: "Име једног од родитеља",
        addressUser: "Улица и број",
        municipalityUser: "Место становања",
        telephoneUser: "Телефон",
        userCompanyName: "Назив фирме",
        userCompanyAddress: "Адреса фирме",
        funeralType: "Врста сахране",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        municipalityOfFuneral: "Место сахране",
        create: "Додај ",
        dateFuneral: "Датум сахране",
        timeFuneral: "Време сахране",        
        usingChapel: "Коришћење капеле",
        exhumationRequired: "Неопходна ексхумација",
        exhumationRequiredNote: "Белешке везане за ексхумацију",
        farewellType: "Врста испраћаја",
        reservedGraveSite: "Тип гробног места",
        reservedGraveSiteExpiryDate: "Резервисано до: ",
        necessaryArrangements: "Неопходни радови",
        solderATinCasket: "Лемовање лименог улошка",
        verticalReservation: "Вертикална резервација",
        horizontalReservation: "Хоризонтална резервација",
        reservationNumber: "Број резервације",
        farewellCremation: "Кремација",
        farewellTransit: "Транзит",
        farewellTransitMunicipality: "Место транзита",
        new: "Ново",
        reserved: "Резервисано",
        additionalItems: "Додатне ставке",
        reservedHall: "Заказана сала за даћу",
        numberOfAttendees: "Оквиран број људи",
        menu: "Мени",
        hallApplicationReceivedBy: "Пријаву запримио",
        transporter: "Превозник",
        informationAboutLeasedGraveSitesAndTombs: "Подаци о закупљеним гробним местима и гробницама где треба обавити сахрану",
        funeralEquipment: "Погребна опрема",
        previouslyBuriedDeceasedAndYearsOfDeath: "Претходно сахрањена лица са годинама смрти",
        graveSiteHasTombstone: "На гробном месту или гробници се налази споменик",
        tombstoneType: "Врста споменика",
        announceThroughMedia: "Објавити у медијима",
        specialTerms: "Посебни услови како би се сахрана могла обавити",
        note: "Белешке",
        farewellMusicType: "Музика за испраћај",
        cremation: "Кремација",
        transit: "Транзит"
      },

      messages: {
        SCHEDULED_FUNERAL_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        SCHEDULED_FUNERAL_DOES_NOT_EXIST: "Непостојећа сахрана",
      },

      values: {
        funeral: "funeral",
        urnStorage: "urn_storage",
        farewell: "farewell",
        cremation: "cremation",
      }
    },

    cremationScheduling: {
      headerTitle: "Заказивање кремирања",
      addPageTitle: "Закажи новo кремирање",
      editPageTitle: "Измени заказано кремирање",
      viewPageTitle: "Прегледај заказано кремирање",
      filterDate: "Филтрирај по датуму",
      funeralNumber: "Шифра кремације",
      deceasedNameSurname: "Покојник",
      graveyard: "Гробље",
      graveSite: "Гробно место",
      dateCreated: "Датум креирања",
      dateTimeFuneral: "Датум и време кремирања",
      deceased: "Подаци о покојнику",
      deathReport: "Пријава смрти, трошкови...",
      cremationDetails: "Детаљи кремације",
      cremationRequest: "Захтев за кремирање",
      urnStorage: "Похрана урне",
      farewell: "Испраћај",
      cremation: "Кремација",
      removeAndReturnGravePlate: "Скидање и враћање плоче",
      dismantleTombstone: "Демонтажа споменика",
      breakTheExtentOfTheGraveSite: "Разбијање опсега",
      hireServicesForArrangingAndMaintainingGreenSurfaces: "Ангажовање Службе за уређење и одржавање зелених површина",
      deathbed: "Одар",
      microphone: "Микрофон",
      pillowsForDecorations: "Јастуче за ордење",
      tableForAttendees: "Сто за грађане",
      bell: "Звоно",
      silent: "Тишина",
      beethoven: "Бетовен",
      lenin: "Лењин",
      schopen: "Шопен",
      chorusOfTheJews: "Хор Јевреја",
      tambourinePlayers: "Тамбураши",
      quietNight: "Тихо ноћи",
      cd: "ЦД",
      music_live: "Музика-уживо",
      direct: "Директна",
      columbarium: "Колумбаријум (касета у зиду)",
      rosarium: "Розаријум (касета у земљи)",
      osarium: "Осаријум",
      other: "Остало",
      otherComment: "(Коментар)",
      gardenOfMemories: "Врт сећања (расути пепео)",
      deathCertificate: "Потврда о смрти",
      extractFromRegistryBookOfDead: "Извод из матичне књиге умрлих",
      invoiceFromPurchasedFuneralEquipment: "Рачун о купљеној погребној опреми",
      authorizationPowerOfAttorneyForLegalEntity: "Овлашћење - пуномоћ за правно лице које се бави погребном делатношћу",
      approvalFromTheAuthority: "Одобрење органа надлежног за покретање или вођење кривичног поступка (за лица која нису умрла природном смрћу)",
      contractForLeasedGraveSite: "Уговор о закупу гробног места",
      proofOfDeceasedWishToBeCremated: "Доказ о жељи покојника за кремирањем",
      will: "Тестамент",
      crematistsAssociation: "Уговор са удружењем крематиста ”Огањ”",
      lifetimeSupportAgreement: "Уговор о доживотном издржавању",
      certifiedStatementOfTheDeceased: "Оверена изјава покојника дата за живота",
      certifiedDeclarationOfRelatives: "Оверена изјава сродника",
      responsiblePerson: "Кремацију креирао",
      responsiblePersonЕditedBy: "Кремацију изменио",

      form: {
        cremationNumber: "Шифра кремације",
        identificationNumber: "Матични број",
        deceasedNameSurname: "Покојник",
        gender: "Пол",
        male: "Мушко",
        female: "Женско",
        fathersName: "Очево име",
        occupation: "Занимање",
        dateOfBirth: "Датум рођења",
        municipalityOfBirth: "Mесто рођена",
        lastAddressOfResidence: "Задња адреса становања",
        permanentResidence: "Пребивалиште",
        permanent: "Стално",
        temporary: "Привремено",
        stateOfResidence: "Држава становања",
        municipalityOfResidence: "Место становања",
        dateTimeOfDeath: "Датум и време смрти",
        municipalityOfDeath: "Место смрти",
        autopsy: "Обдукција",
        yes: "Да",
        no: "Не",
        causeOfDeath: "Узрок смрти",
        causeOfDeathCode: "Шифра узрока",
        doctor: "Лекар",
        weight: "Тежина",
        height: "Висина",
        ok: "Ок",
        cancel: "Одустани",
        submitter: "Подносилац пријаве",
        addNewSubmitter: "Додај новог подносиоца пријаве",
        nameSurnameSubmitter: "Име и презиме",
        addressSubmitter: "Улица и број",
        municipalitySubmitter: "Место становања",
        telephoneSubmitter: "Телефон",
        submitterNameOfOneParent: "Име једног од родитеља",
        IDCardOrPassportNumber: "Бр. личне карте / бр. пасоша",
        IDCardOrPassportIssuer: "Документ издат од стране",
        IDCardOrPassportIssueDate: "Датум издавања",
        employed: "Запослен",
        submitterCompanyName: "Назив фирме",
        submitterCompanyAddress: "Адреса фирме",
        relationToDeceased: "Сродство са умрлим",
        cost: "Трошкове сноси",
        firmCost: "Назив фирме",
        firmPays: "Фирма плаћа трошкове",
        addNewCost: "Додај новог уплатиоца",
        taxNumberCosts: "ПИБ",
        headquarters: "Седиште",
        telephoneCosts: "Телефон",
        contactPerson: "Особа за контакт",
        nameSurnameCosts: "Име и презиме",
        costNameOfOneParent: "Име једног од родитеља",
        addressCosts: "Улица и број",
        municipalityCosts: "Место становања",
        costCompanyName: "Назив фирме",
        costCompanyAddress: "Адреса фирме",
        shorthenedSubmitter: "Подн. при.",
        shorthenedCosts: "Трош. сно.",
        graveSiteUser: "Закупац гробног места",
        nameSurnameUser: "Презиме и име",
        userNameOfOneParent: "Име једног од родитеља",
        addressUser: "Улица и број",
        municipalityUser: "Место становања",
        telephoneUser: "Телефон",
        userCompanyName: "Назив фирме",
        userCompanyAddress: "Адреса фирме",
        funeralType: "Врста сахране",
        graveyard: "Гробље",
        graveSite: "Гробно место",
        municipalityOfFuneral: "Место сахране",
        create: "Додај ",
        dateTimeFuneral: "Датум и време сахране",
        usingChapel: "Коришћење капеле",
        exhumationRequired: "Неопходна ексхумација",
        exhumationRequiredNote: "Белешке везане за ексхумацију",
        farewellType: "Врста испраћаја",
        reservedGraveSite: "Тип гробног места",
        reservedGraveSiteExpiryDate: "Резервисано до: ",
        necessaryArrangements: "Неопходни радови",
        solderATinCasket: "Лемовање лименог улошка",
        verticalReservation: "Вертикална резервација",
        horizontalReservation: "Хоризонтална резервација",
        reservationNumber: "Број резервације",
        farewellCremation: "Кремација",
        farewellTransit: "Транзит",
        farewellTransitMunicipality: "Место транзита",
        new: "Ново",
        reserved: "Резервисано",
        additionalItems: "Додатне ставке",
        reservedHall: "Заказана сала за даћу",
        numberOfAttendees: "Оквиран број људи",
        menu: "Мени",
        hallApplicationReceivedBy: "Пријаву запримио",
        transporter: "Превозник",
        informationAboutLeasedGraveSitesAndTombs: "Подаци о закупљеним гробним местима и гробницама где треба обавити сахрану",
        funeralEquipment: "Погребна опрема",
        previouslyBuriedDeceasedAndYearsOfDeath: "Претходно сахрањена лица са годинама смрти",
        graveSiteHasTombstone: "На гробном месту или гробници се налази споменик",
        tombstoneType: "Врста споменика",
        announceThroughMedia: "Објавити у медијима",
        specialTerms: "Посебни услови како би се сахрана могла обавити",
        note: "Белешке",
        farewellMusicType: "Музика за испраћај",
        urn: "Урна",
        hasTombstone: "Има надгробно обележје",
        noTombstone: "Нема надгробно обележје",
        burnTombstone: "Спалити надгробно обележје",
        urnStorageType: "Урну похранити у: ",
        otherGraveSiteNote: "Остало (белешке)",
        attachedDocuments: "Приложени документи уз захтев",
        contractFinalized: "Уговор о закупу гробног места",
        finalized: "Закључен",
        notFinalized: "Није закључен",
        legalEntity: "Правно лице",
        individual: "Физичко лице",
        requestSubmitter: "Подносилац захтева",
        nameSurnameDriver: "Презиме и име возача",
        nameOfLegalEntity: "Назив фирме",
        legalEntityHeadquarters: "Седиште",
        expressCremation: "Експрес Кремација"
      },

      messages: {
        SCHEDULED_CREMATION_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        SCHEDULED_CREMATION_DOES_NOT_EXIST: "Непостојећа кремација"
      },
    },

    appointments: {
      headerTitle: "Термини",
      filterAppointmentsByDay: "Прикажи термине по датуму",
      showScheduledFunerals: "Прикажи заказане сахране",
      appointmentDeleted: "Термин је успешно обрисан",
      appointmentAdded: "Термин је додат",
      appointmentReserved: "Термин је резервисан",
      appointmentCanceled: "Резервација је отказана",
      appointmentUpdated: "Термин је ажуриран.",
      addAppointment: "Додај термин",
      appointmentsList: "Термини",
      addPageTitle: "Додај нови термин",
      appointmentsNotFoundMessage: "Тренутно нема термина за дан",
      scheduledFuneralsNotFoundMessage: "Тренутно нема заказаних сахрана за овај дан",
      cancelButton: "Откажи",
      reservationButton: "Резервиши",
      reservedButton: "Резервисано",
      makeAnAppointmentButton: "Закажи",
      deleteButton: "Обриши",
      appointmentHours: "Сати",
      appointmentMinutes: "Минути",
      note: "Напомена",
      reservedBy: "Резервисао",
      scheduledBy: "Заказао",
      transporter: "Транспорт",
      number: "Број",
      cityGraveyard: "Градско гробље",
      externalGraveyard: "Спољна гробља",
      municipalityOfDeath: "Место смрти",
      musicBell: "Музика за испраћај",
      letterM: "M",
      letterD: "Директна",
      letterZ: "З",
      appointmentTakenMessage: "Термин jе заузет",
      appointmentNotTakenMessage: "Термин je слободан",
      appointmentScheduledMessage: "Термин је заказан",
      pickAppointmentMessage: "Молимо вас изаберите термин",
      alreadyTakenAppointmentForTheChapel: "Већ постоји заказана сахрана која користи капелу за изабрани датум и време.",
      messages: {
        APPOINTMENT_TAKEN: "Термин је резервисан. Прво га морате отказати да бисте обрисали термин.",
        APPOINTMENT_DOES_NOT_EXIST: "Термин не постоји",
        FORBIDDEN_CANCEL_APPOINTMENT_MESSAGE: "Нисте резервисали овај термин. Није вам дозвољено да га откажете.",
        APPOINTMENT_SCHEDULED: "Термин је заказан. Није вам дозвољено да га обришете."
      },
    },

    equipmentOrder: {
      headerTitle: "Налози за опрему",
      addPageTitle: "Додај нови налог за опрему",
      editPageTitle: "Измени налог за опрему",
      viewPageTitle: "Прегледај налог за опрему",
      deceased: "Подаци о покојнику",
      equipmentDetails: "Потребна опрема", 
      orderNumber: "Шифра налога",
      deceasedNameSurname: "Покојник",
      responsiblePerson: "Налог креирао",
      responsiblePersonЕditedBy: "Налог изменио",
      dateCreated: "Датум креирања",
      latin: "Латиница",
      cyrillic: "Ћирилица",
      metalLetters: "Метална слова",
      plasticLetters: "Пластична слова",
      cross: "Крст",
      pyramid: "Пирамида",
      reformationBoard: "Реформаторска табла",
      washDeceased: "Купање",
      shaveDeceased: "Бријање",
      dressDeceased: "Облачење",
      withinCityLimits: "Превоз у граду до 30 км",
      outsideCityLimitsWithinState: "Превоз по пређеном километру ван града у земљи",
      outsideCityLimitsOutsideState: "Превоз по пређеном километру ван града у иностранству",

      form: {
        orderNumber: "Број налога",
        identificationNumber: "Матични број",
        deceasedNameSurname: "Покојник",
        dateOfBirth: "Датум рођења",
        dateTimeOfDeath: "Датум и време смрти",
        yearsOfLife: "Године живота",
        weight: "Тежина",
        height: "Висина",
        letterType: "Писмо",
        currentLocationOfDeceased: "Тренутна локација покојника",
        dateTimeOfTakeOut: "Датум и време изношења покојника",
        placeOfTakeOut: "Место износа покојника",
        deceasedIdNumber: "ИД број покојника",
        dateTimeOfFuneral: "Датум сахране/испраћаја",
        graveyardFuneral: "Гробље на ком се врши сахрана",
        ok: "Ок",
        cancel: "Одустани",
        yes: "Да",
        no: "Не",
        casket: "Сандук",
        casketAmount: "Цена сандука",
        cover: "Покров",
        coverAmount: "Цена покрова",
        crossAmount: "Цена крста",
        pyramidAmount: "Цена пирамиде",
        reformationBoardAmount: "Цена рефoрматорске табле",
        coverType: "Тип покрова",
        tombstones: "Надгробно обележје",
        tombstoneType: "Тип надгробног обележја",
        letters: "Слова",
        numberOfLetters: "Број слова",
        metalLettersAmount: "Цена металних слова",
        plasticLettersAmount: "Цена пластичних слова",
        obituaryNotice: "Парте",
        numberOfNotices: "Број парти",
        obituaryAmount: "Цена парти",
        tilesAndBadges: "Плочица/беџ",
        numberOfTilesAndBadges: "Број плочица/беџева",
        tilesAndBadgesAmount: "Цена плочица/беџева",
        floralTribute: "Флор трака",
        numberOfFloralTributes: "Број флор трака",
        floralTributeAmount: "Цена флор трака",
        scarves: "Марама/шал",
        numberOfScarves: "Број марама/шалова",
        scarvesAmount: "Цена марама/шалова",
        towels: "Пешкир",
        numberOfTowels: "Број пешкира",
        towelsAmount: "Цена пешкира",
        urn: "Урна",
        urnType: "Тип урне",
        urnAmount: "Цена урне",
        deceasedPreparations: "Припремање покојника",
        deceasedPreparationsAmount: "Цена припремања",
        furnishCoffin: "Опремање ковчега",
        furnishCoffinAmount: "Цена опремања",
        transportDeceased: "Превоз покојника и опреме",
        transportOptions: "Опције превоза",
        numberOfKilometers: "Број километара за транспорт",
        transportDeceasedAmount: "Цена превоза",
        funeralCosts: "Трошкови сахрањивања",
        funeralCostsAmount: "Цена трошкова",
        typeOfGraveSite: "Врста гробног места",
        cremationCosts: "Трошкови кремације",
        cremationCostsAmount: "Цена кремације",
        typeOfCremation: "Врста кремације",
        pvcInsert: "ПВЦ уложак",
        pvcInsertAmount: "Цена улошка",
        pvcBagForDeceasedTakeOut: "ПВЦ врећа за изношење покојника",
        pvcBagForDeceasedTakeOutAmount: "Цена ПВЦ вреће",
        tinInsert: "Лимени уложак",
        tinInsertAmount: "Цена лименог улошка",
        deceasedTakeOutFromApartment: "Изношење покојника из стана",
        deceasedTakeOutFromApartmentAmount: "Цена изношења",
        additionalFieldOne: "Додатна опција",
        additionalFieldOneAmount: "Цена опције 1",
        additionalFieldTwo: "Додатна опција",
        additionalFieldTwoAmount: "Цена опције 2",
        additionalFieldThree: "Додатна опција",
        additionalFieldThreeAmount: "Цена опције 3",
        amount: "УКУПНО",
        refundAmount: "РЕФУНДАЦИЈА-пензиони фонд",
        totalAmount: "СВЕГА ЗА НАПЛАТУ",
      },

      messages: {
        EQUIPMENT_ORDER_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        EQUIPMENT_ORDER_DOES_NOT_EXIST: "Непостојећи налог"
      },
    },

    exhumationRequest: {
      headerTitle: "Захтеви за ексхумације",
      addPageTitle: "Додај нови захтев за ексхумацију",
      editPageTitle: "Измени захтев за ексхумацију",
      viewPageTitle: "Прегледај захтев за ексхумацију",
      exhumationRequestNumber: "Број захтева",
      deceasedNameSurname: "Покојник",
      fromGraveyard: "Старо гробље",
      toGraveyard: "Ново гробље",
      fromGraveSite: "Старо гр. место",
      toGraveSite: "Ново гр. место",
      dateTimeOfExhumation: "Датум и време ексхумације",
      dateCreated: "Датум креирања",
      tomb: "Гробница",
      grave: "Рака",
      cassette: "Касета",
      columbarium: "Колумбаријум (касета у зиду)",
      rosarium: "Розаријум (касета у земљи)",
      gardenOfMemories: "Врт сећања (расути пепео)",
      other: "Остало",

      form: {
        exhumationRequestNumber: "Број захтева за ексхумацију",
        gender: "Пол",
        male: "Мушко",
        female: "Женско",
        deceasedNameSurname: "Презиме и име покојника",
        placeOfRest: "Место почивања",
        fromGraveyard: "Гробље",
        fromGraveSite: "Гробно место",
        dateOfFuneralString: "Датум сахране",
        field: "Поље",
        placeOfTakeover: "Место преношења",
        toGraveyard: "Гробље на које се врши пренос",
        toGraveSite: "Ознака гробног места у које се врши пренос",
        graveOrTomb: "Тип гробног места",
        grave: "Рака",
        tomb: "Гробница",
        numberOfCasketsForTomb: "За број ковчега: ",
        tombEntrance: "Са улазом: ",
        graveSiteType: "Тип гробног места",
        entranceFromTop: "Од горе",
        entranceFromSide: "Са стране",
        graveSiteTypeOtherNotes: "Коментар",
        hasTombstone: "Надгробно обележје",
        withTombstone: "Има",
        noTombstone: "Нема",
        hasDeceased: "Сахрањена лица",
        withDeceased: "Има",
        noDeceased: "Нема сахрањених лица",
        deceasedList: "Имена и презимена сахрањених лица са годинама смрти",
        requestSubmitterNameSurname: "Име и презиме подносиоца захтева",
        requestSubmitterMunicipality: "Место пребивалишта подносиоца захтева",
        requestSubmitterAddress: "Адреса подносиоца захтева",
        requestSubmitterIDCardOrPassportNumber: "Број личне карте/пасоша подносиоца захтева",
        requestSubmitterIdentificationNumber: "ЈМБГ подносиоца захтева",
        requestSubmitterTelephone: "Контакт телефон подносиоца захтева",
        paymentNumber: "Број уплате",
        paymentDate: "Датум уплате",
        dateTimeOfExhumation: "Датум и време извршења ексхумације",
        supervisorNameSurname: "Име и презиме пословође који врши надзор",
        specialNote: "ПОСЕБНА НАПОМЕНА",
        ok: "ОК",
        cancel: "Одустани",
      },

      messages: {
        EXHUMATION_REQUEST_WITH_NAME_SURNAME_CANT_BE_NULL: "Име и презиме покојника не може бити празно",
        EXHUMATION_REQUEST_DOES_NOT_EXIST: "Непостојећи захтев"
      },
    },

    scheduledFuneralList: {
      pageTitle: "Заказане сахране",
      showActive: "Прикажи активне",
      showPassed: "Прикажи завршене",
      pageTitleAppointments: "Распоред сахрана на дан:"
    },

    scheduledCremationList: {
      pageTitle: "Заказане кремације",
    },

    equipmentOrderList: {
      pageTitle: "Налози за опрему",
    },

    exhumationRequestList: {
      pageTitle: "Захтеви за ексхумацију",
    },

    reviserReview: {
      headerTitle: "Прегледи за ревизоре",
      pageTitle: "Прегледи за ревизоре",
      generateReport: "Генериши извештај"
    },

    expectedIncomeReview: {
      headerTitle: "Прегледи очекиваних прихода",
      pageTitle: "Прегледи очекиваних прихода",
      generateReport: "Генериши извештај",
      graveSiteTypes: "Тип гробног места",
      year: "Година",
      monthFrom: "Месец од",
      monthTo: "Месец до",
    },

    expiryByGraveFieldReview: {
      headerTitle: "Прегледи истека по парцелама и уговорима",
      pageTitle: "Прегледи истека по парцелама и уговорима",
      generateReport: "Генериши извештај",
      graveSiteTypes: "Тип гробног места",
      graveyard: "Гробље",
    },

    user: {
      user: "Корисник",
      addUser: "Додај",
      viewAddr: "VIEW",
      userHomePage: "Home Page",
      pageTitle: "Додај корисника",
      pageTitleEdit: "Измени корисника",
      pageTitleView: "Преглед корисника",
      headerTitle: "Корисници",
      changePassword: "Шифра успешно промењена",
      form: {
        name: "Име",
        surname: "Презиме",
        email: "Имејл",
        phoneNumber: "Бр. телефона",
        address: "Адреса",
        birthDate: "Датум рођења",
        selectBirthDate: "Изабери датум",
        company: "Компанија",
        errorAdding: "Грешка приликом додавања корисника",
        added: "Корисник додат",
        deleted: "Корисник обрисан",
        ok: "Ок",
        cancel: "Одустани"
      },
      editForm: {
        errorEditing: "Грешка приликом измене корисника",
        edited: "Корисник измењен"
      },
      userList: {
        name: 'Име',
        surname: 'Презиме',
        phoneNumber: 'Бр. телефона',
        address: 'Адреса',
        email: 'Имејл',
        birthDate: "Датум рођења",
        isDeleted: 'Је обрисан',
        dateCreated: 'Датум брисања',
        pageTitle: 'Корисници',
        enabled: 'Омогућен',
        userDelete: 'Корисник обрисан',
        userRestored: 'Корисник враћен',
      },
      messages: {
        USER_WITH_EMAIL_ALREADY_EXIST: "Корисник са овим имејлом већ постоји",
        USER_COMPANY_CANT_BE_NULL: "Поље компанија не може бити празно",
        LOGIN_AS_ADMIN: "Пријави се као администратор",
        USER_NOT_EXIST: "Корисник не постоји",
        USER_EMAIL_CANT_BE_NULL: "Поље имејл не може бити празно",
        USER_NAME_CANT_BE_NULL: "Поље име не може бити празно",
        USER_SURNAME_CANT_BE_NULL: "Поље презиме не може бити празно",
        USER_PHONE_CANT_BE_NULL: "Поље телефон не може бити празно"
      },
      homePage: {
        fullName: "Пуно име: ",
        email: "Имејл: ",
        address: "Адреса: ",
      }
    },

    cremationStatement: {
      messages: {
        CREMATION_STATEMENT_DOES_NOT_EXIST: "Непостојећа изјава",
        CREMATION_STATEMENT_FULLNAME_CANT_BE_NULL: "Име и презиме не могу бити празни",
        CREMATION_STATEMENT_JMBG_CANT_BE_NULL: "ЈМБГ не може бити празан",
        CREMATION_STATEMENT_MUNICIPALITY_CANT_BE_NULL: "Место становања не може бити празно",
        CREMATION_STATEMENT_PHONENUMBER_CANT_BE_NULL: "Телефон не може бити празан",
        CREMATION_STATEMENT_STATE_CANT_BE_NULL: "Држава не може бити празна",
        CREMATION_STATEMENT_REFERENCENUMBER_CANT_BE_NULL: "Деловодни број изјаве не може бити празан",
        CREMATION_STATEMENT_COMMENTS_CANT_BE_NULL: "Напомена не може бити празна",
      }
    },

    cremationStatementList: {
      pageTitle: "Изјаве за кремацију",
      fullName: "Име",
      jmbg: "ЈМБГ",
      municipality: "Место становања",
      phoneNumber: "Телефон",
      country: "Држава",
      referenceNumber: "Деловодни број изјаве",
      comments: "НАПОМЕНА",
      dateCreated: "Датум креирања",
      dateUpdated: "Датум ажурирања",
    },

    delete: {
      confirmation: "Да ли сте сигурни да желите да обришете?",
    },
  }

});

export default strings;