import React from 'react'
import { bindActionCreators } from 'redux';
import TablePage from '../../common/TablePage';
import strings from '../../localization';
import * as Actions from "../../actions/Actions";
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import connect from 'react-redux/es/connect/connect';
import { deletePostalAddressCodeImproved, getDeletedPostalAddressCodesImproved, getPostalAddressCodesImproved,restorePostalAddressCodeImproved } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';
import AddPostalAddressCodeImproved from './AddPostalAddressCodeImproved';
import EditPostalAddressCodeImproved from './EditPostalAddressCodeImproved'
import ViewPostalAddressCodeImproved from './ViewPostalAddressCodeImproved'

class PostalAddressCodeImprovedList extends TablePage {

    tableDescription = [
        { key: 'pac', label: strings.postalAddressCodeImprovedList.pac, sort: 'pac' },
        { key: 'stateName', label: strings.postalAddressCodeImprovedList.state, sort: 'stateName' },
        { key: 'postalCodeImprovedCityZipName', label: strings.postalAddressCodeImprovedList.cityAndZip, sort: 'postalCodeImproved' },
        { key: 'street', label: strings.postalAddressCodeImprovedList.street, sort: 'street' },
        { key: 'number', label: strings.postalAddressCodeImprovedList.number, sort: 'number' },
    ];

    constructor(props) {
        super(props)

        this.state.sortBy = "pac,ASC";
    }

    fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            companyId: this.state.companyId,
            sort: this.state.sortBy,
            userId: this.props.auth.user.id
        };

        {
            (this.state.companyId != undefined && !this.state.showDeleted) &&
                getPostalAddressCodesImproved(data).then(response => {
                    if (!response.ok) {
                        return;
                    }
                    this.setState({
                        tableData: response.data.entities,
                        total: response.data.total,
                    });
                });
        }

        {
            (this.state.companyId != undefined && this.state.showDeleted) &&
            getDeletedPostalAddressCodesImproved(data).then(response => {
                if (!response.ok) {
                  return;
                }
                this.setState({
                  tableData: response.data.entities,
                  total: response.data.total,
                });
              });
          }
    }

    handleDeletedChange(){
        this.setState({
          showDeleted : !this.state.showDeleted
        },() =>{ this.fetchData(); })
        
      }
      usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "ПАК"
        }
        this.getPermissionsS(data);
        this.showAdd(this.props.showAdd);
        this.showRemove(this.props.remove);
        this.showEdit(this.props.showEdit);
        this.showView(this.props.showView);
        this.removedItemsShow(this.props.removedItemsShow);
    
      }
    
      componentDidMount() {
        this.usePermissions()
        this.setState({
            companyId: this.props.auth.user.company.id
        }, () => {
            this.fetchData();
        })
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: 'pac', label: strings.postalAddressCodeImprovedList.pac, sort: 'pac' },
            { key: 'stateName', label: strings.postalAddressCodeImprovedList.state, sort: 'stateName' },
            { key: 'postalCodeImprovedCityZipName', label: strings.postalAddressCodeImprovedList.cityAndZip, sort: 'postalCodeImproved' },
            { key: 'street', label: strings.postalAddressCodeImprovedList.street, sort: 'street' },
            { key: 'number', label: strings.postalAddressCodeImprovedList.number, sort: 'number' },
        ];
    }
    getPageHeader() {
        return <h1>{strings.postalAddressCodeImprovedList.pageTitle}</h1>;
    }

    renderAddContent() {
        return <AddPostalAddressCodeImproved
            cid={this.state.companyId}
            uemail={this.props.auth.user.email}
            onCancel={this.onCancel}
            onFinish={this.onFinish}
            flag={"add"}
        />
    }

    renderEditContent() {
        return <EditPostalAddressCodeImproved
            id={this.state.selectedItem.id}
            uemail={this.props.auth.user.email}
            cid={this.state.companyId}
            onCancel={this.onCancel}
            onFinish={this.onFinish}
            isDisabled={false}
            flag={"edit"}
            auth={this.props.auth}
        />
    }

    renderViewContent() {

        return <ViewPostalAddressCodeImproved
            id={this.state.selectedItem.id}
            isDisabled={true}
            onCancel={this.onCancel}
            onFinish={this.onFinish}
            flag={"view"}
            auth={this.props.auth}
        />
    }

    delete(item) {

        this.setState({
            lockTable: true
        });

        deletePostalAddressCodeImproved(item.id, this.props.auth.user.id).then(response => {

            if (response && !response.ok) {
                this.onFinish(null);
                return;
            }

            this.props.enqueueSnackbar(strings.postalAddressCodeImprovedList.postalAddressCodeDeleted, { variant: 'success' });

            this.onFinish(item);
            this.cancelDelete();

            this.setState({
                lockTable: false
            });
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.auth.user.email,
            companyId: this.state.companyId
        }
    }
      restore(item){
        
        restorePostalAddressCodeImproved(this.transformRequest(item)).then(response => {
          if (response && !response.ok) {
            this.onFinish(null);
            return;
          }
          this.setState({
            showDeleted : !this.state.showDeleted,
          },() =>{ this.fetchData(); })
        })
      }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PostalAddressCodeImprovedList)));