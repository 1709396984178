import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getCassetteRents(data) {
    return await request('/api/cassetterent/search', data);
}

export async function addCassetteRent(data, cid) {
    return await request('/api/cassetterent/' + cid, data, HttpMethod.POST);
}

export function getCassetteRentById(id, userId, companyId) {
    return  request("api/cassetterent/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editCassetteRent(data) {
    return await request("api/cassetterent/" + data.id, data, HttpMethod.PUT);
}

export async function deleteCassetteRent(id, uid) {
    return await request('/api/cassetterent/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function stornoCassetteRent(id, uid) {
    return await request('/api/cassetterent/cancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function undoStornoCassetteRent(id, uid) {
    return await request('/api/cassetterent/undoCancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function getDeletedCassetteRents(data) {
    return await request('/api/cassetterent/deleted', data);
}

export async function restoreCassetteRent(data) {
    return await request("api/cassetterent/restore/" + data.id, data, HttpMethod.PUT);
}