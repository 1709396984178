import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MenuState from "../constants/MenuState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Drawer } from "@material-ui/core";
import strings from "../localization";
import { getUserFromLocalStorage } from "../base/OAuth";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { getPermissionAdministration, getPermissionNavigation } from '../services/permissionAdministration/PermissionAdministrationService';

class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = {

      user: getUserFromLocalStorage() ? getUserFromLocalStorage() : {},
      submenu: {
        contracts: false
      },
    
    };
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }
  
  componentDidMount(){
  
    if(Object.keys(this.state.user) > 0 || this.props.auth.user != null){

      getPermissionNavigation().then(response =>{
        if (!response.ok) {
          return;
        }

        this.setState({
          navigations: response.data.entities
        })
        
      })
    }
  }

  getNavigationClass() {

    if (this.props.menu.state === MenuState.SHORT) {
      return 'navigation-content-container short';
    }
    else {
      return 'navigation-content-container'
    }
  }

  isCurrentPath(path) {

    return this.props.history.location.pathname == path;
  }

  containsCheck(str, navig) {

    if (navig != undefined) {
      for (let element of navig) {
        if (element === str) {
          return true;
        }
      }
    }
    return false;
  }

  handleCardClick = (cardName) => {
    this.props.onCardSelect(cardName);
  };

  toggleSubmenu(key) {

    let submenu = this.state.submenu;

    submenu[key] = !submenu[key];

    this.setState({
      submenu: submenu
    });
  }

  render() {
  
    return (
      <Drawer variant="permanent" id='navigation'>

        <div className={this.getNavigationClass()}>
          <div className='logo-container'>

            <div className='title'>
              <h2 className='titleFirstPart'>Е-Катастар</h2>
              <h2 className='titleSecondPart'>гробних места</h2>
            </div>

          </div>
          <List component="nav">
            {this.state.user.role === "SUPERADMIN" &&
              <div>
                <Link
                  to={"/companies"}
                  className={
                    this.isCurrentPath("/companies")
                      ? "navigation-link active"
                      : "navigation-link"
                  }
                  onClick={() => this.handleCardClick('Kompanije')}
                >
                  <ListItem className="navigation-item">
                    <ListItemText
                      inset
                      primary={strings.company.headerTitle}
                      className="navigation-text"
                    />
                  </ListItem>
                </Link>
              </div>
            }
            {this.state.user.role != "SUPERADMIN"  &&
              <div>
                {(this.containsCheck("Картон гробног места", this.props.auth.permissions) || this.containsCheck("Картон гробног места", this.state.navigations)) &&
                  <Link
                    to={"/cardGraveSiteInputForm"}
                    className={
                      this.isCurrentPath("/cardGraveSiteInputForm")
                        ? "navigation-link active"
                        : "navigation-link"
                    }
                    onClick={() => this.handleCardClick('Karton grobnog mesta')}
                  >
                    <ListItem className="navigation-item">
                      <ListItemText
                        inset
                        primary={strings.review.reviewsRecords.cardGraveSite}
                        className="navigation-text"
                      />
                    </ListItem>
                  </Link>
                }
                {/* OVDE COMMENT */}
                {(this.containsCheck("Заказивање", this.props.auth.permissions) || this.containsCheck("Заказивање", this.state.navigations)) &&
                  <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('funeralScheduling')} >
                  <ListItemText inset primary={strings.navigation.scheduling} className='navigation-text' />
                  {this.state.submenu.funeralScheduling ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                </ListItem>}
                <Collapse in={this.state.submenu.funeralScheduling} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className='submenu'>
                    {(this.containsCheck("Заказивање сахрана", this.props.auth.permissions) || this.containsCheck("Заказивање сахрана", this.state.navigations)) &&
                      <Link
                        to={"/funeralScheduling"}
                        className={
                          this.isCurrentPath("/funeralScheduling")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Zakazivanje sahrana')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.funeralScheduling.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Заказивање кремирања", this.props.auth.permissions) || this.containsCheck("Заказивање кремирања", this.state.navigations)) &&
                      <Link
                        to={"/cremationScheduling"}
                        className={
                          this.isCurrentPath("/cremationScheduling")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Zakazivanje kremiranja')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.cremationScheduling.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Термини", this.props.auth.permissions) || this.containsCheck("Термини", this.state.navigations)) &&
                      <Link
                        to={"/appointments"}
                        className={
                          this.isCurrentPath("/appointments")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Termini')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.appointments.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Налози за опрему", this.props.auth.permissions) || this.containsCheck("Налози за опрему", this.state.navigations)) &&
                      <Link
                        to={"/equipmentOrders"}
                        className={
                          this.isCurrentPath("/equipmentOrders")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Nalog za opremu')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.equipmentOrder.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Захтеви за ексхумације", this.props.auth.permissions) || this.containsCheck("Захтеви за ексхумације", this.state.navigations)) &&
                      <Link
                        to={"/exhumationRequests"}
                        className={
                          this.isCurrentPath("/exhumationRequests")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Zahtev za ekshumaciju')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.exhumationRequest.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                  </List>
                </Collapse>

                {(this.containsCheck("Шифарник", this.props.auth.permissions) || this.containsCheck("Шифарник", this.state.navigations)) &&
                  <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('listCodebook')} >
                  <ListItemText inset primary={strings.navigation.listCodebook} className='navigation-text' />
                  {this.state.submenu.listCodebook ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                </ListItem>}
                <Collapse in={this.state.submenu.listCodebook} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className='submenu'>
                    {this.containsCheck("Помагала", this.props.auth.permissions) || this.containsCheck("Помагала", this.state.navigations) &&
                      <Link
                        to={"/aids"}
                        className={
                          this.isCurrentPath("/aids")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Pomagala')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.aid.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Места за чување", this.props.auth.permissions) || this.containsCheck("Места за чување", this.state.navigations)) &&
                      <Link
                        to={"/storages"}
                        className={
                          this.isCurrentPath("/storages")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Mesta za čuvanje')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.storagePlace.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Довежeни покојници", this.props.auth.permissions) || this.containsCheck("Довежни покојници", this.state.navigations)) &&
                      <Link
                        to={"/deceased"}
                        className={
                          this.isCurrentPath("/deceased") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Doveženi pokojnici')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.deceasedBrought.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Списак сандука", this.props.auth.permissions) || this.containsCheck("Списак сандука", this.state.navigations)) &&
                      <Link
                        to={"/crates"}
                        className={
                          this.isCurrentPath("/crates") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Spisak sanduka')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.crate.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Надгробна обележја", this.props.auth.permissions) || this.containsCheck("Надгробна обележја", this.state.navigations)) &&
                      <Link
                        to={"/tombstones"}
                        className={
                          this.isCurrentPath("/tombstones") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Nadgrobna obeležja')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.tombstone.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Списак писaма", this.props.auth.permissions) || this.containsCheck("Списак писма", this.state.navigations)) &&
                      <Link
                        to={"/letters"}
                        className={
                          this.isCurrentPath("/letters") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Spisak pisama')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.letter.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Списак језика", this.props.auth.permissions) || this.containsCheck("Списак језика", this.state.navigations)) &&
                      <Link
                        to={"/languages"}
                        className={
                          this.isCurrentPath("/languages") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Spisak jezika')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.language.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Врсте слова", this.props.auth.permissions) || this.containsCheck("Врсте слова", this.state.navigations)) &&
                      <Link
                        to={"/typefaces"}
                        className={
                          this.isCurrentPath("/typefaces") ? "navigation-link active" : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Vrste slova')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.typeface.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {this.containsCheck("Налогодавци", this.props.auth.permissions) || this.containsCheck("Налогодавци", this.state.navigations) &&
                      <Link
                        to={"/principals"}
                        className={
                          this.isCurrentPath("/principals")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Nalogodavci')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.principal.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Разлози довожења покојника", this.props.auth.permissions) || this.containsCheck("Разлози довожења покојника", this.state.navigations)) &&
                      <Link
                        to={"/reasons"}
                        className={
                          this.isCurrentPath("/reasons")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Razlozi dovoženja pokojnika')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.reasonBringingDeceased.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                  </List>
                </Collapse>
              </div>
            }
            {this.state.user.role !== "SUPERADMIN" &&
              <div>
                {(this.containsCheck("Основне евиденције", this.props.auth.permissions) || this.containsCheck("Основне евиденције", this.state.navigations)) &&
                  <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('primaryEvidence')} >
                  <ListItemText inset primary={strings.navigation.primaryEvidence} className='navigation-text' />
                  {this.state.submenu.primaryEvidence ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                </ListItem>}
                <Collapse in={this.state.submenu.primaryEvidence} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className='submenu'>
                    <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('deceasedReception')} >
                      <ListItemText inset primary={strings.navigation.deceasedReception} className='navigation-text' />
                      {this.state.submenu.deceasedReception ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                    </ListItem>
                    <Collapse in={this.state.submenu.deceasedReception} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className='submenu'>

                        {(this.containsCheck("Записник о преузимању покојника", this.props.auth.permissions) || this.containsCheck("Записник о преузимању покојника", this.state.navigations)) &&
                          <Link
                            to={"/takeoverDeceased"}
                            className={
                              this.isCurrentPath("/takeoverDeceased")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Zapisnik o preuzimanju pokojnika')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.deceasedTakeoverRecord.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                        {(this.containsCheck("Записник о преузимању урне", this.props.auth.permissions) || this.containsCheck("Записник о преузимању урне", this.state.navigations)) &&
                          <Link
                            to={"/takeoverUrn"}
                            className={
                              this.isCurrentPath("/takeoverUrn")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Zapisnik o preuzimanju urne')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.urnTakeoverRecord.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                        {(this.containsCheck("Записник о одношењу покојника", this.props.auth.permissions) || this.containsCheck("Записник о одношењу покојника", this.state.navigations)) &&
                          <Link
                            to={"/takeawayDeceased"}
                            className={
                              this.isCurrentPath("/takeawayDeceased")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Zapisnik o odnošenju pokojnika')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.deceasedTakeawayRecord.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                      </List>
                    </Collapse>
                    {(this.containsCheck("Главна књига сахрањених", this.props.auth.permissions) || this.containsCheck("Главна књига сахрањених", this.state.navigations)) &&
                      <Link
                        to={"/mainBookOfBuried"}
                        className={
                          this.isCurrentPath("/mainBookOfBuried")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Glavna knjiga sahranjenih')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.mainBookOfBuried.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Резервације", this.props.auth.permissions) || this.containsCheck("Резервације", this.state.navigations)) &&

                      <Link
                        to={"/reservations"}
                        className={
                          this.isCurrentPath("/reservations")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Rezervacije')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.reservation.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Гробнице - закупи", this.props.auth.permissions) || this.containsCheck("Гробнице - закупи", this.state.navigations)) &&
                      <Link
                        to={"/tombRent"}
                        className={
                          this.isCurrentPath("/tombRent")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Grobnice - zakupi')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.tombRent.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Касете - закупи", this.props.auth.permissions) || this.containsCheck("Касете - закупи", this.state.navigations)) &&
                      <Link
                        to={"/cassetteRent"}
                        className={
                          this.isCurrentPath("/cassetteRent")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Kasete - zakupi')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.cassetteRent.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Главна књига кремираних", this.props.auth.permissions) || this.containsCheck("Главна књига кремираних", this.state.navigations)) &&
                      <Link
                        to={"/mainBookOfCremated"}
                        className={
                          this.isCurrentPath("/mainBookOfCremated")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Glavna knjiga kremiranih')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.mainBookOfCremated.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Ексхумације", this.props.auth.permissions) || this.containsCheck("Ексхумације", this.state.navigations)) &&
                      <Link
                        to={"/exhumations"}
                        className={
                          this.isCurrentPath("/exhumations")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Ekshumacije')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.exhumation.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Каменорезачки радови", this.props.auth.permissions) || this.containsCheck("Каменорезачки радови", this.state.navigations)) &&
                      <Link
                        to={"/stoneCuttingWorks"}
                        className={
                          this.isCurrentPath("/stoneCuttingWorks")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Kamenorezački radovi')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.stoneCuttingWorks.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Гробна места", this.props.auth.permissions) || this.containsCheck("Гробна места", this.state.navigations)) &&
                      <Link
                        to={"/graveSites"}
                        className={
                          this.isCurrentPath("/graveSites")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Grobna mesta')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.graveSite.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Изјаве за кремацију", this.props.auth.permissions) || this.containsCheck("Изјаве за кремацију", this.state.navigations)) &&
                      <Link
                        to={"/cremationStatements"}
                        className={
                          this.isCurrentPath("/cremationStatements")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Izjave za kremaciju')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.cremationStatementList.pageTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                  </List>
                </Collapse>
                {(this.containsCheck("Одржавање шифарника", this.props.auth.permissions) || this.containsCheck("Одржавање шифарника", this.state.navigations)) &&
                  <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('codebook')} >
                  <ListItemText inset primary={strings.navigation.codebook} className='navigation-text' />
                  {this.state.submenu.codebook ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                </ListItem>}
                <Collapse in={this.state.submenu.codebook} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className='submenu'>

                    {(this.containsCheck("Занимања", this.props.auth.permissions) || this.containsCheck("Занимања", this.state.navigations)) &&
                      <Link
                        to={"/occupations"}
                        className={
                          this.isCurrentPath("/occupations")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Zanimanja')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.occupation.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Државе", this.props.auth.permissions) || this.containsCheck("Државе", this.state.navigations)) &&
                      <Link
                        to={"/states"}
                        className={
                          this.isCurrentPath("/states")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Države')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.state.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Узроци смрти", this.props.auth.permissions) || this.containsCheck("Узроци смрти", this.state.navigations)) &&
                      <Link
                        to={"/causeOfDeaths"}
                        className={
                          this.isCurrentPath("/causeOfDeaths")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Uzroci smrti')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.causeOfDeath.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Доктори", this.props.auth.permissions) || this.containsCheck("Доктори", this.state.navigations)) &&
                      <Link
                        to={"/doctors"}
                        className={
                          this.isCurrentPath("/doctors")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Doktori')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.doctor.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Транспортери", this.props.auth.permissions) || this.containsCheck("Транспортери", this.state.navigations)) &&
                      <Link
                        to={"/transporters"}
                        className={
                          this.isCurrentPath("/transporters")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Transporteri')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.transporter.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Ценовници", this.props.auth.permissions) || this.containsCheck("Ценовници", this.state.navigations)) &&
                      <Link
                        to={"/pricelists"}
                        className={
                          this.isCurrentPath("/pricelists")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Cenovnici')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.pricelist.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Урне", this.props.auth.permissions) || this.containsCheck("Урне", this.state.navigations)) &&
                      <Link
                        to={"/urns"}
                        className={
                          this.isCurrentPath("/urns")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Urne')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.urn.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Знаменити грађани", this.props.auth.permissions) || this.containsCheck("Знаменити грађани", this.state.navigations)) &&
                      <Link
                        to={"/famousCitizens"}
                        className={
                          this.isCurrentPath("/famousCitizens")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Znameniti građani')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.famousCitizen.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {/* <Link
                      to={"/settlements"}
                      className={
                        this.isCurrentPath("/settlements")
                          ? "navigation-link active"
                          : "navigation-link"
                      }
                    >
                      <ListItem className="navigation-item">
                        <ListItemText
                          inset
                          primary={strings.settlement.headerTitle}
                          className="navigation-text"
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to={"/postalCodes"}
                      className={
                        this.isCurrentPath("/postalCodes")
                          ? "navigation-link active"
                          : "navigation-link"
                      }
                    >
                      <ListItem className="navigation-item">
                        <ListItemText
                          inset
                          primary={strings.postalCode.headerTitle}
                          className="navigation-text"
                        />
                      </ListItem>
                    </Link> */}

                    {(this.containsCheck("Поштански бројеви и места", this.props.auth.permissions) || this.containsCheck("Поштански бројеви и места", this.state.navigations)) &&
                      <Link
                        to={"/postalCodesImproved"}
                        className={
                          this.isCurrentPath("/postalCodesImproved")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Poštanski brojevi i mesta')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.postalCodeImproved.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {/* <Link
                      to={"/postalAddressCodes"}
                      className={
                        this.isCurrentPath("/postalAddressCodes")
                          ? "navigation-link active"
                          : "navigation-link"
                      }
                    >
                      <ListItem className="navigation-item">
                        <ListItemText
                          inset
                          primary={strings.postalAddressCode.headerTitle}
                          className="navigation-text"
                        />
                      </ListItem>
                    </Link> */}

                    {(this.containsCheck("ПАК", this.props.auth.permissions) || this.containsCheck("ПАК", this.state.navigations)) &&
                      <Link
                        to={"/postalAddressCodesImproved"}
                        className={
                          this.isCurrentPath("/postalAddressCodesImproved")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('PAK')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.postalAddressCode.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Каменоресци", this.props.auth.permissions) || this.containsCheck("Каменоресци", this.state.navigations)) &&
                      <Link
                        to={"/stonecutters"}
                        className={
                          this.isCurrentPath("/stonecutters")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Kamenoresci')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.stonecutter.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Гробља", this.props.auth.permissions) || this.containsCheck("Гробља", this.state.navigations)) &&
                      <Link
                        to={"/graveyards"}
                        className={
                          this.isCurrentPath("/graveyards")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Groblja')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.graveyard.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Фирме", this.props.auth.permissions) || this.containsCheck("Фирме", this.state.navigations)) &&
                      <Link
                        to={"/firms"}
                        className={
                          this.isCurrentPath("/firms")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Firme')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.firm.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {(this.containsCheck("Грађани", this.props.auth.permissions) || this.containsCheck("Грађани", this.state.navigations)) &&
                      <Link
                        to={"/citizens"}
                        className={
                          this.isCurrentPath("/citizens")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Građani')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.citizen.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                     } 
                  </List>
                </Collapse>
              {(this.containsCheck("Обнове и опомене", this.props.auth.permissions) || this.containsCheck("Обнове и опомене", this.state.navigations)) &&
                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('renewals')} >
                <ListItemText inset primary={strings.navigation.renewals} className='navigation-text' />
                {this.state.submenu.renewals ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
              </ListItem>}
                <Collapse in={this.state.submenu.renewals} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className='submenu'>
                    {/* <Link
                      to={"/renewals"}
                      className={
                        this.isCurrentPath("/renewals")
                          ? "navigation-link active"
                          : "navigation-link"
                      }
                    >
                      <ListItem className="navigation-item">
                        <ListItemText
                          inset
                          primary={strings.renewal.headerTitle}
                          className="navigation-text"
                        />
                      </ListItem>
                    </Link> */}

                    {(this.containsCheck("Обнове", this.props.auth.permissions) || this.containsCheck("Обнове", this.state.navigations)) &&
                      <Link
                        to={"/renewalsEdited"}
                        className={
                          this.isCurrentPath("/renewalsEdited")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                        onClick={() => this.handleCardClick('Obnove')}
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.renewal.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    }
                    {/* {(this.containsCheck("Трава", this.props.auth.permissions) || this.containsCheck("Трава", this.state.navigations)) &&
                      <Link
                        to={"/grass"}
                        className={
                          this.isCurrentPath("/grass")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.grass.headerTitle}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    } */}
                    
                    {/* TODO: if the collapse below does not work as expected, remove it and uncomment this
                    {(this.containsCheck("Обнове накнаде за коришћење и одржавање гробаља", this.props.auth.permissions) || this.containsCheck("Обнове накнаде за коришћење и одржавање гробаља", this.state.navigations)) &&
                      <Link
                        to={"/renewalFeesForUsingAndMaintaningGraveyards"}
                        className={
                          this.isCurrentPath("/renewalFeesForUsingAndMaintaningGraveyards")
                            ? "navigation-link active"
                            : "navigation-link"
                        }
                      >
                        <ListItem className="navigation-item">
                          <ListItemText
                            inset
                            primary={strings.navigation.renewalFeesForUsingAndMaintaningGraveyards}
                            className="navigation-text"
                          />
                        </ListItem>
                      </Link>
                    } */}

                    <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('renewalFeesForUsingAndMaintaningGraveyards')} >
                      <ListItemText inset primary={strings.navigation.renewalFeesForUsingAndMaintaningGraveyards} className='navigation-text' />
                        {this.state.submenu.renewalFeesForUsingAndMaintaningGraveyards ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                    </ListItem>
                    <Collapse in={this.state.submenu.renewalFeesForUsingAndMaintaningGraveyards} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className='submenu'>
                        {(this.containsCheck("Обрада", this.props.auth.permissions) || this.containsCheck("Обрада", this.state.navigations)) &&
                          <Link
                            to={"/renewalProcessing"}
                            className={
                              this.isCurrentPath("/renewalProcessing")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Obrada obnova')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.renewalProcessing.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                        {(this.containsCheck("Обрада закупа", this.props.auth.permissions) || this.containsCheck("Обрада закупа", this.state.navigations)) &&
                          <Link
                            to={"/leaseProcessing"}
                            className={
                              this.isCurrentPath("/leaseProcessing")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Obrada zakupa')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.leaseProcessing.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
              
                        
               {(this.containsCheck("Прегледи", this.props.auth.permissions) || this.containsCheck("Прегледи", this.state.navigations)) &&
                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('review')} >
                  <ListItemText inset primary={strings.navigation.review} className='navigation-text' />
                  {this.state.submenu.review ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                </ListItem>}
  
                <Collapse in={this.state.submenu.review} timeout="auto" unmountOnExit>

                  <List component="div" disablePadding className='submenu'>

                    <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('reviewsRecords')} >
                      <ListItemText inset primary={strings.navigation.reviewsRecords} className='navigation-text' />
                      {this.state.submenu.reviewsRecords ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                    </ListItem>
                    <Collapse in={this.state.submenu.reviewsRecords} timeout="auto" unmountOnExit>

                      <List component="div" disablePadding className='submenu'>

                        {(this.containsCheck("Прегледи за ревизоре", this.props.auth.permissions) || this.containsCheck("Прегледи за ревизоре", this.state.navigations)) &&
                          <Link
                            to={"/reviserReview"}
                            className={
                              this.isCurrentPath("/reviserReview")
                                ? "navigation-link active"
                                : "navigation-link"
                            }onClick={() => this.handleCardClick('Pregledi za revizore')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.reviserReview.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                        {(this.containsCheck("Прегледи очекиваних прихода", this.props.auth.permissions) || this.containsCheck("Прегледи очекиваних прихода", this.state.navigations)) &&
                          <Link
                            to={"/expectedIncome"}
                            className={
                              this.isCurrentPath("/expectedIncome")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Pregledi očekivanih prihoda')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.expectedIncomeReview.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                        {(this.containsCheck("Прегледи истека по парцелама и уговорима", this.props.auth.permissions) || this.containsCheck("Прегледи истека по парцелама и уговорима", this.state.navigations)) &&
                          <Link
                            to={"/expiryByGraveField"}
                            className={
                              this.isCurrentPath("/expiryByGraveField")
                                ? "navigation-link active"
                                : "navigation-link"
                            }
                            onClick={() => this.handleCardClick('Pregledi isteka po parcelama i ugovorima')}
                          >
                            <ListItem className="navigation-item">
                              <ListItemText
                                inset
                                primary={strings.expiryByGraveFieldReview.headerTitle}
                                className="navigation-text"
                              />
                            </ListItem>
                          </Link>
                        }
                      </List>

                    </Collapse>


                    <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('tables')} >
                      <ListItemText inset primary={strings.navigation.tables} className='navigation-text' />
                      {this.state.submenu.tables ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                    </ListItem>
                    <Collapse in={this.state.submenu.tables} timeout="auto" unmountOnExit>

                      <List component="div" disablePadding className='submenu'>

                        <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('funerals')} >
                          <ListItemText inset primary={strings.navigation.funerals} className='navigation-text' />
                          {this.state.submenu.funerals ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                        </ListItem>
                        <Collapse in={this.state.submenu.funerals} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className='submenu'>
                            {(this.containsCheck("Број сахрана за гробља по месецима и урне за одабрану календарску годину", this.props.auth.permissions) || this.containsCheck("Број сахрана за гробља по месецима и урне за одабрану календарску годину", this.state.navigations)) &&
                              <Link
                                to={"/numberOfFuneralsAndUrnsPerMonthForSelectedYear"}
                                className={
                                  this.isCurrentPath("/numberOfFuneralsAndUrnsPerMonthForSelectedYear")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj sahrana')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.funerals.numberOfFuneralsAndUrnsPerMonthForSelectedYear}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                            {(this.containsCheck("Број сахрана за поља и гробља по месецима за одабрану календарску годину", this.props.auth.permissions) || this.containsCheck("Број сахрана за поља и гробља по месецима за одабрану календарску годину", this.state.navigations)) &&
                              <Link
                                to={"/numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear"}
                                className={
                                  this.isCurrentPath("/numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj sahrana')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.funerals.numberOfFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                            {(this.containsCheck("Број сахрана за поља и одабрано гробље по врсти гробних места", this.props.auth.permissions) || this.containsCheck("Број сахрана за поља и одабрано гробље по врсти гробних места", this.state.navigations)) &&
                              <Link
                                to={"/numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite"}
                                className={
                                  this.isCurrentPath("/numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj sahrana')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.funerals.numberOfFuneralsForGraveFieldsAndSelectedGraveyardByTypeOfGraveSite}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                            {(this.containsCheck("Број сахрана за сва гробља по врсти гробних места", this.props.auth.permissions) || this.containsCheck("Број сахрана за сва гробља по врсти гробних места", this.state.navigations)) &&
                              <Link
                                to={"/numberOfFuneralsForAllGraveyardsByTypeOfGraveSite"}
                                className={
                                  this.isCurrentPath("/numberOfFuneralsForAllGraveyardsByTypeOfGraveSite")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj sahrana')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.funerals.numberOfFuneralsForAllGraveyardsByTypeOfGraveSites}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                            {(this.containsCheck("Број сахрана за године сахранивања и одабрана гробља", this.props.auth.permissions) || this.containsCheck("Број сахрана за године сахранивања и одабрана гробља", this.state.navigations)) &&
                              <Link
                                to={"/numberOfFuneralsForYearsOfBurialAndSelectedGraveyards"}
                                className={
                                  this.isCurrentPath("/numberOfFuneralsForYearsOfBurialAndSelectedGraveyards")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj sahrana')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.funerals.numberOfFuneralsForYearsOfBurialAndSelectedGraveyards}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                          </List>
                        </Collapse>

                        <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('reservations')} >
                          <ListItemText inset primary={strings.navigation.review_tables.reservations} className='navigation-text' />
                          {this.state.submenu.reservations ? <ExpandLess className='navigation-icon' /> : <ExpandMore className='navigation-icon' />}
                        </ListItem>
                        <Collapse in={this.state.submenu.reservations} timeout="auto" unmountOnExit>

                          <List component="div" disablePadding className='submenu'>
                            {(this.containsCheck("Број продатих уређених гробних места по величини и месецима продаје за изабрану годину и гробље", this.props.auth.permissions) || this.containsCheck("Број продатих уређених гробних места ппо величини и месецима продаје за изабрану годину и гробље", this.state.navigations)) &&
                              <Link
                                to={"/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard"}
                                className={
                                  this.isCurrentPath("/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard")
                                    ? "navigation-link active"
                                    : "navigation-link"
                                }
                                onClick={() => this.handleCardClick('Broj prodatih uređenih parcela')}
                              >
                                <ListItem className="navigation-item">
                                  <ListItemText
                                    inset
                                    primary={strings.review.tables.reservations.numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyard}
                                    className="navigation-text"
                                  />
                                </ListItem>
                              </Link>
                            }
                            {/* <Link
                              to={"/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot"}
                              className={
                                this.isCurrentPath("/numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot")
                                  ? "navigation-link active"
                                  : "navigation-link"
                              }
                            >
                              <ListItem className="navigation-item">
                                <ListItemText
                                  inset
                                  primary={strings.review.tables.reservations.numberOfSoldArrangedGraveSitesForSelectedYearAndGraveyardAndPlot}
                                  className="navigation-text"
                                />
                              </ListItem>
                            </Link>

                            <Link
                              to={"/numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths"}
                              className={
                                this.isCurrentPath("/numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths")
                                  ? "navigation-link active"
                                  : "navigation-link"
                              }
                            >
                              <ListItem className="navigation-item">
                                <ListItemText
                                  inset
                                  primary={strings.review.tables.reservations.numberOfInheritedArrangedGraveSitesForCurrentYearPerMonths}
                                  className="navigation-text"
                                />
                              </ListItem>
                            </Link>

                            <Link
                              to={"/numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths"}
                              className={
                                this.isCurrentPath("/numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths")
                                  ? "navigation-link active"
                                  : "navigation-link"
                              }
                            >
                              <ListItem className="navigation-item">
                                <ListItemText
                                  inset
                                  primary={strings.review.tables.reservations.numberOfInheritedArrangedGraveSitesForCurrentYearAndSelectedPlotPerMonths}
                                  className="navigation-text"
                                />
                              </ListItem>
                            </Link> */}


                          </List>
                        </Collapse>


                      </List>
                    </Collapse>
                  </List>

                </Collapse>

              </div>
            }
            {this.state.user.role !== "USER" &&
              <div>

                {(this.containsCheck("Корисници", this.props.auth.permissions) || this.containsCheck("Корисници", this.state.navigations)) &&
                  <Link
                    to={"/users"}
                    className={
                      this.isCurrentPath("/users")
                        ? "navigation-link active"
                        : "navigation-link"
                    }
                    onClick={() => this.handleCardClick('Korisnici')}
                  >
                    <ListItem className="navigation-item">
                      <ListItemText
                        inset
                        primary={strings.user.headerTitle}
                        className="navigation-text"
                      />
                    </ListItem>
                  </Link>
                }
                {(this.containsCheck("Роле", this.props.auth.permissions) || this.containsCheck("Роле", this.state.navigations)) &&
                  <Link
                    to={"/roles"}
                    className={
                      this.isCurrentPath("/rоles")
                        ? "navigation-link active"
                        : "navigation-link"
                    }
                    onClick={() => this.handleCardClick('Role')}
                  >
                    <ListItem className="navigation-item">
                      <ListItemText
                        inset
                        primary={strings.roles.pageTitle}
                        className="navigation-text"
                      />
                    </ListItem>
                  </Link>}

                {/* TODO: if new permissions are needed to be added, uncomment this */}
                <Link
                  to={"/permissions"}
                  className={
                    this.isCurrentPath("/permissions")
                      ? "navigation-link active"
                      : "navigation-link"
                  }
                >
                  <ListItem className="navigation-item">
                    <ListItemText
                      inset
                      primary={strings.permissions.pageTitle}
                      className="navigation-text"
                    />
                  </ListItem>
                </Link>

                {(this.containsCheck("Дозволе рола", this.props.auth.permissions) || this.containsCheck("Дозволе рола", this.state.navigations)) &&
                  <Link
                    to={"/role_permissions"}
                    className={
                      this.isCurrentPath("/rоle_permissions")
                        ? "navigation-link active"
                        : "navigation-link"
                    }
                    onClick={() => this.handleCardClick('Dozvole rola')}
                  >
                    <ListItem className="navigation-item">
                      <ListItemText
                        inset
                        primary={strings.rolePermission.pageTitle}
                        className="navigation-text"
                      />
                    </ListItem>
                  </Link>
                }
                {(this.containsCheck("Рола корисника", this.props.auth.permissions) || this.containsCheck("Рола корисника", this.state.navigations)) &&
                  <Link
                    to={"/user_roles"}
                    className={
                      this.isCurrentPath("/user_rоles")
                        ? "navigation-link active"
                        : "navigation-link"
                    }
                    onClick={() => this.handleCardClick('Role korisnika')}
                  >
                    <ListItem className="navigation-item">
                      <ListItemText
                        inset
                        primary={strings.userRole.pageTitle}
                        className="navigation-text"
                      />
                    </ListItem>
                  </Link>}
                <Link
                  to={"/auditTrails"}
                  className={
                    this.isCurrentPath("/auditTrails")
                      ? "navigation-link active"
                      : "navigation-link"
                  }
                  onClick={() => this.handleCardClick('Tragovi korišćenja')}
                >
                  <ListItem className="navigation-item">
                    <ListItemText
                      inset
                      primary={strings.auditTrailList.pageTitle}
                      className="navigation-text"
                    />
                  </ListItem>
                </Link>
              </div>
            }
          </List>
        </div>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
