import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import AddUser from "./AddUser";
import { withSnackbar } from "notistack";
import EditUser from './EditUser';
import ViewUser from './ViewUser';
import { deleteUser, getUsers } from '../../services/admin/AdminService';


class UserList extends TablePage {

  tableDescription = [
    { key: 'email', label: strings.userList.email, sort: 'email' },
    { key: 'company', label: strings.userList.company, transform: 'renderColumnCompany', sort: 'companyId' },
    { key: 'name', label: strings.userList.firstName, sort: 'name' },
    { key: 'surname', label: strings.userList.lastName, sort: 'surname' },
    { key: 'dateCreated', label: strings.userList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    { key: 'registrationConfirmed', label: strings.userList.registrationConfirmed, transform: 'renderColumnDeleted', sort: 'registrationConfirmed' }
  ];

  constructor(props) {
    super(props);

    this.state.sortBy = "dateCreated,ASC";
  }

  renderColumnCompany(item) {

    if (!item) {
      return "";
    }

    return item.name;
  }

  fetchData() {
    var data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
      getUsers(data).then(response => {
        if (!response.ok) {
          return;
        }

        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      });
    }

  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Корисници"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company == null ? 0 : this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'email', label: strings.userList.email, sort: 'email' },
      { key: 'company', label: strings.userList.company, transform: 'renderColumnCompany', sort: 'companyId' },
      { key: 'name', label: strings.userList.firstName, sort: 'name' },
      { key: 'surname', label: strings.userList.lastName, sort: 'surname' },
      { key: 'dateCreated', label: strings.userList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
      { key: 'registrationConfirmed', label: strings.userList.registrationConfirmed, transform: 'renderColumnDeleted', sort: 'registrationConfirmed' }
    ];
  }

  getPageHeader() {

    return <h1>{strings.userList.pageTitle}</h1>;
  }

  renderAddContent() {

    return <AddUser
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      role={this.props.auth.user.role}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
    />
  }

  renderEditContent() {

    return <EditUser
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      role={this.props.auth.user.role}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewUser
      id={this.state.selectedItem.id}
      role={this.props.auth.user.role}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      auth={this.props.auth}
    />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });
    
    deleteUser(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.userList.userDelete, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList)));