import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getScheduledCremations(data) {
    return await request('/api/scheduledCremations/search', data);
}

export async function addScheduledCremation(data, cid) {
    return await request('/api/scheduledCremations/' + cid, data, HttpMethod.POST);
}

export function getScheduledCremationById(id, userId, companyId) {
    return  request("api/scheduledCremations/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editScheduledCremation(data) {
    return await request("api/scheduledCremations/" + data.id, data, HttpMethod.PUT);
}

export function getAutocompleteObjectsById(data) {
    return  request("api/scheduledCremations/objects/" + data.id , data, HttpMethod.PUT);
}

export async function deleteScheduledCremation(id, uid) {
    return await request('/api/scheduledCremations/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function restoreScheduledCremation(data) {
    return await request("api/scheduledCremations/restore/" + data.id, data, HttpMethod.PUT);
}

export async function generateScheduledCremationRequestPdf(data) {
    return await request('/api/scheduledCremations/scheduled-cremation-request/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function getDeletedScheduledCremations(data) {
    return await request('/api/scheduledCremations/search/deleted', data);
}