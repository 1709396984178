import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getSettlements, deleteSettlement } from '../../services/settlement/SettlementService';
import AddSettlement from './AddSettlement';
import EditSettlement from './EditSettlement';
import ViewSettlement from './ViewSettlement';


class SettlementList extends TablePage {

  tableDescription = [
    { key: 'code', label: strings.settlementList.code, sort: 'code' },
    { key: 'name', label: strings.settlementList.name, sort: 'name' },
    { key: 'municipalityCode', label: strings.settlementList.municipalityCode, sort: 'municipalityCode' },
    { key: 'municipalityName', label: strings.settlementList.municipalityName, sort: 'municipalityName' },
    { key: 'dateCreated', label: strings.settlementList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
      getSettlements(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      });
    }
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'code', label: strings.settlementList.code, sort: 'code' },
      { key: 'name', label: strings.settlementList.name, sort: 'name' },
      { key: 'municipalityCode', label: strings.settlementList.municipalityCode, sort: 'municipalityCode' },
      { key: 'municipalityName', label: strings.settlementList.municipalityName, sort: 'municipalityName' },
      { key: 'dateCreated', label: strings.settlementList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.settlementList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddSettlement cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  renderEditContent() {
    return <EditSettlement
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewSettlement id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteSettlement(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.settlementDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettlementList)));