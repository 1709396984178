import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllFamousCitizens() {
    return await request('/api/famousCitizen/all');
}

export async function getFamousCitizens(data) {
    return await request('/api/famousCitizen/search', data);
}

export async function addFamousCitizen(data, cid) {
    return await request('/api/famousCitizen/' + cid, data, HttpMethod.POST);
}

export function getFamousCitizenById(id, userId, companyId) {
    return request("api/famousCitizen/" + id, {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editFamousCitizen(data) {
    return await request("api/famousCitizen/" + data.id, data, HttpMethod.PUT);
}

export async function deleteFamousCitizen(id, uid) {
    return await request('/api/famousCitizen/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedFamousCitizens(data){
    return await request('/api/famousCitizen/deleted', data)
}

export async function restoreFamousCitizen(data) {
    return await request("api/famousCitizen/restore/" + data.id, data, HttpMethod.PUT);
}