import FormComponent from "../../common/FormComponent";
import React from "react";
import { getCrateById } from "../../services/crate/CrateService";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import CrateForm from "../../components/forms/crate/CrateForm";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions"
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

class ViewCrate extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getCrateById(this.props.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
      this.setState({
        data: response.data
      });
    });
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.crate.viewPageTitle}</h1>
        </div>

        <Paper className="paper">
          <CrateForm
            data={this.state.data}
            isDisabled={this.props.isDisabled}
          />
        </Paper>

      </Grid>

    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewCrate)
  )
);