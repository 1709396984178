import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getStoneCuttingWorks, getDeletedStoneCuttingWorks, deleteStoneCuttingWorks, restoreStoneCuttingWorks } from '../../services/stoneCuttingWorks/StoneCuttingWorksService';
import AddStoneCuttingWork from './AddStoneCuttingWork';
import EditStoneCuttingWorks from './EditStoneCuttingWorks';
import ViewStoneCuttingWorks from './ViewStoneCuttingWorks';


class StoneCuttingWorksList extends TablePage {

  tableDescription = [
    { key: 'code', label: strings.stoneCuttingWorksList.code, sort: 'code' },
    { key: 'stonecutterName', label: strings.stoneCuttingWorksList.stonecutterName, sort: 'stonecutterName' },
    { key: 'jobType', label: strings.stoneCuttingWorksList.jobType, sort: 'jobType' },
    { key: 'mainBookOfBuriedDeceasedNameSurname', label: strings.stoneCuttingWorksList.mainBookOfBuriedDeceasedNameSurname, sort: 'mainBookOfBuriedDeceasedNameSurname' },
    { key: 'permissionNumber', label: strings.stoneCuttingWorksList.permissionNumber, sort: 'permissionNumber' },
    { key: 'permissionStartDate', label: strings.stoneCuttingWorksList.permissionStartDate, transform: 'renderColumnDate', sort: 'permissionStartDate' },
    { key: 'graveyardCode', label: strings.stoneCuttingWorksList.graveyardCode, sort: 'graveyardCode' },
    { key: 'graveSiteCode', label: strings.stoneCuttingWorksList.graveSiteCode, sort: 'graveSiteCode' },
    { key: 'tombstoneType', label: strings.stoneCuttingWorksList.tombstoneType, sort: 'tombstoneType' },
    { key: 'citizenName', label: strings.stoneCuttingWorksList.citizenName, sort: 'citizenName' },
    { key: 'citizenSurname', label: strings.stoneCuttingWorksList.citizenSurname, sort: 'citizenSurname' },
    { key: 'hasNote', label: strings.stoneCuttingWorksList.hasNote, transform: 'renderColumnDeleted', sort: 'hasNote' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "code,ASC";
    this.state.showDeleted = false;

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getStoneCuttingWorks(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedStoneCuttingWorks(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Каменорезачки радови"
    }
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);
    
    

  }
  
  
  componentDidMount() {
    
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'code', label: strings.stoneCuttingWorksList.code, sort: 'code' },
      { key: 'stonecutterName', label: strings.stoneCuttingWorksList.stonecutterName, sort: 'stonecutterName' },
      { key: 'jobType', label: strings.stoneCuttingWorksList.jobType, sort: 'jobType' },
      { key: 'mainBookOfBuriedDeceasedNameSurname', label: strings.stoneCuttingWorksList.mainBookOfBuriedDeceasedNameSurname, sort: 'mainBookOfBuriedDeceasedNameSurname' },
      { key: 'permissionNumber', label: strings.stoneCuttingWorksList.permissionNumber, sort: 'permissionNumber' },
      { key: 'permissionStartDate', label: strings.stoneCuttingWorksList.permissionStartDate, transform: 'renderColumnDate', sort: 'permissionStartDate' },
      { key: 'graveyardCode', label: strings.stoneCuttingWorksList.graveyardCode, sort: 'graveyardCode' },
      { key: 'graveSiteCode', label: strings.stoneCuttingWorksList.graveSiteCode, sort: 'graveSiteCode' },
      { key: 'tombstoneType', label: strings.stoneCuttingWorksList.tombstoneType, sort: 'tombstoneType' },
      { key: 'citizenName', label: strings.stoneCuttingWorksList.citizenName, sort: 'citizenName' },
      { key: 'citizenSurname', label: strings.stoneCuttingWorksList.citizenSurname, sort: 'citizenSurname' },
      { key: 'hasNote', label: strings.stoneCuttingWorksList.hasNote, transform: 'renderColumnDeleted', sort: 'hasNote' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.stoneCuttingWorksList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddStoneCuttingWork cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} flag={"add"} auth={this.props.auth} />
  }

  renderEditContent() {
    return <EditStoneCuttingWorks
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewStoneCuttingWorks id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag={"view"} auth={this.props.auth} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteStoneCuttingWorks(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.stoneCuttingWorkDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreStoneCuttingWorks(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(StoneCuttingWorksList)));