import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { addCremationStatement, editCremationStatement, getCremationStatementById } from "../../services/cremationStatement/CremationStatementService";
import CremationStatementForm from "../../components/forms/cremationStatement/CremationStatementForm";
import { getPostalCodesImproved } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import { getStates } from "../../services/state/StateService";

class AddCremationStatement extends FormComponent {

    validationList = {
        fullName: [{ type: Validators.REQUIRED }],
        jmbg: [{ type: Validators.REQUIRED }],
        phoneNumber: [{ type: Validators.REQUIRED }],
        comments: [{ type: Validators.REQUIRED }],
        referenceNumber: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
    }

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });
    }

    handleTextInputChange = (e) => {
        if (e.target.name === "state" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.auth.user.company.id,
                sort: "name,ASC",
                userId: 0
            };

            {
                data.companyId != undefined &&
                    getStates(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            ...this.state,
                            states: response.data.entities,
                        });
                    });
            }
        }
        else if (e.target.name === "municipality" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.auth.user.company.id,
                sort: 'city',
                userId: 0
            };

            {
                data.companyId != undefined &&
                    getPostalCodesImproved(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            ...this.state,
                            settlements: response.data.entities,
                        });
                    });
            }
        }
    }

    componentDidMount() {
        // getCremationStatementById(this.props.id).then(response => {
        //   this.setState({
        //     data: response.data,
        //     settlements: [response.data.municipality],
        //     states: [response.data.state]
        //   });
        // });
    }

    submit() {

        if (!this.validate()) {
            return;
        }

        this.showDrawerLoader();

        addCremationStatement(this.transformRequest(this.state.data)).then(response => {
            if (!response.ok) {
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.cremationStatement.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.cremationStatementAdded, { variant: 'success' });
            this.props.onFinish(response.data.cremationStatement);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            companyId: this.props.auth.user.company.id,
            userId: this.props.auth.user.id,
            municipalityId: data.municipality.id,
            stateId: data.state.id,
            id: 0
        }
    }

    handleError(message) {
        // switch (message) {
        //     case "FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST":
        //         this.setError("code", strings.famousCitizen.messages.FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST);
        //         break;
        //     case "FAMOUS_CITIZEN_CODE_CANT_BE_NULL":
        //         this.setError("code", strings.famousCitizen.messages.FAMOUS_CITIZEN_CODE_CANT_BE_NULL);
        //         break;
        //     case "FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL":
        //         this.setError("description", strings.famousCitizen.messages.FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL);
        //         break;
        // }
    }

    render() {
        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.famousCitizen.editPageTitle}</h1>
                </div>

                <Paper className="paper">
                    <CremationStatementForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
                        states={this.state.states == undefined ? [] : this.state.states}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                    />
                </Paper>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddCremationStatement)
    )
);
