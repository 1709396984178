import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getPostalCodes, deletePostalCode } from '../../services/postalCode/PostalCodeService';
import AddPostalCode from './AddPostalCode';
import EditPostalCode from './EditPostalCode';
import ViewPostalCode from './ViewPostalCode';


class PostalCodeList extends TablePage {

  tableDescription = [
    { key: 'code', label: strings.postalCodeList.code, sort: 'code' },
    { key: 'name', label: strings.postalCodeList.name, sort: 'name' },
    { key: 'dateCreated', label: strings.postalCodeList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
        getPostalCodes(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'code', label: strings.postalCodeList.code, sort: 'code' },
      { key: 'name', label: strings.postalCodeList.name, sort: 'name' },
      { key: 'dateCreated', label: strings.postalCodeList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.postalCodeList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddPostalCode cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  renderEditContent() {
    return <EditPostalCode
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      auth={this.props.auth}
    />
  }

  renderViewContent() {

    return <ViewPostalCode id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} auth={this.props.auth} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deletePostalCode(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.postalCodeDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PostalCodeList)));