import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getStoneCuttingWorks(data) {
    return await request('/api/stoneworks/search', data);
}

export async function addStoneCuttingWorks(data, cid) {
    return await request('/api/stoneworks/' + cid, data, HttpMethod.POST);
}

export function getStoneCuttingWorksById(id, userId, companyId) {
    return  request("api/stoneworks/" + id , {userId: userId, companyId: companyId}, HttpMethod.GET);
}

export async function editStoneCuttingWorks(data) {
    return await request("api/stoneworks/" + data.id, data, HttpMethod.PUT);
}

export async function deleteStoneCuttingWorks(id, uid) {
    return await request('/api/stoneworks/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedStoneCuttingWorks(data){
    return await request('/api/stoneworks/deleted', data)
}

export async function restoreStoneCuttingWorks(data) {
    return await request("api/stoneworks/restore/" + data.id, data, HttpMethod.PUT);
}